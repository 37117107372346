import {
  Box,
  Button,
  Divider,
  FormLabel,
  Heading,
  Link,
  Stack,
  Switch,
  Tabs,
  Select,
  Text,
  useColorModeValue,
  useToast,
  Skeleton,
  RadioGroup,
  Radio,
  TabList,
  Tab,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverCloseButton,
  PopoverHeader,
  PopoverBody,
  TabPanels,
  TabPanel,
} from "@chakra-ui/react";

import axios from "axios";
import { FormikErrors, useFormik } from "formik";
import { ReactNode, useEffect, useState } from "react";
import { BiArrowBack } from "react-icons/bi";
import { FormattedMessage, useIntl } from "react-intl";
import ReactQuill from "react-quill";

import { Link as RouterLink, useLocation, useNavigate } from "react-router-dom";

import { PageWrapper } from "../../utils/globalStyle";
import ProductGeneralForm from "../products/Forms/ProductGeneralForm";
import Coupon from "./Coupon";

export type ProductType = {
  PRODUCT_TYPE_ID: string;
  PRODUCT_TYPE_NAME: string;
  PRODUCT_TYPE_STATUS: string;
};

const AddCoupon = () => {
  const location = useLocation();

  const { from } = (location?.state as { from?: string }) || "";

  const [tabIndex, setTabIndex] = useState<number>(0);

  const [actionButtons, setActionButtons] = useState<ReactNode>();

  const getActionButtons = (node?: ReactNode) => {
    setActionButtons(node);
  };

  return (
    <PageWrapper>
      <Box className="page_header">
        <Box className="page_header_title">
   
          <Box className="page_header_title_content">
            <Heading fontSize="2xl">
              <FormattedMessage id={"coupon.create"} />
            </Heading>
          </Box>
        </Box>
        <Stack direction="row">{actionButtons}</Stack>
      </Box>

      <Coupon actionButtons={getActionButtons} />
    </PageWrapper>
  );
};

export default AddCoupon;
