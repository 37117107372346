import {
  Box,
  Heading,
  LinkOverlay,
  useColorModeValue,
  Text,
  IconButton,
  Stack,
} from "@chakra-ui/react";
import { Fragment, useState } from "react";
import { BiChevronDown, BiChevronUp } from "react-icons/bi";
import { useIntl } from "react-intl";

import { Link as RouterLink, useLocation } from "react-router-dom";
import { NavItemProps } from "./NavItem.types";
import { NavItemWrapper } from "./NavItems.styles";

const NavItem: React.FC<NavItemProps> = (props) => {
  const { href, Icon, label, selected, children ,open,onClick} = props;
  const location = useLocation();

  const selectedHeadingColor = useColorModeValue("main.300", "main.200");
  const headingColor = "#FFFFFF";
  const hoverBgColor = "linear(to-r, green.200, pink.500)";
  const intl = useIntl();

  return (
    <NavItemWrapper
      onClick={onClick!}
      ml="11px"
      mt="7.5px"
      w={{ base: "37px", lg: "auto" }}
      position="relative"
      display="flex"
      alignItems="center"
      _hover={{
        borderRadius: { lg: "25px 0px 0px 25px", base: "50px" },
        bgGradient: "linear(to-r, #0093FF,#FF00FF)",
        // backgroundColor: "linear(to-r, #47F3C2,#905AE7)",
        cursor: "pointer",
      }}
      borderRadius={{ lg: "25px 0px 0px 25px", base: "25px" }}
      bgGradient={selected ? "linear(to-r, #0093FF,#FF00FF)" : "#061223"}
    >
      <Heading
        className="navContent"
        size="sm"
        fontWeight="semibold"
        display="flex"
        alignItems="center"
        justifyContent="center"
        w={{ base: "70px", lg: "auto" }}
        p={{
          base: "8px 0px 8px 0px",
          lg: "12px 3px 9px 13px",
        }}
        color={headingColor}
      >
        <LinkOverlay
         
          alignItems={"center"}
          display="flex"
          as={RouterLink}
          to={href}
          state={{ pageName: label, from: location.pathname }}
        >
          {Icon}
          <Text marginLeft="8px" display={{ base: "none", lg: "block" }}>
            {intl.formatMessage({
              id: label,
            })}
          </Text>
      
         
        </LinkOverlay>
      </Heading>

     
    </NavItemWrapper>
  );
};

export default NavItem;
