export const PUBLIC_API_ENDPOINT = "https://api-webcom.dgcaisse.tn/route";
export type UserTokens =
  | {
      refresh: string;
      access: string;
    }
  | undefined;

export type CacheUserDataType = {
  firstName: string;
  lastName: string;
  avatar: string;
  _id: string;
};
// export const ChekToken = () => {
//   const token = getDataFromCache("token", false);
//   const data = {
//     service: "auth",
//     action: "checkToken",
//     data: {
//       token: token,
//     },
//   };
//   axios
//     .post(PUBLIC_API_ENDPOINT, data, {
//       headers: {
//         Accept: "application/json",
//       },
//     })
//     .then((res) => {
//       if (res.status === 200) {
//         console.log(res.data, "res");
//         const resultat = res.data;
//         if (resultat.status == 0) {
//           window.location.href = "/authentication/login";
//         }
//       }
//     });
// };

export const user_data = {
  avatar:
    "https://res.cloudinary.com/dgpjskmv1/image/upload/v1654815498/users/624eea32faa74a79a806f8e4/e2f853f8232acad78bf143c32f2f3a04.webp.webp",
  firstName: "Foulen ",
  lastName: "Ben Foulen",
  _id: "624eea32faa74a79a806f8e4",
};

export type products = {
  __typename?: "Query";
  GetCurrentSessionUserProducts: {
    __typename?: "Products";
    queryCount?: number | null;
    productsCount?: number | null;
    products?: Array<{
      __typename?: "Product";
      _id?: any | null;
      status?: string | null;
      brand?: string | null;
      images?: Array<string> | null;
      name?: string | null;
      slug?: string | null;
      updatedAt?: any | null;
      createdAt?: any | null;
      category?: {
        __typename?: "Category";
        _id?: any | null;
        name?: string | null;
      } | null;
      store?: { __typename?: "Store"; _id?: any | null; name: string } | null;
      options: Array<{ __typename?: "Option"; name?: string | null }>;
      price?: { __typename?: "Price"; price?: number | null } | null;
      inventory?: {
        __typename?: "Inventory";
        _id: any;
        barCode?: string | null;
        createdAt?: any | null;
        informOn?: number | null;
        qunatity?: number | null;
        sellOutOfStock?: boolean | null;
        sku?: string | null;
        trackQunatity?: boolean | null;
        updatedAt?: any | null;
        weightPerUnit?: number | null;
      } | null;
      variations: Array<{
        __typename?: "Variant";
        variantPrice?: number | null;
        updatedAt?: any | null;
        isActive?: boolean | null;
        images?: Array<string> | null;
        createdAt?: any | null;
        _id?: any | null;
        selectedOption?: Array<{
          __typename?: "SelectedOption";
          KEY?: string | null;
          VALUE?: string | null;
        }> | null;
        inventory?: {
          __typename?: "Inventory";
          _id: any;
          createdAt?: any | null;
          barCode?: string | null;
          informOn?: number | null;
          qunatity?: number | null;
          sellOutOfStock?: boolean | null;
          sku?: string | null;
          trackQunatity?: boolean | null;
          updatedAt?: any | null;
          weightPerUnit?: number | null;
        } | null;
      }>;
    }> | null;
  };
};
export type GetAllProductsQuery = {
  __typename?: "Query";
  GetAllProducts: {
    __typename?: "Products";
    productsCount?: number | null;
    queryCount?: number | null;
    products?: Array<{
      __typename?: "Product";
      _id?: any | null;
      status?: string | null;
      brand?: string | null;
      images?: Array<string> | null;
      name?: string | null;
      slug?: string | null;
      updatedAt?: any | null;
      createdAt?: any | null;
      category?: {
        __typename?: "Category";
        _id?: any | null;
        name?: string | null;
      } | null;
      store?: { __typename?: "Store"; _id?: any | null; name: string } | null;
      options: Array<{ __typename?: "Option"; name?: string | null }>;
      price?: { __typename?: "Price"; price?: number | null } | null;
      inventory?: {
        __typename?: "Inventory";
        _id: any;
        barCode?: string | null;
        createdAt?: any | null;
        informOn?: number | null;
        qunatity?: number | null;
        sellOutOfStock?: boolean | null;
        sku?: string | null;
        trackQunatity?: boolean | null;
        updatedAt?: any | null;
        weightPerUnit?: number | null;
      } | null;
      variations: Array<{
        __typename?: "Variant";
        variantPrice?: number | null;
        updatedAt?: any | null;
        isActive?: boolean | null;
        images?: Array<string> | null;
        createdAt?: any | null;
        _id?: any | null;
        selectedOption?: Array<{
          __typename?: "SelectedOption";
          KEY?: string | null;
          VALUE?: string | null;
        }> | null;
        inventory?: {
          __typename?: "Inventory";
          _id: any;
          createdAt?: any | null;
          barCode?: string | null;
          informOn?: number | null;
          qunatity?: number | null;
          sellOutOfStock?: boolean | null;
          sku?: string | null;
          trackQunatity?: boolean | null;
          updatedAt?: any | null;
          weightPerUnit?: number | null;
        } | null;
      }>;
    }> | null;
  };
};
export type Product = {
  __typename?: string;
  _id?: any | null;
  status?: string | null;
  brand?: string | null;
  images?: Array<string> | null;
  name?: string | null;
  slug?: string | null;
  updatedAt?: any | null;
  createdAt?: any | null;
  category?: {
    __typename?: string;
    _id?: any | null;
    name?: string | null;
  } | null;
  store?: { __typename?: string; _id?: any | null; name: string } | null;
  options: Array<{ __typename?: string; name?: string | null }>;
  price?: { __typename?: string; price?: number | null } | null;
  inventory?: {
    __typename?: string;
    _id: any;
    barCode?: string | null;
    createdAt?: any | null;
    informOn?: number | null;
    qunatity?: number | null;
    sellOutOfStock?: boolean | null;
    sku?: string | null;
    trackQunatity?: boolean | null;
    updatedAt?: any | null;
    weightPerUnit?: number | null;
  } | null;
  variations: Array<{
    __typename?: string;
    variantPrice?: number | null;
    updatedAt?: any | null;
    isActive?: boolean | null;
    images?: Array<string> | null;
    createdAt?: any | null;
    _id?: any | null;
    selectedOption?: Array<{
      __typename?: string;
      KEY?: string | null;
      VALUE?: string | null;
    }> | null;
    inventory?: {
      __typename?: string;
      _id: any;
      createdAt?: any | null;
      barCode?: string | null;
      informOn?: number | null;
      qunatity?: number | null;
      sellOutOfStock?: boolean | null;
      sku?: string | null;
      trackQunatity?: boolean | null;
      updatedAt?: any | null;
      weightPerUnit?: number | null;
    } | null;
  }>;
};
export const fakeData = {
  productsCount: 6,
  queryCount: 5,
  __typename: "Products",
  products: [
    {
      brand: "Simfer",
      category: {
        __typename: "Category",
        _id: "62aa084b17087659c20d29e1",
        name: "Chauffage",
      },
      createdAt: "2022-06-15T16:37:45.975Z",
      images: [
        "https://res.cloudinary.com/dgpjskmv1/image/upload/v1655311039/users/629f66d6811a735b840ff5ed/products/128642313_3492253337539730_893114670555521356_o-scaled.jpg.jpg",
      ],
      inventory: {
        barCode: "",
        createdAt: "2022-06-06T14:10:28.240Z",
        informOn: 0,
        qunatity: 10,
        sellOutOfStock: false,
        sku: "WGE5YHU1",
        trackQunatity: false,
        updatedAt: "2022-06-06T14:10:28.240Z",
        weightPerUnit: 0,
        __typename: "Inventory",
        _id: "62aa0ad9811a735b8413bfec",
      },
      name: "Chauffage Électrique À 2 Tubes Florence 1200W",
      options: [],
      price: { __typename: "Price", price: 39 },
      slug: "Chauffage-Electrique-A-2-Tubes-Florence-1200W",
      status: "DECLINED",
      store: {
        __typename: "Store",
        _id: "629f66d6811a735b840ff5ed",
        name: "K&N",
      },
      updatedAt: "2022-08-11T11:33:50.499Z",
      variations: [],
      __typename: "Product",
      _id: "62aa0ad9811a735b8413bfxee",
    },
    {
      brand: "STAR ONE",
      category: {
        __typename: "Category",
        _id: "62aa084b17087659c20d29e1",
        name: "Chauffage",
      },
      createdAt: "2022-06-15T16:34:42.483Z",
      images: [
        "https://res.cloudinary.com/dgpjskmv1/image/upload/v1655310844/users/629f66d6811a735b840ff5ed/products/DSC_8047-scaled.jpg.jpg",
      ],
      inventory: {
        barCode: "",
        createdAt: "2022-06-06T14:10:28.240Z",
        informOn: 0,
        qunatity: 3,
        sellOutOfStock: false,
        sku: "WGE5YHU1",
        trackQunatity: false,
        updatedAt: "2022-06-06T14:10:28.240Z",
        weightPerUnit: 0,
        __typename: "Inventory",
        _id: "62aa0ad9811a735b8413bfec",
      },
      name: "Chauffage À Gaz STAR ONE",
      options: [],
      price: { __typename: "Price", price: 159 },
      slug: "Chauffage-A-Gaz-STAR-ONE",
      status: "DECLINED",
      store: {
        __typename: "Store",
        _id: "629f66d6811a735b840ff5ed",
        name: "K&N",
      },
      updatedAt: "2022-08-12T08:56:28.657Z",
      variations: [],
      __typename: "Product",
      _id: "62aa0a22811a735b8413ba41",
    },
    {
      brand: "Hge",
      category: {
        __typename: "Category",
        _id: "62aa04ec17087659c20d1d59",
        name: "Machine à laver",
      },
      createdAt: "2022-06-15T16:20:00.009Z",
      images: [
        "https://res.cloudinary.com/dgpjskmv1/image/upload/v1655310042/users/629f66d6811a735b840ff5ed/products/machine-a-laver-semi-automatique-12kg-starone.png.png",
      ],
      inventory: {
        barCode: "",
        createdAt: "2022-06-06T14:10:28.240Z",
        informOn: 0,
        qunatity: 10,
        sellOutOfStock: false,
        sku: "WGE5YHU1",
        trackQunatity: false,
        updatedAt: "2022-06-06T14:10:28.240Z",
        weightPerUnit: 0,
        __typename: "Inventory",
        _id: "62aa0ad9811a735b8413bfec",
      },
      name: "MACHINE A LAVER HGE S/AUT",
      options: [],
      price: { __typename: "Price", price: 340 },
      slug: "MACHINE-A-LAVER-HGE-SAUT",
      status: "PUBLISHED",
      store: {
        __typename: "Store",
        _id: "629f66d6811a735b840ff5ed",
        name: "K&N",
      },
      updatedAt: "2022-08-11T11:33:50.499Z",
      variations: [],
      __typename: "Product",
      _id: "62aa06b0811a735b8413b816",
    },
    {
      brand: "FOCUS",
      category: {
        __typename: "Category",
        _id: "62a9f69f17087659c20cef9b",
        name: "Hotte",
      },
      createdAt: "2022-06-15T15:49:15.860Z",
      images: [
        "https://res.cloudinary.com/dgpjskmv1/image/upload/v1655308133/users/629f66d6811a735b840ff5ed/products/hotte.jpg.jpg",
      ],
      inventory: {
        barCode: "",
        createdAt: "2022-06-06T14:10:28.240Z",
        informOn: 0,
        qunatity: 10,
        sellOutOfStock: false,
        sku: "WGE5YHU1",
        trackQunatity: false,
        updatedAt: "2022-06-06T14:10:28.240Z",
        weightPerUnit: 0,
        __typename: "Inventory",
        _id: "62aa0ad9811a735b8413bfec",
      },
      name: "Hotte Pyramidale FOCUS – Noir F605B",
      options: [],
      price: { __typename: "Price", price: 279 },
      slug: "Hotte-Pyramidale-FOCUS-Noir-F605B",
      status: "PUBLISHED",
      store: {
        __typename: "Store",
        _id: "629f66d6811a735b840ff5ed",
        name: "K&N",
      },
      updatedAt: "2022-08-11T11:33:50.499Z",
      variations: [],
      __typename: "Product",
      _id: "62a9ff7b811a735b8413a53b",
    },
    {
      brand: "",
      category: {
        __typename: "Category",
        _id: "62a9f69f17087659c20cef9b",
        name: "Hotte",
      },
      createdAt: "2022-06-15T16:37:45.975Z",
      images: [
        "https://res.cloudinary.com/dgpjskmv1/image/upload/v1655300313/users/629f66d6811a735b840ff5ed/products/unnamed-10.jpg.jpg",
      ],
      inventory: {
        barCode: "",
        createdAt: "2022-06-06T14:10:28.240Z",
        informOn: 0,
        qunatity: 10,
        sellOutOfStock: false,
        sku: "WGE5YHU1",
        trackQunatity: false,
        updatedAt: "2022-06-06T14:10:28.240Z",
        weightPerUnit: 0,
        __typename: "Inventory",
        _id: "62aa0ad9811a735b8413bfec",
      },
      name: "Hotte Casquette F 601X",
      options: [],
      price: { __typename: "Price", price: 178 },
      slug: "Hotte-Casquette-F-601X",
      status: "PUBLISHED",
      store: {
        __typename: "Store",
        _id: "629f66d6811a735b840ff5ed",
        name: "K&N",
      },
      updatedAt: "2022-08-11T11:33:50.499Z",
      variations: [],
      __typename: "Product",
      _id: "62a9fc7b811a735b84139b7c",
    },
  ],
};
export const declinedfakeData = {
  productsCount: 6,
  queryCount: 5,
  __typename: "Products",
  products: [
    {
      brand: "Simfer",
      category: {
        __typename: "Category",
        _id: "62aa084b17087659c20d29e1",
        name: "Chauffage",
      },
      createdAt: "2022-06-15T16:37:45.975Z",
      images: [
        "https://res.cloudinary.com/dgpjskmv1/image/upload/v1655311039/users/629f66d6811a735b840ff5ed/products/128642313_3492253337539730_893114670555521356_o-scaled.jpg.jpg",
      ],
      inventory: {
        barCode: "",
        createdAt: "2022-06-06T14:10:28.240Z",
        informOn: 0,
        qunatity: 10,
        sellOutOfStock: false,
        sku: "WGE5YHU1",
        trackQunatity: false,
        updatedAt: "2022-06-06T14:10:28.240Z",
        weightPerUnit: 0,
        __typename: "Inventory",
        _id: "62aa0ad9811a735b8413bfec",
      },
      name: "Chauffage Électrique À 2 Tubes Florence 1200W",
      options: [],
      price: { __typename: "Price", price: 39 },
      slug: "Chauffage-Electrique-A-2-Tubes-Florence-1200W",
      status: "DECLINED",
      store: {
        __typename: "Store",
        _id: "629f66d6811a735b840ff5ed",
        name: "K&N",
      },
      updatedAt: "2022-08-11T11:33:50.499Z",
      variations: [],
      __typename: "Product",
      _id: "62aa0ad9811a735b8413bfxee",
    },
    {
      brand: "STAR ONE",
      category: {
        __typename: "Category",
        _id: "62aa084b17087659c20d29e1",
        name: "Chauffage",
      },
      createdAt: "2022-06-15T16:34:42.483Z",
      images: [
        "https://res.cloudinary.com/dgpjskmv1/image/upload/v1655310844/users/629f66d6811a735b840ff5ed/products/DSC_8047-scaled.jpg.jpg",
      ],
      inventory: {
        barCode: "",
        createdAt: "2022-06-06T14:10:28.240Z",
        informOn: 0,
        qunatity: 3,
        sellOutOfStock: false,
        sku: "WGE5YHU1",
        trackQunatity: false,
        updatedAt: "2022-06-06T14:10:28.240Z",
        weightPerUnit: 0,
        __typename: "Inventory",
        _id: "62aa0ad9811a735b8413bfec",
      },
      name: "Chauffage À Gaz STAR ONE",
      options: [],
      price: { __typename: "Price", price: 159 },
      slug: "Chauffage-A-Gaz-STAR-ONE",
      status: "DECLINED",
      store: {
        __typename: "Store",
        _id: "629f66d6811a735b840ff5ed",
        name: "K&N",
      },
      updatedAt: "2022-08-12T08:56:28.657Z",
      variations: [],
      __typename: "Product",
      _id: "62aa0a22811a735b8413ba41",
    },
  ],
};

export const publishedfakeData = {
  productsCount: 6,
  queryCount: 5,
  __typename: "Products",
  products: [
    {
      brand: "Hge",
      category: {
        __typename: "Category",
        _id: "62aa04ec17087659c20d1d59",
        name: "Machine à laver",
      },
      createdAt: "2022-06-15T16:20:00.009Z",
      images: [
        "https://res.cloudinary.com/dgpjskmv1/image/upload/v1655310042/users/629f66d6811a735b840ff5ed/products/machine-a-laver-semi-automatique-12kg-starone.png.png",
      ],
      inventory: {
        barCode: "",
        createdAt: "2022-06-06T14:10:28.240Z",
        informOn: 0,
        qunatity: 10,
        sellOutOfStock: false,
        sku: "WGE5YHU1",
        trackQunatity: false,
        updatedAt: "2022-06-06T14:10:28.240Z",
        weightPerUnit: 0,
        __typename: "Inventory",
        _id: "62aa0ad9811a735b8413bfec",
      },
      name: "MACHINE A LAVER HGE S/AUT",
      options: [],
      price: { __typename: "Price", price: 340 },
      slug: "MACHINE-A-LAVER-HGE-SAUT",
      status: "PUBLISHED",
      store: {
        __typename: "Store",
        _id: "629f66d6811a735b840ff5ed",
        name: "K&N",
      },
      updatedAt: "2022-08-11T11:33:50.499Z",
      variations: [],
      __typename: "Product",
      _id: "62aa06b0811a735b8413b816",
    },
    {
      brand: "FOCUS",
      category: {
        __typename: "Category",
        _id: "62a9f69f17087659c20cef9b",
        name: "Hotte",
      },
      createdAt: "2022-06-15T15:49:15.860Z",
      images: [
        "https://res.cloudinary.com/dgpjskmv1/image/upload/v1655308133/users/629f66d6811a735b840ff5ed/products/hotte.jpg.jpg",
      ],
      inventory: {
        barCode: "",
        createdAt: "2022-06-06T14:10:28.240Z",
        informOn: 0,
        qunatity: 10,
        sellOutOfStock: false,
        sku: "WGE5YHU1",
        trackQunatity: false,
        updatedAt: "2022-06-06T14:10:28.240Z",
        weightPerUnit: 0,
        __typename: "Inventory",
        _id: "62aa0ad9811a735b8413bfec",
      },
      name: "Hotte Pyramidale FOCUS – Noir F605B",
      options: [],
      price: { __typename: "Price", price: 279 },
      slug: "Hotte-Pyramidale-FOCUS-Noir-F605B",
      status: "PUBLISHED",
      store: {
        __typename: "Store",
        _id: "629f66d6811a735b840ff5ed",
        name: "K&N",
      },
      updatedAt: "2022-08-11T11:33:50.499Z",
      variations: [],
      __typename: "Product",
      _id: "62a9ff7b811a735b8413a53b",
    },
    {
      brand: "",
      category: {
        __typename: "Category",
        _id: "62a9f69f17087659c20cef9b",
        name: "Hotte",
      },
      createdAt: "2022-06-15T16:37:45.975Z",
      images: [
        "https://res.cloudinary.com/dgpjskmv1/image/upload/v1655300313/users/629f66d6811a735b840ff5ed/products/unnamed-10.jpg.jpg",
      ],
      inventory: {
        barCode: "",
        createdAt: "2022-06-06T14:10:28.240Z",
        informOn: 0,
        qunatity: 10,
        sellOutOfStock: false,
        sku: "WGE5YHU1",
        trackQunatity: false,
        updatedAt: "2022-06-06T14:10:28.240Z",
        weightPerUnit: 0,
        __typename: "Inventory",
        _id: "62aa0ad9811a735b8413bfec",
      },
      name: "Hotte Casquette F 601X",
      options: [],
      price: { __typename: "Price", price: 178 },
      slug: "Hotte-Casquette-F-601X",
      status: "PUBLISHED",
      store: {
        __typename: "Store",
        _id: "629f66d6811a735b840ff5ed",
        name: "K&N",
      },
      updatedAt: "2022-08-11T11:33:50.499Z",
      variations: [],
      __typename: "Product",
      _id: "62a9fc7b811a735b84139b7c",
    },
  ],
};
export type Categories = {
  hasNodes: boolean;
  name: string;
  __typename: string;
  _id: string;
};

export const fakeCategories = [
  {
    hasNodes: true,
    name: "Market",
    __typename: "categorie",
    _id: "628bb64571e5d25c8ddac559",
  },
  {
    hasNodes: true,
    name: "Electromenager",
    __typename: "categorie",
    _id: "628bb68571e5d25c8ddac56f",
  },
  {
    hasNodes: false,
    name: "Sport & accessoires",
    __typename: "categorie",
    _id: "628bb78271e5d25c8ddaca9d",
  },
  {
    hasNodes: false,
    name: "DÉSODORISANTS",
    __typename: "categorie",
    _id: "62a201b517087659c209c24f",
  },
  {
    hasNodes: false,
    name: "Bricolage & jardin",
    __typename: "categorie",
    _id: "628bb76b71e5d25c8ddaca15",
  },
  {
    hasNodes: false,
    name: "Produits pour bébés",
    __typename: "categorie",
    _id: "62a8801617087659c20bf7db",
  },
  {
    hasNodes: false,
    name: "PETIT ÉLECTROMÉNAGER",
    __typename: "categorie",
    _id: "62a201ea17087659c209c3de",
  },
  {
    hasNodes: true,
    name: "Electronique & Bureautique",
    __typename: "categorie",
    _id: "628bb75271e5d25c8ddac995",
  },
  {
    hasNodes: false,
    name: "Cuisine & maison",
    __typename: "categorie",
    _id: "628bb75e71e5d25c8ddac9d4",
  },
  {
    hasNodes: false,
    name: "Le supermarché",
    __typename: "categorie",
    _id: "628bb77771e5d25c8ddaca58",
  },
  {
    hasNodes: false,
    name: "Linge de maison",
    __typename: "categorie",
    _id: "628bb78b71e5d25c8ddacae4",
  },
  {
    hasNodes: false,
    name: "Accessoires voitures",
    __typename: "categorie",
    _id: "628bb79771e5d25c8ddacb2d",
  },
  {
    hasNodes: true,
    __typename: "categorie",
    name: "PARFUMS & CADEAUX",
    _id: "62a868a117087659c20bc318",
  },
  {
    hasNodes: true,
    name: "MODE",
    __typename: "categorie",
    _id: "62a87fd117087659c20bf471",
  },
];
export enum ProductStatusType {
  Archive = "ARCHIVE",
  Declined = "DECLINED",
  Draft = "DRAFT",
  Pending = "PENDING",
  Published = "PUBLISHED",
}
export type GetAllUsers = {
  __typename?: string;
  lastName?: string | null;
  firstName?: string | null;
  email?: string | null;
  _id?: string | null;
  userAddress?: Array<{
    __typename?: string;
    phoneNumber?: {
      __typename?: string;
      number?: string | null;
      prefix?: string | null;
    } | null;
  }> | null;
};
export type GetAllOrders = {
  __typename?: string;
  _id: string;
  createdAt?: string | null;
  paymentStatus?: string | null;
  paymentType?: string | null;
  refId: string;
  status?: string | null;
  totalePrice?: number | null;
  updatedAt?: any | null;
  totaleItems?: number | null;
  user?: {
    __typename?: string;
    lastName?: string | null;
    firstName?: string | null;
    email?: string | null;
    _id?: string | null;
    userAddress?: Array<{
      __typename?: string;
      phoneNumber?: {
        __typename?: string;
        number?: string | null;
        prefix?: string | null;
      } | null;
    }> | null;
  } | null;
  orderNotes?: Array<{
    __typename?: string;
    note?: string | null;
  }> | null;
};
export type GetAllCountries = {
  __typename?: string;
  dialCode?: string | null;
  flag?: string | null;
  isoCode?: string | null;
  name?: string | null;
};
export const countries = [
  {
    dialCode: "+93",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/af.svg",
    isoCode: "AF",
    name: "Afghanistan",
  },
  {
    dialCode: "+358",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/ax.svg",
    isoCode: "AX",
    name: "Aland Islands",
    __typename: "Country",
  },
  {
    dialCode: "+355",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/al.svg",
    isoCode: "AL",
    name: "Albania",
    __typename: "Country",
  },
  {
    dialCode: "+213",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/dz.svg",
    isoCode: "DZ",
    name: "Algeria",
    __typename: "Country",
  },
  {
    dialCode: "+1684",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/as.svg",
    isoCode: "AS",
    name: "American Samoa",
    __typename: "Country",
  },
  {
    dialCode: "+376",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/ad.svg",
    isoCode: "AD",
    name: "Andorra",
    __typename: "Country",
  },
  {
    dialCode: "+244",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/ao.svg",
    isoCode: "AO",
    name: "Angola",
    __typename: "Country",
  },
  {
    dialCode: "+1264",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/ai.svg",
    isoCode: "AI",
    name: "Anguilla",
    __typename: "Country",
  },
  {
    dialCode: "+672",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/aq.svg",
    isoCode: "AQ",
    name: "Antarctica",
    __typename: "Country",
  },
  {
    dialCode: "+1268",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/ag.svg",
    isoCode: "AG",
    name: "Antigua and Barbuda",
    __typename: "Country",
  },
  {
    dialCode: "+54",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/ar.svg",
    isoCode: "AR",
    name: "Argentina",
    __typename: "Country",
  },
];
export const users = [
  {
    __typename: "User",
    lastName: "Belhareth",
    firstName: "Ghasen",
    email: "kasenoj918@jrvps.com",
    _id: "62c41cff64cb26c5707d1d1c",
    userAddress: [
      {
        __typename: "Address",
        phoneNumber: {
          __typename: "PhoneNumber",
          number: "837489723987982",
          prefix: "216",
        },
      },
    ],
  },
  {
    __typename: "User",
    lastName: "blobli",
    firstName: "blablo",
    email: "pacibeg583@giftcv.com",
    _id: "62b1e5d3b3bb38731912a56a",
    userAddress: [
      {
        __typename: "Address",
        phoneNumber: {
          __typename: "PhoneNumber",
          number: "66282929",
          prefix: "66282929",
        },
      },
    ],
  },
  {
    __typename: "User",
    email: "felon89745@dilanfa.com",
    firstName: "nouri",
    lastName: "blablo",
    _id: "629a46fd9d6c4d4c81e4e5e3",
    userAddress: [
      {
        __typename: "Address",
        phoneNumber: {
          __typename: "PhoneNumber",
          number: "50309556",
          prefix: "216",
        },
      },
    ],
  },
  {
    __typename: "User",
    lastName: "wefoc18320",
    firstName: "wefoc18320",
    email: "wefoc18320@nifect.com",
    _id: "629638f49d6c4d4c81e4d845",
    userAddress: [
      { __typename: "PhoneNumber", number: "50309556", prefix: "216" },
    ],
  },
];

export const fakeOrders = {
  __typename: "Orders",
  ordersCount: 0,
  queryCount: 10,
  orders: [
    {
      _id: "62d7c309840660505addd508",
      createdAt: "2022-07-29T11:07:57.783Z",
      orderNotes: [{ __typename: "OrderNote", note: "" }],
      paymentStatus: "PENDING",
      paymentType: "OFFLINE",
      refId: "#PAI61ZOI",
      status: "PENDING",

      __typename: "Order",
      totaleItems: 2,
      totalePrice: 5545,
      updatedAt: "2022-07-29T11:07:57.783Z",
      user: {
        __typename: "User",
        lastName: "Belhareth",
        firstName: "Ghasen",
        email: "kasenoj918@jrvps.com",
        _id: "62c41cff64cb26c5707d1d1c",
        userAddress: [
          {
            __typename: "Address",
            phoneNumber: {
              __typename: "PhoneNumber",
              number: "837489723987982",
              prefix: "216",
            },
          },
        ],
      },
    },
    {
      _id: "62d7c309840660505addd508",
      createdAt: "2022-07-20T08:55:37.689Z",
      orderNotes: [{ __typename: "OrderNote", note: "" }],
      paymentStatus: "PENDING",
      paymentType: "OFFLINE",
      refId: "#LWPP7FIR",
      status: "PENDING",

      __typename: "Order",
      totaleItems: 2,
      totalePrice: 468,
      updatedAt: "2022-07-20T08:55:37.689Z",
      user: {
        __typename: "User",
        lastName: "Belhareth",
        firstName: "Ghasen",
        email: "kasenoj918@jrvps.com",
        _id: "62c41cff64cb26c5707d1d1c",
        userAddress: [
          {
            __typename: "Address",
            phoneNumber: {
              __typename: "PhoneNumber",
              number: "837489723987982",
              prefix: "216",
            },
          },
        ],
      },
    },
    {
      _id: "62cd48cb840660505addd4d6",
      createdAt: "2022-07-12T10:11:23.128Z",
      orderNotes: [{ __typename: "OrderNote", note: "" }],
      paymentStatus: "PENDING",
      paymentType: "OFFLINE",
      refId: "#0B1R3V1X",
      status: "PENDING",

      __typename: "Order",
      totaleItems: 2,
      totalePrice: 151,
      updatedAt: "2022-07-12T10:11:23.128Z",
      user: {
        __typename: "User",
        lastName: "Belhareth",
        firstName: "Ghasen",
        email: "kasenoj918@jrvps.com",
        _id: "62c41cff64cb26c5707d1d1c",
        userAddress: [
          {
            __typename: "Address",
            phoneNumber: {
              __typename: "PhoneNumber",
              number: "837489723987982",
              prefix: "216",
            },
          },
        ],
      },
    },
    {
      _id: "62c59ef12344fd8b49c1e0dc",
      createdAt: "2022-07-06T14:40:49.604Z",
      orderNotes: [{ __typename: "OrderNote", note: "" }],
      paymentStatus: "PENDING",
      paymentType: "OFFLINE",
      refId: "#ZJJ9MAZG",
      status: "PENDING",

      __typename: "Order",
      totaleItems: 2,
      totalePrice: 435,
      updatedAt: "2022-07-06T14:40:49.604Z",
      user: {
        __typename: "User",
        lastName: "Belhareth",
        firstName: "Ghasen",
        email: "kasenoj918@jrvps.com",
        _id: "62c41cff64cb26c5707d1d1c",
        userAddress: [
          {
            __typename: "Address",
            phoneNumber: {
              __typename: "PhoneNumber",
              number: "837489723987982",
              prefix: "216",
            },
          },
        ],
      },
    },
    {
      _id: "62c41d652344fd8b49c1e0b9",
      createdAt: "2022-07-05T11:15:49.039Z",
      orderNotes: [{ __typename: "OrderNote", note: "" }],
      paymentStatus: "PENDING",
      paymentType: "OFFLINE",
      refId: "#4JJ09WLA",
      status: "PENDING",

      __typename: "Order",
      totaleItems: 2,
      totalePrice: 340,
      updatedAt: "2022-07-05T11:15:49.039Z",
      user: {
        __typename: "User",
        lastName: "Belhareth",
        firstName: "Ghasen",
        email: "kasenoj918@jrvps.com",
        _id: "62c41cff64cb26c5707d1d1c",
        userAddress: [
          {
            __typename: "Address",
            phoneNumber: {
              __typename: "PhoneNumber",
              number: "837489723987982",
              prefix: "216",
            },
          },
        ],
      },
    },
    {
      _id: "62bda6252344fd8b49c1e094",
      createdAt: "2022-06-30T13:33:25.023Z",
      orderNotes: [{ __typename: "OrderNote", note: "" }],
      paymentStatus: "PENDING",
      paymentType: "OFFLINE",
      refId: "#93HVE9C8",
      status: "PENDING",

      __typename: "Order",
      totaleItems: 2,
      totalePrice: 234,
      updatedAt: "2022-06-30T13:33:25.023Z",
      user: {
        __typename: "User",
        lastName: "blobli",
        firstName: "blablo",
        email: "pacibeg583@giftcv.com",
        _id: "62b1e5d3b3bb38731912a56a",
        userAddress: [
          {
            __typename: "Address",
            phoneNumber: {
              __typename: "PhoneNumber",
              number: "66282929",
              prefix: "66282929",
            },
          },
        ],
      },
    },
    {
      _id: "62acb0ec575b95adf5b14780",
      createdAt: "2022-06-17T16:50:52.363Z",
      orderNotes: [{ __typename: "OrderNote", note: "" }],
      paymentStatus: "PENDING",
      paymentType: "OFFLINE",
      refId: "#07QS0QML",
      status: "PENDING",

      __typename: "Order",
      totaleItems: 2,
      totalePrice: 2948,
      updatedAt: "2022-06-17T16:50:52.363Z",
      user: {
        __typename: "User",
        email: "felon89745@dilanfa.com",
        firstName: "nouri",
        lastName: "blablo",
        _id: "629a46fd9d6c4d4c81e4e5e3",
        userAddress: [
          {
            __typename: "Address",
            phoneNumber: {
              __typename: "PhoneNumber",
              number: "50309556",
              prefix: "216",
            },
          },
        ],
      },
    },
    {
      _id: "62a8c535575b95adf5b1475c",
      __typename: "Order",
      createdAt: "2022-06-14T17:28:21.651Z",
      orderNotes: [{ __typename: "OrderNote", note: "" }],
      paymentStatus: "PENDING",
      paymentType: "OFFLINE",
      refId: "#0XHLKXN2",
      status: "CONFIRMED",
      totaleItems: 2,
      totalePrice: 175,
      updatedAt: "2022-06-14T17:28:21.651Z",
      user: {
        __typename: "User",
        lastName: "blablo",
        firstName: "nouri",
        email: "felon89745@dilanfa.com",
        _id: "629a46fd9d6c4d4c81e4e5e3",
        userAddress: [
          { __typename: "PhoneNumber", number: "50309556", prefix: "216" },
        ],
      },
    },
    {
      _id: "62a8c361575b95adf5b14750",
      __typename: "Order",
      createdAt: "2022-06-14T17:28:21.651Z",
      orderNotes: [{ __typename: "OrderNote", note: "" }],
      paymentStatus: "PENDING",
      paymentType: "OFFLINE",
      refId: "#AWW6Y4J2",
      status: "PENDING",
      totaleItems: 2,
      totalePrice: 175,
      updatedAt: "2022-06-14T17:28:21.651Z",
      user: {
        __typename: "User",
        lastName: "blablo",
        firstName: "nouri",
        email: "felon89745@dilanfa.com",
        _id: "629a46fd9d6c4d4c81e4e5e3",
        userAddress: [
          { __typename: "PhoneNumber", number: "50309556", prefix: "216" },
        ],
      },
    },
    {
      _id: "6298e4b9121a1526d3f20b5e",
      __typename: "Order",
      createdAt: "2022-06-02T16:26:33.582Z",
      orderNotes: [{ __typename: "OrderNote", note: "" }],
      paymentStatus: "PENDING",
      paymentType: "OFFLINE",
      refId: "#DUSHWXJ1",
      status: "TRANSPORTING",
      totaleItems: 2,
      totalePrice: 280,
      updatedAt: "2022-07-28T15:51:34.001Z",
      user: {
        __typename: "User",
        lastName: "wefoc18320",
        firstName: "wefoc18320",
        email: "wefoc18320@nifect.com",
        _id: "629638f49d6c4d4c81e4d845",
        userAddress: [
          { __typename: "PhoneNumber", number: "50309556", prefix: "216" },
        ],
      },
    },
  ],
};
export const HomePage = {
  name: "xx",
  __typename: "Home",
  _id: "62478a5f967f06d6beba5e81",
  banners: [
    {
      image:
        "https://res.cloudinary.com/dgpjskmv1/image/upload/v1655313420/users/homepage/BLACK_FRIDAY_SPLASH_BANNER_01.jpg.jpg",
      url: "https://www.timelapse-agency.com/",
      __typename: "Banners",
      _id: "62e3b468d9d2dc6a6c29505d",
    },
    {
      image:
        "https://res.cloudinary.com/dgpjskmv1/image/upload/v1655313512/users/homepage/106z_graphic_224_sale_19.jpg.jpg",
      url: "https://www.timelapse-agency.com/",
      __typename: "Banners",
      _id: "62e3b468d9d2dc6a6c29505e",
    },
    {
      image:
        "https://res.cloudinary.com/dgpjskmv1/image/upload/v1655735840/users/homepage/cube-structure-abstract-architecture.jpg.jpg",
      url: "https://dribbble.com/",
      __typename: "Banners",
      _id: "62e3b468d9d2dc6a6c29505f",
    },
  ],
  collections: [
    {
      color: {
        __typename: "Color",
        _id: "62e3b468d9d2dc6a6c29506b",
        hexColor: "#e63838",
        hslaColor: null,
        name: null,
        rgbaColor: null,
      },
      products: [
        {
          brand: "Simfer",
          category: {
            __typename: "Category",
            _id: "62aa084b17087659c20d29e1",
            name: "Chauffage",
          },
          createdAt: "2022-06-15T16:37:45.975Z",
          images: [
            "https://res.cloudinary.com/dgpjskmv1/image/upload/v1655311039/users/629f66d6811a735b840ff5ed/products/128642313_3492253337539730_893114670555521356_o-scaled.jpg.jpg",
          ],
          inventory: {
            barCode: "",
            createdAt: "2022-06-06T14:10:28.240Z",
            informOn: 0,
            qunatity: 10,
            sellOutOfStock: false,
            sku: "WGE5YHU1",
            trackQunatity: false,
            updatedAt: "2022-06-06T14:10:28.240Z",
            weightPerUnit: 0,
            __typename: "Inventory",
            _id: "62aa0ad9811a735b8413bfec",
          },
          name: "Chauffage Électrique À 2 Tubes Florence 1200W",
          options: [],
          price: { __typename: "Price", price: 39 },
          slug: "Chauffage-Electrique-A-2-Tubes-Florence-1200W",
          status: "DECLINED",
          store: {
            __typename: "Store",
            _id: "629f66d6811a735b840ff5ed",
            name: "K&N",
          },
          updatedAt: "2022-08-11T11:33:50.499Z",
          variations: [],
          __typename: "Product",
          _id: "62aa0ad9811a735b8413bfxee",
        },
        {
          brand: "STAR ONE",
          category: {
            __typename: "Category",
            _id: "62aa084b17087659c20d29e1",
            name: "Chauffage",
          },
          createdAt: "2022-06-15T16:34:42.483Z",
          images: [
            "https://res.cloudinary.com/dgpjskmv1/image/upload/v1655310844/users/629f66d6811a735b840ff5ed/products/DSC_8047-scaled.jpg.jpg",
          ],
          inventory: {
            barCode: "",
            createdAt: "2022-06-06T14:10:28.240Z",
            informOn: 0,
            qunatity: 3,
            sellOutOfStock: false,
            sku: "WGE5YHU1",
            trackQunatity: false,
            updatedAt: "2022-06-06T14:10:28.240Z",
            weightPerUnit: 0,
            __typename: "Inventory",
            _id: "62aa0ad9811a735b8413bfec",
          },
          name: "Chauffage À Gaz STAR ONE",
          options: [],
          price: { __typename: "Price", price: 159 },
          slug: "Chauffage-A-Gaz-STAR-ONE",
          status: "DECLINED",
          store: {
            __typename: "Store",
            _id: "629f66d6811a735b840ff5ed",
            name: "K&N",
          },
          updatedAt: "2022-08-12T08:56:28.657Z",
          variations: [],
          __typename: "Product",
          _id: "62aa0a22811a735b8413ba41",
        },
        {
          brand: "Hge",
          category: {
            __typename: "Category",
            _id: "62aa04ec17087659c20d1d59",
            name: "Machine à laver",
          },
          createdAt: "2022-06-15T16:20:00.009Z",
          images: [
            "https://res.cloudinary.com/dgpjskmv1/image/upload/v1655310042/users/629f66d6811a735b840ff5ed/products/machine-a-laver-semi-automatique-12kg-starone.png.png",
          ],
          inventory: {
            barCode: "",
            createdAt: "2022-06-06T14:10:28.240Z",
            informOn: 0,
            qunatity: 10,
            sellOutOfStock: false,
            sku: "WGE5YHU1",
            trackQunatity: false,
            updatedAt: "2022-06-06T14:10:28.240Z",
            weightPerUnit: 0,
            __typename: "Inventory",
            _id: "62aa0ad9811a735b8413bfec",
          },
          name: "MACHINE A LAVER HGE S/AUT",
          options: [],
          price: { __typename: "Price", price: 340 },
          slug: "MACHINE-A-LAVER-HGE-SAUT",
          status: "PUBLISHED",
          store: {
            __typename: "Store",
            _id: "629f66d6811a735b840ff5ed",
            name: "K&N",
          },
          updatedAt: "2022-08-11T11:33:50.499Z",
          variations: [],
          __typename: "Product",
          _id: "62aa06b0811a735b8413b816",
        },
        {
          brand: "FOCUS",
          category: {
            __typename: "Category",
            _id: "62a9f69f17087659c20cef9b",
            name: "Hotte",
          },
          createdAt: "2022-06-15T15:49:15.860Z",
          images: [
            "https://res.cloudinary.com/dgpjskmv1/image/upload/v1655308133/users/629f66d6811a735b840ff5ed/products/hotte.jpg.jpg",
          ],
          inventory: {
            barCode: "",
            createdAt: "2022-06-06T14:10:28.240Z",
            informOn: 0,
            qunatity: 10,
            sellOutOfStock: false,
            sku: "WGE5YHU1",
            trackQunatity: false,
            updatedAt: "2022-06-06T14:10:28.240Z",
            weightPerUnit: 0,
            __typename: "Inventory",
            _id: "62aa0ad9811a735b8413bfec",
          },
          name: "Hotte Pyramidale FOCUS – Noir F605B",
          options: [],
          price: { __typename: "Price", price: 279 },
          slug: "Hotte-Pyramidale-FOCUS-Noir-F605B",
          status: "PUBLISHED",
          store: {
            __typename: "Store",
            _id: "629f66d6811a735b840ff5ed",
            name: "K&N",
          },
          updatedAt: "2022-08-11T11:33:50.499Z",
          variations: [],
          __typename: "Product",
          _id: "62a9ff7b811a735b8413a53b",
        },
        {
          brand: "",
          category: {
            __typename: "Category",
            _id: "62a9f69f17087659c20cef9b",
            name: "Hotte",
          },
          createdAt: "2022-06-15T16:37:45.975Z",
          images: [
            "https://res.cloudinary.com/dgpjskmv1/image/upload/v1655300313/users/629f66d6811a735b840ff5ed/products/unnamed-10.jpg.jpg",
          ],
          inventory: {
            barCode: "",
            createdAt: "2022-06-06T14:10:28.240Z",
            informOn: 0,
            qunatity: 10,
            sellOutOfStock: false,
            sku: "WGE5YHU1",
            trackQunatity: false,
            updatedAt: "2022-06-06T14:10:28.240Z",
            weightPerUnit: 0,
            __typename: "Inventory",
            _id: "62aa0ad9811a735b8413bfec",
          },
          name: "Hotte Casquette F 601X",
          options: [],
          price: { __typename: "Price", price: 178 },
          slug: "Hotte-Casquette-F-601X",
          status: "PUBLISHED",
          store: {
            __typename: "Store",
            _id: "629f66d6811a735b840ff5ed",
            name: "K&N",
          },
          updatedAt: "2022-08-11T11:33:50.499Z",
          variations: [],
          __typename: "Product",
          _id: "62a9fc7b811a735b84139b7c",
        },
      ],
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ulla",
      title: "Nouvelle Collection",
      __typename: "Collection",
      _id: "62e3b468d9d2dc6a6c29506a",
    },

    {
      title: "Collection",
      __typename: "Collection",
      _id: "62e3b468d9d2dc6a6c29506c",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ",
      color: {
        __typename: "Color",
        _id: "62e3b468d9d2dc6a6c29506d",
        hexColor: "#175cb0",
        hslaColor: null,
        name: null,
        rgbaColor: null,
      },
      products: [
        {
          brand: "Simfer",
          category: {
            __typename: "Category",
            _id: "62aa084b17087659c20d29e1",
            name: "Chauffage",
          },
          createdAt: "2022-06-15T16:37:45.975Z",
          images: [
            "https://res.cloudinary.com/dgpjskmv1/image/upload/v1655311039/users/629f66d6811a735b840ff5ed/products/128642313_3492253337539730_893114670555521356_o-scaled.jpg.jpg",
          ],
          inventory: {
            barCode: "",
            createdAt: "2022-06-06T14:10:28.240Z",
            informOn: 0,
            qunatity: 10,
            sellOutOfStock: false,
            sku: "WGE5YHU1",
            trackQunatity: false,
            updatedAt: "2022-06-06T14:10:28.240Z",
            weightPerUnit: 0,
            __typename: "Inventory",
            _id: "62aa0ad9811a735b8413bfec",
          },
          name: "Chauffage Électrique À 2 Tubes Florence 1200W",
          options: [],
          price: { __typename: "Price", price: 39 },
          slug: "Chauffage-Electrique-A-2-Tubes-Florence-1200W",
          status: "DECLINED",
          store: {
            __typename: "Store",
            _id: "629f66d6811a735b840ff5ed",
            name: "K&N",
          },
          updatedAt: "2022-08-11T11:33:50.499Z",
          variations: [],
          __typename: "Product",
          _id: "62aa0ad9811a735b8413bfxee",
        },
        {
          brand: "STAR ONE",
          category: {
            __typename: "Category",
            _id: "62aa084b17087659c20d29e1",
            name: "Chauffage",
          },
          createdAt: "2022-06-15T16:34:42.483Z",
          images: [
            "https://res.cloudinary.com/dgpjskmv1/image/upload/v1655310844/users/629f66d6811a735b840ff5ed/products/DSC_8047-scaled.jpg.jpg",
          ],
          inventory: {
            barCode: "",
            createdAt: "2022-06-06T14:10:28.240Z",
            informOn: 0,
            qunatity: 3,
            sellOutOfStock: false,
            sku: "WGE5YHU1",
            trackQunatity: false,
            updatedAt: "2022-06-06T14:10:28.240Z",
            weightPerUnit: 0,
            __typename: "Inventory",
            _id: "62aa0ad9811a735b8413bfec",
          },
          name: "Chauffage À Gaz STAR ONE",
          options: [],
          price: { __typename: "Price", price: 159 },
          slug: "Chauffage-A-Gaz-STAR-ONE",
          status: "DECLINED",
          store: {
            __typename: "Store",
            _id: "629f66d6811a735b840ff5ed",
            name: "K&N",
          },
          updatedAt: "2022-08-12T08:56:28.657Z",
          variations: [],
          __typename: "Product",
          _id: "62aa0a22811a735b8413ba41",
        },
        {
          brand: "Hge",
          category: {
            __typename: "Category",
            _id: "62aa04ec17087659c20d1d59",
            name: "Machine à laver",
          },
          createdAt: "2022-06-15T16:20:00.009Z",
          images: [
            "https://res.cloudinary.com/dgpjskmv1/image/upload/v1655310042/users/629f66d6811a735b840ff5ed/products/machine-a-laver-semi-automatique-12kg-starone.png.png",
          ],
          inventory: {
            barCode: "",
            createdAt: "2022-06-06T14:10:28.240Z",
            informOn: 0,
            qunatity: 10,
            sellOutOfStock: false,
            sku: "WGE5YHU1",
            trackQunatity: false,
            updatedAt: "2022-06-06T14:10:28.240Z",
            weightPerUnit: 0,
            __typename: "Inventory",
            _id: "62aa0ad9811a735b8413bfec",
          },
          name: "MACHINE A LAVER HGE S/AUT",
          options: [],
          price: { __typename: "Price", price: 340 },
          slug: "MACHINE-A-LAVER-HGE-SAUT",
          status: "PUBLISHED",
          store: {
            __typename: "Store",
            _id: "629f66d6811a735b840ff5ed",
            name: "K&N",
          },
          updatedAt: "2022-08-11T11:33:50.499Z",
          variations: [],
          __typename: "Product",
          _id: "62aa06b0811a735b8413b816",
        },
        {
          brand: "FOCUS",
          category: {
            __typename: "Category",
            _id: "62a9f69f17087659c20cef9b",
            name: "Hotte",
          },
          createdAt: "2022-06-15T15:49:15.860Z",
          images: [
            "https://res.cloudinary.com/dgpjskmv1/image/upload/v1655308133/users/629f66d6811a735b840ff5ed/products/hotte.jpg.jpg",
          ],
          inventory: {
            barCode: "",
            createdAt: "2022-06-06T14:10:28.240Z",
            informOn: 0,
            qunatity: 10,
            sellOutOfStock: false,
            sku: "WGE5YHU1",
            trackQunatity: false,
            updatedAt: "2022-06-06T14:10:28.240Z",
            weightPerUnit: 0,
            __typename: "Inventory",
            _id: "62aa0ad9811a735b8413bfec",
          },
          name: "Hotte Pyramidale FOCUS – Noir F605B",
          options: [],
          price: { __typename: "Price", price: 279 },
          slug: "Hotte-Pyramidale-FOCUS-Noir-F605B",
          status: "PUBLISHED",
          store: {
            __typename: "Store",
            _id: "629f66d6811a735b840ff5ed",
            name: "K&N",
          },
          updatedAt: "2022-08-11T11:33:50.499Z",
          variations: [],
          __typename: "Product",
          _id: "62a9ff7b811a735b8413a53b",
        },
        {
          brand: "",
          category: {
            __typename: "Category",
            _id: "62a9f69f17087659c20cef9b",
            name: "Hotte",
          },
          createdAt: "2022-06-15T16:37:45.975Z",
          images: [
            "https://res.cloudinary.com/dgpjskmv1/image/upload/v1655300313/users/629f66d6811a735b840ff5ed/products/unnamed-10.jpg.jpg",
          ],
          inventory: {
            barCode: "",
            createdAt: "2022-06-06T14:10:28.240Z",
            informOn: 0,
            qunatity: 10,
            sellOutOfStock: false,
            sku: "WGE5YHU1",
            trackQunatity: false,
            updatedAt: "2022-06-06T14:10:28.240Z",
            weightPerUnit: 0,
            __typename: "Inventory",
            _id: "62aa0ad9811a735b8413bfec",
          },
          name: "Hotte Casquette F 601X",
          options: [],
          price: { __typename: "Price", price: 178 },
          slug: "Hotte-Casquette-F-601X",
          status: "PUBLISHED",
          store: {
            __typename: "Store",
            _id: "629f66d6811a735b840ff5ed",
            name: "K&N",
          },
          updatedAt: "2022-08-11T11:33:50.499Z",
          variations: [],
          __typename: "Product",
          _id: "62a9fc7b811a735b84139b7c",
        },
      ],
    },
    {
      title: "saadcsa",
      __typename: "Collection",
      _id: "62e3b468d9d2dc6a6c29506e",
      description: "",
      products: [
        {
          brand: "Simfer",
          category: {
            __typename: "Category",
            _id: "62aa084b17087659c20d29e1",
            name: "Chauffage",
          },
          createdAt: "2022-06-15T16:37:45.975Z",
          images: [
            "https://res.cloudinary.com/dgpjskmv1/image/upload/v1655311039/users/629f66d6811a735b840ff5ed/products/128642313_3492253337539730_893114670555521356_o-scaled.jpg.jpg",
          ],
          inventory: {
            barCode: "",
            createdAt: "2022-06-06T14:10:28.240Z",
            informOn: 0,
            qunatity: 10,
            sellOutOfStock: false,
            sku: "WGE5YHU1",
            trackQunatity: false,
            updatedAt: "2022-06-06T14:10:28.240Z",
            weightPerUnit: 0,
            __typename: "Inventory",
            _id: "62aa0ad9811a735b8413bfec",
          },
          name: "Chauffage Électrique À 2 Tubes Florence 1200W",
          options: [],
          price: { __typename: "Price", price: 39 },
          slug: "Chauffage-Electrique-A-2-Tubes-Florence-1200W",
          status: "DECLINED",
          store: {
            __typename: "Store",
            _id: "629f66d6811a735b840ff5ed",
            name: "K&N",
          },
          updatedAt: "2022-08-11T11:33:50.499Z",
          variations: [],
          __typename: "Product",
          _id: "62aa0ad9811a735b8413bfxee",
        },
        {
          brand: "STAR ONE",
          category: {
            __typename: "Category",
            _id: "62aa084b17087659c20d29e1",
            name: "Chauffage",
          },
          createdAt: "2022-06-15T16:34:42.483Z",
          images: [
            "https://res.cloudinary.com/dgpjskmv1/image/upload/v1655310844/users/629f66d6811a735b840ff5ed/products/DSC_8047-scaled.jpg.jpg",
          ],
          inventory: {
            barCode: "",
            createdAt: "2022-06-06T14:10:28.240Z",
            informOn: 0,
            qunatity: 3,
            sellOutOfStock: false,
            sku: "WGE5YHU1",
            trackQunatity: false,
            updatedAt: "2022-06-06T14:10:28.240Z",
            weightPerUnit: 0,
            __typename: "Inventory",
            _id: "62aa0ad9811a735b8413bfec",
          },
          name: "Chauffage À Gaz STAR ONE",
          options: [],
          price: { __typename: "Price", price: 159 },
          slug: "Chauffage-A-Gaz-STAR-ONE",
          status: "DECLINED",
          store: {
            __typename: "Store",
            _id: "629f66d6811a735b840ff5ed",
            name: "K&N",
          },
          updatedAt: "2022-08-12T08:56:28.657Z",
          variations: [],
          __typename: "Product",
          _id: "62aa0a22811a735b8413ba41",
        },
        {
          brand: "Hge",
          category: {
            __typename: "Category",
            _id: "62aa04ec17087659c20d1d59",
            name: "Machine à laver",
          },
          createdAt: "2022-06-15T16:20:00.009Z",
          images: [
            "https://res.cloudinary.com/dgpjskmv1/image/upload/v1655310042/users/629f66d6811a735b840ff5ed/products/machine-a-laver-semi-automatique-12kg-starone.png.png",
          ],
          inventory: {
            barCode: "",
            createdAt: "2022-06-06T14:10:28.240Z",
            informOn: 0,
            qunatity: 10,
            sellOutOfStock: false,
            sku: "WGE5YHU1",
            trackQunatity: false,
            updatedAt: "2022-06-06T14:10:28.240Z",
            weightPerUnit: 0,
            __typename: "Inventory",
            _id: "62aa0ad9811a735b8413bfec",
          },
          name: "MACHINE A LAVER HGE S/AUT",
          options: [],
          price: { __typename: "Price", price: 340 },
          slug: "MACHINE-A-LAVER-HGE-SAUT",
          status: "PUBLISHED",
          store: {
            __typename: "Store",
            _id: "629f66d6811a735b840ff5ed",
            name: "K&N",
          },
          updatedAt: "2022-08-11T11:33:50.499Z",
          variations: [],
          __typename: "Product",
          _id: "62aa06b0811a735b8413b816",
        },
        {
          brand: "FOCUS",
          category: {
            __typename: "Category",
            _id: "62a9f69f17087659c20cef9b",
            name: "Hotte",
          },
          createdAt: "2022-06-15T15:49:15.860Z",
          images: [
            "https://res.cloudinary.com/dgpjskmv1/image/upload/v1655308133/users/629f66d6811a735b840ff5ed/products/hotte.jpg.jpg",
          ],
          inventory: {
            barCode: "",
            createdAt: "2022-06-06T14:10:28.240Z",
            informOn: 0,
            qunatity: 10,
            sellOutOfStock: false,
            sku: "WGE5YHU1",
            trackQunatity: false,
            updatedAt: "2022-06-06T14:10:28.240Z",
            weightPerUnit: 0,
            __typename: "Inventory",
            _id: "62aa0ad9811a735b8413bfec",
          },
          name: "Hotte Pyramidale FOCUS – Noir F605B",
          options: [],
          price: { __typename: "Price", price: 279 },
          slug: "Hotte-Pyramidale-FOCUS-Noir-F605B",
          status: "PUBLISHED",
          store: {
            __typename: "Store",
            _id: "629f66d6811a735b840ff5ed",
            name: "K&N",
          },
          updatedAt: "2022-08-11T11:33:50.499Z",
          variations: [],
          __typename: "Product",
          _id: "62a9ff7b811a735b8413a53b",
        },
        {
          brand: "",
          category: {
            __typename: "Category",
            _id: "62a9f69f17087659c20cef9b",
            name: "Hotte",
          },
          createdAt: "2022-06-15T16:37:45.975Z",
          images: [
            "https://res.cloudinary.com/dgpjskmv1/image/upload/v1655300313/users/629f66d6811a735b840ff5ed/products/unnamed-10.jpg.jpg",
          ],
          inventory: {
            barCode: "",
            createdAt: "2022-06-06T14:10:28.240Z",
            informOn: 0,
            qunatity: 10,
            sellOutOfStock: false,
            sku: "WGE5YHU1",
            trackQunatity: false,
            updatedAt: "2022-06-06T14:10:28.240Z",
            weightPerUnit: 0,
            __typename: "Inventory",
            _id: "62aa0ad9811a735b8413bfec",
          },
          name: "Hotte Casquette F 601X",
          options: [],
          price: { __typename: "Price", price: 178 },
          slug: "Hotte-Casquette-F-601X",
          status: "PUBLISHED",
          store: {
            __typename: "Store",
            _id: "629f66d6811a735b840ff5ed",
            name: "K&N",
          },
          updatedAt: "2022-08-11T11:33:50.499Z",
          variations: [],
          __typename: "Product",
          _id: "62a9fc7b811a735b84139b7c",
        },
      ],
      color: {
        __typename: "Color",
        _id: "62e3b468d9d2dc6a6c29506f",
        hexColor: "#3997c0",
        hslaColor: null,
        name: null,
        rgbaColor: null,
      },
    },
  ],
  flashSales: [
    {
      description: "",
      endDate: "2022-07-29T15:15:00.000Z",
      title: "test",
      __typename: "FlashSale",
      _id: "62e3b468d9d2dc6a6c295064",

      color: {
        __typename: "Color",
        _id: "62e3b468d9d2dc6a6c295065",
        hexColor: "#f9e000",
        hslaColor: null,
        name: null,
        rgbaColor: null,
      },
      products: [
        {
          brand: "Simfer",
          category: {
            __typename: "Category",
            _id: "62aa084b17087659c20d29e1",
            name: "Chauffage",
          },
          createdAt: "2022-06-15T16:37:45.975Z",
          images: [
            "https://res.cloudinary.com/dgpjskmv1/image/upload/v1655311039/users/629f66d6811a735b840ff5ed/products/128642313_3492253337539730_893114670555521356_o-scaled.jpg.jpg",
          ],
          inventory: {
            barCode: "",
            createdAt: "2022-06-06T14:10:28.240Z",
            informOn: 0,
            qunatity: 10,
            sellOutOfStock: false,
            sku: "WGE5YHU1",
            trackQunatity: false,
            updatedAt: "2022-06-06T14:10:28.240Z",
            weightPerUnit: 0,
            __typename: "Inventory",
            _id: "62aa0ad9811a735b8413bfec",
          },
          name: "Chauffage Électrique À 2 Tubes Florence 1200W",
          options: [],
          price: { __typename: "Price", price: 39 },
          slug: "Chauffage-Electrique-A-2-Tubes-Florence-1200W",
          status: "DECLINED",
          store: {
            __typename: "Store",
            _id: "629f66d6811a735b840ff5ed",
            name: "K&N",
          },
          updatedAt: "2022-08-11T11:33:50.499Z",
          variations: [],
          __typename: "Product",
          _id: "62aa0ad9811a735b8413bfxee",
        },
        {
          brand: "STAR ONE",
          category: {
            __typename: "Category",
            _id: "62aa084b17087659c20d29e1",
            name: "Chauffage",
          },
          createdAt: "2022-06-15T16:34:42.483Z",
          images: [
            "https://res.cloudinary.com/dgpjskmv1/image/upload/v1655310844/users/629f66d6811a735b840ff5ed/products/DSC_8047-scaled.jpg.jpg",
          ],
          inventory: {
            barCode: "",
            createdAt: "2022-06-06T14:10:28.240Z",
            informOn: 0,
            qunatity: 3,
            sellOutOfStock: false,
            sku: "WGE5YHU1",
            trackQunatity: false,
            updatedAt: "2022-06-06T14:10:28.240Z",
            weightPerUnit: 0,
            __typename: "Inventory",
            _id: "62aa0ad9811a735b8413bfec",
          },
          name: "Chauffage À Gaz STAR ONE",
          options: [],
          price: { __typename: "Price", price: 159 },
          slug: "Chauffage-A-Gaz-STAR-ONE",
          status: "DECLINED",
          store: {
            __typename: "Store",
            _id: "629f66d6811a735b840ff5ed",
            name: "K&N",
          },
          updatedAt: "2022-08-12T08:56:28.657Z",
          variations: [],
          __typename: "Product",
          _id: "62aa0a22811a735b8413ba41",
        },
        {
          brand: "Hge",
          category: {
            __typename: "Category",
            _id: "62aa04ec17087659c20d1d59",
            name: "Machine à laver",
          },
          createdAt: "2022-06-15T16:20:00.009Z",
          images: [
            "https://res.cloudinary.com/dgpjskmv1/image/upload/v1655310042/users/629f66d6811a735b840ff5ed/products/machine-a-laver-semi-automatique-12kg-starone.png.png",
          ],
          inventory: {
            barCode: "",
            createdAt: "2022-06-06T14:10:28.240Z",
            informOn: 0,
            qunatity: 10,
            sellOutOfStock: false,
            sku: "WGE5YHU1",
            trackQunatity: false,
            updatedAt: "2022-06-06T14:10:28.240Z",
            weightPerUnit: 0,
            __typename: "Inventory",
            _id: "62aa0ad9811a735b8413bfec",
          },
          name: "MACHINE A LAVER HGE S/AUT",
          options: [],
          price: { __typename: "Price", price: 340 },
          slug: "MACHINE-A-LAVER-HGE-SAUT",
          status: "PUBLISHED",
          store: {
            __typename: "Store",
            _id: "629f66d6811a735b840ff5ed",
            name: "K&N",
          },
          updatedAt: "2022-08-11T11:33:50.499Z",
          variations: [],
          __typename: "Product",
          _id: "62aa06b0811a735b8413b816",
        },
        {
          brand: "FOCUS",
          category: {
            __typename: "Category",
            _id: "62a9f69f17087659c20cef9b",
            name: "Hotte",
          },
          createdAt: "2022-06-15T15:49:15.860Z",
          images: [
            "https://res.cloudinary.com/dgpjskmv1/image/upload/v1655308133/users/629f66d6811a735b840ff5ed/products/hotte.jpg.jpg",
          ],
          inventory: {
            barCode: "",
            createdAt: "2022-06-06T14:10:28.240Z",
            informOn: 0,
            qunatity: 10,
            sellOutOfStock: false,
            sku: "WGE5YHU1",
            trackQunatity: false,
            updatedAt: "2022-06-06T14:10:28.240Z",
            weightPerUnit: 0,
            __typename: "Inventory",
            _id: "62aa0ad9811a735b8413bfec",
          },
          name: "Hotte Pyramidale FOCUS – Noir F605B",
          options: [],
          price: { __typename: "Price", price: 279 },
          slug: "Hotte-Pyramidale-FOCUS-Noir-F605B",
          status: "PUBLISHED",
          store: {
            __typename: "Store",
            _id: "629f66d6811a735b840ff5ed",
            name: "K&N",
          },
          updatedAt: "2022-08-11T11:33:50.499Z",
          variations: [],
          __typename: "Product",
          _id: "62a9ff7b811a735b8413a53b",
        },
        {
          brand: "",
          category: {
            __typename: "Category",
            _id: "62a9f69f17087659c20cef9b",
            name: "Hotte",
          },
          createdAt: "2022-06-15T16:37:45.975Z",
          images: [
            "https://res.cloudinary.com/dgpjskmv1/image/upload/v1655300313/users/629f66d6811a735b840ff5ed/products/unnamed-10.jpg.jpg",
          ],
          inventory: {
            barCode: "",
            createdAt: "2022-06-06T14:10:28.240Z",
            informOn: 0,
            qunatity: 10,
            sellOutOfStock: false,
            sku: "WGE5YHU1",
            trackQunatity: false,
            updatedAt: "2022-06-06T14:10:28.240Z",
            weightPerUnit: 0,
            __typename: "Inventory",
            _id: "62aa0ad9811a735b8413bfec",
          },
          name: "Hotte Casquette F 601X",
          options: [],
          price: { __typename: "Price", price: 178 },
          slug: "Hotte-Casquette-F-601X",
          status: "PUBLISHED",
          store: {
            __typename: "Store",
            _id: "629f66d6811a735b840ff5ed",
            name: "K&N",
          },
          updatedAt: "2022-08-11T11:33:50.499Z",
          variations: [],
          __typename: "Product",
          _id: "62a9fc7b811a735b84139b7c",
        },
      ],
    },
    {
      products: [
        {
          brand: "Simfer",
          category: {
            __typename: "Category",
            _id: "62aa084b17087659c20d29e1",
            name: "Chauffage",
          },
          createdAt: "2022-06-15T16:37:45.975Z",
          images: [
            "https://res.cloudinary.com/dgpjskmv1/image/upload/v1655311039/users/629f66d6811a735b840ff5ed/products/128642313_3492253337539730_893114670555521356_o-scaled.jpg.jpg",
          ],
          inventory: {
            barCode: "",
            createdAt: "2022-06-06T14:10:28.240Z",
            informOn: 0,
            qunatity: 10,
            sellOutOfStock: false,
            sku: "WGE5YHU1",
            trackQunatity: false,
            updatedAt: "2022-06-06T14:10:28.240Z",
            weightPerUnit: 0,
            __typename: "Inventory",
            _id: "62aa0ad9811a735b8413bfec",
          },
          name: "Chauffage Électrique À 2 Tubes Florence 1200W",
          options: [],
          price: { __typename: "Price", price: 39 },
          slug: "Chauffage-Electrique-A-2-Tubes-Florence-1200W",
          status: "DECLINED",
          store: {
            __typename: "Store",
            _id: "629f66d6811a735b840ff5ed",
            name: "K&N",
          },
          updatedAt: "2022-08-11T11:33:50.499Z",
          variations: [],
          __typename: "Product",
          _id: "62aa0ad9811a735b8413bfxee",
        },
        {
          brand: "STAR ONE",
          category: {
            __typename: "Category",
            _id: "62aa084b17087659c20d29e1",
            name: "Chauffage",
          },
          createdAt: "2022-06-15T16:34:42.483Z",
          images: [
            "https://res.cloudinary.com/dgpjskmv1/image/upload/v1655310844/users/629f66d6811a735b840ff5ed/products/DSC_8047-scaled.jpg.jpg",
          ],
          inventory: {
            barCode: "",
            createdAt: "2022-06-06T14:10:28.240Z",
            informOn: 0,
            qunatity: 3,
            sellOutOfStock: false,
            sku: "WGE5YHU1",
            trackQunatity: false,
            updatedAt: "2022-06-06T14:10:28.240Z",
            weightPerUnit: 0,
            __typename: "Inventory",
            _id: "62aa0ad9811a735b8413bfec",
          },
          name: "Chauffage À Gaz STAR ONE",
          options: [],
          price: { __typename: "Price", price: 159 },
          slug: "Chauffage-A-Gaz-STAR-ONE",
          status: "DECLINED",
          store: {
            __typename: "Store",
            _id: "629f66d6811a735b840ff5ed",
            name: "K&N",
          },
          updatedAt: "2022-08-12T08:56:28.657Z",
          variations: [],
          __typename: "Product",
          _id: "62aa0a22811a735b8413ba41",
        },
        {
          brand: "Hge",
          category: {
            __typename: "Category",
            _id: "62aa04ec17087659c20d1d59",
            name: "Machine à laver",
          },
          createdAt: "2022-06-15T16:20:00.009Z",
          images: [
            "https://res.cloudinary.com/dgpjskmv1/image/upload/v1655310042/users/629f66d6811a735b840ff5ed/products/machine-a-laver-semi-automatique-12kg-starone.png.png",
          ],
          inventory: {
            barCode: "",
            createdAt: "2022-06-06T14:10:28.240Z",
            informOn: 0,
            qunatity: 10,
            sellOutOfStock: false,
            sku: "WGE5YHU1",
            trackQunatity: false,
            updatedAt: "2022-06-06T14:10:28.240Z",
            weightPerUnit: 0,
            __typename: "Inventory",
            _id: "62aa0ad9811a735b8413bfec",
          },
          name: "MACHINE A LAVER HGE S/AUT",
          options: [],
          price: { __typename: "Price", price: 340 },
          slug: "MACHINE-A-LAVER-HGE-SAUT",
          status: "PUBLISHED",
          store: {
            __typename: "Store",
            _id: "629f66d6811a735b840ff5ed",
            name: "K&N",
          },
          updatedAt: "2022-08-11T11:33:50.499Z",
          variations: [],
          __typename: "Product",
          _id: "62aa06b0811a735b8413b816",
        },
        {
          brand: "FOCUS",
          category: {
            __typename: "Category",
            _id: "62a9f69f17087659c20cef9b",
            name: "Hotte",
          },
          createdAt: "2022-06-15T15:49:15.860Z",
          images: [
            "https://res.cloudinary.com/dgpjskmv1/image/upload/v1655308133/users/629f66d6811a735b840ff5ed/products/hotte.jpg.jpg",
          ],
          inventory: {
            barCode: "",
            createdAt: "2022-06-06T14:10:28.240Z",
            informOn: 0,
            qunatity: 10,
            sellOutOfStock: false,
            sku: "WGE5YHU1",
            trackQunatity: false,
            updatedAt: "2022-06-06T14:10:28.240Z",
            weightPerUnit: 0,
            __typename: "Inventory",
            _id: "62aa0ad9811a735b8413bfec",
          },
          name: "Hotte Pyramidale FOCUS – Noir F605B",
          options: [],
          price: { __typename: "Price", price: 279 },
          slug: "Hotte-Pyramidale-FOCUS-Noir-F605B",
          status: "PUBLISHED",
          store: {
            __typename: "Store",
            _id: "629f66d6811a735b840ff5ed",
            name: "K&N",
          },
          updatedAt: "2022-08-11T11:33:50.499Z",
          variations: [],
          __typename: "Product",
          _id: "62a9ff7b811a735b8413a53b",
        },
        {
          brand: "",
          category: {
            __typename: "Category",
            _id: "62a9f69f17087659c20cef9b",
            name: "Hotte",
          },
          createdAt: "2022-06-15T16:37:45.975Z",
          images: [
            "https://res.cloudinary.com/dgpjskmv1/image/upload/v1655300313/users/629f66d6811a735b840ff5ed/products/unnamed-10.jpg.jpg",
          ],
          inventory: {
            barCode: "",
            createdAt: "2022-06-06T14:10:28.240Z",
            informOn: 0,
            qunatity: 10,
            sellOutOfStock: false,
            sku: "WGE5YHU1",
            trackQunatity: false,
            updatedAt: "2022-06-06T14:10:28.240Z",
            weightPerUnit: 0,
            __typename: "Inventory",
            _id: "62aa0ad9811a735b8413bfec",
          },
          name: "Hotte Casquette F 601X",
          options: [],
          price: { __typename: "Price", price: 178 },
          slug: "Hotte-Casquette-F-601X",
          status: "PUBLISHED",
          store: {
            __typename: "Store",
            _id: "629f66d6811a735b840ff5ed",
            name: "K&N",
          },
          updatedAt: "2022-08-11T11:33:50.499Z",
          variations: [],
          __typename: "Product",
          _id: "62a9fc7b811a735b84139b7c",
        },
      ],
      color: {
        __typename: "Color",
        _id: "62e3b468d9d2dc6a6c295067",
        hexColor: "#f745bb",
        hslaColor: null,
        name: null,
        rgbaColor: null,
      },
      description: "",
      endDate: "2022-08-03T23:00:00.000Z",
      title: "Summer flash sales",
      __typename: "FlashSale",
      _id: "62e3b468d9d2dc6a6c295066",
    },
    {
      color: {
        __typename: "Color",
        _id: "62e3b468d9d2dc6a6c295069",
        hexColor: "#ffffff",
        hslaColor: null,
        name: null,
        rgbaColor: null,
      },
      products: [],
      description: "",
      endDate: null,
      title: "",
      __typename: "FlashSale",
      _id: "62e3b468d9d2dc6a6c295068",
    },
  ],
  seo: {
    keywords: null,
    metadescription:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam",
    seotitle: "ELMALL",
    __typename: "Seo",
    _id: "62478a5f967f06d6beba5e80",
    metatags: [
      {
        __typename: "MetaTags",
        _id: "62e3b468d9d2dc6a6c29505c",
        key: "Marketpalce",
        value: "vente",
      },
    ],
  },
  slides: [
    {
      __typename: "Slides",
      _id: "62e3b468d9d2dc6a6c295060",
      description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
      image:
        "https://res.cloudinary.com/dgpjskmv1/image/upload/v1655169268/users/homepage/wal1.jpg.jpg",
      showOverlay: true,
      title: "Promo été",
      action: { __typename: "Action", to: "" },
    },
    {
      action: { __typename: "Action", to: "" },
      description: "Lorem ipsum dolor sit fugiat nulla pariatur. ",
      image:
        "https://res.cloudinary.com/dgpjskmv1/image/upload/v1655169172/users/homepage/wal2.webp.webp",
      showOverlay: true,
      title: "New collection",
      __typename: "Slides",
      _id: "62e3b468d9d2dc6a6c295062",
    },
  ],
};
