import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Avatar,
  Box,
  Button,
  ButtonGroup,
  Card,
  CardBody,
  CardFooter,
  Divider,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  IconButton,
  Image,
  Link,
  Menu,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Skeleton,
  Stack,
  Switch,
  Text,
  Textarea,
  Tooltip,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { FormikErrors, useFormik } from "formik";
import { linkSync } from "fs";
import { useEffect, useMemo, useState } from "react";
import {
  BiArrowBack,
  BiBlock,
  BiChevronLeft,
  BiChevronRight,
  BiEdit,
  BiFilterAlt,
  BiLinkExternal,
  BiPlus,
  BiSearch,
  BiTrash,
} from "react-icons/bi";
import {IoIosArrowDropdown} from "react-icons/io"

import { FormattedMessage, useIntl } from "react-intl";
import ReactQuill from "react-quill";
import { useNavigate, Link as RouterLink, useLocation } from "react-router-dom";
import {
  BlogsDataType,
  useBlogsCreateOne,
  useBlogsFindAll,
  useBlogsDeleteOne,
  useBlogsUpdateOne,
} from "../../api/blogs";
import {
  socialLinksDataType,
  SocialLinksSectionsDataType,
  useDeleteOneSocialLinksSections,
  useSocialLinksCreateOne,
  useSocialLinksSectionsCreateOne,
  useSocialLinksSectionsFindAll,
  useUpdateSocialLinks,
  useUpdateSocialLinksSections,
} from "../../api/socialLinks";
import { ImageUpload, Input } from "../../components/commons";
import ConfirmDeleteDialog from "../../components/modules/ConfirmDeleteDialog/ConfirmDeleteDialog";
import { PaginationWrapper } from "../../components/modules/Table/Table.styles";
import { PageWrapper } from "../../utils/globalStyle";

type SectionsProps = {
  section: SocialLinksSectionsDataType;
  key: number | undefined;
  loading: boolean;
};
type CreateBlogModalProps = {
  onClose: () => void;

  onSubmit: () => void;
  selectedSection?: SocialLinksSectionsDataType;
  action: string;
};
type UpdateBlogModalProps = {
  onClose: () => void;

  onSubmit: () => void;
  selectedBlog?: SocialLinksSectionsDataType;
};

const SocialLinks = () => {
  const {
    mutate: SectionsFindAllMutate,
    data: SectionsFindAllData,
    isLoading: SectionsFindAllIsLoading,
    isSuccess: SectionsFindAllIsSuccess,
    isError: SectionsFindAllIsError,
    error: SectionsFindAllError,
  } = useSocialLinksSectionsFindAll();
  const [selectedSection, setSelectedSectselectedSection] =
    useState<SocialLinksSectionsDataType>();
  const [action, setAction] = useState("Create");
  const SectionItem: React.FC<SectionsProps> = ({ section, key, loading }) => {
    return key === undefined ? (
      <Skeleton isLoaded={!loading} mt={4} opacity={loading ? 0.4 : 1}>
        <AccordionItem key={section._id} border="0px solid red !important">
          {({ isExpanded }) => (
            <>
              <h2>
                <Stack
                  direction={{ sm: "row", base: "column" }}
                  // spacing="14px"
                >
                  {" "}
                  <AccordionButton>
                    <Box flex="1" textAlign="left" >
                      <Heading size="md">{section.section}</Heading>
                    </Box>

                    <AccordionIcon />
                  </AccordionButton>
                  <ConfirmDeleteDialog
                    item="section"
                    dialogButtonProps={{
                      type: "IconButton",

                      children: (
                        <IconButton
                    aria-label="delete section"
                    size="sm"
                    variant="outline"
                    colorScheme="red"
                  
                  >
                    <BiBlock size={14} />
                  </IconButton>
                      ),
                      props: {
                        variant: "ghost",
                        size: "sm",
                      },
                    }}
                    onDelete={() => {
                      console.log(
                        "count",
                        SectionsFindAllData?.data.linksCount === 1,
                        pagination.skip - pagination.limit
                      );
                      if (SectionsFindAllData?.data.linksCount === 1) {
                        setPagination((prevState) => {
                          let temp = { ...prevState };
                          temp.skip = temp.skip - temp.limit;
                          return temp;
                        });
                      }
                      DeleteOneSocialLinksSectionsMutate(section._id);
                      SectionsFindAllMutate({
                        skip:
                          SectionsFindAllData?.data.linksCount === 1
                            ? pagination.skip - pagination.limit
                            : pagination.skip,
                        limit: pagination.limit,
                        search: search,

                        createdAt: sortByCreated,
                        updatedAt: sortByUpdated,
                      });
                    }}
                  />
                  
                  <Tooltip label={intl.formatMessage({
        id: "section.update",
      })} aria-label="A tooltip">
                    <IconButton
                      aria-label={intl.formatMessage({
                        id: "section.update",
                      })}
                      size="sm"
                      variant="outline"
                      colorScheme="whatsapp"
                      onClick={() => {
                        setSelectedSectselectedSection(section);
                        setAction("Update");
                        onOpenOptionModal();
                        // setSelectedBlog(blog);
                        // onOpenBlogModal();
                      }}
                    >
                      <BiEdit size={16} />
                    </IconButton>
                  </Tooltip>
                </Stack>
              </h2>
              <AccordionPanel p={5}>
                <FormLabel>Social Links</FormLabel>
                {section.socialLinks.map((link, index) => (
                  <Skeleton
                    isLoaded={!loading}
                    mt={4}
                    opacity={loading ? 0.4 : 1}
                  >
                    <Stack
                      direction="row"
                      //   justifyContent="space-between"
                      alignItems="center"
                    >
                      <Avatar size="md" name={link.name} src={link.icon} />
                      <Link href={link.url} isExternal>
                        {link.name}
                        <BiLinkExternal size={14} />
                      </Link>
                    </Stack>
                  </Skeleton>
                ))}
              </AccordionPanel>
            </>
          )}
        </AccordionItem>
      </Skeleton>
    ) : (
      <Box></Box>
    );
  };
  const location = useLocation();
  const intl = useIntl();
  const navigate = useNavigate();
  const [sections, setSections] = useState<SocialLinksSectionsDataType[]>([]);

  const {
    mutate: DeleteOneSocialLinksSectionsMutate,
    data: DeleteOneSocialLinksSectionsData,
    isLoading: DeleteOneSocialLinksSectionsIsLoading,
    isSuccess: DeleteOneSocialLinksSectionsIsSuccess,
    isError: DeleteOneSocialLinksSectionsIsError,
    error: DeleteOneSocialLinksSectionsError,
  } = useDeleteOneSocialLinksSections();

  useEffect(() => {
    if (DeleteOneSocialLinksSectionsIsSuccess) {
      toast({
        title: "Section supprimée avec succès",
        status: "success",
        position: `bottom-right`,

        isClosable: true,
      });
      SectionsFindAllMutate({
        skip: pagination.skip,
        limit: pagination.limit,
        search: search,
        // author:author,
        createdAt: sortByCreated,
        updatedAt: sortByUpdated,
      });
    }
  }, [DeleteOneSocialLinksSectionsIsSuccess]);
  const [search, setSearch] = useState("");
  const [author, setAuthor] = useState("");
  const [sortByCreated, setSortByCreated] = useState<1 | -1>(-1);
  const [sortByUpdated, setSortByUpdated] = useState<1 | -1>(-1);
  const [pagination, setPagination] = useState({
    count: 0,
    limit: 5,
    skip: 0,
    page: 1,
  });
  const {
    isOpen: openFilter,
    onOpen: onOpenFilter,
    onClose: onCloseFilter,
  } = useDisclosure();
  useEffect(() => {
    SectionsFindAllMutate({
      skip: pagination.skip,
      limit: pagination.limit,
      search: search,
      // author:author,
      createdAt: sortByCreated,
      updatedAt: sortByUpdated,
    });
  }, [search, pagination.limit, pagination.skip]);
  useEffect(() => {
    if (SectionsFindAllIsSuccess) {
      // console.log("links", SectionsFindAllData.data.links);
      setSections(SectionsFindAllData.data.links);
      setPagination((prevState) => {
        let temp = { ...prevState };
        temp.count = SectionsFindAllData?.data.queryCount;
        return temp;
      });
    }
  }, [SectionsFindAllIsSuccess]);
  const handleLimitChange = (value: number) => {
    setPagination((prevState) => {
      let temp = { ...prevState };
      temp.limit = value;
      return temp;
    });
  };
  const handlePageChange = (dir: "next" | "prev") => {
    if (dir === "next") {
      setPagination((prevState) => {
        let temp = { ...prevState };
        temp.skip = temp.skip + temp.limit;
        return temp;
      });
    } else {
      setPagination((prevState) => {
        let temp = { ...prevState };
        temp.skip = temp.skip - temp.limit;
        return temp;
      });
    }
  };
  const rows = useMemo(
    () => sections.map((item, index) => ({ render: item, key: index })),
    [sections]
  );
  const { from } = (location?.state as { from?: string }) || "";
  const handleSearchSubmit = (event: any) => {
    if (event.code === "Enter") {
      // console.log(event.target.value);
      setSearch(event.target.value);
      SectionsFindAllMutate({
        skip: pagination.skip,
        limit: pagination.limit,
        search: search,
        // author:author,
        createdAt: sortByCreated,
        updatedAt: sortByUpdated,
      });
    }
  };
  const {
    isOpen: isOpenOptionModal,
    onOpen: onOpenOptionModal,
    onClose: onCloseOptionModal,
  } = useDisclosure();
  const {
    isOpen: isOpenBlogModal,
    onOpen: onOpenBlogModal,
    onClose: onCloseBlogModal,
  } = useDisclosure();
  const toast = useToast();
  return (
    <PageWrapper>
      {" "}
      <Stack
        direction={{ base: "column", md: "row" }}
        spacing="18px"
        className="page_header"
      >
        <Box className="page_header_title">
          <Box className="page_header_title_content">
            <Heading fontSize="2xl">
              <FormattedMessage id={"section.list"} />
            </Heading>
          </Box>
        </Box>

        <Stack direction="row">
          {/* <Button variant='outline'>Export</Button> */}
          <Button colorScheme="main" onClick={() => onOpenOptionModal()}>
            <FormattedMessage id={"section.create"} />
          </Button>
        </Stack>
      </Stack>
      
      <Accordion allowMultiple mb="4">
        {" "}
        {sections &&
          sections.map((section, index) => (
            <SectionItem section={section} key={index} loading={false} />
          ))}
      </Accordion>
      {pagination && rows.length !== 0 && (
        <PaginationWrapper flexDirection={{ base: "column", sm: "row" }}>
          <Box className="rows_perpage">
            <Text><FormattedMessage id="pagination" /></Text>
            <Select
              size="sm"
              onChange={(e) => handleLimitChange(parseInt(e.target.value))}
              value={pagination.limit}
            >
              <option value={5}>5</option>
              <option value={10}>10</option>
              <option value={25}>25</option>
              <option value={50}>50</option>
              <option value={100}>100</option>
            </Select>
          </Box>
          <Box
            marginTop={{ base: "10px", sm: "0px" }}
            className="pagination_action"
          >
            <Text>{`${pagination.skip + 1} - ${
              pagination.skip + pagination.limit >= pagination.count
                ? pagination.count
                : pagination.skip + pagination.limit
            } of ${pagination.count}`}</Text>
            <IconButton
              onClick={() => handlePageChange("prev")}
              disabled={pagination.skip < 1}
              aria-label="previous_button"
              size="sm"
              variant="ghost"
            >
              <BiChevronLeft size={24} />
            </IconButton>
            <IconButton
              onClick={() => handlePageChange("next")}
              aria-label="next_button"
              disabled={pagination.count <= pagination.skip + pagination.limit}
              size="sm"
              variant="ghost"
            >
              <BiChevronRight size={24} />
            </IconButton>
          </Box>
        </PaginationWrapper>
      )}
      <Modal
        isOpen={isOpenOptionModal}
        onClose={onCloseOptionModal}
        isCentered
        size="xl"
        scrollBehavior="inside"
      >
        <ModalOverlay />
        <CreateSectionModal
          selectedSection={action === "Update" ? selectedSection : undefined}
          onClose={() => {
            onCloseOptionModal();
            setAction("Create");
            setSelectedSectselectedSection(undefined);
          }}
          action={action}
          onSubmit={() => {
            SectionsFindAllMutate({
              skip: pagination.skip,
              limit: pagination.limit,
              search: search,
              // author:author,
              createdAt: sortByCreated,
              updatedAt: sortByUpdated,
            });
            setAction("Create");
            setSelectedSectselectedSection(undefined);
          }}
        />
      </Modal>
    </PageWrapper>
  );
};
export default SocialLinks;
const CreateSectionModal = (props: CreateBlogModalProps) => {
  const [files, setFiles] = useState<
    {
      progress: boolean;
      id: string;
      src: File | string | undefined;
    }[]
  >([]);
  const [selectedLink, setSelectedLink] = useState("0");
  const {
    mutate: CreateOneSocialLinksMutate,
    data: CreateOneSocialLinksData,
    isLoading: CreateOneSocialLinksIsLoading,
    isSuccess: CreateOneSocialLinksIsSuccess,
    isError: CreateOneSocialLinksIsError,
    error: CreateOneSocialLinksError,
  } = useSocialLinksCreateOne();
  const {
    mutate: UpdateSocialLinksMutate,
    data: UpdateSocialLinksData,
    isLoading: UpdateSocialLinksIsLoading,
    isSuccess: UpdateSocialLinksIsSuccess,
    isError: UpdateSocialLinksIsError,
    error: UpdateSocialLinksError,
  } = useUpdateSocialLinks();
  const {
    mutate: UpdateSocialLinksSectionsMutate,
    data: UpdateSocialLinksSectionsData,
    isLoading: UpdateSocialLinksSectionsIsLoading,
    isSuccess: UpdateSocialLinksSectionsIsSuccess,
    isError: UpdateSocialLinksSectionsIsError,
    error: UpdateSocialLinksSectionsError,
  } = useUpdateSocialLinksSections();
  const {
    mutate: CreateOneSocialLinksSectionsMutate,
    data: CreateOneSocialLinksSectionsData,
    isLoading: CreateOneSocialLinksSectionsIsLoading,
    isSuccess: CreateOneSocialLinksSectionsIsSuccess,
    isError: CreateOneSocialLinksSectionsIsError,
    error: CreateOneSocialLinksSectionsError,
  } = useSocialLinksSectionsCreateOne();
  const handleCloseModal = () => {
    setFiles([]);

    props.onClose();
  };
  const modules = {
    toolbar: [
      [{ header: "1" }, { header: "2" }, { font: [] }],
      [{ size: [] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link"],
    ],
    clipboard: {
      // toggle to add extra line breaks when pasting HTML:
      matchVisual: false,
    },
  };
  const [action, setAction] = useState(props.action);
  useEffect(() => {
    if (props.selectedSection && action === "Update") {
      SectionFormik.setFieldValue("section", props.selectedSection.section);
      SectionFormik.setFieldValue(
        "socialLinks",
        props.selectedSection.socialLinks.map((e) => e._id)
      );
      setLinks(props.selectedSection.socialLinks);
    } else {
      setAction("Create");
      SectionFormik.setFieldValue("section", "");
      SectionFormik.setFieldValue("socialLinks", []);
      setLinks([]);
    }
  }, [props.selectedSection]);
  useEffect(() => {
    if (props.action === "Create") {
      setAction("Create");
      SectionFormik.setFieldValue("section", "");
      SectionFormik.setFieldValue("socialLinks", []);
      setLinks([]);
    }
  }, [action]);
  const [links, setLinks] = useState<
    {
      name: string;
      url: string;
      isActive: boolean;
      icon: string | null;
      _id: string;
    }[]
  >([]);
  useEffect(() => {
    if (CreateOneSocialLinksIsSuccess) {
      const newElement: {
        name: string;
        url: string;
        isActive: boolean;
        icon: string;
        _id: string;
      } = CreateOneSocialLinksData.data.socialLink;

      SectionFormik.setFieldValue("socialLinks", [
        ...SectionFormik.values.socialLinks,
        newElement._id,
      ]);
      setLinks((prevState) => {
        let temp = [...prevState];
        temp = temp.filter((e) => e._id !== "0");
        temp[temp.length] = newElement;

        return temp;
      });
      SocialLinkFormik.setFieldValue("name", "");
      SocialLinkFormik.setFieldValue("url", "");
      SocialLinkFormik.setFieldValue("isActive", false);
      SocialLinkFormik.setFieldValue("icon", "");
    }
  }, [CreateOneSocialLinksIsSuccess]);
  useEffect(() => {
    if (CreateOneSocialLinksSectionsIsSuccess) {
      toast({
        title: "section creé avec succès",
        status: "success",
        position: `bottom-right`,

        isClosable: true,
      });

      props.onSubmit();
      props.onClose();
    }
  }, [CreateOneSocialLinksSectionsIsSuccess]);
  useEffect(() => {
    if (UpdateSocialLinksSectionsIsSuccess) {
      toast({
        title: "section modifiée avec succès",
        status: "success",
        position: `bottom-right`,

        isClosable: true,
      });

      props.onSubmit();
      props.onClose();
    }
  }, [UpdateSocialLinksSectionsIsSuccess]);
  useEffect(() => {
    if (UpdateSocialLinksIsSuccess) {
      toast({
        title: "links modifiée avec succès",
        status: "success",
        position: `bottom-right`,

        isClosable: true,
      });

      setSelectedLink("00");
    }
  }, [UpdateSocialLinksIsSuccess]);
  const intialSectionValues: {
    section: string;
    socialLinks: string[];
  } = {
    section: "",
    socialLinks: [],
  };
  const toast = useToast();
  const SectionFormik = useFormik({
    validateOnMount: false,
    validateOnBlur: false,
    validateOnChange: false,
    initialValues: intialSectionValues,
    validate: (values) => {
      const errors: FormikErrors<typeof values> = {};
     
      if (!values.section) {
        errors.section = "section name is required";
      }
      return errors;
    },
    onSubmit: (values, { setSubmitting }) => {
      if (
        (links[links.length - 1] ? links[links.length - 1]._id : "1") !== "0"
      ) {
        if (action === "Create") CreateOneSocialLinksSectionsMutate(values);
        else
          UpdateSocialLinksSectionsMutate({
            obj: values,
            id: props.selectedSection ? props.selectedSection?._id : "",
          });
      } else {
        toast({
          title: intl.formatMessage({
            id: "socialLink.condition",
          })
    ,
          status: "warning",
          position: `bottom-right`,

          isClosable: true,
        });
      }
    },
  });
  const SocialLinkFormik = useFormik({
    validateOnMount: false,
    validateOnBlur: false,
    validateOnChange: false,
    initialValues: { name: "", url: "", isActive: false, icon: "" },
    validate: (values) => {
      const errors: FormikErrors<typeof values> = {};
      if (!values.url) {
        errors.url = "url is required";
      }
      if (!values.name) {
        errors.name = "name is required";
      }
      return errors;
    },

    onSubmit: (values, { setSubmitting }) => {
      if (selectedLink === "0") {
        CreateOneSocialLinksMutate(values);
      } else {
        UpdateSocialLinksMutate({ obj: values, id: selectedLink });
      }
    },
  });
  const handleFile = async (file: File, index: number) => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("upload_preset", "ml_default");
    formData.append("public_id", `homepage/${file.name}`);
    setLinks((prevState) => {
      let temp = [...prevState];

      temp[index].icon = null;

      return temp;
    });
    SocialLinkFormik.setFieldValue("icon", null);

    const data = await (
      await fetch("https://api.cloudinary.com/v1_1/dgpjskmv1/upload", {
        method: "POST",
        body: formData,
      })
    ).json();
    setLinks((prevState) => {
      let temp = [...prevState];

      temp[index].icon = data.secure_url;

      return temp;
    });
    SocialLinkFormik.setFieldValue("icon", data.secure_url);
  };
  const handleDelete = (index: number) => {
    setLinks((prevState) => {
      let temp = [...prevState];

      temp[index].icon = null;

      return temp;
    });
    SocialLinkFormik.setFieldValue("icon", null);
  };

  const intl = useIntl();
  useEffect(() => {
    // console.log("selected", selectedLink);
  }, [selectedLink]);

  return (
    <ModalContent>
      <ModalHeader borderBottomWidth="1px">
        {action === "Create"
          ? intl.formatMessage({
              id: "section.create",
            })
          : props.selectedSection && props.selectedSection?.section}
      </ModalHeader>
      <ModalCloseButton onClick={props.onClose} />
      <ModalBody>
        <form id="create-update-variant" onSubmit={SectionFormik.handleSubmit}>
          <Stack spacing="12px" mb={4}>
            <Input
              label={intl.formatMessage({
                id: "section.name",
              })}
              inputProps={{
                placeholder: intl.formatMessage({
                  id: "section.name",
                }),
                name: "section",
                onChange: SectionFormik.handleChange,
                value: SectionFormik.values.section,
              }}
              errorMessage={SectionFormik.errors.section}
              isError={SectionFormik.errors.section !== undefined}
            />
          </Stack>
          <Heading size="sm" fontSize="md" fontWeight="medium">
            <FormattedMessage id={"nav.socialLinks"} />
          </Heading>
          <Stack mt="4" spacing="18px" mb="4">
            {links &&
              links.map((link, index) =>
                selectedLink === link._id ? (
                  // <Skeleton
                  //   isLoaded={false}
                  //   mt={4}
                  // opacity={loading ? 0.4 : 1}
                  // >
                  <Grid
                    key={index}
                    mt="1"
                    mb="1"
                    display="flex"
                    alignContent={"center"}
                    alignItems={"center"}
                    justifyContent="space-between"
                    p="14px 18px"
                    border="1px"
                    borderColor="white"
                    rounded="md"
                    boxShadow="md"
                    transition="0.1s ease-in all"
                    _hover={{
                      // backgroundColor: "gray.50",
                      cursor: "pointer",
                      boxShadow: "0 0 0 1px #0b5eda",
                      borderColor: "#0b5eda",
                    }}
                  >
                    <Box className="formikBox" width="500px">
                      <Box alignItems="center">
                        <Stack
                          direction={{ sm: "row", base: "column" }}
                          spacing="14px"
                        >
                          {" "}
                          <ImageUpload
                            height="150px"
                            width="150px"
                            images={
                              link.icon === null
                                ? []
                                : [
                                    {
                                      progress: false,
                                      id: link._id,
                                      src: link.icon,
                                    },
                                  ]
                            }
                            handleUpload={(file) =>
                              handleFile(
                                file,

                                index
                              )
                            }
                            handleDelete={() => handleDelete(index)}
                            label="Logo"
                          />
                          <Stack direction={"column"} spacing="14px">
                            {/* <Skeleton isLoaded={!userLoading} w="full"> */}
                            <Input
                              label={intl.formatMessage({
                                id: "name",
                              })}
                              errorMessage={SocialLinkFormik.errors.name}
                              isError={
                                SocialLinkFormik.errors.name !== undefined
                              }
                              inputProps={{
                                placeholder: intl.formatMessage({
                                  id: "name",
                                }),
                                name: "name",
                                value: SocialLinkFormik.values.name,
                                onChange: (nom) => {
                                  SocialLinkFormik.setFieldValue(
                                    "name",
                                    nom.target.value
                                  );
                                  setLinks((prevState) => {
                                    let temp = [...prevState];

                                    temp[index].name = nom.target.value;

                                    return temp;
                                  });
                                },
                              }}
                            />

                            {/* </Skeleton> */}
                            {/* <Skeleton isLoaded={!userLoading} w="full"> */}
                            <Input
                              label={intl.formatMessage({
                                id: "url",
                              })}
                              inputProps={{
                                placeholder: intl.formatMessage({
                                  id: "url",
                                }),
                                name: "url",
                                value: SocialLinkFormik.values.url,
                                onChange: (url) => {
                                  SocialLinkFormik.setFieldValue(
                                    "url",
                                    url.target.value
                                  );
                                  setLinks((prevState) => {
                                    let temp = [...prevState];

                                    temp[index].url = url.target.value;

                                    return temp;
                                  });
                                },
                              }}
                              errorMessage={SocialLinkFormik.errors.url}
                              isError={
                                SocialLinkFormik.errors.url !== undefined
                              }
                            />
                            <Stack direction={"row"} spacing="14px">
                              {" "}
                              <FormLabel marginBottom="1">
                                <FormattedMessage id={"active"} />
                              </FormLabel>
                              <Stack direction="row" alignItems="center">
                                <Switch
                                  size="md"
                                  colorScheme="green"
                                  name="isActive"
                                  onChange={(v) => {
                                    SocialLinkFormik.setFieldValue(
                                      "isActive",
                                      v.target.checked
                                    );
                                    setLinks((prevState) => {
                                      let temp = [...prevState];

                                      temp[index].isActive = v.target.checked;

                                      return temp;
                                    });
                                  }}
                                  isChecked={SocialLinkFormik.values.isActive}
                                />
                              </Stack>
                              <Text
                                color={
                                  SocialLinkFormik.values.isActive
                                    ? "green.400"
                                    : undefined
                                }
                              >
                                {SocialLinkFormik.values.isActive
                                  ? intl.formatMessage({
                                    id: "active",
                                  })
                                  : intl.formatMessage({
                                    id:"notActive",
                                  })}
                              </Text>
                            </Stack>
                            {/* </Skeleton> */}
                          </Stack>
                        </Stack>
                      </Box>
                      <Box text-align-last={"end"}>
                        {" "}
                        <Button
                          // type="submit"
                          onClick={() => {
                            SocialLinkFormik.handleSubmit();
                          }}
                          colorScheme="main"
                          isLoading={CreateOneSocialLinksIsLoading}
                        >
                          <FormattedMessage id={"valider"} />
                        </Button>
                        <Button
                          variant="ghost"
                          mr={3}
                          onClick={() => {
                            setSelectedLink("aa");
                          }}
                        >
                          <FormattedMessage id={"discard"} />
                        </Button>
                      </Box>
                    </Box>
                  </Grid>
                ) : (
                  // </Skeleton>

                  <Grid
                    key={index}
                    mt="1"
                    mb="1"
                    display="flex"
                    alignContent={"center"}
                    alignItems={"center"}
                    justifyContent="space-between"
                    p="14px 18px"
                    border="1px"
                    borderColor="white"
                    rounded="md"
                    boxShadow="md"
                    transition="0.1s ease-in all"
                    _hover={{
                      // backgroundColor: "gray.50",
                      cursor: "pointer",
                      boxShadow: "0 0 0 1px #0b5eda",
                      borderColor: "#0b5eda",
                    }}
                  >
                    <GridItem
                      display="flex"
                      flexDirection="row"
                      justifyContent="space-between"
                    >
                      <Avatar
                        size="md"
                        name={link.name}
                        src={link.icon ? link.icon : ""}
                        mr={4}
                      />
                      <GridItem flexDirection="row">
                        <Link href={link.url} isExternal>
                          {link.name}
                        </Link>
                        <BiLinkExternal size={14} />
                      </GridItem>
                    </GridItem>
                    <GridItem
                      display="flex"
                      flexDirection="row"
                      justifyContent="space-between"
                    >
                      <Menu>
                        {" "}
                        <IconButton
                          variant="ghost"
                          colorScheme="red"
                          aria-label="delete_meta-tags"
                          onClick={() => {
                            setLinks((prevState) => {
                              let temp = [...prevState];
                              temp = temp.filter((e) => e._id !== link._id);

                              return temp;
                            });

                            if (link._id !== "0") {
                              SectionFormik.setFieldValue(
                                "socialLinks",
                                SectionFormik.values.socialLinks.filter(
                                  (e) => e !== link._id
                                )
                              );
                            }
                          }}
                        >
                          <BiTrash size="18" />
                        </IconButton>
                        <IoIosArrowDropdown size="24"  style={{
                            alignSelf: "center",
                          }}
                          align-self="center"
                          width="11"
                          height="11"
                          
                          onClick={() => {
                            setSelectedLink(link._id);
                            SocialLinkFormik.setFieldValue("name", link.name);
                            SocialLinkFormik.setFieldValue("url", link.url);
                            SocialLinkFormik.setFieldValue(
                              "isActive",
                              link.isActive
                            );
                            SocialLinkFormik.setFieldValue("icon", link.icon);
                          }}/>
                        {/* <svg
                          style={{
                            alignSelf: "center",
                          }}
                          align-self="center"
                          width="11"
                          height="11"
                          viewBox="0 0 11 11"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          onClick={() => {
                            setSelectedLink(link._id);
                            SocialLinkFormik.setFieldValue("name", link.name);
                            SocialLinkFormik.setFieldValue("url", link.url);
                            SocialLinkFormik.setFieldValue(
                              "isActive",
                              link.isActive
                            );
                            SocialLinkFormik.setFieldValue("icon", link.icon);
                          }}
                        >
                          <circle cx="1.5" cy="1.5" r="1.5" fill="#979797" />
                          <circle cx="5.5" cy="1.5" r="1.5" fill="#979797" />
                          <circle cx="9.5" cy="1.5" r="1.5" fill="#979797" />
                          <circle cx="1.5" cy="5.5" r="1.5" fill="#979797" />
                          <circle cx="5.5" cy="5.5" r="1.5" fill="#979797" />
                          <circle cx="9.5" cy="5.5" r="1.5" fill="#979797" />
                          <circle cx="1.5" cy="9.5" r="1.5" fill="#979797" />
                          <path
                            d="M7 9.5C7 10.3284 6.32843 11 5.5 11C4.67157 11 4 10.3284 4 9.5C4 8.67157 4.67157 8 5.5 8C6.32843 8 7 8.67157 7 9.5Z"
                            fill="#979797"
                          />
                          <circle cx="9.5" cy="9.5" r="1.5" fill="#979797" />
                        </svg> */}
                      </Menu>
                    </GridItem>
                  </Grid>
                )
              )}
            <Button
              onClick={() => {
                if (
                  (links[links.length - 1]
                    ? links[links.length - 1]._id
                    : "1") !== "0"
                ) {
                  setLinks((prevFiles) => [
                    ...prevFiles,
                    {
                      name: "Link Name",
                      url: "",
                      isActive: false,
                      icon: null,
                      _id: "0",
                    },
                  ]);
                  // setSelectedLink("0");
                  SocialLinkFormik.setFieldValue("name", "");
                  SocialLinkFormik.setFieldValue("url", "");
                  SocialLinkFormik.setFieldValue("isActive", false);
                  SocialLinkFormik.setFieldValue("icon", null);
                } else {
                  toast({
                    title:
                      "il faut terminer la création du dernier lien ou le supprimer",
                    status: "warning",
                    position: `bottom-right`,

                    isClosable: true,
                  });
                }
                // SectionFormik.setFieldValue("section", "");
                // SectionFormik.setFieldValue("socialLinks", []);
              }}
              variant="outline"
              leftIcon={<BiPlus />}
              // isFullWidth
            >
              <FormattedMessage id={"link.add"} />
            </Button>
          </Stack>
        </form>
      </ModalBody>

      <ModalFooter borderTopWidth="1px">
        <Button variant="ghost" mr={3} onClick={handleCloseModal}>
          <FormattedMessage id={"discard"} />
        </Button>
        <Button
          type="submit"
          form="create-update-variant"
          colorScheme="main"
          isLoading={CreateOneSocialLinksSectionsIsLoading}
        >
          {action === "Create"
            ? intl.formatMessage({
                id: "section.add",
              })
            : "Modifier la Section"}
        </Button>
      </ModalFooter>
    </ModalContent>
  );
};
