import {
  Box,
  Link,
  Heading,
  Text,
  Stack,
  useColorModeValue,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Spinner,
  Button,
  useToast,
  Divider,
  FormControl,
  FormLabel,
  Textarea,
  Skeleton,
  Select,
  IconButton,
  Wrap,
  WrapItem,
  Flex,
} from "@chakra-ui/react";
import { useFormik } from "formik";
import moment from "moment";
import { useEffect, useState } from "react";
import { AiOutlineEye } from "react-icons/ai";
import {
  BiArrowBack,
  BiChevronDown,
  BiChevronLeft,
  BiChevronRight,
  BiMap,
  BiMoney,
} from "react-icons/bi";
import { RiContactsFill } from "react-icons/ri";
import { FormattedMessage, useIntl } from "react-intl";
import { Link as RouterLink, useLocation, useParams } from "react-router-dom";
import {
  OrdersTypeData,
  PayementTypeData,
  useExportOrderItem,
  useFindAllPayments,
  useFindOneOrder,
  usePatchOneOrder,
  usePreviewOrderItem,
} from "../../../api/Orders";
import { PaginationWrapper } from "../../../components/modules/Table/Table.styles";

import { PageWrapper } from "../../../utils/globalStyle";
import {
  declinedfakeData,
  fakeOrders,
  GetAllOrders,
} from "../../../utils/globalTypes";
type PaymentItemProps = {
  payment: PayementTypeData;
  index: number;
};

const OrderDetails = () => {
  const [pagination, setPagination] = useState({
    count: 0,
    limit: 5,
    skip: 0,
    page: 1,
  });
  const handleLimitChange = (value: number) => {
    setPagination((prevState) => {
      let temp = { ...prevState };
      temp.limit = +value;
      return temp;
    });
  };
  const handlePageChange = (dir: "next" | "prev") => {
    if (dir === "next") {
      setPagination((prevState) => {
        let temp = { ...prevState };
        temp.skip = temp.skip + temp.limit;
        return temp;
      });
    } else {
      setPagination((prevState) => {
        let temp = { ...prevState };
        temp.skip = temp.skip - temp.limit;
        return temp;
      });
    }
  };
  const PaymentItem = (props: PaymentItemProps) => {
    const { payment, index } = props;

    const wrapperBorder = useColorModeValue("gray.200", "gray.600");
    const bgHoverColor = useColorModeValue("gray.50", "gray.700");

    const storeID = "";

    const location = useLocation();
    const intl = useIntl();
    const renderPaymentStatus = (status: string | null | undefined) => {
      switch (status) {
        case "PENDING":
          return {
            text: intl.formatMessage({
              id: "awaitingPayment",
            }),
            color: "yellow",
          };
        case "SUCCESS":
          return {
            text: intl.formatMessage({
              id: "Paid",
            }),
            color: "green",
          };
        case "FAILED":
          return {
            text: intl.formatMessage({
              id: "Payment failed",
            }),
            color: "red",
          };
        case "REFUNDED":
          return {
            text: intl.formatMessage({
              id: "Refunded",
            }),
            color: "blue",
          };
        case "COMPLETED":
          return {
            text: intl.formatMessage({
              id: "Payment completed",
            }),
            color: "green",
          };
        default:
          return {
            text: status,
            color: "black",
          };
      }
    };

    const renderOrderStatus = (status: string) => {
      switch (status) {
        case "PENDING":
          return {
            text: intl.formatMessage({
              id: "awaitingProcessing",
            }),
            color: "blue",
          };
        case "PROCESSING":
          return {
            text: intl.formatMessage({
              id: "processing",
            }),
            color: "blue",
          };
        case "CONFIRMED":
          return {
            text: intl.formatMessage({
              id: "confirmed",
            }),
            color: "green",
          };
        case "READY":
          return {
            text: intl.formatMessage({
              id: "readyDelivery",
            }),
            color: "green",
          };
        case "TRANSPORTING":
          return {
            text: intl.formatMessage({
              id: "transportation",
            }),
            color: "blue",
          };
        case "COMPLETED":
          return {
            text: intl.formatMessage({
              id: "orderCompleted",
            }),
            color: "green",
          };
        case "CANCELED":
          return {
            text: intl.formatMessage({
              id: "orderCanceled",
            }),
            color: "red",
          };
        case "REFUNDED":
          return {
            text: intl.formatMessage({
              id: "orderRefunded",
            }),
            color: "red",
          };
        case "FAILED":
          return {
            text: intl.formatMessage({
              id: "orderFailed",
            }),
            color: "red",
          };
        default:
          return {
            text: status,
            color: "black",
          };
      }
    };
  

    const renderPaymentType = (type: string | null | undefined) => {
      switch (type) {
        case "ONLINE":
          return (
            <>
              <BiMoney fill="#3A85F5" size={18} />
              <Text ml="1" fontWeight="medium">
                Online payment
              </Text>
            </>
          );
        default:
          return (
            <>
              <BiMoney color="#3A85F5" />
              <Text ml="1" fontWeight="medium">
                Cash on delivery
              </Text>
            </>
          );
      }
    };

    const toast = useToast();

    // const [changeOrderStatusMutation, changeOrderStatusParams] =
    //   useUpdateOrderStatusMutation();

    return (
      <Flex
        mb="2"
        // gap={"5"}
        wrap="wrap"
        align={{ base: "flex-start", md: "center" }}
        //justify={{base:"column",md:"row"}}
        direction="row" 
        //justifyContent="space-between"
        m="20px"
        p="0 18px"
        //spacing={{ lg: "50px", base: "20px" }}
        // border="1px"
        // borderColor={wrapperBorder}
        rounded="md"
        boxShadow="none"
        transition="0.1s ease-in all"
        _hover={{
          // backgroundColor: bgHoverColor,
          cursor: "pointer",
          boxShadow: "0 0 0 1px #0b5eda",
          borderColor: "#0b5eda",
        }}
      >
        <Flex
          align={{ base: "flex-start", md: "center" }}
          //justify={{base:"column",md:"row"}}
          direction={{ base: "column", md: "row" }}
          //justifyContent="space-between"
         
          
        >
          {" "}
          <Box
            display="flex"
            mr={3}
            my={2}
            flexDirection="column"
            justifyContent="space-between"
          >
            <Heading size="md" fontWeight="bold" fontSize="lg">
              #{payment?.refId.replaceAll("#", "")}
            </Heading>
           
          </Box>
          <Box mr={3} my={2} alignItems={"center"} gap={"5"} display="flex">
            <Stack spacing="6px">
              <Button
                variant="link"
                height="fit-content"
                colorScheme={renderPaymentStatus(payment?.status).color}
                pointerEvents="none"
              >
                <Heading size="md" fontWeight="semibold" fontSize="lg">
                  {renderPaymentStatus(payment?.status).text}
                </Heading>
              </Button>
            
            </Stack>
            <Box mr={3} my={2} alignItems={"center"}>
              <Button
                variant="link"
                height="fit-content"
                colorScheme={renderPaymentStatus(payment?.status).color}
                pointerEvents="none"
              >
                <Heading size="md" fontWeight="semibold" fontSize="lg">
                  {renderOrderStatus(payment?.status).text}
                </Heading>
              </Button>
            </Box>
          </Box>
          <Box
            alignItems={"center"}
            ml={{ base: "0", md: "auto" }}
            textAlign="right"
          >
            <Stack direction="row" spacing="10px" alignItems="center">
              <Heading size="md" fontWeight="semibold" fontSize="xl">
                {payment?.payedAmount} TND
              </Heading>
            </Stack>
          </Box>
        </Flex>
        <Flex
          align={{ base: "flex-start", md: "center" }}
          //justify={{base:"column",md:"row"}}
          direction={{ base: "column", md: "row" }}
          //justifyContent="space-between"
         
          p="0 18px"
        >
            <Box
            display="flex"
            mr={3}
            my={2}
            flexDirection="column"
            justifyContent="space-between"
          >
          
            <Text opacity={0.6}>
              {" "}
              {moment(payment?.createdAt).format("LLL")}
            </Text>
          </Box>
          <Box mr={3} my={2} alignItems={"center"} gap={"5"} display="flex">
            <Stack spacing="6px">
            
         
              <Box display="flex" alignItems="center">
                {payment.transactionProvider}
              </Box>
            </Stack>
           
          </Box>
        </Flex>
      </Flex>
    );
  };
  useEffect(() => {}, []);
  const {
    data: exportItemData,
    isSuccess: exportIsSuccess,
    isLoading: exportOrderItemLoder,
    mutate: mutateExportOrderItem,
  } = useExportOrderItem();
  const {
    data: previewItemData,
    isSuccess: previewIsSuccess,
    isLoading:previewOrderItemLoder,
    mutate: mutatePreviewOrderItem,
  } = usePreviewOrderItem();
  const {
    mutate: ordersUpdateMutate,
    data: ordersUpdateData,
    isLoading: ordersUpdateIsLoading,
    isSuccess: ordersUpdateIsSuccess,
    isError: ordersUpdateIsError,
    error: ordersUpdateError,
  } = usePatchOneOrder();
  const {
    mutate: paymentsGettAllMutate,
    data: paymentsGettAllData,
    isLoading: paymentsGettAllIsLoading,
    isSuccess: paymentsGettAllIsSuccess,
    isError: paymentsGettAllIsError,
    error: paymentsGettAllError,
  } = useFindAllPayments();
  const { orderID } = useParams();
  const [order, setOrder] = useState<OrdersTypeData>();
  const [payments, setPayments] = useState<PayementTypeData[]>();
  const [exportFile, setExportFile] = useState("");
  const { mutate, data, isLoading, isSuccess, isError, error } =
    useFindOneOrder();
  const wrapperBorder = useColorModeValue("gray.200", "gray.600");
  useEffect(() => {
    mutate(orderID);
  }, []);
  useEffect(() => {
    paymentsGettAllMutate({
      order: orderID ? orderID : "",
      skip: pagination.skip,
      limit: pagination.limit,
    });
  }, [pagination]);
  useEffect(() => {
    if (exportIsSuccess) {
      console.log("exportItemData", exportItemData.data.export);
      setExportFile(exportItemData.data.export);
    }
  }, [exportIsSuccess]);
  useEffect(() => {
    if (isSuccess) {
      setOrder(data.data.order);
    }
  }, [isSuccess]);
  useEffect(() => {
    if (paymentsGettAllIsSuccess) {
      setPayments(paymentsGettAllData.data.payments);
    }
  }, [paymentsGettAllIsSuccess]);
  const renderItemStatus = (status: string | null | undefined) => {
    switch (status) {
      case "READY":
        return {
          text: "Ready",
          color: "green",
        };
      case "NOTREADY":
        return {
          text: "Not ready",
          color: "red",
        };
      default:
        return {
          text: status,
          color: "black",
        };
    }
  };

  const renderPaymentStatus = (status: string | null | undefined) => {
    switch (status) {
      case "PENDING":
        return {
          text: intl.formatMessage({
            id: "awaitingPayment",
          }),
          color: "yellow",
        };
      case "SUCCESS":
        return {
          text: intl.formatMessage({
            id: "Paid",
          }),
          color: "green",
        };
      case "FAILED":
        return {
          text: intl.formatMessage({
            id: "Payment failed",
          }),
          color: "red",
        };
      case "REFUNDED":
        return {
          text: intl.formatMessage({
            id: "Refunded",
          }),
          color: "blue",
        };
      case "COMPLETED":
        return {
          text: intl.formatMessage({
            id: "Payment completed",
          }),
          color: "green",
        };
      default:
        return {
          text: status,
          color: "black",
        };
    }
  };

  const renderOrderStatus = (status: string | null | undefined) => {
    switch (status) {
      case "PENDING":
        return {
          text: intl.formatMessage({
            id: "awaitingProcessing",
          }),
          color: "blue",
        };
      case "PROCESSING":
        return {
          text: intl.formatMessage({
            id: "processing",
          }),
          color: "blue",
        };
      case "CONFIRMED":
        return {
          text: intl.formatMessage({
            id: "confirmed",
          }),
          color: "green",
        };
      case "READY":
        return {
          text: intl.formatMessage({
            id: "readyDelivery",
          }),
          color: "green",
        };
      case "TRANSPORTING":
        return {
          text: intl.formatMessage({
            id: "transportation",
          }),
          color: "blue",
        };
      case "COMPLETED":
        return {
          text: intl.formatMessage({
            id: "orderCompleted",
          }),
          color: "green",
        };
      case "CANCELED":
        return {
          text: intl.formatMessage({
            id: "orderCanceled",
          }),
          color: "red",
        };
      case "REFUNDED":
        return {
          text: intl.formatMessage({
            id: "orderRefunded",
          }),
          color: "red",
        };
      case "FAILED":
        return {
          text: intl.formatMessage({
            id: "orderFailed",
          }),
          color: "red",
        };
      default:
        return {
          text: status,
          color: "black",
        };
    }
  };
  const toast = useToast();

  const handleChangeOrderStatus = (
    orderID: string | undefined,
    status: string
  ) => {
    ordersUpdateMutate({
      catObj: { status: status },
      id: orderID ? orderID : "",
    });
   
      setOrder((prevState) => {
        let temp = prevState;
        if (temp) temp.status = status;

        return temp;
      });
    
  };
  useEffect(() => {
    if (ordersUpdateIsSuccess) {
      toast({
        title: "Order status has bean updates with sucsess",
        position: `bottom-right`,
        status: "success",
        isClosable: true,
      });
      
      
    }
  }, [ordersUpdateIsSuccess]);
  const handleChangeItemStatus = (
    orderId: string | undefined,
    itemId: string,
    status: "READY" | "NOTREADY"
  ) => {
    // changeItemStatusMutation({
    // 	variables: {
    // 		_id: orderID,
    // 		refId: itemId,
    // 		status:
    // 			status === "READY" ? ItemStatusType.Ready : ItemStatusType.Notready,
    // 	},
    // 	onCompleted: (data) => {
    // 		if (data.SetOrderItemStatus._id) {
    // 			toast({
    // 				title: "Item status has been updated with success!",
    // 				status: "success",
    // 				position: "bottom-right",
    // 				duration: 5000,
    // 				isClosable: true,
    // 			});
    // 		}
    // 		apolloClient.refetchQueries({
    // 			include: "active",
    // 		});
    // 	},
    // 	onError: (error) => {
    // 		toast({
    // 			title: "An error occurred while updating Item status.",
    // 			description: error.message,
    // 			status: "error",
    // 			position: "bottom-right",
    // 			duration: 5000,
    // 			isClosable: true,
    // 		});
    // 	},
    // });
  };

  const [activateNote, setActivateNote] = useState<boolean>(false);
  const intl = useIntl();
  return (
    <PageWrapper>
      <Box className="page_header">
        <Box className="page_header_title">
          
          <Box className="page_header_title_content">
            <Heading fontSize="2xl">
              #{order?.refId.replaceAll("#", "")}
            </Heading>
          </Box>
        </Box>
      </Box>
      <Stack
        spacing="18px"
        flexDirection={{ base: "column", lg: "row" }}
        justifyContent="space-between"
      >
        <Stack spacing="18px" width={"-webkit-fill-available"}>
          <Box
            p="18px 24px"
            border="1px"
            borderColor={wrapperBorder}
            rounded="md"
          >
            <Box display="flex" justifyContent="space-between">
              <Heading size="md" fontWeight="medium" fontSize="lg">
                {moment(order?.createdAt).format("LLL")}
              </Heading>

              <Heading size="md" fontWeight="semibold" fontSize="xl">
                {order?.totalePrice} TND
              </Heading>
            </Box>
            <Stack direction="row" spacing="16px" mt="4">
              <Box>
                <Text>
                  <FormattedMessage id={"paymentStatus"} />
                </Text>
                <Button
                  variant="link"
                  pointerEvents="none"
                  height="fit-content"
                  colorScheme={renderPaymentStatus(order?.paymentStatus).color}
                >
                  <Heading size="md" fontWeight="semibold" fontSize="lg">
                    {renderPaymentStatus(order?.paymentStatus).text}
                  </Heading>
                </Button>
              </Box>

              <Box>
                <Text>Status</Text>

                <Menu>
                  <MenuButton
                    as={Button}
                    variant="link"
                    rightIcon={<BiChevronDown size={18} />}
                    height="fit-content"
                    colorScheme={renderOrderStatus(order?.status).color}
                  >
                    <Heading size="md" fontWeight="semibold" fontSize="lg">
                      {renderOrderStatus(order?.status).text}
                    </Heading>
                  </MenuButton>
                  <MenuList>
                    <MenuItem
                      onClick={() =>
                        handleChangeOrderStatus(order?._id, "PENDING")
                      }
                    >
                      {renderOrderStatus("PENDING").text}
                    </MenuItem>
                    <MenuItem
                      onClick={() =>
                        handleChangeOrderStatus(order?._id, "PROCESSING")
                      }
                    >
                      {renderOrderStatus("PROCESSING").text}
                    </MenuItem>
                    <MenuItem
                      onClick={() =>
                        handleChangeOrderStatus(order?._id, "CONFIRMED")
                      }
                    >
                      {renderOrderStatus("CONFIRMED").text}
                    </MenuItem>
                    <MenuItem
                      onClick={() =>
                        handleChangeOrderStatus(order?._id, "READY")
                      }
                    >
                      {renderOrderStatus("READY").text}
                    </MenuItem>
                    <MenuItem
                      onClick={() =>
                        handleChangeOrderStatus(order?._id, "TRANSPORTING")
                      }
                    >
                      {renderOrderStatus("TRANSPORTING").text}
                    </MenuItem>
                    <MenuItem
                      onClick={() =>
                        handleChangeOrderStatus(order?._id, "COMPLETED")
                      }
                    >
                      {renderOrderStatus("COMPLETED").text}
                    </MenuItem>
                    <MenuItem
                      onClick={() =>
                        handleChangeOrderStatus(order?._id, "CANCELED")
                      }
                    >
                      {renderOrderStatus("CANCELED").text}
                    </MenuItem>
                    <MenuItem
                      onClick={() =>
                        handleChangeOrderStatus(order?._id, "REFUNDED")
                      }
                    >
                      {renderOrderStatus("REFUNDED").text}
                    </MenuItem>
                    <MenuItem
                      onClick={() =>
                        handleChangeOrderStatus(order?._id, "FAILED")
                      }
                    >
                      {renderOrderStatus("FAILED").text}
                    </MenuItem>
                  </MenuList>
                </Menu>
              </Box>
            </Stack>
            <Box mt="4">
              {order?.items &&
                order.items.map((item, index) => (
                  <Box
                    key={index}
                    p="12px 16px"
                    border="1px"
                    borderColor={wrapperBorder}
                    rounded="md"
                    display="flex"
                    justifyContent="space-between"
                    mb="3"
                  >
                    <Box display="flex">
                      <Box
                        h="70px"
                        w="70px"
                        rounded="md"
                        objectFit="cover"
                        overflow="hidden"
                        mr="2"
                      >
                        <img
                          src={
                            item.images?.[0] ||
                            "https://www.freeiconspng.com/thumbs/no-image-icon/no-image-icon-4.png"
                          }
                          alt="product_image"
                        />
                      </Box>
                      <Stack spacing="0px">
                        {/* <Text fontSize="sm">
                        Vendor{" "}
                        <Link
                          as={RouterLink}
                          to={`/admin/stores/${item?._id}`}
                          state={{
                            from: location.pathname,
                            pageName: "Stores",
                          }}
                          color="blue.400"
                        >
                          {item?.name}
                        </Link>
                      </Text> */}
                        <Text fontWeight="semibold">
                          {item?.quantity}x {item?.itemName}{" "}
                          {item?.hasCustomfields?(<></>):(  <Link
                            fontSize="xs"
                            fontWeight={"medium"}
                            as={RouterLink}
                            to={`/products/${item.refId}`}
                            // state={{
                            //   from: location.pathname,
                            //   pageName: "Products",
                            // }}
                            color="blue.400"
                          >
                            <FormattedMessage id={"View product"} />
                          </Link>)}
                          
                        
                        </Text>
                        {item?.hasCustomfields && (
                          <Text
                            display="inline-block"
                            fontSize="xs"
                            opacity={0.8}
                          >
                            {/* {item?.customfields?.map(
                            (option, index) =>
                              `${
                                item?.options?.length !== index + 1 ? " / " : ""
                              }`
                          )} */}
                          </Text>
                        )}
                        {/* {item?.?.sku && (
                        <Text
                          display="inline-block"
                          fontSize="xs"
                          opacity={0.8}
                        >
                          SKU: {item?.inventory?.sku}
                        </Text>
                      )} */}
                      </Stack>
                    </Box>
                    <Box textAlign="right">
                      <Heading
                        size="md"
                        fontWeight="semibold"
                        fontSize="lg"
                        mb="4"
                      >
                        {item.itemPrice * item.quantity}
                        <FormattedMessage id={"TND"} />
                      </Heading>
                      <Stack textAlign="end" alignItems={"end"} >
                        {exportOrderItemLoder && item?.hasCustomfields ? (
                          <Spinner  key={item?._id+"1"}/>
                        ) : (
                          item?.hasCustomfields && (
                            <Text
                            as="button"
                            textAlign="end"
                            style={{ color: "blue" }}
                            onClick={() => {
                              mutateExportOrderItem(item);
                            }}
                            _hover={{ textDecoration: "underline" }}
                            fontSize="sm"
                            opacity={0.6}
                          >
                            Export
                          </Text>
                    
                           
                          )
                        )}
                           {previewOrderItemLoder && item?.hasCustomfields ? (
                          <Spinner  key={item?._id+"1"}/>
                        ) : (
                          item?.hasCustomfields && (
                            <Text
                            textAlign="end"
                          as="button"
                          
                          style={{ color: "blue" }}
                          onClick={() => {
                            mutatePreviewOrderItem(item);
                          }}
                          _hover={{ textDecoration: "underline" }}
                          fontSize="sm"
                          opacity={0.6}
                        >
                          Preview
                        </Text>
                    
                           
                          )
                        )}
                        <Text fontSize="sm" opacity={0.6} mt="1.5" textAlign="end">
                          <FormattedMessage id={"availabilityStatus"} />
                        </Text>

                        <Menu>
                        <Text color={renderItemStatus("READY").color}>
                        
                            {renderItemStatus("READY").text}
                          </Text>
                        </Menu>
                      </Stack>
                      
                    </Box>
                  </Box>
                ))}
            </Box>
            <Box mt="4">
              <Divider mt="4" />
              <Box display="flex" justifyContent="space-between">
                <Text size="sm">
                  <FormattedMessage id={"Total items"} />: {order?.totalItems}
                </Text>

                <Stack direction="row">
                  <Text fontWeight="medium" size="sm" mr="6">
                    <FormattedMessage id={"Total to pay"} />
                  </Text>
                  <Text fontWeight="medium" size="sm">
                    {order?.totalePrice} <FormattedMessage id={"TND"} />
                  </Text>
                </Stack>
              </Box>
            </Box>
          </Box>
          <Box
            p="18px 24px"
            border="1px"
            borderColor={wrapperBorder}
            rounded="md"
          >
            <Box display="flex" justifyContent="space-between">
              <Heading size="2xl" fontWeight="medium" fontSize="xl">
                <FormattedMessage id={"transactions"} />
              </Heading>
            </Box>
            <Box width="100%" overflowY="scroll">
              <Box>
                {payments?.map((payment, index) => (
                  <PaymentItem payment={payment} key={index} index={index} />
                ))}
              </Box>
              {payments && (
                <PaginationWrapper>
                  <Box className="rows_perpage">
                    <Text>
                      <FormattedMessage id={"pagination"} />
                    </Text>
                    <Select
                      size="sm"
                      onChange={(e) =>
                        handleLimitChange(parseInt(e.target.value))
                      }
                      value={pagination.limit}
                    >
                      <option value={5}>5</option>
                      <option value={10}>10</option>
                      <option value={25}>25</option>
                      <option value={50}>50</option>
                      <option value={100}>100</option>
                    </Select>
                  </Box>
                  <Box className="pagination_action">
                    <Text>{`${pagination.skip + 1} - ${
                      pagination.skip + pagination.limit >
                      (paymentsGettAllData?.data.queryCount || 0)
                        ? paymentsGettAllData?.data.queryCount || 0
                        : pagination.skip + pagination.limit
                    } of ${paymentsGettAllData?.data.queryCount || 0}`}</Text>
                    <IconButton
                      onClick={() => handlePageChange("prev")}
                      disabled={pagination.skip < 1}
                      aria-label="previous_button"
                      size="sm"
                      variant="ghost"
                    >
                      <BiChevronLeft size={24} />
                    </IconButton>
                    <IconButton
                      onClick={() => handlePageChange("next")}
                      aria-label="next_button"
                      disabled={
                        (paymentsGettAllData?.data.queryCount || 0) <
                        pagination.skip + pagination.limit
                      }
                      size="sm"
                      variant="ghost"
                    >
                      <BiChevronRight size={24} />
                    </IconButton>
                  </Box>
                </PaginationWrapper>
              )}
            </Box>
          </Box>
        </Stack>
        <Stack
          p="18px 24px"
          maxW={"500px"}
          border="1px"
          borderColor={wrapperBorder}
          rounded="md"
          w="full"
          mt={"0px !important"}
          height="fit-content"
        >
          <Stack>
         
          {order?.user ? (
            <Box> <Heading size="sm" fontWeight="semibold" display="flex" gap="4px">
            <RiContactsFill fill="#3A85F5" size={18} />{" "}
            <FormattedMessage id={"user"} />
          </Heading>
          <Box  mr={3} my={2} alignItems={"center"} gap={"5"}  display="flex" >
           
           <Box>
             <Link
               color="blue.300"
               target="_blank"
               href={`/users/${order?.user._id ? order?.user._id : ""}`}
             >
               <Stack direction="row" alignItems="center" spacing="4px">
                 <Text whiteSpace="nowrap">{order?.user.fullName}</Text>{" "}
                 <AiOutlineEye size={16} />
               </Stack>
             </Link>
           </Box>
        
       </Box>
       <Heading size="sm" fontWeight="semibold" display="flex" gap="4px">
            <RiContactsFill fill="#3A85F5" size={18} />{" "}
         <FormattedMessage id={"nav.client"} />
          </Heading>
          <Stack direction="row" pt="2" alignItems={"center"}>
              <Text fontWeight="medium">
                  <Link
                    fontSize="sm"
                    color="blue.400"
                    href={`tel:${order?.contactInfo.phoneNumber}`}
                  >
                    {order?.contactInfo.phoneNumber}
                  </Link>
                 
                  </Text>
                  <Text fontWeight="medium">
                  <Link
                  isExternal
                    fontSize="sm"
                    color="blue.400"
                    href={`https://mail.google.com/mail/u/0/?fs=1&to=${order?.contactInfo.email}&tf=cm`}
                  >
                    {order?.contactInfo?.email}
                  </Link>
                  </Text>
              </Stack></Box>
        
        ):
            order?.contactInfo ? (
              <Box>
              <Heading size="sm" fontWeight="semibold" display="flex" gap="4px">
            <RiContactsFill fill="#3A85F5" size={18} />{" "}
           <FormattedMessage id={"nav.client"} />
          </Heading>
              <Stack direction="row" pt="2" alignItems={"center"}>
              <Text fontWeight="medium">
                  <Link
                    fontSize="sm"
                    color="blue.400"
                    href={`tel:${order?.contactInfo.phoneNumber}`}
                  >
                    {order?.contactInfo.phoneNumber}
                  </Link>
                 
                  </Text>
                  <Text fontWeight="medium">
                  <Link
                  isExternal
                    fontSize="sm"
                    color="blue.400"
                    href={`https://mail.google.com/mail/u/0/?fs=1&to=${order?.contactInfo.email}&tf=cm`}
                  >
                    {order?.contactInfo?.email}
                  </Link>
                  </Text>
              </Stack>
           </Box>
            ) : (
              <></>
            )}

            <Box>
              <Heading size="sm" fontWeight="semibold" display="flex" gap="4px">
                <BiMoney fill="#3A85F5" size={18} />{" "}
                <FormattedMessage id={"payment"} />
              </Heading>
              <Stack direction="row" pt="2">
                <Text>
                  <FormattedMessage id={"choice"} />:{" "}
                </Text>
                <Text fontWeight="medium">
                  {order?.paymentType === "ONLINE"
                    ? "Online payment"
                    : "Cash on delivery"}
                </Text>
              </Stack>
            </Box>
            <Divider />
            {order?.shippment?(   <Box>
              <Heading size="sm" fontWeight="semibold" display="flex" gap="4px">
                <BiMap fill="#3A85F5" size={18} />
                <FormattedMessage id={"Address"} />
              </Heading>
              <Text mt="2">
                {order?.shippment.apartment?order?.shippment.apartment:"" +
                  " " +
                  order?.shippment?.streetAddress?order?.shippment?.streetAddress:""}
              </Text>
              <Text>
                {order?.shippment?.city?order?.shippment?.city:"" + " " + order?.shippment?.zipCode?order?.shippment?.zipCode:""}
              </Text>
              {/* <Text>
                {
                    order?.shippment
                      .
                  }
              </Text> */}
              {/* <Text>
                Phone:{" "}
                <Link
                  color="blue.400"
                  href={`tel:${order?.user?.userAddress?.[0]?.phoneNumber?.prefix} ${order?.user?.userAddress?.[0]?.phoneNumber?.number}`}
                >
                  {order?.user?.userAddress?.[0]?.phoneNumber?.prefix}
                  {order?.user?.userAddress?.[0]?.phoneNumber?.number}
                </Link>
              </Text> */}
            </Box>):(<></>)}
         
          </Stack>
        </Stack>
      </Stack>
    </PageWrapper>
  );
};

export default OrderDetails;
