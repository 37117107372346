import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  Checkbox,
  Divider,
  Heading,
  IconButton,
  Image,
  Link,
  ListItem,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Skeleton,
  Stack,
  Tab,
  TabList,
  Tabs,
  Text,
  Tooltip,
  UnorderedList,
  useDisclosure,
  useToast,
  WrapItem,
} from "@chakra-ui/react";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import {
  BiArrowBack,
  BiBlock,
  BiBriefcase,
  BiFilterAlt,
  BiLinkExternal,
  BiRadioCircleMarked,
  BiSearch,
  BiShowAlt,
  BiChevronDown,
  BiEdit,
  BiTrash,
  BiGift,
} from "react-icons/bi";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";

import Input from "../../components/commons/Input/Input";
import { TableHeadType } from "../../components/modules/Table/Table.types";
import Table from "../../components/modules/Table/Table";
import { PageWrapper } from "../../utils/globalStyle";

import ProductsFilterDrawer from "./Filters/ProductsFilterDrawer";
import { getDataFromCache } from "../../utils/cache";

import Categorie, { Category } from "../Categories/Categories";
import ConfirmDeleteDialog from "../../components/modules/ConfirmDeleteDialog/ConfirmDeleteDialog";
import { FormattedMessage, useIntl } from "react-intl";

import { BsFillArrowDownRightCircleFill } from "react-icons/bs";
import { FaShippingFast } from "react-icons/fa";
import {
  CouponsDataType,
  useCouponsDeleteOne,
  useFindAllCoupons,
  usePatchOneCoupons,
} from "../../api/Coupons";
import { AiOutlineEye } from "react-icons/ai";

const Coupons = () => {
  const [syn, setSyn] = useState(1);
  const [pagination, setPagination] = useState({
    count: 0,
    limit: 5,
    skip: 0,
    page: 1,
  });
  const location = useLocation();
  const {
    isOpen: openFilter,
    onOpen: onOpenFilter,
    onClose: onCloseFilter,
  } = useDisclosure();

  const [defaultFilterValues, setDefaultFilterValues] = useState({
    name: "",
    brand: "",
    type: "",
    category: {
      label: "",
      id: "",
    },
  });

  const handleProducts = () => {};
  const [search, setSearch] = useState("");
  const [type, setType] = useState("");
  const [sortByCreated, setSortByCreated] = useState<1 | -1>(-1);
  const [sortByUpdated, setSortByUpdated] = useState<1 | -1>(-1);
  const { mutate, data, isLoading, isSuccess, isError, error } =
    useFindAllCoupons();
  useEffect(() => {
    if (type === "") {
      mutate({
        skip: pagination.skip,
        limit: pagination.limit,
        search: search,

        createdAt: sortByCreated,
        updatedAt: sortByUpdated,
      });
    } else {
      mutate({
        skip: pagination.skip,
        limit: pagination.limit,
        search: search,
        type: type,
        createdAt: sortByCreated,
        updatedAt: sortByUpdated,
      });
    }
  }, [pagination, search, type]);
  useEffect(() => {
    if (isSuccess) {
      // console.log("coupons", data.data.coupons);
      setCoupons(data.data.coupons);
    }
  }, [isSuccess]);

  const handlePageChange = (dir: "next" | "prev") => {
    if (dir === "next") {
      setPagination((prevState) => {
        let temp = { ...prevState };
        temp.skip = temp.skip + temp.limit;
        return temp;
      });
    } else {
      setPagination((prevState) => {
        let temp = { ...prevState };
        temp.skip = temp.skip - temp.limit;
        return temp;
      });
    }
  };

  const handleLimitChange = (value: number) => {
    setPagination((prevState) => {
      let temp = { ...prevState };
      temp.limit = value;
      return temp;
    });
  };
  const handleResetFilter = () => {
    setFilters(undefined);
    setDefaultFilterValues({
      brand: "",
      category: {
        label: "",
        id: "",
      },
      name: "",
      type: "",
    });
  };
  const [filters, setFilters] = useState<any>();
  const handleFilter = (values: {
    name: string;
    type: string;
    category: {
      label: string;
      id: string;
    };
    brand: string;
  }) => {
    setDefaultFilterValues(values);
    setFilters({
      brand: values.brand || undefined,
      category: values.category || undefined,
      name: values.name || undefined,
      type: values.type || undefined,
    });
  };
  const navigate = useNavigate();

  const [coupons, setCoupons] = useState<CouponsDataType[]>([]);

  const [tabIndex, setTabIndex] = useState<number>(0);

  const { from } = (location?.state as { from?: string }) || "";
  const handleChangeTabIndex = (index: number) => {
    switch (index) {
      case 1: {
        setType("GIFT");
        break;
      }
      case 2: {
        setType("REDUCTION");
        break;
      }
      case 3: {
        setType("FREESHIP");
        break;
      }
      default:
        setType("");
    }
    setTabIndex(index);
  };
  const toast = useToast();

  const renderCouponType = (type: string | null | undefined) => {
    switch (type) {
      case "GIFT":
        return (
          <Stack
            direction="row"
            alignItems="center"
            spacing="2px"
            color="orange"
          >
            <BiGift />
            <Text>
              {" "}
              <FormattedMessage id={"gift"} />
            </Text>
          </Stack>
        );
      case "REDUCTION":
        return (
          <Stack
            direction="row"
            alignItems="center"
            spacing="2px"
            color="green.400"
          >
            <BsFillArrowDownRightCircleFill />
            <Text>
              <FormattedMessage id={"reduction"} />
            </Text>
          </Stack>
        );

      case "FREESHIP":
        return (
          <Stack direction="row" alignItems="center" spacing="2px" color="blue">
            <FaShippingFast />
            <Text>
              <FormattedMessage id={"freeShippment"} />
            </Text>
          </Stack>
        );
    }
  };
  const {
    mutate: CouponsUpdateMutate,
    data: CouponsUpdateData,
    isLoading: CouponsUpdateIsLoading,
    isSuccess: CouponsUpdateIsSuccess,
    isError: CouponsUpdateIsError,
    error: CouponsUpdateError,
  } = usePatchOneCoupons();
  useEffect(() => {
    if (CouponsUpdateIsSuccess)
      toast({
        title: "Coupon type has bean updates with sucsess",
        position: `bottom-right`,
        status: "success",
        isClosable: true,
      });
    handleProducts();
  }, [CouponsUpdateIsSuccess]);

  const handleChangeOrderStatus = (type: string, id: string, index: number) => {
    CouponsUpdateMutate({
      id: id,
      catObj: { type: type },
    });

    setCoupons((prevState) => {
      let temp = [...prevState];
      temp[index].type = type;

      return temp;
    });
  };

  const { mutate: deleteCouponsMutate, isSuccess: isSuccessdeleteCoupons } =
    useCouponsDeleteOne();
  const handleDeleteCoupon = (id: string) => {
    deleteCouponsMutate(id);
    if (isSuccessdeleteCoupons) {
      toast({
        title: "Coupon has been deleted with success.",
        status: "success",

        position: "bottom-right",
        duration: 5000,
        isClosable: true,
      });
      mutate({
        skip: pagination.skip,
        limit: pagination.limit,
        search: search,

        createdAt: sortByCreated,
        updatedAt: sortByUpdated,
      });
    } 
  };

  const [selectedCoupons, setSelectedCoupons] = useState<string[]>([]);

  const handleSelectCoupon = (id: string) => {
    setSelectedCoupons((prevState) => [...prevState, id]);
  };

  const handleRemoveSelectedCoupon = (id: string) => {
    setSelectedCoupons((prevState) => {
      let temp = [...prevState];
      const index = temp.indexOf(id, 0);
      if (index > -1) {
        temp.splice(index, 1);
      }
      return temp;
    });
  };
  const couponsTableData =
    (coupons &&
      coupons.map((coupon, index) => ({
        key: coupon._id,
        check: (
          <Checkbox
            value={coupon._id}
            onChange={(e) => {
              if (e.target.checked) {
                handleSelectCoupon(e.target.value);
              } else {
                handleRemoveSelectedCoupon(e.target.value);
              }
            }}
            isChecked={selectedCoupons?.includes(coupon._id)}
          />
        ),

        code: coupon.code,
        used:coupon.used,
        Products: (
          <Menu>
            <MenuButton
              transition="all 0.2s"
              borderRadius="md"
              // bg={"white"}
              borderWidth="1px"
              // _hover={{ bg: "gray.400" }}
              _expanded={{ bg: "blue.400" }}
              as={Button}
              rightIcon={<BiChevronDown />}
            >
              <FormattedMessage id={"list"} />
            </MenuButton>

            {coupon.products === null ? (
              "Pas de produits séléctionés"
            ) : (
              <MenuList maxH={150} overflowY="scroll">
                {coupon.products.map((Product, index) => (
                  <MenuItem minH="48px" key={index}>
                    <Image
                      boxSize="2rem"
                      borderRadius="full"
                      src={
                        Product.images[0]
                          ? Product.images[0]
                          : "https://res.cloudinary.com/dgpjskmv1/image/upload/v1654815498/users/624eea32faa74a79a806f8e4/e2f853f8232acad78bf143c32f2f3a04.webp.webp"
                      }
                      alt="Fluffybuns the destroyer"
                      mr="12px"
                    />
                    <Link
                      color="blue.300"
                      target="_blank"
                      href={`/products/${Product._id}`}
                    >
                      <Stack direction="row" alignItems="center" spacing="4px">
                        <Text whiteSpace="nowrap">{Product.name}</Text>{" "}
                        <AiOutlineEye size={16} />
                      </Stack>
                    </Link>
                  </MenuItem>
                ))}
              </MenuList>
            )}
          </Menu>
        ),
       
        effectOn: coupon.effectOn  + "%",
        quantity: coupon.quantity,
        type: (
          <WrapItem>
            <Menu>
              <MenuButton
                as={Button}
                variant="link"
                rightIcon={<BiChevronDown size={18} />}
                height="fit-content"
              >
                <Heading size="md" fontWeight="semibold" fontSize="lg">
                  {renderCouponType(coupons[index].type)}
                </Heading>
              </MenuButton>
              <MenuList>
                {["GIFT", "REDUCTION", "FREESHIP"]
                  .filter((e) => e != coupon.type)
                  .map((status: string, i: number) => (
                    <MenuItem
                    key={i}
                      onClick={() =>
                        handleChangeOrderStatus(status, coupon._id, index)
                      }
                    >
                      {renderCouponType(status)}
                    </MenuItem>
                  ))}
              </MenuList>
            </Menu>
          </WrapItem>
        ),
        creationDate: moment(coupon.createdAt).format("lll"),

        action: (
          <Stack
            direction="row"
            display="inline-flex"
            width="fit-content"
            alignItems="center"
            justifyContent="flex-end"
          >
            <ConfirmDeleteDialog
              item="Produit"
              dialogButtonProps={{
                type: "IconButton",

                children: "Delete coupon",
                props: {
                  icon: <BiTrash size={18} />,
                  color: "red",
                  rounded: "base",
                },
              }}
              onDelete={() => handleDeleteCoupon(coupon?._id)}
            />
            <IconButton
              onClick={() => {
                navigate(`/coupons/${coupon._id}`);
              }}
              aria-label="editer le product"
              size="md"
              variant="ghost"
              color="green.400"
            >
              <BiEdit size={18} />
            </IconButton>
          </Stack>
        ),
      }))) ||
    [];
  const intl = useIntl();
  type CouponsTableData = typeof couponsTableData[number];
  const [loading, setLoading] = useState(isLoading);
  const couponsTableHead: TableHeadType<CouponsTableData>[] = [
    //{ accessor: "check", label: "" },
    {
      accessor: "code",
      label: intl.formatMessage({
        id: "code",
      }),
    },
    {
      accessor: "type",
      label: intl.formatMessage({
        id: "type",
      }),
    },
    {
      accessor: "Products",
      label: intl.formatMessage({
        id: "category.nav.product",
      }),
    },
   

    {
      accessor: "effectOn",
      label: intl.formatMessage({
        id: "value",
      }),
    },
    {
      accessor: "quantity",
      label: intl.formatMessage({
        id: "quantite",
      }),
    },
    {
      accessor: "used",
      label: intl.formatMessage({
        id: "used",
      }),
    },
    {
      accessor: "creationDate",
      label: intl.formatMessage({
        id: "createdDate",
      }),
    },

    { accessor: "action", label: "", headCellProps: { isNumeric: true } },
  ];

  const handleSearchSubmit = (event: any) => {
    if (event.code === "Enter") {
      // console.log(event.target.value);
      setSearch(event.target.value);
      mutate({
        skip: pagination.skip,
        limit: pagination.limit,
        search: search,
        // category:category,
        // isAvliable:isAvliable,

        // status: status,
        createdAt: sortByCreated,
        updatedAt: sortByUpdated,
      });
    }
  };
  useEffect(() => {
    if (isSuccessdeleteCoupons) {
      toast({
        title: "Coupon has been deleted with success.",
        status: "success",

        position: "bottom-right",
        duration: 5000,
        isClosable: true,
      });
      mutate({
        skip: pagination.skip,
        limit: pagination.limit,
        search: search,

        createdAt: sortByCreated,
        updatedAt: sortByUpdated,
      });
    }
  }, [isSuccessdeleteCoupons]);
  const {
    isOpen: isOpenExportDialog,
    onOpen: onOpenExportDialog,
    onClose: onCloseExportDialog,
  } = useDisclosure();

  const cancelExportRef = useRef(null);

  return (
    <PageWrapper>
        <Stack direction={{ base: "column", md: "row" }} spacing="18px"className="page_header">
        <Box className="page_header_title">
         
          <Box className="page_header_title_content">
            <Heading fontSize="2xl">
              <FormattedMessage id={"coupons.list"} />
            </Heading>
          </Box>
        </Box>

        <Stack direction="row">
          <Button
            colorScheme="main"
            onClick={() =>
              navigate(`/coupons/create`, {
                state: {
                  from: location.pathname,
                  pageName: "coupons",
                },
              })
            }
          >
            <FormattedMessage id={"coupons.create"} />
          </Button>
        </Stack>
      </Stack>
      {selectedCoupons.length > 0 && (
        <Text fontSize="sm" opacity={0.6}>
          {selectedCoupons.length} coupons sélectionnés
        </Text>
      )}
      <Tabs
        colorScheme="blue"
        mb="14px"
        index={tabIndex}
        onChange={handleChangeTabIndex}
      >
        <TabList  overflowX="auto"
        overflowY= "hidden">
          <Tab>
            {intl.formatMessage({
              id: "all",
            })}
          </Tab>
          <Tab>
            <FormattedMessage id={"gift"} />
          </Tab>
          <Tab>
            {" "}
            <FormattedMessage id={"reduction"} />
          </Tab>
          <Tab>
            <FormattedMessage id={"freeShippment"} />
          </Tab>
        </TabList>
      </Tabs>
      {/* )} */}
    

      <Table
        head={couponsTableHead}
        data={couponsTableData}
        isLoading={loading}
        emptyState={
          <Box className="empty_table_container">
            <BiBriefcase size={42} />
            <Text fontSize="md" fontWeight="medium"></Text>
          </Box>
        }
        pagination={{
          count: data?.data.queryCount || 0,
          limit: pagination.limit,
          skip: pagination.skip,
          onPageChange: handlePageChange,
          onChangeLimit: handleLimitChange,
        }}
      />
      {/* </Skeleton> */}
      <ProductsFilterDrawer
        isOpen={openFilter}
        onClose={onCloseFilter}
        handleSubmitFilter={handleFilter}
        defaultValues={defaultFilterValues}
        handleResetFilter={handleResetFilter}
      />
    </PageWrapper>
  );
};

export default Coupons;
