import { objToParam } from "./categories";
export type paginationOrders = {
  skip?: number;
  limit?: number;
  search?: string;
  status?: string;
  user?: 1 | -1;
  paymentType?: string;
  createdAt?: 1 | -1;
  updatedAt?: 1 | -1;
};

export const ORDERS_FIND_ALL = (obj: paginationOrders) =>
  `orders/findAll${objToParam(obj)}`;
  export const PAYMENTS_FIND_ALL = (obj: {order:string; skip?: number;
    limit?: number;}) =>
  `payments/findAll${objToParam(obj)}`;

export const ORDERS_PATCH_ONE = (id: string) => `orders/patchOne/${id}`;
export const ORDERS_FIND_ONE = (obj?: string) => `orders/findOne/${obj}`;
export const ORDERS_EXPORT_ITEM = () => `orders/export`;
export const ORDERS_PREVIEW_ITEM = () => `orders/preview`;