import { Box, Button, Heading, Text } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

export interface IAboutPageProps {}

const AboutPage: React.FunctionComponent<IAboutPageProps> = (props) => {
  const [message, setMessage] = useState("");
  const { number } = useParams();
  const navigate = useNavigate();
  useEffect(() => {
    if (number) {
      setMessage("The number is " + number);
    } else {
      setMessage("No number was provided");
    }
  }, []);

  return (
    <div>
      <Box textAlign="center" py={10} px={6}>
      <Heading
        display="inline-block"
        as="h2"
        size="2xl"
        bgGradient="linear(to-r, #0093FF,#FF00FF)"
        backgroundClip="text">
        404
      </Heading>
      <Text fontSize="18px" mt={3} mb={2}>
        Page Not Found
      </Text>
      <Text color={'gray.500'} mb={6}>
        The page you're looking for does not seem to exist
      </Text>

      <Button
       onClick={() =>
        navigate(`/`)
      }
      colorScheme="#FF00FF"
        bgGradient="linear(to-r, #0093FF,#0093FF)"
        color="white"
        variant="solid">
        Go to Home
      </Button>
    </Box>
    </div>
  );
};

export default AboutPage;
