import {
  Box,
  Button,
  Divider,
  FormLabel,
  Heading,
  Link,
  Stack,
  Switch,
  Tabs,
  Select,
  Text,
  useColorModeValue,
  useToast,
  Skeleton,
  RadioGroup,
  Radio,
  TabList,
  Tab,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverCloseButton,
  PopoverHeader,
  PopoverBody,
  TabPanels,
  TabPanel,
} from "@chakra-ui/react";

import axios from "axios";
import { FormikErrors, useFormik } from "formik";
import { ReactNode, useEffect, useState } from "react";
import { BiArrowBack } from "react-icons/bi";

import { Link as RouterLink, useLocation, useNavigate } from "react-router-dom";

import { PageWrapper } from "../../../utils/globalStyle";
import { PUBLIC_API_ENDPOINT } from "../../../utils/globalTypes";
import { Category } from "../../Categories/Categories";
import ProductGeneralForm from "../Forms/ProductGeneralForm";

export type ProductType = {
  PRODUCT_TYPE_ID: string;
  PRODUCT_TYPE_NAME: string;
  PRODUCT_TYPE_STATUS: string;
};

const CreateProduct = () => {
  const location = useLocation();

  const { from } = (location?.state as { from?: string }) || "";

  const [tabIndex, setTabIndex] = useState<number>(0);

  const [actionButtons, setActionButtons] = useState<ReactNode>();

  const getActionButtons = (node?: ReactNode) => {
    setActionButtons(node);
  };

  return (
    <PageWrapper>
      <Box className="page_header">
        <Box className="page_header_title">
         
          <Box className="page_header_title_content">
            <Heading fontSize="2xl">Create Product</Heading>
          </Box>
        </Box>
        <Stack direction="row">{actionButtons}</Stack>
      </Box>
      <Tabs index={tabIndex} colorScheme="blue" isLazy>
        <TabList  overflowX="auto"
        overflowY= "hidden">
          <Tab>General</Tab>
          <Popover>
            <PopoverTrigger>
              <Tab px={4}>Options</Tab>
            </PopoverTrigger>

            <PopoverContent>
              <PopoverArrow />
              <PopoverCloseButton />
              <PopoverHeader>Hold on!</PopoverHeader>
              <PopoverBody>
                Save product general settings to access their options.
              </PopoverBody>
            </PopoverContent>
          </Popover>
          <Popover>
            <PopoverTrigger>
              <Tab px={4}>Variations</Tab>
            </PopoverTrigger>

            <PopoverContent>
              <PopoverArrow />
              <PopoverCloseButton />
              <PopoverHeader>Hold on!</PopoverHeader>
              <PopoverBody>
                Save product general settings to access their variations.
              </PopoverBody>
            </PopoverContent>
          </Popover>
          <Popover>
            <PopoverTrigger>
              <Tab px={4}>Inventory</Tab>
            </PopoverTrigger>

            <PopoverContent>
              <PopoverArrow />
              <PopoverCloseButton />
              <PopoverHeader>Hold on!</PopoverHeader>
              <PopoverBody>
                Save product general settings to access their inventory.
              </PopoverBody>
            </PopoverContent>
          </Popover>
          <Popover>
            <PopoverTrigger>
              <Tab px={4}>Files</Tab>
            </PopoverTrigger>

            <PopoverContent>
              <PopoverArrow />
              <PopoverCloseButton />
              <PopoverHeader>Hold on!</PopoverHeader>
              <PopoverBody>
                Save product general settings to access their files.
              </PopoverBody>
            </PopoverContent>
          </Popover>
          <Popover>
            <PopoverTrigger>
              <Tab px={4}>SEO</Tab>
            </PopoverTrigger>

            <PopoverContent>
              <PopoverArrow />
              <PopoverCloseButton />
              <PopoverHeader>Hold on!</PopoverHeader>
              <PopoverBody>
                Save product general settings to access their SEO.
              </PopoverBody>
            </PopoverContent>
          </Popover>
          <Popover>
            <PopoverTrigger>
              <Tab px={4}>Related Products</Tab>
            </PopoverTrigger>

            <PopoverContent>
              <PopoverArrow />
              <PopoverCloseButton />
              <PopoverHeader>Hold on!</PopoverHeader>
              <PopoverBody>
                Save product general settings to access their Related Products.
              </PopoverBody>
            </PopoverContent>
          </Popover>
          {/* <Popover>
						<PopoverTrigger>
							<Tab px={4}>Related products</Tab>
						</PopoverTrigger>

						<PopoverContent>
							<PopoverArrow />
							<PopoverCloseButton />
							<PopoverHeader>Hold on!</PopoverHeader>
							<PopoverBody>
								Save product general settings to access their related products.
							</PopoverBody>
						</PopoverContent>
					</Popover> */}
        </TabList>
        <TabPanels>
          <TabPanel p="12px 0px">
            <ProductGeneralForm actionButtons={getActionButtons} />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </PageWrapper>
  );
};

export default CreateProduct;
