import {
  Box,
  Stack,
  useColorModeValue,
  Text,
  IconButton,
  Heading,
  Button,
  Skeleton,
  useColorMode,
  WrapItem,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
} from "@chakra-ui/react";
import { FC, useContext, useState } from "react";
import {
  BiArrowBack,
  BiBookBookmark,
  BiBriefcaseAlt,
  BiBrush,
  BiBrushAlt,
  BiChevronDown,
  BiCog,
  BiDesktop,
  BiGlobe,
  BiLinkExternal,
  BiPaint,
  BiShieldQuarter,
  BiSpreadsheet,
  BiStoreAlt,
  BiUserCircle,
} from "react-icons/bi";
import { GiEarthAfricaEurope } from "react-icons/gi";
import { FormattedMessage } from "react-intl";
import { Navigate, Outlet, useLocation, useNavigate } from "react-router-dom";
import { IApplicationProps } from "../../../App";
import { logoImage, logoImageDARKUI } from "../../../assets";
import { AppContext } from "../../../context/appContext";
import { getDataFromCache } from "../../../utils/cache";
import { Logo } from "../../commons";
import SettingsNavigation from "../Navigation/alternatives/SettingsNavigation";
import { NavigationPropsType } from "../Navigation/Navigation.types";

import { SettingsContainer } from "./SettingsWrapper.styles";

const storeID = "";
const locals: { label: string; id: string }[] = [
  { label: "arabic", id: "ar" },
  { id: "fr", label: "french" },
  { id: "en", label: "english" },
];
const getNavigation = (): NavigationPropsType["items"] => {
  return [
    {
      Icon: <BiUserCircle size="18" />,
      href: `/settings/profile`,
      label: "Profile",
      children: [],
    },
    {
      Icon: <BiShieldQuarter size="18" />,
      href: `/settings/security`,
      label: "accountSecurity",
      children: [],
    },
    "SEPERATOR",
    {
      Icon: <BiDesktop size="18" />,
      href: "/settings/home",
      label: "homeConfig",
      children: [],
    },

    {
      Icon: <BiBookBookmark size="18" />,
      href: "/settings/policies",
      label: "Market Policies",
      children: [],
    },
  ];
};

const SettingsWrapper: FC<IApplicationProps> = (props) => {
  const { colorMode } = useColorMode();
  const [isAuth, setIsAuth] = useState(getDataFromCache("accessToken", true));
  const location = useLocation();

  const bannerBgColor = useColorModeValue("white", "gray.900");

  const containerBgColor = useColorModeValue("gray.100", "gray.800");

  const navList = getNavigation();

  const { from } = (location?.state as { from?: string }) || "";

  const navigate = useNavigate();
  const { setLanguage, language } = useContext(AppContext);
  if (isAuth) {
    return (
      <SettingsContainer>
        <Box bgColor={containerBgColor} className="app_container">
          <Box
            background={bannerBgColor}
            shadow="md"
            position={"sticky"}
            top="0"
            overflow="hidden"
            zIndex={99}
          >
            <Stack direction="row" p="8px 16px" alignItems="center">
              <IconButton
                size="sm"
                variant="ghost"
                aria-label="back_settings_button"
                onClick={() =>
                  navigate("/", {
                    state: {
                      from: location.pathname,
                    },
                  })
                }
              >
                <BiArrowBack size={20} />
              </IconButton>
              <Heading
                size="md"
                fontWeight="semibold"
                display="flex"
                alignItems="center"
                gap="5px"
              >
                <BiCog size={22} /> <FormattedMessage id={"nav.settings"} />
              </Heading>
              {/* <WrapItem>
                <Menu autoSelect={false}>
                  <MenuButton
                    as={Button}
                    variant="link"
                    leftIcon={<GiEarthAfricaEurope size={18} />}
                    rightIcon={<BiChevronDown size={18} />}
                    height="fit-content"
                  >
                    <Heading size="md" fontWeight="semibold" fontSize="lg">
                      <FormattedMessage
                        id={
                          language == "en"
                            ? "english"
                            : language == "fr"
                            ? "french"
                            : "arabic"
                        }
                      />
                    </Heading>
                  </MenuButton>
                  <MenuList minW={"9rem"}>
                    {locals.map((lang, index) => (
                      <MenuItem
                        key={index}
                        onClick={() => setLanguage(lang.id)}
                      >
                        <FormattedMessage id={lang.label} />
                      </MenuItem>
                    ))}
                  </MenuList>
                </Menu>
              </WrapItem> */}
            </Stack>
          </Box>
          <Stack
            direction="row"
            p={{ base: "10px", lg: "42px" }}
            spacing="24px"
          >
            <SettingsNavigation
              items={navList}
              width="260px"
              topSection={
                // data?.GetCurrentSessionUser.userPermessions._id ===
                // "62fe185fc74ebd89e50a1f0b" ? (
                //   <Box p="16px">
                //     <Skeleton
                //       isLoaded={!storeNameLoading}
                //       opacity={storeNameLoading ? 0.6 : 1}
                //       minH="46px"
                //     >
                //       <Stack direction="row">
                //         {storeNameData?.GetCurrentSessionUserStores?.stores?.[0]
                //           ?.storeMedia?.logo && (
                //           <img
                //             className="store_logo_settings"
                //             src={
                //               storeNameData?.GetCurrentSessionUserStores
                //                 ?.stores?.[0]?.storeMedia?.logo
                //             }
                //             alt="store_logo"
                //           />
                //         )}
                //         <Box>
                //           <Text fontSize="lg">
                //             {
                //               storeNameData?.GetCurrentSessionUserStores
                //                 .stores?.[0].name
                //             }
                //           </Text>
                //           <Text opacity={0.6}>
                //             {`${userData?.firstName} ${userData?.lastName}`}
                //           </Text>
                //         </Box>
                //       </Stack>
                //     </Skeleton>
                //   </Box>
                // ) : (
                <Box
                  p={{ base: "0px", lg: "16px" }}
                  w={{ base: "fit-content", lg: "auto" }}
                  margin={{ base: "0 auto 15px auto", lg: "0px" }}
                >
                  <Logo />
                </Box>
                // )
              }
            />
            <Box w="full" flex={1}>
              <Outlet />
            </Box>
          </Stack>
        </Box>
      </SettingsContainer>
    );
  } else return <Navigate to="../authentication/login" />;
};

export default SettingsWrapper;
