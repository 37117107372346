import { useMutation } from "@tanstack/react-query";
import { ClientsDataType } from "../Clients";
import {
  ORDERS_EXPORT_ITEM,
  ORDERS_FIND_ALL,
  ORDERS_FIND_ONE,
  ORDERS_PATCH_ONE,
  ORDERS_PREVIEW_ITEM,
  paginationOrders,
  PAYMENTS_FIND_ALL,
} from "../endpoints/orders";
import { protectedGet, protectedPatch, protectedPost } from "../http-methods";

export type OrdersTypeData = {
  _id: string;
  user: ClientsDataType;
  contactInfo: {
    email: string;
    phoneNumber: string;
    _id: string;
  };
  items: {
    quantity: number;
    itemName: string;
    itemPrice: number;
    refId: string;
    images: string[];
    hasCustomfields: boolean;
    _id: string;
    customfields: {
      backgroundColor: string;
      backgroundLogo: string;
      foregroundLogo: string;
      foregroundColor: string;
      signalColor: string;
      qrCodeColor: string;
      text: string;
      textColor: string;
      textFontSize: number;
    };
  }[];
  status: string;
  paymentStatus: string;
  shippment: {
    shippingFees: string;
    streetAddress: string;
    apartment: string;
    suiteNumber: string;
    city: string;
    state: string;
    zipCode: string;
    createdAt: string;
    _id: string;
  };
  paymentType: string;
  refId: string;
  totalePrice: number;
  totalItems: number;
  createdAt: string;
  updatedAt: string;
  __v: number;
  isBusinessPlan: boolean;
};

export type Item = {
  quantity: number;
  itemName: string;
  itemPrice: number;
  refId: string;
  images: string[];
  hasCustomfields: boolean;
  _id: string;
  customfields: {
    backgroundColor: string;
    backgroundLogo: string;
    foregroundLogo: string;
    foregroundColor: string;
    signalColor: string;
    qrCodeColor: string;
    text: string;
    textColor: string;
    textFontSize: number;
  };
};
export type PayementTypeData = {
  _id: string;
  order: OrdersTypeData;
  status: string;
  transactionProvider: string;
  transactionId: string;
  payedAmount: number;
  error: string;
  refId: string;
  createdAt: string;
  updatedAt: string;
  __v: number;
};
export type UpdateOrdersTypeData = {
  status?: string;
};
export const useFindAllOrders = () => {
  return useMutation({
    mutationFn: (obj: paginationOrders) => {
      return protectedGet(ORDERS_FIND_ALL(obj));
    },
  });
};
export const useFindAllPayments = () => {
  return useMutation({
    mutationFn: (obj: { order: string; skip?: number; limit?: number }) => {
      return protectedGet(PAYMENTS_FIND_ALL(obj));
    },
  });
};
export const usePatchOneOrder = () => {
  return useMutation({
    mutationFn: ({
      id,
      catObj,
    }: {
      id: string;
      catObj: UpdateOrdersTypeData;
    }) => {
      return protectedPatch(ORDERS_PATCH_ONE(id), catObj);
    },
  });
};
export const useFindOneOrder = () => {
  return useMutation({
    mutationFn: (catObj?: string) => {
      return protectedGet(ORDERS_FIND_ONE(catObj));
    },
  });
};

export const useExportOrderItem = () => {
  return useMutation({
    mutationFn: (item: Item) => {
      return protectedPost(ORDERS_EXPORT_ITEM(), { ...item });
    },
    onSuccess: ({ data }) => {
      console.log(data.export);
      // window.location(data.export)
      window.open(data.export, `href=${data.export} ,download`);
    },
  });
};
export const usePreviewOrderItem = () => {
  return useMutation({
    mutationFn: (item: Item) => {
      return protectedPost(ORDERS_PREVIEW_ITEM(), { ...item });
    },
    onSuccess: ({ data }) => {
      console.log(data);
      // window.location(data.export)
      // window.open(data.export, `href=${data.export}`);
      window.open(data.export, `href=${data.export} ,download`);
    },
  });
};