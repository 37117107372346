import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  IconButton,
  Menu,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Skeleton,
  Spacer,
  Stack,
  Text,
  Textarea,
  useColorModeValue,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import axios from "axios";
import { FormikErrors, useFormik } from "formik";
import { useEffect, useState } from "react";
import { BiBriefcaseAlt, BiPlus, BiShowAlt, BiTrash } from "react-icons/bi";
import { FormattedMessage } from "react-intl";
import ReactQuill from "react-quill";
import { CategoryDataType } from "../../../api/categories";
import { ImageUpload, Input, SelectInput } from "../../../components/commons";

import Table from "../../../components/modules/Table/Table";
import { TableHeadType } from "../../../components/modules/Table/Table.types";
import { getDataFromCache } from "../../../utils/cache";
import {
  declinedfakeData,
  fakeData as dumydata,
  Product,
  ProductStatusType,
  PUBLIC_API_ENDPOINT,
  publishedfakeData,
} from "../../../utils/globalTypes";
import { Category } from "../Categories";

const modules = {
  toolbar: [
    [{ header: "1" }, { header: "2" }, { font: [] }],
    [{ size: [] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
    ],
    ["link"],
  ],
  clipboard: {
    // toggle to add extra line breaks when pasting HTML:
    matchVisual: false,
  },
};

type GeneralCategoryFormProps = {
  onSubmit: (e?: React.FormEvent<HTMLFormElement> | undefined) => void;
  values: {
    name: string;
    description: string;
    status: string;
  };
  handleChange: {
    (e: React.ChangeEvent<any>): void;
    <T_1 = string | React.ChangeEvent<any>>(
      field: T_1
    ): T_1 extends React.ChangeEvent<any>
      ? void
      : (e: string | React.ChangeEvent<any>) => void;
  };
  errors: FormikErrors<{
    name: string;
    description: string;
    status: string;
  }>;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) =>
    | Promise<void>
    | Promise<
        FormikErrors<{
          name: string;
          description: string;
          status: string;
        }>
      >;
  files: {
    progress: boolean;
    id: string;
    src: string | File | undefined;
  }[];
  handleFile: (file: File) => Promise<void>;
  handleDelete: (id: string) => void;
  isLoading: boolean;
};

const GeneralCategoryFormLoading = () => {
  return (
    <Stack spacing="14px" opacity={0.4}>
      <Skeleton height="245px" width="470px" />
      <Stack direction="row">
        <Skeleton height="32px" width="full" />
        <Skeleton height="32px" width="420px" />
      </Stack>
      <Skeleton height="310px" />
    </Stack>
  );
};

export const GeneralCategoryForm = (props: GeneralCategoryFormProps) => {
  const {
    onSubmit,
    values,
    handleChange,
    errors,
    setFieldValue,
    files,
    handleFile,
    handleDelete,
    isLoading,
  } = props;
  const [cat, setCat] = useState<CategoryDataType>();
  const sectionBgColor = useColorModeValue("white", "gray.900");
  return (
    <form onSubmit={onSubmit} id="update-general-category-form">
      {isLoading ? (
        <GeneralCategoryFormLoading />
      ) : (
        <Stack spacing="14px">
          <ImageUpload
            height="230px"
            width="470px"
            images={files}
            handleUpload={handleFile}
            handleDelete={handleDelete}
            label="image"
          />
          <Stack direction="row">
            <Input
              label="name"
              inputProps={{
                placeholder: "Nom",
                name: "name",
                onChange: handleChange,
                value: values.name,
              }}
              errorMessage={errors.name}
              isError={errors.name !== undefined}
            />
            <Spacer />
            <SelectInput
              formControlProps={{
                w: "100%",
              }}
              options={[
                { label: "Draft", value: "DRAFT" },
                { label: "Published", value: "PUBLISHED" },
              ]}
              label="status"
              selectProps={{
                name: "status",
                value: values.status,
                onChange: handleChange,
              }}
              errorMessage={errors.status}
              isError={errors.status !== undefined}
            />
          </Stack>
          <Box
       
        >
            <FormLabel marginBottom="1">Déscription</FormLabel>
            <ReactQuill
              
              value={values.description}
              onChange={(content) => setFieldValue("description", content)}
              modules={modules}
              placeholder="Describe this category"
            />
          </Box>
        </Stack>
      )}
    </form>
  );
};
export type Term = {
  TERM_ID: string;
  TERM_NAME: string;
  TERM_DESCRIPTION: string;
};
export type Attribut = {
  ATTRIBUT_ID: string;
  ATTRIBUT_NAME: string;
  ATTRIBUT_DESCRIPTION: string;
  TERMS: Term[] | undefined;
};

export const AttributCategory = (props: { categoryID?: string | null }) => {
  const { categoryID } = props;
  const [attributs, setattributs] = useState<Attribut[]>([]);
  const toast = useToast();
  const [loading, setLoading] = useState(true);
  const [selectedAttribut, setselectedAttribut] = useState<Attribut>();
  function getRandomInt(max: number) {
    return Math.floor(Math.random() * max);
  }
  const [syn, setsyn] = useState<number>(getRandomInt(222));
  useEffect(() => {
    const data = {
      service: "attribut",
      action: "getAttributByIdCtegorie",
      data: {
        token: getDataFromCache("token"),
        category_id: categoryID,
      },
    };
    axios
      .post(PUBLIC_API_ENDPOINT, data, {
        headers: {
          Accept: "application/json",
        },
      })
      .then((res) => {
        // if (res.status === 200) {

        const resultat = res.data.data;
        // setCat(resultat);

        setattributs(resultat);
        // console.log(attributs, "attributs");
        setLoading(false);
      });
  }, [categoryID, syn]);
  const [pagination, setPagination] = useState({
    limit: 10,
    skip: 0,
  });
  const [selectedAttributs, setSelectedAttributs] = useState<string[]>([]);
  const handleSelectAttribut = (id: string) => {
    setSelectedAttributs((prevState) => [...prevState, id]);
  };

  const handleRemoveSelectedAttribut = (id: string) => {
    setSelectedAttributs((prevState) => {
      let temp = [...prevState];
      const index = temp.indexOf(id, 0);
      if (index > -1) {
        temp.splice(index, 1);
      }
      return temp;
    });
  };
  const handlePageChange = (dir: "next" | "prev") => {
    if (dir === "next") {
      setPagination((prevState) => {
        let temp = { ...prevState };
        temp.skip = temp.skip + temp.limit;
        return temp;
      });
    } else {
      setPagination((prevState) => {
        let temp = { ...prevState };
        temp.skip = temp.skip - temp.limit;
        return temp;
      });
    }
  };
  const handleLimitChange = (value: number) => {
    setPagination((prevState) => {
      let temp = { ...prevState };
      temp.limit = +value;
      return temp;
    });
  };
  const handleDeleteAttribut = (id: string) => {
    const data = {
      service: "attribut",
      action: "deleteAttributById",
      data: {
        token: getDataFromCache("token"),
        id: id,
      },
    };
    axios
      .post(PUBLIC_API_ENDPOINT, data, {
        headers: {
          Accept: "application/json",
        },
      })
      .then((res) => {
        const resultat = res.data;
        if (resultat.status == 1) {
          toast({
            title: "Attribut has been deleted with success!",
            status: "success",
            position: `bottom-right`,

            isClosable: true,
          });

          setsyn(getRandomInt(55));
        } else if (resultat.status == 0) {
          toast({
            title: resultat.status_message,
            position: `bottom-right`,
            status: "error",
            isClosable: true,
          });
        }
      });
  };
  const categoryAttributsTableData =
    attributs?.map((attribut, index) => ({
      id: attribut.ATTRIBUT_ID,

      name: attribut.ATTRIBUT_NAME,
      description: attribut.ATTRIBUT_DESCRIPTION,
      action: (
        <Menu>
          <IconButton
            //  onClick={() => navigate(`/products/${product._id}`)}
            aria-label="show_button"
            size="sm"
            variant="ghost"
            onClick={() => {
              setselectedAttribut(attribut);
              updateAttributFormik.setFieldValue(
                "name",
                attribut.ATTRIBUT_NAME
              );
              updateAttributFormik.setFieldValue(
                "description",
                attribut.ATTRIBUT_DESCRIPTION
              );
              setAttributTerms(
                (attribut.TERMS != undefined &&
                  attribut.TERMS.map((file, key) => ({
                    name: file.TERM_NAME,
                    description: file.TERM_DESCRIPTION,
                    id: file.TERM_ID,
                  }))) ||
                  []
              );
              onOpenUpdateAttributModal();
              // console.log("testtt", selectedAttribut);
            }}
          >
            <BiShowAlt size={18} />
          </IconButton>
          <IconButton
            size="sm"
            aria-label="unassign_product"
            variant="ghost"
            colorScheme="red"
            // isLoading={unassignProductsParam.loading}
            onClick={() => handleDeleteAttribut(attribut.ATTRIBUT_ID)}
          >
            <BiTrash size={18} />
          </IconButton>
        </Menu>
      ),
    })) || [];
  type CategoryAttributTableType = typeof categoryAttributsTableData[number];
  const [attributTerms, setAttributTerms] = useState<
    { name?: string | null; description?: string; id: string | "" }[]
  >([]);

  const updateAttributFormik = useFormik({
    validateOnMount: false,
    validateOnBlur: false,
    validateOnChange: false,
    initialValues: {
      name: "",
      description: "",
    },

    onSubmit: (values, { setSubmitting }) => {
      // console.log("attribut", values);
      // console.log("terms", attributTerms);
      const data = {
        service: "attribut",
        action: "editAttributById",
        data: {
          id: selectedAttribut?.ATTRIBUT_ID,
          token: getDataFromCache("token"),
          category_id: categoryID,
          name: values.name,
          description: values.description,
        },
      };
      axios
        .post(PUBLIC_API_ENDPOINT, data, {
          headers: {
            Accept: "application/json",
          },
        })
        .then((res) => {
          const resultat = res.data.data;

          if (res.data.status == 1) {
            // attributTerms.map(attribut,index)=>{};
            toast({
              title: "Attribut has been updated with success!",
              status: "success",
              position: `bottom-right`,

              isClosable: true,
            });

            if (attributTerms) {
              attributTerms.forEach((element, key) => {
                // console.log("element", element);
                UpdateTerms(element, selectedAttribut?.ATTRIBUT_ID);
              });
            }
            setsyn(getRandomInt(11));
            onCloseUpdateAttributModal();
          } else if (res.data.status == 0) {
            toast({
              title: res.data.status_message,
              position: `bottom-right`,
              status: "error",
              isClosable: true,
            });
          }
        });
    },
  });
  const [loadingTerms, setLoadingTerms] = useState(true);
  const addAttributFormik = useFormik({
    validateOnMount: false,
    validateOnBlur: false,
    validateOnChange: false,
    initialValues: {
      name: "",
      description: "",
    },
    onSubmit: (values, { setSubmitting }) => {
      // console.log("attribut", values);
      // console.log("terms", attributTerms);
      const data = {
        service: "attribut",
        action: "addAttribut",
        data: {
          token: getDataFromCache("token"),
          category_id: categoryID,
          name: values.name,
          description: values.description,
        },
      };
      axios
        .post(PUBLIC_API_ENDPOINT, data, {
          headers: {
            Accept: "application/json",
          },
        })
        .then((res) => {
          const resultat = res.data.data;

          if (res.data.status == 1) {
            // attributTerms.map(attribut,index)=>{};
            toast({
              title: "Attribut has been added with success!",
              status: "success",
              position: `bottom-right`,

              isClosable: true,
            });
            // console.log("idiiii", resultat.id);
            attributTerms.forEach((element) => {
              AddTerms(element, resultat.id);
            });
            setAttributTerms([]);
            setsyn(getRandomInt(11));
            addAttributFormik.setFieldValue("name", "");
            addAttributFormik.setFieldValue("description", "");
            onClose();
          } else if (res.data.status == 0) {
            toast({
              title: res.data.status_message,
              position: `bottom-right`,
              status: "error",
              isClosable: true,
            });
          }
        });
    },
  });
  function AddTerms(
    term: { name?: string | null; description?: string },
    id: string | null | undefined
  ) {
    const data = {
      service: "term",
      action: "addTerm",
      data: {
        token: getDataFromCache("token"),
        attribut_id: id,
        name: term.name,
        description: term.description,
      },
    };
    axios
      .post(PUBLIC_API_ENDPOINT, data, {
        headers: {
          Accept: "application/json",
        },
      })
      .then((res) => {
        const resultat = res.data;
        if (resultat.status == 1) {
          // console.log("terms added ", resultat);
        } else if (resultat.status == 0) {
          toast({
            title: resultat.status_message,
            position: `bottom-right`,
            status: "error",
            isClosable: true,
          });
        }
      });
  }
  function UpdateTerms(
    term: { name?: string | null; description?: string; id?: string },
    id: string | null | undefined
  ) {
    if (term?.id === "") {
      AddTerms(term, id);
    } else {
      const data = {
        service: "term",
        action: "editTermById",
        data: {
          id: term.id,
          token: getDataFromCache("token"),
          attribut_id: id,
          name: term.name,
          description: term.description,
        },
      };
      axios
        .post(PUBLIC_API_ENDPOINT, data, {
          headers: {
            Accept: "application/json",
          },
        })
        .then((res) => {
          const resultat = res.data;
          if (resultat.status == 1) {
            // console.log("terms updated ", resultat);
          } else if (resultat.status == 0) {
            toast({
              title: resultat.status_message,
              position: `bottom-right`,
              status: "error",
              isClosable: true,
            });
          }
        });
    }
  }
  const handleChangeAttributTerms = (
    value: string,
    index: number,
    type: "key" | "value"
  ) => {
    setAttributTerms((prevState) => {
      let temp = [...prevState];
      if (type === "key") {
        temp[index].name = value;
      } else {
        temp[index].description = value;
      }
      return temp;
    });
  };

  const handleDeleteAttributTerm = (index: number) => {
    setAttributTerms((prevState) => {
      let temp = [...prevState];
      temp.splice(index, 1);
      return temp;
    });
  };
  const handleDeleteAttributTerms = (index: number, id: string) => {
    if (id === "") {
      handleDeleteAttributTerm(index);
    } else {
      const data = {
        service: "term",
        action: "deleteTermById",
        data: {
          id: id,
          token: getDataFromCache("token"),
        },
      };
      axios
        .post(PUBLIC_API_ENDPOINT, data, {
          headers: {
            Accept: "application/json",
          },
        })
        .then((res) => {
          const resultat = res.data;
          if (resultat.status == 1) {
            // console.log("terms deleted ", resultat);
            setsyn(getRandomInt(1122));
            setAttributTerms((prevState) => {
              let temp = [...prevState];
              temp.splice(index, 1);
              return temp;
            });
          } else if (resultat.status == 0) {
            toast({
              title: resultat.status_message,
              position: `bottom-right`,
              status: "error",
              isClosable: true,
            });
          }
        });
    }
  };

  const handleAddAttributTerm = () => {
    setAttributTerms((prevState) => [
      ...prevState,
      { name: "", description: "", id: "" },
    ]);
  };

  const categoryAttributsTableHead: TableHeadType<CategoryAttributTableType>[] =
    [
      { accessor: "name", label: "Nom" },
      { accessor: "description", label: "Déscription" },
      { accessor: "action", label: "", headCellProps: { isNumeric: true } },
    ];
  const {
    isOpen: isOpenUpdateAttributModal,
    onOpen: onOpenUpdateAttributModal,
    onClose: onCloseUpdateAttributModal,
  } = useDisclosure();
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <Stack spacing="14px">
      <Stack direction={{ base: "column", md: "row" }} spacing="18px">
     
        <Heading size="md">Attributs </Heading>
        <Button variant="outline" size="sm" onClick={onOpen}>
          Ajouter un attribut à la catégorie
        </Button>
      </Stack>
      <Table
        head={categoryAttributsTableHead}
        data={categoryAttributsTableData}
        isLoading={loading}
        emptyState={
          <Box className="empty_table_container">
            <BiBriefcaseAlt size={42} />
            <Text fontSize="md" fontWeight="medium">
              Il n'y a pas encore d'attributs !
            </Text>
          </Box>
        }
        pagination={{
          count: 5 || 0,
          limit: pagination.limit,
          skip: pagination.skip,
          onPageChange: handlePageChange,
          onChangeLimit: handleLimitChange,
        }}
      />
    
     
    </Stack>
  );
};


type SEOCategoryFormProps = {
  onSubmit: (e?: React.FormEvent<HTMLFormElement> | undefined) => void;
  values: {
    slug:string;
    seotitle: string;
    keywords: string;
    metadescription: string;
  };
  handleChange: {
    (e: React.ChangeEvent<any>): void;
    <T_1 = string | React.ChangeEvent<any>>(
      field: T_1
    ): T_1 extends React.ChangeEvent<any>
      ? void
      : (e: string | React.ChangeEvent<any>) => void;
  };
  errors: FormikErrors<{
    slug:string;
    seotitle: string;
    keywords: string;
    metadescription: string;
  }>;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) =>
    | Promise<void>
    | Promise<
        FormikErrors<{
          slug:string;
          seotitle: string;
          keywords: string;
          metadescription: string;
        }>
      >;
  isLoading: boolean;
  metaTags: { key?: string | null; value?: string | null }[];
  handleChangeMetaTags: (
    value: string,
    index: number,
    type: "key" | "value"
  ) => void;
  handleAddMetatag: () => void;
  handleDeleteMetaTag: (index: number) => void;
};

export const SEOCategoryForm = (props: SEOCategoryFormProps) => {
  const {
    errors,
    handleChange,
    isLoading,
    onSubmit,
    values,
    metaTags,
    handleChangeMetaTags,
    handleAddMetatag,
    handleDeleteMetaTag,
  } = props;

  return (
    <form onSubmit={onSubmit} id="update-seo-category-form">
      {isLoading ? (
        <GeneralCategoryFormLoading />
      ) : (
        <Stack spacing="14px">
          <Box>
            <Heading size="md">SEO</Heading>
            <Text fontSize="sm" opacity={0.4}>
              <FormattedMessage id={"category.seo.text"} />
            </Text>
          </Box>
          <Input
            label="title"
            inputProps={{
              placeholder: "title",
              name: "seotitle",
              onChange: handleChange,
              value: values.seotitle,
            }}
            errorMessage={errors.seotitle}
            isError={errors.seotitle !== undefined}
          />
          <FormControl isInvalid={errors.metadescription !== undefined}>
            <FormLabel marginBottom="1">
              <FormattedMessage id={"metaDescription"} />
            </FormLabel>
            <Textarea
              placeholder="metaDescription"
              name="metadescription"
              onChange={handleChange}
              value={values.metadescription}
            />
            {errors.metadescription && (
              <FormErrorMessage>{errors.metadescription}</FormErrorMessage>
            )}
          </FormControl>

          <Input
            label="seo.slug"
            inputProps={{
              placeholder: "seo.slug",
              name: "slug",
              onChange: handleChange,
              value: values.slug,
             
            }}
            inputLeftAddon={{
              children: "bitaqa.tn/home/collections/",
            }}
            errorMessage={errors.slug}
            isError={errors.slug !== undefined}
          />

          <Box>
            <FormLabel>
              <FormattedMessage id={"meta.tags"} />
            </FormLabel>
            {metaTags.map((tag, index) => (
              <Box>
                <Text>
                  <FormattedMessage id={"tags"} />
                  {index + 1}
                </Text>
                <Stack
                  mb="12px"
                  direction="row"
                  alignItems="flex-end"
                  spacing="14px"
                >
                  <Input
                    label="key"
                    inputProps={{
                      placeholder: "Key",
                      onChange: (e) =>
                        handleChangeMetaTags(e.target.value, index, "key"),
                      value: tag.key || "",
                    }}
                  />
                  <Input
                    label="value"
                    inputProps={{
                      placeholder: "value",
                      onChange: (e) =>
                        handleChangeMetaTags(e.target.value, index, "value"),
                      value: tag.value || "",
                    }}
                  />
                  <IconButton
                    variant="ghost"
                    colorScheme="red"
                    aria-label="delete_meta-tags"
                    onClick={() => handleDeleteMetaTag(index)}
                  >
                    <BiTrash size="18" />
                  </IconButton>
                </Stack>
              </Box>
            ))}
            <Button
              onClick={handleAddMetatag}
              variant="outline"
              leftIcon={<BiPlus />}
            >
              <FormattedMessage id={"metaTag.add"} />
            </Button>
          </Box>
        </Stack>
      )}
    </form>
  );
};


