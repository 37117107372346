import {
  Box,
  Button,
  Checkbox,
  color,
  Divider,
  FormControl,
  FormLabel,
  Heading,
  Image,
  InputGroup,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Radio,
  RadioGroup,
  Select,
  Skeleton,
  Stack,
  Switch,
  Tag,
  Text,
  useColorModeValue,
  useDisclosure,
  useToast,
  WrapItem,
} from "@chakra-ui/react";
import { FormikErrors, useFormik } from "formik";
import { ReactNode, useEffect, useState, Fragment } from "react";
import "react-quill/dist/quill.snow.css";
import ReactQuill from "react-quill";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { FormattedMessage, useIntl } from "react-intl";

import { access } from "fs";
import moment, { updateLocale } from "moment";
import {
  CouponsDataType,
  CreateCouponsDataType,
  UpdateCouponsDataType,
  useCouponsCreateOne,
  usePatchOneCoupons,
} from "../../api/Coupons";
import {
  BiAddToQueue,
  BiBriefcaseAlt,
  BiChevronDown,
  BiGift,
} from "react-icons/bi";
import { BsFillArrowDownRightCircleFill } from "react-icons/bs";
import { FaShippingFast } from "react-icons/fa";
import { DateTimeInput, Input } from "../../components/commons";
import { TbRefresh } from "react-icons/tb";
import { productDataType, useFindAllProducts } from "../../api/products";
import { TableHeadType } from "../../components/modules/Table/Table.types";
import Table from "../../components/modules/Table/Table";
import {
  ClientsDataType,
  useClientsCreateOne,
  useClientsFindAll,
  usePatchOneClient,
} from "../../api/Clients";
import { Link as RouterLink } from "react-router-dom";

type CouponFormProps = {
  loading?: boolean;
  accessType?: string;
  coupon?: CouponsDataType;
  actionButtons: (node: ReactNode) => void;
};

const Coupon = (props: CouponFormProps) => {
  const {
    mutate: createOneCoupontMutate,
    data: createOneCoupontData,
    isLoading: createOneCoupontIsLoading,
    isSuccess: createOneCoupontIsSuccess,
    isError: createOneCoupontIsError,
    error: createOneCoupontError,
  } = useCouponsCreateOne();
  const {
    mutate: CoupontsUpdateMutate,
    data: CoupontsUpdateData,
    isLoading: CoupontsUpdateIsLoading,
    isSuccess: CoupontsUpdateIsSuccess,
    isError: CoupontsUpdateIsError,
    error: CoupontsUpdateError,
  } = usePatchOneCoupons();
  const { accessType, coupon, loading } = props;
  const couponInitialValues: UpdateCouponsDataType = {
    code: "",
    quantity: 0,
    type: "REDUCTION",
    effectOn: 0,
    products: [],

    validUntil: null,
  };

  const toast = useToast();
  const {
    mutate: createOneCouponMutate,
    data: createOneCouponData,
    isLoading: createOneCouponIsLoading,
    isSuccess: createOneCouponIsSuccess,
    isError: createOneCouponIsError,
    error: createOneCouponError,
  } = useCouponsCreateOne();
  const {
    mutate: couponUpdateMutate,
    data: couponUpdateData,
    isLoading: couponUpdateIsLoading,
    isSuccess: couponUpdateIsSuccess,
    isError: couponUpdateIsError,
    error: couponUpdateError,
  } = usePatchOneCoupons();
  useEffect(() => {
    if (createOneCouponIsSuccess == true) {
      toast({
        title: "Coupon created with succcess",
        status: "success",
        position: `bottom-right`,

        isClosable: true,
      });
      navigate(`/coupons`, {
        state: {
          pageName: "Coupons",
        },
      });
    }
  }, [createOneCouponIsSuccess]);
  useEffect(() => {
    if (couponUpdateIsSuccess) {
      toast({
        title: "Product updated with succcess",
        status: "success",
        position: `bottom-right`,

        isClosable: true,
      });
    }
  }, [couponUpdateIsSuccess]);
  useEffect(() => {
    if (coupon) {
      UpdateCouponFormik.setFieldValue("code", coupon.code);
      UpdateCouponFormik.setFieldValue("quantity", coupon.quantity);
      UpdateCouponFormik.setFieldValue("type", coupon.type);
      UpdateCouponFormik.setFieldValue("effectOn", coupon.effectOn);
      UpdateCouponFormik.setFieldValue("products", coupon.products);

      UpdateCouponFormik.setFieldValue(
        "validUntil",
        coupon.validUntil ? new Date(coupon.validUntil.toString()) : null
      );
      setAddedProducts(coupon.products);
    }
  }, [coupon]);
  const UpdateCouponFormik = useFormik({
    validateOnMount: false,
    validateOnBlur: false,
    validateOnChange: false,
    initialValues: couponInitialValues,
    validate: (values) => {
      const errors: FormikErrors<typeof values> = {};
      if (!values.code) {
        errors.code = "Coupon code is required!";
      }
      if (!values.effectOn || values.effectOn < 1 || values.effectOn > 100) {
        errors.effectOn = "Coupon value must be between 0 and 100!";
      }
      return errors;
    },
    onSubmit: (values, { setSubmitting }) => {
      const result: CreateCouponsDataType = {
        code: values.code ? values.code : "",
        quantity: values.quantity ? values.quantity : 0,
        type: values.type ? values.type : "",
        effectOn: values.effectOn
          ? parseInt(Math.abs(values.effectOn).toString())
          : 0,
        products: values.products ? values.products : [],

        validUntil: moment(values.validUntil).format("yyyy-MM-DD"),
      };
      // console.log("accessType", accessType);
      if (accessType === "UPDATE") {
        couponUpdateMutate({ catObj: result, id: coupon ? coupon?._id : "" });
      } else {
        createOneCouponMutate(result);
      }
    },
  });
  const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
  function generateString(length: number) {
    let result = " ";
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }

    return result;
  }
  // useEffect(() => {
  //   if ( createOneCouponIsSuccess == true) {
  //     toast({
  //       title: "Product created with succcess",
  //       status: "success",
  //       position: `bottom-right`,

  //       isClosable: true,
  //     });
  //     navigate(`/products`, {
  //       state: {
  //         pageName: "Products",
  //       },
  //     });
  //   }
  // }, [  createOneCouponIsSuccess]);
  const renderCouponType = (type: string | null | undefined) => {
    switch (type) {
      case "GIFT":
        return (
          <Stack
            direction="row"
            alignItems="center"
            spacing="2px"
            color="orange"
          >
            <BiGift />
            <Text>
              {" "}
              <FormattedMessage id={"gift"} />
            </Text>
          </Stack>
        );
      case "REDUCTION":
        return (
          <Stack
            direction="row"
            alignItems="center"
            spacing="2px"
            color="green.400"
          >
            <BsFillArrowDownRightCircleFill />
            <Text>
              {" "}
              <FormattedMessage id={"reduction"} />
            </Text>
          </Stack>
        );

      case "FREESHIP":
        return (
          <Stack direction="row" alignItems="center" spacing="2px" color="blue">
            <FaShippingFast />
            <Text>
              <FormattedMessage id={"freeShippment"} />
            </Text>
          </Stack>
        );
    }
  };
  const modules = {
    toolbar: [
      [{ header: "1" }, { header: "2" }, { font: [] }],
      [{ size: [] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link"],
    ],
    clipboard: {
      matchVisual: false,
    },
  };
  useEffect(() => {
    if (createOneCoupontIsSuccess == true) {
      toast({
        title: "Coupon created with succcess",
        status: "success",
        position: `bottom-right`,

        isClosable: true,
      });
      navigate(`/coupons`, {
        state: {
          pageName: "coupons",
        },
      });
    }
  }, [createOneCouponIsSuccess]);
  useEffect(() => {
    props.actionButtons(
      <>
        <Button
          variant="outline"
          onClick={() =>
            navigate(`/coupons`, {
              state: {
                pageName: "coupons",
              },
            })
          }
        >
          <FormattedMessage id={"discard"} />
        </Button>
        <Button
          colorScheme="main"
          type="submit"
          form="create-product-form"
          isLoading={createOneCoupontIsLoading}
        >
          <FormattedMessage id={"save"} />
        </Button>
      </>
    );
  }, [UpdateCouponFormik.isSubmitting]);

  const location = useLocation();
  const navigate = useNavigate();
  const handleAssignProducts = (products: productDataType[]) => {
    UpdateCouponFormik.setFieldValue(
      `products`,
      products.map((e) => e._id)
    );
    setAddedProducts(products);
  };

  //   useEffect(() => {
  //     if (coupo) {
  //       toast({
  //         title: "Product updated with succcess",
  //         status: "success",
  //         position: `bottom-right`,

  //         isClosable: true,
  //       });
  //     }
  //   }, [coupo]);
  const [files, setFiles] = useState<
    {
      progress: boolean;
      id: string;
      src: File | string | undefined;
    }[]
  >([]);

  const [addedProducts, setAddedProducts] = useState<productDataType[]>([]);
  const [addedUsers, setAddedUsers] = useState<ClientsDataType[]>([]);
  const {
    isOpen: isOpenProductModal,
    onOpen: onOpenProductModal,
    onClose: onCloseProductModal,
  } = useDisclosure();
  const {
    isOpen: isOpenUserModal,
    onOpen: onOpenUserModal,
    onClose: onCloseUserModal,
  } = useDisclosure();
  const intl = useIntl();
  const borderColor = useColorModeValue("gray.100", "gray.700");
  const { from } = (location?.state as { from?: string }) || "";

  return (
    <form id="create-product-form" onSubmit={UpdateCouponFormik.handleSubmit}>
      <Stack
        direction="row"
        spacing="14px"
        //border="1px solid red"
        alignItems="flex-start"
      >
        <Stack
          direction={{ base: "column-reverse", xl: "row" }}
          flex={1}
          spacing="12px"
        >
          <Stack flex={1} spacing="18px" p={{ base: "auto", xl: "8px" }}>
            {/* <Skeleton
                isLoaded={!generalProductLoading}
                opacity={generalProductLoading ? 0.4 : 1}
              > */}

            <Stack
              padding="18px 18px"
              border="2px"
              borderColor={borderColor}
              rounded="base"
              spacing="18px"
              border-radius={"12px !important"}
            >
              <Heading size="md" fontWeight="semibold">
                <FormattedMessage id={"generalInforation"} />
              </Heading>
              <Stack direction={{ base: "column", xl: "row" }} spacing="25px">
                <Input
                  label={intl.formatMessage({
                    id: "code",
                  })}
                  inputProps={{
                    placeholder: intl.formatMessage({
                      id: "code",
                    }),
                    name: "code",
                    onChange: UpdateCouponFormik.handleChange,
                    value: UpdateCouponFormik.values.code,
                  }}
                  errorMessage={UpdateCouponFormik.errors.code}
                  inputRightAddon={{
                    children: (
                      <TbRefresh
                        onClick={() => {
                          UpdateCouponFormik.setFieldValue(
                            "code",
                            generateString(Math.random() * 15)
                          );
                        }}
                      />
                    ),
                  }}
                  isError={UpdateCouponFormik.errors.code !== undefined}
                />
                <Input
                  label={intl.formatMessage({
                    id: "quantite",
                  })}
                  inputProps={{
                    placeholder: intl.formatMessage({
                      id: "quantite",
                    }),
                    name: "quantity",
                    onChange: UpdateCouponFormik.handleChange,
                    value: UpdateCouponFormik.values.quantity,
                  }}
                  errorMessage={UpdateCouponFormik.errors.quantity}
                  isError={UpdateCouponFormik.errors.quantity !== undefined}
                />
                {accessType === "UPDATE" ? (
                  <Input
                    label={intl.formatMessage({
                      id: "used",
                    })}
                    inputProps={{
                      placeholder: intl.formatMessage({
                        id: "used",
                      }),
                      name: "used",

                      value: coupon?.used,
                    }}
                  />
                ) : (
                  <></>
                )}
              </Stack>

              <Stack direction={{ base: "column", xl: "row" }} spacing="25px">
                <DateTimeInput
                  name="validUntil"
                  label={intl.formatMessage({
                    id: "validUntil",
                  })}
                  onChange={(date) =>
                    UpdateCouponFormik.setFieldValue("validUntil", date)
                  }
                  selected={UpdateCouponFormik.values?.validUntil}
                  errorMessage={UpdateCouponFormik.errors.validUntil}
                  isError={UpdateCouponFormik.errors.validUntil !== undefined}
                />
                <Input
                  label={intl
                    .formatMessage({
                      id: "value",
                    })
                    .concat(" %")}
                  inputProps={{
                    placeholder: intl
                      .formatMessage({
                        id: "value",
                      })
                      .concat(" %"),
                    name: "effectOn",
                    onChange: UpdateCouponFormik.handleChange,
                    value: UpdateCouponFormik.values.effectOn,
                  }}
                  errorMessage={UpdateCouponFormik.errors.effectOn}
                  isError={UpdateCouponFormik.errors.effectOn !== undefined}
                />
                <FormControl>
                  <FormLabel marginBottom="1">
                    <FormattedMessage id={"type"} />
                  </FormLabel>
                  <InputGroup>
                    {" "}
                    <WrapItem>
                      <Menu>
                        <MenuButton
                          className="typeSelect"
                          as={Button}
                          variant="link"
                          rightIcon={<BiChevronDown size={18} />}
                          height="fit-content"
                        >
                          <Heading
                            size="md"
                            fontWeight="semibold"
                            fontSize="lg"
                          >
                            {renderCouponType(UpdateCouponFormik.values.type)}
                          </Heading>
                        </MenuButton>
                        <MenuList>
                          {["GIFT", "REDUCTION", "DRAFT", "FREESHIP"]
                            .filter((e) => e != UpdateCouponFormik.values.type)
                            .map((type: string, i: number) => (
                              <MenuItem
                                onClick={() =>
                                  UpdateCouponFormik.setFieldValue("type", type)
                                }
                              >
                                {renderCouponType(type)}
                              </MenuItem>
                            ))}
                        </MenuList>
                      </Menu>
                    </WrapItem>
                  </InputGroup>
                </FormControl>{" "}
              </Stack>
            </Stack>
            <Stack
              padding="18px 18px"
              border="2px"
              borderColor={borderColor}
              rounded="base"
              spacing="18px"
              border-radius={"12px !important"}
            >
              <Heading size="md" fontSize="xl" fontWeight="medium">
                <FormattedMessage id={"nav.product"} />
              </Heading>
              <Stack mt="4" spacing="18px" mb="6">
                <Box>
                  <Box
                    mb="2"
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <FormLabel marginBottom="1">
                      {" "}
                      <FormattedMessage id={"product.liste"} />
                    </FormLabel>
                    <Button
                      leftIcon={<BiAddToQueue size={18} />}
                      size="sm"
                      onClick={() => {
                        onOpenProductModal();
                      }}
                    >
                      <FormattedMessage id={"assignProducts"} />
                    </Button>
                  </Box>
                  <Divider my="2" />
                  {addedProducts &&
                    addedProducts.map((product, index) => (
                      <Fragment key={product._id}>
                        <Stack
                          direction="row"
                          justifyContent="space-between"
                          alignItems="center"
                        >
                          <Text fontWeight="medium" fontSize="lg">
                            {product.name}
                          </Text>
                          <Button
                            variant="link"
                            colorScheme="red"
                            size="sm"
                            onClick={() => {
                              UpdateCouponFormik.setFieldValue(
                                `products`,
                                addedProducts
                                  ?.filter((e) => e._id !== product._id)
                                  .map((e) => e._id)
                              );
                              setAddedProducts((prevState) => {
                                let temp = [...prevState].filter(
                                  (e) => e._id !== product._id
                                );

                                return temp;
                              });
                            }}
                          >
                            <FormattedMessage id={"unassignProduct"} />
                          </Button>
                        </Stack>
                        <Divider mt="2" />
                      </Fragment>
                    ))}
                </Box>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
      <ProductAssignModal
        isOpen={isOpenProductModal}
        onClose={onCloseProductModal}
        assignProducts={handleAssignProducts}
        products={addedProducts}
      />
    </form>
  );
};

export default Coupon;
type ProductAssignModalProps = {
  isOpen: boolean;
  onClose: () => void;
  assignProducts: (products: productDataType[]) => void;
  products: productDataType[];
};

type UserAssignModalProps = {
  isOpen: boolean;
  onClose: () => void;
  assignUsers: (users: ClientsDataType[]) => void;
  users: ClientsDataType[];
};

const ProductAssignModal = (props: ProductAssignModalProps) => {
  const { isOpen, onClose, assignProducts, products } = props;
  const { mutate, data, isLoading, isSuccess, isError, error } =
    useFindAllProducts();

  const handleAssignProduct = () => {
    assignProducts(selectedUsers);
    onClose();
  };
  const [productList, setProductList] = useState<productDataType[]>();
  const [search, setSearch] = useState("");

  const [sortByName, setSortByName] = useState<1 | -1>(1);
  const [sortByPrice, setSortByPrice] = useState<1 | -1>(1);
  const [sortByCreated, setSortByCreated] = useState<1 | -1>(-1);
  const [sortByUpdated, setSortByUpdated] = useState<1 | -1>(-1);
  const [pagination, setPagination] = useState({
    limit: 10,
    skip: 0,
  });

  useEffect(() => {
    mutate({
      skip: pagination.skip,
      limit: pagination.limit,
      search: search,
      // category:category,
      // isAvliable:isAvliable,
      name: sortByName,
      price: sortByPrice,
      // status: status,
      createdAt: sortByCreated,
      updatedAt: sortByUpdated,
    });
  }, [pagination]);
  useEffect(() => {
    if (isSuccess) {
      setProductList(data.data.products);
    }
  }, [isSuccess, pagination]);

  const handlePageChange = (dir: "next" | "prev") => {
    if (dir === "next") {
      setPagination((prevState) => {
        let temp = { ...prevState };
        temp.skip = temp.skip + temp.limit;
        return temp;
      });
    } else {
      setPagination((prevState) => {
        let temp = { ...prevState };
        temp.skip = temp.skip - temp.limit;
        return temp;
      });
    }
  };

  const handleLimitChange = (value: number) => {
    setPagination((prevState) => {
      let temp = { ...prevState };
      temp.limit = +value;
      return temp;
    });
  };

  const [selectedUsers, setSelectedUsers] =
    useState<productDataType[]>(products);

  useEffect(() => {
    setSelectedUsers(products);
  }, [products]);
  // console.log("selectedItem", selectedUsers);
  const handleSelectProduct = (prod: productDataType) => {
    setSelectedUsers((prevState) => [...prevState, prod]);
  };

  const handleRemoveSelectedProduct = (id: string) => {
    setSelectedUsers((prevState) => {
      let temp = [...prevState];
      const index = temp.findIndex((object) => {
        return object._id === id;
      });
      if (index > -1) {
        temp.splice(index, 1);
      }
      return temp;
    });
  };

  const productsToAssignTableData =
    (productList &&
      productList?.map((item, index) => ({
        id: item._id,
        check: (
          <Checkbox
            value={item._id}
            isChecked={Boolean(selectedUsers.find((e) => e._id == item._id))}
            onChange={(e) => {
              if (e.target.checked) {
                handleSelectProduct(item);
              } else {
                handleRemoveSelectedProduct(e.target.value);
              }
            }}
          />
        ),
        name: item.name,

        category: item.category && item.category.name,

        basePrice: item.price.price + " TND",
      }))) ||
    [];

  type ProductsToAssignTableType = typeof productsToAssignTableData[number];

  const productsToAssignTableHead: TableHeadType<ProductsToAssignTableType>[] =
    [
      { accessor: "check", label: "" },
      { accessor: "name", label: "Name" },

      { accessor: "category", label: "Category" },
      { accessor: "basePrice", label: "Base Price" },
    ];

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      scrollBehavior="inside"
      size="2xl"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader borderBottomWidth="1px">
          <FormattedMessage id={"assignProducts"} />
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Stack spacing="14px" py="14px">
            <Box>
              {selectedUsers.length !== 0 && (
                <Text fontSize="sm" opacity={0.6}>
                  {selectedUsers.length} <FormattedMessage id={"selected"} />
                </Text>
              )}
              <Table
                head={productsToAssignTableHead}
                data={productsToAssignTableData}
                // isLoading={productToAssignListLoading}
                emptyState={
                  <Box className="empty_table_container">
                    <BiBriefcaseAlt size={42} />
                    <Text fontSize="md" fontWeight="medium">
                      There is no assigned products!
                    </Text>
                  </Box>
                }
                pagination={{
                  count: data?.data.queryCount,
                  limit: pagination.limit,
                  skip: pagination.skip,
                  onPageChange: handlePageChange,
                  onChangeLimit: handleLimitChange,
                }}
              />
            </Box>
          </Stack>
        </ModalBody>

        <ModalFooter borderTopWidth="1px">
          <Button variant="Outline" onClick={onClose}>
            <FormattedMessage id={"role.update.btn.cancel"} />
          </Button>
          <Button colorScheme="main" onClick={handleAssignProduct}>
            <FormattedMessage id={"validate"} />
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
