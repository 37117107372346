import {
  Box,
  Link,
  Text,
  Stack,
  Button,
  Heading,
  Divider,
  useToast,
  useDisclosure,
  useColorModeValue,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Select,
  IconButton,
  Spinner,
  Skeleton,
  Wrap,
  WrapItem,
  Image,
  Flex,
} from "@chakra-ui/react";
import moment from "moment";
import { useEffect, useState } from "react";
import { AiOutlineEye } from "react-icons/ai";

import {
  BiArrowBack,
  BiChevronDown,
  BiChevronLeft,
  BiChevronRight,
  BiFilterAlt,
  BiMoney,
  BiSearch,
  BiShow,
} from "react-icons/bi";
import { FormattedMessage, useIntl } from "react-intl";
import { useNavigate, Link as RouterLink, useLocation } from "react-router-dom";
import {
  OrdersTypeData,
  useFindAllOrders,
  usePatchOneOrder,
} from "../../api/Orders";
import { Input } from "../../components/commons";

import { PaginationWrapper } from "../../components/modules/Table/Table.styles";

import { PageWrapper } from "../../utils/globalStyle";
import { GetAllOrders, fakeOrders } from "../../utils/globalTypes";
import OrderListFilter from "./Filters/OrdersListFilter";

// import OrderListFilter from "./Filters/OrdersListFilter";

const Orders = () => {
  const OrderItem = (props: OrderItemProps) => {
    const { order, index } = props;
    const {
      mutate: ordersUpdateMutate,
      data: ordersUpdateData,
      isLoading: ordersUpdateIsLoading,
      isSuccess: ordersUpdateIsSuccess,
      isError: ordersUpdateIsError,
      error: ordersUpdateError,
    } = usePatchOneOrder();

    const wrapperBorder = useColorModeValue("gray.200", "gray.600");
    const bgHoverColor = useColorModeValue("gray.50", "gray.700");

    const storeID = "";

    const location = useLocation();

    const navigate = useNavigate();

    const renderPaymentStatus = (status: string | null | undefined) => {
      switch (status) {
        case "PENDING":
          return {
            text: intl.formatMessage({
              id: "awaitingPayment",
            }),
            color: "yellow",
          };
        case "SUCCESS":
          return {
            text: intl.formatMessage({
              id: "Paid",
            }),
            color: "green",
          };
        case "FAILED":
          return {
            text: intl.formatMessage({
              id: "Payment failed",
            }),
            color: "red",
          };
        case "REFUNDED":
          return {
            text: intl.formatMessage({
              id: "Refunded",
            }),
            color: "blue",
          };
        case "COMPLETED":
          return {
            text: intl.formatMessage({
              id: "Payment completed",
            }),
            color: "green",
          };
        default:
          return {
            text: status,
            color: "black",
          };
      }
    };

    const renderOrderStatus = (status: string) => {
      switch (status) {
        case "PENDING":
          return {
            text: intl.formatMessage({
              id: "awaitingProcessing",
            }),
            color: "blue",
          };
        case "PROCESSING":
          return {
            text: intl.formatMessage({
              id: "processing",
            }),
            color: "blue",
          };
        case "CONFIRMED":
          return {
            text: intl.formatMessage({
              id: "confirmed",
            }),
            color: "green",
          };
        case "READY":
          return {
            text: intl.formatMessage({
              id: "readyDelivery",
            }),
            color: "green",
          };
        case "TRANSPORTING":
          return {
            text: intl.formatMessage({
              id: "transportation",
            }),
            color: "blue",
          };
        case "COMPLETED":
          return {
            text: intl.formatMessage({
              id: "orderCompleted",
            }),
            color: "green",
          };
        case "CANCELED":
          return {
            text: intl.formatMessage({
              id: "orderCanceled",
            }),
            color: "red",
          };
        case "REFUNDED":
          return {
            text: intl.formatMessage({
              id: "orderRefunded",
            }),
            color: "red",
          };
        case "FAILED":
          return {
            text: intl.formatMessage({
              id: "orderFailed",
            }),
            color: "red",
          };
        default:
          return {
            text: status,
            color: "black",
          };
      }
    };
    useEffect(() => {
      if (ordersUpdateIsSuccess) {
        toast({
          title: "Order status has bean updates with sucsess",
          position: `bottom-right`,
          status: "success",
          isClosable: true,
        });
      }
    }, [ordersUpdateIsSuccess]);

    const renderPaymentType = (type: string | null | undefined) => {
      switch (type) {
        case "ONLINE":
          return (
            <>
              <BiMoney fill="#3A85F5" size={18} />
              <Text ml="1" fontWeight="medium">
                <FormattedMessage id={"onlinePayment"} />
              </Text>
            </>
          );
        default:
          return (
            <>
              <BiMoney color="#3A85F5" />
              <Text ml="1" fontWeight="medium">
                <FormattedMessage id={"cashDelivery"} />
              </Text>
            </>
          );
      }
    };

    const toast = useToast();

    // const [changeOrderStatusMutation, changeOrderStatusParams] =
    //   useUpdateOrderStatusMutation();

    const handleChangeOrderStatus = (orderID: string, status: string) => {
      ordersUpdateMutate({ catObj: { status: status }, id: orderID });
      setOrders((prevState) => {
        let temp = [...prevState];
        temp[index].status = status;

        return temp;
      });
      if (ordersUpdateIsSuccess) {
        setOrders((prevState) => {
          let temp = [...prevState];
          temp[index].status = status;

          return temp;
        });
      }
    };

    return (
      <Flex
        mb="2"
        gap={"5"}
        wrap="wrap"
        align={{ base: "flex-start", md: "center" }}
        //justify={{base:"column",md:"row"}}
        direction={{ base: "column", md: "row" }}
        //justifyContent="space-between"
        m="20px"
        p="0 18px"
        //spacing={{ lg: "50px", base: "20px" }}
        // border="1px"
        // borderColor={wrapperBorder}
        rounded="md"
        boxShadow="none"
        transition="0.1s ease-in all"
        _hover={{
          backgroundColor: bgHoverColor,
          cursor: "pointer",
          boxShadow: "0 0 0 1px #0b5eda",
          borderColor: "#0b5eda",
        }}
      >
        <Box
          display="flex"
          mr={3}
          
          my={2}
          flexDirection="column"
          justifyContent="space-between"
        >
          <Heading size="md" fontWeight="bold" fontSize="lg">
            #{order?.refId.replaceAll("#", "")}
          </Heading>
          <Text opacity={0.6}>{moment(order?.createdAt).format("LLL")}</Text>
        </Box>
        <Box mr={3} my={2}  alignItems={"center"}  gap={"5"}  display="flex">
          {/* <Stack spacing="6px"> */}
            <Button
              variant="link"
              height="fit-content"
              colorScheme={renderPaymentStatus(order?.paymentStatus).color}
              pointerEvents="none"
            >
              <Heading size="md" fontWeight="semibold" fontSize="lg">
                {renderPaymentStatus(order?.paymentStatus).text}
              </Heading>
            </Button>
            <Box display="flex" alignItems="center">
              {renderPaymentType(order.paymentType)}
            </Box>
          {/* </Stack> */}
        </Box>
        <Box  mr={0} my={0} alignItems={"center"} gap={"5"}  display="flex" >
          <Menu >
            <MenuButton
              as={Button}
              variant="link"
              rightIcon={<BiChevronDown size={18} />}
              height="fit-content"
              colorScheme={renderOrderStatus(order?.status).color}
            >
              <Heading size="md" fontWeight="semibold" fontSize="lg">
                {renderOrderStatus(order?.status).text}
              </Heading>
            </MenuButton>
            <MenuList>
              <MenuItem 
                onClick={() => handleChangeOrderStatus(order?._id, "PENDING")}
              >
                {renderOrderStatus("PENDING").text}
              </MenuItem>
              <MenuItem
                onClick={() =>
                  handleChangeOrderStatus(order?._id, "PROCESSING")
                }
              >
                {renderOrderStatus("PROCESSING").text}
              </MenuItem>
              <MenuItem
                onClick={() => handleChangeOrderStatus(order?._id, "CONFIRMED")}
              >
                {renderOrderStatus("CONFIRMED").text}
              </MenuItem>
              <MenuItem
                onClick={() => handleChangeOrderStatus(order?._id, "READY")}
              >
                {renderOrderStatus("READY").text}
              </MenuItem>
              <MenuItem
                onClick={() =>
                  handleChangeOrderStatus(order?._id, "TRANSPORTING")
                }
              >
                {renderOrderStatus("TRANSPORTING").text}
              </MenuItem>
              <MenuItem
                onClick={() => handleChangeOrderStatus(order?._id, "COMPLETED")}
              >
                {renderOrderStatus("COMPLETED").text}
              </MenuItem>
              <MenuItem
                onClick={() => handleChangeOrderStatus(order?._id, "CANCELED")}
              >
                {renderOrderStatus("CANCELED").text}
              </MenuItem>
              <MenuItem
                onClick={() => handleChangeOrderStatus(order?._id, "REFUNDED")}
              >
                {renderOrderStatus("REFUNDED").text}
              </MenuItem>
              <MenuItem
                onClick={() => handleChangeOrderStatus(order?._id, "FAILED")}
              >
                {renderOrderStatus("FAILED").text}
              </MenuItem>
            </MenuList>
          </Menu>
        </Box>
        {order?.user ? (
           <Box  mr={3} my={2} alignItems={"center"} gap={"5"}  display="flex" >
           
              <Box>
                <Link
                  color="blue.300"
                  target="_blank"
                  href={`/users/${order?.user._id ? order?.user._id : ""}`}
                >
                  <Stack direction="row" alignItems="center" spacing="4px">
                    <Text whiteSpace="nowrap">{order?.user.fullName}</Text>{" "}
                    <AiOutlineEye size={16} />
                  </Stack>
                </Link>
              </Box>
           
          </Box>
        ) : (
          <Box></Box>
        )}

        <Box
          alignItems={"center"}
          ml={{ base: "0", md: "auto" }}
          textAlign="right"
        >
          <Stack direction="row" spacing="10px" alignItems="center">
            <Heading size="md" fontWeight="semibold" fontSize="xl">
              {order?.totalePrice} TND
            </Heading>
            {/* <Button variant='outline'>Print</Button> */}
            <Button
              variant="outline"
              onClick={() => navigate(`/orders/${order._id}`)}
              leftIcon={<BiShow size={18} />}
            >
              <FormattedMessage id={"viewOrder"} />
            </Button>
          </Stack>
        </Box>
      </Flex>
    );
  };
  const { mutate, data, isLoading, isSuccess, isError, error } =
    useFindAllOrders();
  const {
    mutate: orderUpdateMutate,
    data: orderUpdateData,
    isLoading: orderUpdateIsLoading,
    isSuccess: orderUpdateIsSuccess,
    isError: orderUpdateIsError,
    error: orderUpdateError,
  } = usePatchOneOrder();
  const location = useLocation();

  const { from } = (location?.state as { from?: string }) || "";

  const toast = useToast();
  const [sortByCreated, setSortByCreated] = useState<1 | -1>(-1);
  const [sortByUpdated, setSortByUpdated] = useState<1 | -1>(-1);
  const navigate = useNavigate();
  const [search, setSearch] = useState("");
  const [pagination, setPagination] = useState({
    count: 0,
    limit: 5,
    skip: 0,
    page: 1,
  });

  // const [filters, setFilters] =
  // 	useState<Pick<GetAllOrdersQueryVariables, "user" | "status">>();
  useEffect(() => {
    mutate({
      skip: pagination.skip,
      limit: pagination.limit,
      search: search,

      createdAt: sortByCreated,
      updatedAt: sortByUpdated,
    });
  }, [pagination, search]);  
  useEffect(() => {
    if (isSuccess) {
      setOrders(data.data.orders);
    }
  }, [isSuccess]);

  const [orders, setOrders] = useState<OrdersTypeData[]>([]);

  const [defaultFilterValues, setDefaultFilterValues] = useState({
    user: "",
    status: "",
  });

  const handlePageChange = (dir: "next" | "prev") => {
    if (dir === "next") {
      setPagination((prevState) => {
        let temp = { ...prevState };
        temp.skip = temp.skip + temp.limit;
        return temp;
      });
    } else {
      setPagination((prevState) => {
        let temp = { ...prevState };
        temp.skip = temp.skip - temp.limit;
        return temp;
      });
    }
  };

  const handleLimitChange = (value: number) => {
    setPagination((prevState) => {
      let temp = { ...prevState };
      temp.limit = +value;
      return temp;
    });
  };

  const handleFilter = (values: { user: string; status: string }) => {
    setDefaultFilterValues(values);
  };
  //   	setFilters({
  //   		user: "",
  //   		status:"",
  //   	});
  //   };

  const getFiltersCount = () => {
    let count = 0;

    //   Object.values(filters).forEach((value, index) => {
    //     let testValue: string | undefined = undefined;
    //     if (typeof value !== "string") {
    //       testValue = value?.id !== "" ? value?.id : undefined;
    //     } else {
    //       testValue = value;
    //     }
    //     if (testValue) {
    //       count = count + 1;
    //     }
    //   });

    return count;
  };
  const {
    isOpen: openFilter,
    onOpen: onOpenFilter,
    onClose: onCloseFilter,
  } = useDisclosure();
  const handleResetFilter = () => {
    // setFilters(undefined);
    setDefaultFilterValues({
      user: "",
      status: "",
    });
  };

  const handleSearchSubmit = (event: any) => {
    if (event.code === "Enter") {
      // console.log(event.target.value);
      setSearch(event.target.value);
      mutate({
        skip: pagination.skip,
        limit: pagination.limit,
        search: search,
        // category:category,
        // isAvliable:isAvliable,

        // status: status,
        createdAt: sortByCreated,
        updatedAt: sortByUpdated,
      });
    }
  };
  const intl = useIntl();
  return (
    <PageWrapper>
      <Box className="page_header">
        <Box className="page_header_title">
         
          <Box className="page_header_title_content">
            <Heading fontSize="2xl">
              <FormattedMessage id={"orders.list"} />
            </Heading>
          </Box>
        </Box>
      </Box>
      <Box className="page_actions">
        <Input
          inputProps={{
            placeholder: intl.formatMessage({
              id: "orderSearch",
            }),
            onKeyUp: handleSearchSubmit,
          }}
          inputLeftElementProps={{ children: <BiSearch /> }}
        />
        {/* <Divider orientation="vertical" />
        <Button
          variant="outline"
          leftIcon={
            getFiltersCount() === 0 ? (
              <BiFilterAlt />
            ) : (
              <Text
                bgColor="main.400"
                color="white"
                px="2"
                py="1"
                rounded="full"
                fontSize="sm"
              >
                {getFiltersCount()}
              </Text>
            )
          }
          onClick={onOpenFilter}
        >
          Filter
        </Button> */}
      </Box>

      <Box width="100%" overflowY="scroll">
        <Box>
          {orders?.map((order, index) => (
            <OrderItem order={order} key={index} index={index} />
          ))}
        </Box>
        {orders && (
          <PaginationWrapper>
            <Box className="rows_perpage">
              <Text>
                <FormattedMessage id={"pagination"} />
              </Text>
              <Select
                size="sm"
                onChange={(e) => handleLimitChange(parseInt(e.target.value))}
                value={pagination.limit}
              >
                <option value={5}>5</option>
                <option value={10}>10</option>
                <option value={25}>25</option>
                <option value={50}>50</option>
                <option value={100}>100</option>
              </Select>
            </Box>
            <Box className="pagination_action">
              <Text>{`${pagination.skip + 1} - ${
                pagination.skip + data?.data.ordersCount >
                (data?.data.queryCount || 0)
                  ? data?.data.queryCount || 0
                  : pagination.skip + data?.data.ordersCount
              } of ${data?.data.queryCount || 0}`}</Text>
              <IconButton
                onClick={() => handlePageChange("prev")}
                disabled={pagination.skip < 1}
                aria-label="previous_button"
                size="sm"
                variant="ghost"
              >
                <BiChevronLeft size={24} />
              </IconButton>
              <IconButton
                onClick={() => handlePageChange("next")}
                aria-label="next_button"
                disabled={
                  (data?.data.queryCount || 0) <
                  pagination.skip + data?.data.ordersCount
                }
                size="sm"
                variant="ghost"
              >
                <BiChevronRight size={24} />
              </IconButton>
            </Box>
          </PaginationWrapper>
        )}
      </Box>

      <OrderListFilter
        isOpen={openFilter}
        onClose={onCloseFilter}
        handleSubmitFilter={handleFilter}
        defaultValues={defaultFilterValues}
        handleResetFilter={handleResetFilter}
      />
    </PageWrapper>
  );
};

type OrderItemProps = {
  order: OrdersTypeData;
  index: number;
};

export default Orders;
