import { useMutation } from "@tanstack/react-query";
import { CONTACTS_FIND_ALL, paginationContacts } from "../endpoints/contacts";
import { protectedGet } from "../http-methods";

export type ContactsDataType = {
  _id:string;
  fullName:string;
  phoneNumber:string;
  email:string;
  object:string;
  message:string;
  createdAt: string;
  updatedAt: string;
  __v:number;
};
export const useFindAllContacts = () => {
    return useMutation({
      mutationFn: (obj: paginationContacts) => {
        return protectedGet(CONTACTS_FIND_ALL(obj));
      },
    });
  };