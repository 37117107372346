import { Box } from "@chakra-ui/react";
import styled from "@emotion/styled";

export const TableWrapper = styled(Box)`
	.table_image {
		height: 32px;
		width: 32px;
		border-radius: 6px;
		> img {
			height: 32px;
			width: 32px;
			border-radius: 6px;
		}
	}
	padding: 8px 12px;
`;

export const PaginationWrapper = styled(Box)`
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 12px 0;
	.rows_perpage {
		display: flex;
		align-items: center;
		> p {
			white-space: nowrap;
			margin-right: 10px;
		}
	}
	.pagination_action {
		display: flex;
		align-items: center;
	}
`;
