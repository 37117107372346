import {
  Box,
  Heading,
  Skeleton,
  Button,
  Tabs,
  TabList,
  Tab,
  TabPanel,
  TabPanels,
  useToast,
  IconButton,
  Stack,
  useDisclosure,
} from "@chakra-ui/react";
import { useFormik } from "formik";
import { useEffect, useRef, useState } from "react";

import { CategoryFormWrapper } from "../Categories.styles";

import "react-quill/dist/quill.snow.css";

import {
  AttributCategory,
  GeneralCategoryForm,
  
  SEOCategoryForm,
} from "./UpdateCategoriesForms";
import { BiTrash } from "react-icons/bi";
import ConfirmDeleteDialog from "../../../components/modules/ConfirmDeleteDialog/ConfirmDeleteDialog";

import { FormattedMessage, useIntl } from "react-intl";
import {
  CategoryDataType,
  useCategoriesDeleteOne,
  useCategoriesUpdateOne,
} from "../../../api/categories";

type UpdateCategoryProps = {
  isLoading: boolean;
  selectedCat: CategoryDataType;

  onDeleteCategory: (action: string) => void;
};

const UpdateCategory = (props: UpdateCategoryProps) => {
  const { isLoading, selectedCat, onDeleteCategory } = props;
  // console.log(selectedCat, "selectedCat");

  // const { data: categoryGeneralData, loading: categoryGeneralLoading } =
  // 	useGetCategoryGeneralByIdQuery({
  // 		variables: {
  // 			id: selectedCat,
  // 		},
  // 	});

  const [files, setFiles] = useState<
    {
      progress: boolean;
      id: string;
      src: File | string | undefined;
    }[]
  >([]);

  const handleFile = async (file: File) => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("upload_preset", "ml_default");
    formData.append("public_id", `categories/${file.name}`);
    setFiles((prevFiles) => [
      ...prevFiles,
      {
        src: file,
        id: file.name,
        progress: true,
        name: file.name,
        type: file.type,
      },
    ]);
    const data = await (
      await fetch("https://api.cloudinary.com/v1_1/dgpjskmv1/upload", {
        method: "POST",
        body: formData,
      })
    ).json();

    setFiles((prevFiles) => [
      ...prevFiles.filter(({ id }) => id !== file.name),
      {
        src: data.secure_url,
        id: data.asset_id,
        progress: false,
      },
    ]);
  };

  const handleDelete = (id: string) => {
    // console.log(
    //   id,
    //   files.filter(({ id: imageId }) => id !== imageId)
    // );
    setFiles((prevFiles) =>
      prevFiles.filter(({ id: imageId }) => id !== imageId)
    );
  };
  const intialGeneralValues: {
    name: string;
    description: string;
    status: string;
    images: string;
  } = {
    name: "",
    description: "",
    status: "",
    images: "",
  };
  // const [updateCategoryGeneralMutation] = useUpdateCategoryGeneralMutation();

  const [tabIndex, setTabIndex] = useState(0);

  const toast = useToast();

  const categoryGeneralSettingsFormik = useFormik({
    validateOnMount: false,
    validateOnBlur: false,
    validateOnChange: false,
    initialValues: intialGeneralValues,
    onSubmit: (values, { setSubmitting }) => {
      values.images = files?.[0]?.src ? (files?.[0]?.src as string) : "";
      // console.log("resultat", values);
      categoriesUpdateMutate({ catObj: values, id: selectedCat._id });
      if (categoriesUpdateIsError)
        toast({
          title: "An error occured while updating category.",

          status: "error",

          position: "bottom-right",
          duration: 5000,
          isClosable: true,
        });
    },
  });

  const {
    mutate: categoriesUpdateMutate,
    data: categoriesUpdateData,
    isLoading: categoriesUpdateIsLoading,
    isSuccess: categoriesUpdateIsSuccess,
    isError: categoriesUpdateIsError,
    error: categoriesUpdateError,
  } = useCategoriesUpdateOne();
  useEffect(() => {
    if (categoriesUpdateIsSuccess) {
      toast({
        title: "Category has been updated with success!",
        status: "success",
        position: `bottom-right`,

        isClosable: true,
      });
      onDeleteCategory("update");
    }
  }, [categoriesUpdateIsSuccess]);
  const {
    mutate: deleteCategoryMutate,
    isSuccess: isSuccessdeleteCategoryMutate,
  } = useCategoriesDeleteOne();
  const handleDeleteCategory = () => {
    deleteCategoryMutate(selectedCat._id);
  };
  useEffect(() => {
    if (isSuccessdeleteCategoryMutate) {
      toast({
        title: "Category has been delated with success!",
        status: "success",
        position: `bottom-right`,

        isClosable: true,
      });
      onDeleteCategory("delete");
    }
  }, [isSuccessdeleteCategoryMutate]);
  useEffect(() => {
    setFiles(
      selectedCat?.image
        ? [
            {
              progress: false,
              id: selectedCat._id,
              src: selectedCat.image,
            },
          ]
        : []
    );

    categoryGeneralSettingsFormik.setFieldValue("name", selectedCat.name || "");
    categoryGeneralSettingsFormik.setFieldValue(
      "description",
      selectedCat.description || ""
    );
    categoryGeneralSettingsFormik.setFieldValue(
      "status",
      selectedCat.status || ""
    );
    categorySEOSettingsFormik.setFieldValue(
      "keywords",
      selectedCat.seo.keywords
    );
    categorySEOSettingsFormik.setFieldValue(
      "slug",
      selectedCat.slug
    );
    categorySEOSettingsFormik.setFieldValue(
      "seotitle",
      selectedCat.seo.seotitle || ""
    );

    setMetaTags(
      selectedCat?.seo.metatags?.map((tag) => ({
        key: tag.key || "",
        value: tag.value || "",
      })) || []
    );
    categorySEOSettingsFormik.setFieldValue(
      "metadescription",
      selectedCat.seo.metadescription || ""
    );
  }, [selectedCat]);

  const [metaTags, setMetaTags] = useState<
    { key?: string | null; value?: string | null }[]
  >([]);
  const handleChangeMetaTags = (
    value: string,
    index: number,
    type: "key" | "value"
  ) => {
    setMetaTags((prevState) => {
      let temp = [...prevState];
      if (type === "key") {
        temp[index].key = value;
      } else {
        temp[index].value = value;
      }
      return temp;
    });
  };

  const handleDeleteMetaTag = (index: number) => {
    setMetaTags((prevState) => {
      let temp = [...prevState];
      temp.splice(index, 1);
      return temp;
    });
  };

  const handleAddMetatag = () => {
    setMetaTags((prevState) => [...prevState, { key: "", value: "" }]);
  };

  const categorySEOSettingsFormik = useFormik({
    validateOnMount: false,
    validateOnBlur: false,
    validateOnChange: false,
    initialValues: {
      seotitle: "",
      slug:"",
      keywords: "",
      metadescription: "",
    },
    onSubmit: (values, { setSubmitting }) => {
      const resultaSeo = {
        slug:values.slug,
        seo: {
          seotitle: values.seotitle,

          keywords: values.keywords,
          metadescription: values.metadescription,
          metatags: metaTags,
        },
      };
      // console.log("seo", values);
      categoriesUpdateMutate({ catObj: resultaSeo, id: selectedCat._id });
    },
  });

  // const [deleteCategoryMutation, deleteCatgoryParam] =
  // 	useDeleteCategoryMutation();
  const intl = useIntl();
  return (
    <CategoryFormWrapper>
      <Box className="category_form_header">
        {selectedCat ? (
          <Stack direction="row" alignItems="center">
            <Heading size="md">
              {intl.formatMessage({
                id: "category",
              })}
              " {selectedCat.name}"
            </Heading>
            <ConfirmDeleteDialog
              item="Category"
              dialogButtonProps={{
                type: "IconButton",

                children: <BiTrash size={18} />,
                props: {
                  variant: "ghost",
                  size: "sm",
                  // isLoading: deleteCatgoryParam.loading,
                },
              }}
              onDelete={handleDeleteCategory}
              // isLoading={deleteCatgoryParam.loading}
            />
          </Stack>
        ) : (
          <Heading size="md">
            {intl.formatMessage({
              id: "category.add.title",
            })}
          </Heading>
        )}
        {tabIndex !== 1 ? (
          <Button
            colorScheme="main"
            size="sm"
            type="submit"
            isLoading={categoriesUpdateIsLoading}
            form={
            
                 "update-general-category-form"
            
            }
          >
            {intl.formatMessage({
              id: "category.Update.btn",
            })}
          </Button>
        ) : (
          <Button
          colorScheme="main"
          size="sm"
          type="submit"
          isLoading={categoriesUpdateIsLoading}
          form={
            
               "update-seo-category-form"
          }
        >
          {intl.formatMessage({
            id: "category.Update.btn",
          })}
        </Button>
        )}
      </Box>
      <Tabs
        index={tabIndex}
        onChange={(index: number) => setTabIndex(index)}
        colorScheme="blue"
        isLazy
      >
        <TabList  overflowX="auto"
        overflowY= "hidden">
          <Tab>
            <FormattedMessage id={"product.nav.general"} />
          </Tab>

          <Tab>
            <FormattedMessage id={"product.nav.seo"} />
          </Tab>
        </TabList>

        <TabPanels>
          <TabPanel p="12px 0px">
            <GeneralCategoryForm
              files={files}
              handleDelete={handleDelete}
              handleFile={handleFile}
              setFieldValue={categoryGeneralSettingsFormik.setFieldValue}
              errors={categoryGeneralSettingsFormik.errors}
              handleChange={categoryGeneralSettingsFormik.handleChange}
              onSubmit={categoryGeneralSettingsFormik.handleSubmit}
              values={categoryGeneralSettingsFormik.values}
              isLoading={isLoading}
            />
          </TabPanel>

          <TabPanel>
            <SEOCategoryForm
              setFieldValue={categorySEOSettingsFormik.setFieldValue}
              errors={categorySEOSettingsFormik.errors}
              handleChange={categorySEOSettingsFormik.handleChange}
              onSubmit={categorySEOSettingsFormik.handleSubmit}
              values={categorySEOSettingsFormik.values}
              isLoading={false}
              handleAddMetatag={handleAddMetatag}
              handleChangeMetaTags={handleChangeMetaTags}
              handleDeleteMetaTag={handleDeleteMetaTag}
              metaTags={metaTags}
            />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </CategoryFormWrapper>
  );
};

export default UpdateCategory;
