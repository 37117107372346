import { objToParam } from "./categories";

export type paginationCoupons = {
  skip?: number;
  limit?: number;
  search?: string;
  type?: string;
  createdAt?: 1 | -1;
  updatedAt?: 1 | -1;
};
export const COUPONS_FIND_ALL = (obj: paginationCoupons = {}) =>
  `coupons/findAll${objToParam(obj)}`;
export const COUPONS_CREATE_ONE = "coupons/createOne";
export const COUPONS_DELETE_ONE = "coupons/deleteOne";
export const COUPONS_FIND_ONE = (obj?: string) => `coupons/findOne/${obj}`;
export const COUPONS_PATCH_ONE = (id: string) => `coupons/patchOne/${id}`;