import { objToParam } from "./categories";

export const INVENTORY_FIND_ALL = (obj: {
 
  product?: string;
  skip?: number;
  limit?: number;
  search?: string,
  createdAt?: 1|-1;
}) => `inventorys/findAll${objToParam(obj)}`;
export const ACTIVATED_INVENTORY_FIND_ALL = (obj: {
  linkedTo?: string;
  skip?: number;
  limit?: number;
  search?: string,
}) => `inventorys/activatedProducts${objToParam(obj)}`;
export const INVENTORY_CREATE_ONE = (qt: number) =>
  `inventorys/createMany/${qt}`;
  export const INVENTORY_PATCH_ONE = (id: string) => `inventorys/patchOne/${id}`;