import {
  Box,
  Button,
  Heading,
  Text,
  Link,
  Stack,
  Tabs,
  TabList,
  Tab,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverCloseButton,
  PopoverHeader,
  PopoverBody,
  TabPanels,
  TabPanel,
  Flex,
  useColorModeValue,
  useToast,
  Skeleton,
} from "@chakra-ui/react";
import {
  Link as RouterLink,
  Navigate,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { BiArrowBack } from "react-icons/bi";
import { PageWrapper } from "../../../utils/globalStyle";
import { ReactNode, useEffect, useState } from "react";
import ProductGeneralForm from "../Forms/ProductGeneralForm";

import { getDataFromCache } from "../../../utils/cache";
import ProductVariationsForm from "../Forms/ProductVariationsForm";
import ProductInventoryForm from "../Forms/ProductInventoryForm";
import ProductSEOForm from "../Forms/ProductSEOForm";
import { productDataType, useFindOneProducts } from "../../../api/products";
import { FormattedMessage } from "react-intl";

const UpdateProduct = () => {
  const location = useLocation();

  const { from } = (location?.state as { from?: string }) || "";

  const { productID } = useParams();
  const [product, setProduct] = useState<productDataType>();

  const storeID = getDataFromCache("store_id");

  const userRole: string = getDataFromCache("user_role", true);

  const [tabIndex, setTabIndex] = useState<number>(0);

  const [actionButtons, setActionButtons] = useState<ReactNode>();

  const getActionButtons = (node?: ReactNode) => {
    setActionButtons(node);
  };

  const toast = useToast();

  const navigate = useNavigate();
  const { mutate, data, isLoading, isSuccess, isError, error } =
    useFindOneProducts();
  useEffect(() => {
    mutate(productID);
  }, []);
  useEffect(() => {
    if (isSuccess) {
      setProduct(data.data.product);
    }
  }, [isSuccess]);

  const handleChangeIndex = (index: number) => {
    setTabIndex(index);
    setActionButtons(undefined);
  };

  return (
    <PageWrapper>
      <Box
        className="page_header"
        height="auto !important"
        alignItems="baseline !important"
        flexDirection={{ base: "column", md: "row" }}
      >
        <Box className="page_header_title">
          <Box className="page_header_title_content">
            <Skeleton
            // isLoaded={!productNameLoading}
            // opacity={productNameLoading ? 0.4 : 1}
            >
              <Heading fontSize="2xl">
                {/* {productNameData?.GetProductById?.name} */}
              </Heading>
            </Skeleton>
          </Box>
        </Box>
        <Stack marginTop={{ base: "12px", md: "auto" }} direction="row">
          {actionButtons}
        </Stack>
      </Box>
      {product?._id !== "63c40e7c0513c8014979ac77" ? (
        <Tabs
          index={tabIndex}
          colorScheme="blue"
          onChange={handleChangeIndex}
          isLazy
        >
          <TabList width="100%" overflowX="scroll" overflowY="clip">
            <Tab>
              <FormattedMessage id={"category.nav.general"} />
            </Tab>

            <Tab>
              <FormattedMessage id={"product.nav.variations"} />
            </Tab>
            <Tab>
              <FormattedMessage id={"product.nav.inventory"} />
            </Tab>

            <Tab>
              <FormattedMessage id={"product.nav.seo"} />
            </Tab>
          </TabList>
          <TabPanels>
            <TabPanel p="12px 0px">
              <ProductGeneralForm
                actionButtons={getActionButtons}
                loading={isLoading}
                accessType="UPDATE"
                product={product}
              />
            </TabPanel>
            <TabPanel p="12px 0px">
              <ProductVariationsForm
                actionButtons={getActionButtons}
                product={product}
                validation={() => mutate(productID)}
              />
            </TabPanel>
            <TabPanel p="12px 0px">
              <ProductInventoryForm
                actionButtons={getActionButtons}
                productID={productID}
              />
            </TabPanel>

            <TabPanel p="12px 0px">
              <ProductSEOForm
                actionButtons={getActionButtons}
                product={product}
              />
            </TabPanel>
          </TabPanels>
        </Tabs>
      ) : (
        <Tabs
          index={tabIndex}
          colorScheme="blue"
          onChange={handleChangeIndex}
          isLazy
        >
          <TabList width="100%" overflowX="scroll" overflowY="clip">
            <Tab>
              <FormattedMessage id={"category.nav.general"} />
            </Tab>

            <Tab>
              <FormattedMessage id={"product.nav.seo"} />
            </Tab>
          </TabList>
          <TabPanels>
            <TabPanel p="12px 0px">
              <ProductGeneralForm
                actionButtons={getActionButtons}
                loading={isLoading}
                accessType="UPDATE"
                product={product}
              />
            </TabPanel>

            <TabPanel p="12px 0px">
              <ProductSEOForm
                actionButtons={getActionButtons}
                product={product}
              />
            </TabPanel>
          </TabPanels>
        </Tabs>
      )}
    </PageWrapper>
  );
};

export default UpdateProduct;
