import React, { useState } from "react";
import { Box, Divider, Stack, useColorModeValue } from "@chakra-ui/react";
import { useLocation } from "react-router-dom";
import { NavItem, Logo } from "../../commons";
import { NavigationPropsType } from "./Navigation.types";

const Navigation: React.FC<NavigationPropsType> = (props) => {
  const { items } = props;
  const location = useLocation();
  const bgColor = "#061223";
  const borderColor = useColorModeValue("gray.200", "gray.700");

  const [anchor, setAnchor] = useState(false);

  // console.log("location.pathname", location.pathname);
  return (
    <Box
      bg={bgColor}
      paddingY={{ base: "2", lg: "5" }}
      borderRight="1px"
      w={{ base: "70px", lg: "auto" }}
      borderRightColor={borderColor}
      height="100vh"
    >
      <Box
        className="logo_container"
        margin={{ base: "0 auto", lg: "0 0 10px 0" }}
        width={{ base: "40px", lg: "200px" }}
      >
        <Logo />
        {/* <Box marginY="2" paddingX="1px" mb="4">
          <Divider borderColor={borderColor} />
        </Box> */}
      </Box>
      <Box marginY="2" paddingX="16px" mb="4">
        <Divider borderColor={borderColor} />
      </Box>

      {items?.map((nav, index) => (
        <React.Fragment key={index}>
          {nav === "SEPERATOR" ? (
            <Box marginY="2" paddingX="16px">
              <Divider borderColor={borderColor} />
            </Box>
          ) : nav === "vide" ? (
            <></>
          ) : (
            <Stack direction="column">
              <NavItem
                label={nav.label}
                Icon={nav.Icon}
                selected={nav.href.slice(1) === location.pathname.split("/")[1]}
                open={anchor}
                href={nav.href}
              />
            </Stack>
          )}
        </React.Fragment>
      ))}
    </Box>
  );
};

export default Navigation;
