import {
  Box,
  Button,
  IconButton,
  Link,
  Stack,
  Text,
  useToast,
} from "@chakra-ui/react";
import styled from "@emotion/styled";
import { Formik, FormikErrors } from "formik";
import { useState } from "react";
import { BiShow, BiHide } from "react-icons/bi";
import { Link as RouterLink, useLocation, useNavigate } from "react-router-dom";
import { Input } from "../../../../components/commons";
import { useRegisterUser } from "../../../../api/auth";
import { PUBLIC_API_ENDPOINT } from "../../../../utils/globalTypes";
import { setInCache } from "../../../../utils/cache";
// import { useSetRegisterVendorMutation } from "../../../../api/generated/graphql";
// import { Input } from "../../../../components";
// import { setInCache } from "../../../../utils/cache";

const AuthFormWrapper = styled("form")`
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 26px;
`;

const RegisterForm = () => {
  // const [mutateRegister] = useSetRegisterVendorMutation();

  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showConfirmPassword, setShowConfirmPassword] =
    useState<boolean>(false);
  const toast = useToast();
  const { mutate, data, isLoading, isSuccess, isError, error } =
    useRegisterUser();

  const navigate = useNavigate();

  return (
    <Formik
      validateOnMount={false}
      validateOnBlur={false}
      validateOnChange={false}
      initialValues={{
        email: "",
        password: "",
        firstname: "",
        lastname: "",
        confirmPassword: "",
      }}
      validate={(values) => {
        const errors: FormikErrors<typeof values> = {};
        if (!values.email) {
          errors.email = "Email is required";
        } else if (
          !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
        ) {
          errors.email = "Invalid email address";
        }
        if (!values.password) {
          errors.password = "Password is required";
        } else if (values.password !== values.confirmPassword) {
          errors.confirmPassword = "Password confirmation is not matching";
        }
        if (values.password.length<8) {
          errors.password = "A minimum 8 characters password ";
        }
        if (values.password.length>16) {
          errors.password = "A maximum 16 characters password ";
        }
        if (!values.password) {
          errors.password = "Password is required";
        }
        if (!values.firstname) {
          errors.firstname = "First name is required";
        }
        if (!values.firstname) {
          errors.lastname = "Last name is required";
        }

        return errors;
      }}
      onSubmit={(values, { setSubmitting, setFieldError }) => {
        mutate({
          email: values.email,
          password: values.password,
          lastName: values.lastname,
          firstName: values.firstname,
        });
        // setFieldError('email',"ehdjfgvcjhgrfc")
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
      }) => (
        <AuthFormWrapper onSubmit={handleSubmit}>
          <Input
            inputProps={{
              type: "text",
              name: "email",
              onChange: handleChange,
              onBlur: handleBlur,
              value: values.email,
              placeholder: "Email",
            }}
            errorMessage={errors.email}
            isError={errors.email !== undefined}
            label="Email"
          />

          <Input
            inputProps={{
              type: "text",
              name: "firstname",
              onChange: handleChange,
              onBlur: handleBlur,
              value: values.firstname,
              placeholder: "First Name",
            }}
            errorMessage={errors.firstname}
            isError={errors.firstname !== undefined}
            label="First Name"
          />
          <Input
            inputProps={{
              type: "text",
              name: "lastname",
              onChange: handleChange,
              onBlur: handleBlur,
              value: values.lastname,
              placeholder: "Last Name",
            }}
            errorMessage={errors.lastname}
            isError={errors.lastname !== undefined}
            label="Last name"
          />
          <Input
            inputProps={{
              type: showPassword ? "text" : "password",
              name: "password",
              onChange: handleChange,
              onBlur: handleBlur,
              value: values.password,
              placeholder: "Password",
            }}
            inputRightElementProps={{
              children: (
                <IconButton
                  tabIndex={-1}
                  onClick={() => setShowPassword(!showPassword)}
                  aria-label="Password toggle"
                  variant="ghost"
                >
                  {showPassword ? <BiShow size={18} /> : <BiHide size={18} />}
                </IconButton>
              ),
            }}
            errorMessage={errors.password}
            isError={errors.password !== undefined}
            label="Password"
          />
          <Input
            inputProps={{
              type: showConfirmPassword ? "text" : "password",
              name: "confirmPassword",
              onChange: handleChange,
              onBlur: handleBlur,
              value: values.confirmPassword,
              placeholder: "Confirm your password",
            }}
            inputRightElementProps={{
              children: (
                <IconButton
                  tabIndex={-1}
                  onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                  aria-label="Password toggle"
                  variant="ghost"
                >
                  {showConfirmPassword ? (
                    <BiShow size={18} />
                  ) : (
                    <BiHide size={18} />
                  )}
                </IconButton>
              ),
            }}
            errorMessage={errors.confirmPassword}
            isError={errors.confirmPassword !== undefined}
            label="Confirm your password"
          />
          <Button
            marginTop="4"
            colorScheme="main"
            type="submit"
            isLoading={isLoading}
            size="lg"
          >
            Create your account
          </Button>
          <Text>
            Already have an account?{" "}
            <Link color="blue.400" as={RouterLink} to="/authentication/login">
              Log in
            </Link>
          </Text>
        </AuthFormWrapper>
      )}
    </Formik>
  );
};

export default RegisterForm;

// toast({
//   title: resultat.status_message,
//   position: `bottom-right`,
//   status: "error",
//   isClosable: true,
// });
