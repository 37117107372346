import {
  Badge,
  Box,
  Button,
  Checkbox,
  Divider,
  FormLabel,
  Heading,
  IconButton,
  Link,
  Menu,
  MenuButton,
  Popover,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  Skeleton,
  Stack,
  Switch,
  Text,
  useColorModeValue,
  useDisclosure,
  useToast,
  WrapItem,
} from "@chakra-ui/react";
import { FormikErrors, useFormik } from "formik";
import moment from "moment";
import { ReactNode, useEffect, useState } from "react";
import { AiOutlineEye } from "react-icons/ai";
import {
  BiBriefcase,
  BiChevronDown,
  BiLinkExternal,
  BiSlider,
} from "react-icons/bi";
import { FormattedMessage, useIntl } from "react-intl";
import { useLocation } from "react-router-dom";
import {
  InventorysDataType,
  useFindAllInventorys,
  useInventorysCreateOne,
  usePatchOneInventorys,
} from "../../../api/inventory";
import { Input, NumberInput, SelectInput } from "../../../components/commons";
import Table from "../../../components/modules/Table/Table";
import { TableHeadType } from "../../../components/modules/Table/Table.types";
import { PageWrapper } from "../../../utils/globalStyle";

type ProductInventoryFormProps = {
  productID?: string;
  actionButtons: (node: ReactNode) => void;
};

const ProductInventoryForm = (props: ProductInventoryFormProps) => {
  const {
    mutate: generateInventorysMutate,
    data: generateInventorysData,
    isLoading: generateInventorysIsLoading,
    isSuccess: generateInventorysIsSuccess,
    isError: generateInventorysIsError,
    error: generateInventorysError,
  } = useInventorysCreateOne();
  const {
    mutate: findAllInventorysMutate,
    data: findAllInventorysData,
    isLoading: findAllInventorysIsLoading,
    isSuccess: findAllInventorysIsSuccess,
    isError: findAllInventorysIsError,
    error: findAllInventorysError,
  } = useFindAllInventorys();
  const [sortByCreated, setSortByCreated] = useState<1 | -1>(-1);
  const [inventorys, setInventorys] = useState<InventorysDataType[]>([]);
  const location = useLocation();
  const [selectedInventorys, setSelectedInventorys] = useState<string[]>([]);

  const handleSelectIventory = (id: string) => {
    setSelectedInventorys((prevState) => [...prevState, id]);
  };
  const {
    mutate: inventorysUpdateMutate,
    data: inventorysUpdateData,
    isLoading: inventorysUpdateIsLoading,
    isSuccess: inventorysUpdateIsSuccess,
    isError: inventorysUpdateIsError,
    error: inventorysUpdateError,
  } = usePatchOneInventorys();
  const handleRemoveSelectedIventory = (id: string) => {
    setSelectedInventorys((prevState) => {
      let temp = [...prevState];
      const index = temp.indexOf(id, 0);
      if (index > -1) {
        temp.splice(index, 1);
      }
      return temp;
    });
  };
  const { productID, actionButtons } = props;
  const [pagination, setPagination] = useState({
    count: 0,
    limit: 5,
    skip: 0,
    page: 1,
  });
  const handlePageChange = (dir: "next" | "prev") => {
    if (dir === "next") {
      setPagination((prevState) => {
        let temp = { ...prevState };
        temp.skip = temp.skip + temp.limit;
        return temp;
      });
    } else {
      setPagination((prevState) => {
        let temp = { ...prevState };
        temp.skip = temp.skip - temp.limit;
        return temp;
      });
    }
  };

  const handleLimitChange = (value: number) => {
    setPagination((prevState) => {
      let temp = { ...prevState };
      temp.limit = value;
      return temp;
    });
  };
  useEffect(() => {
    if (findAllInventorysIsSuccess) {
      setInventorys(findAllInventorysData.data.inventorys);
    }
  }, [findAllInventorysIsSuccess]);
  useEffect(() => {
    if (generateInventorysIsSuccess) {
      toast({
        title: "Inventaires crées avec succés",
        position: `bottom-right`,
        status: "success",
        isClosable: true,
      });
      findAllInventorysMutate({
        // createdAt: sortByCreated,
        product: productID,
        skip: pagination.skip,
        limit: pagination.limit,
      });
      inventoryProductFormik.setFieldValue("quantite", 0);
    }
  }, [generateInventorysIsSuccess]);
  const inventorysAdminTableData =
    (inventorys &&
      inventorys.map((inventory, index) => ({
        key: inventory._id,
        check: (
          <Checkbox
            value={inventory._id}
            onChange={(e) => {
              if (e.target.checked) {
                handleSelectIventory(e.target.value);
              } else {
                handleRemoveSelectedIventory(e.target.value);
              }
            }}
            isChecked={selectedInventorys?.includes(inventory._id)}
          />
        ),
        refId: inventory.refId,
        code: inventory.code,

        url: (
          <Link href={inventory.url} isExternal>
            <BiLinkExternal size={14} />
          </Link>
        ),
        from: inventory.from,

        creationDate: moment(inventory.createdAt).format("lll"),
        active: (
          <Stack direction="row" alignItems="center">
            {inventory.isActive ? (
              <Badge variant="subtle" colorScheme="green">
                <FormattedMessage id={"active"} />
              </Badge>
            ) : (
              <Badge variant="subtle" colorScheme="red">
                <FormattedMessage id={"notActive"} />
              </Badge>
            )}
          </Stack>
        ),
        used: (
          <Stack direction="row" alignItems="center">
            {inventory.isUsed ? (
              <Badge variant="subtle" colorScheme="green">
                <FormattedMessage id={"used"} />
              </Badge>
            ) : (
              <Badge variant="subtle" colorScheme="red">
                <FormattedMessage id={"notUsed"} />
              </Badge>
            )}
          </Stack>
        ),
        printed: (
          <Stack direction="row" alignItems="center">
            <Switch
              size="md"
              colorScheme="green"
              name="isPrinted"
              onChange={(v) => {
                // console.log(
                //   "chaked",
                //   inventory._id,
                //   " name: ",
                //   inventory.code,
                //   " result",
                //   v.target.checked
                // );
                inventorysUpdateMutate({
                  id: inventory._id,
                  catObj: { isPrinted: v.target.checked },
                });
                if (inventorysUpdateIsSuccess) {
                  setInventorys((prevState) => {
                    let temp = [...prevState];
                    temp[index].isPrinted = v.target.checked;

                    return temp;
                  });
                  toast({
                    title:
                      "status d'impresssion de " +
                      inventory.code +
                      " changé avec succés",
                    position: `bottom-right`,
                    status: "success",
                    isClosable: true,
                  });
                }
              }}
              isChecked={inventory.isPrinted}
            />
          </Stack>
        ),
        product: inventory.product ? (
          <Link
            color="blue.300"
            target="_blank"
            href={`https://bitaqa.tn/home/products/${inventory.product.slug}`}
          >
            <Stack direction="row" alignItems="center" spacing="4px">
              <Text whiteSpace="nowrap">
                <FormattedMessage id={"consult"} />
              </Text>{" "}
              <AiOutlineEye size={16} />
            </Stack>
          </Link>
        ) : (
          <></>
        ),
      }))) ||
    [];
  const intl = useIntl();
  type ProductsInventorysTableData = typeof inventorysAdminTableData[number];
  const productsInventorysTableHead: TableHeadType<ProductsInventorysTableData>[] =
    [
      { accessor: "check", label: "" },

      {
        accessor: "refId",
        label: intl.formatMessage({
          id: "refId",
        }),
      },
      {
        accessor: "code",
        label: intl.formatMessage({
          id: "code",
        }),
      },
      {
        accessor: "url",
        label: intl.formatMessage({
          id: "url",
        }),
      },
      {
        accessor: "from",
        label: intl.formatMessage({
          id: "from",
        }),
      },
      {
        accessor: "product",
        label: intl.formatMessage({
          id: "product",
        }),
      },
      {
        accessor: "creationDate",
        label: intl.formatMessage({
          id: "createdDate",
        }),
      },
      {
        accessor: "active",
        label: intl.formatMessage({
          id: "activity",
        }),
      },

      {
        accessor: "printed",
        label: intl.formatMessage({
          id: "toPrint",
        }),
      },
    ];

  const borderColor = useColorModeValue("gray.100", "gray.700");

  const toast = useToast();

  useEffect(() => {
    findAllInventorysMutate({
      // createdAt: sortByCreated,
      product: productID,
      skip: pagination.skip,
      limit: pagination.limit,
    });
  }, [productID, pagination]);

  // const [updateInventoryMutation] = useUpdateProductsInventoryByIdMutation();

  const inventoryProductFormik = useFormik({
    validateOnMount: false,
    validateOnBlur: false,
    validateOnChange: false,
    initialValues: { quantite: 0 },
    validate: (values) => {
      const errors: FormikErrors<typeof values> = {};
      if (!values.quantite) {
        errors.quantite = "Product quantity is required!";
      }
      return errors;
    },
    onSubmit: (values, { setSubmitting }) => {
      generateInventorysMutate({
        qt: values.quantite,
        obj: { product: productID ? productID : "" },
      });
    },
  });

  return (
    <PageWrapper>
      {selectedInventorys.length > 0 && (
        <Text fontSize="sm" opacity={0.6}>
          {selectedInventorys.length}{" "}
          <FormattedMessage id={"products.select"} />
        </Text>
      )}
      <Stack direction="row" m={4}>
        <Input
          inputProps={{
            name: "quantite",
            placeholder: intl.formatMessage({
              id: "inventory.nbr",
            }),
            value: inventoryProductFormik.values.quantite,
            onChange: inventoryProductFormik.handleChange,
          }}
          errorMessage={inventoryProductFormik.errors.quantite}
          isError={inventoryProductFormik.errors.quantite !== undefined}
        />
        <Divider orientation="vertical" />
        <Button
          isLoading={generateInventorysIsLoading}
          onClick={() => inventoryProductFormik.handleSubmit()}
        >
          <FormattedMessage id={"inventory.generate"} />
        </Button>
      </Stack>

      <Table
        head={productsInventorysTableHead}
        data={inventorysAdminTableData}
        isLoading={findAllInventorysIsLoading}
        emptyState={
          <Box className="empty_table_container">
            <BiBriefcase size={42} />
            <Text fontSize="md" fontWeight="medium"></Text>
          </Box>
        }
        pagination={{
          count: findAllInventorysData?.data.queryCount || 0,
          limit: pagination.limit,
          skip: pagination.skip,
          onPageChange: handlePageChange,
          onChangeLimit: handleLimitChange,
        }}
      />
    </PageWrapper>
  );
};

export default ProductInventoryForm;
