import {
  Box,
  Heading,
  Skeleton,
  Button,
  Tabs,
  TabList,
  Tab,
  TabPanel,
  TabPanels,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverHeader,
  PopoverBody,
  PopoverCloseButton,
  Popover,
  Stack,
  Flex,
  Spacer,
  FormLabel,
  useColorModeValue,
  useColorMode,
  useToast,
} from "@chakra-ui/react";
import { Formik, FormikErrors, useFormik } from "formik";
import { useEffect, useState } from "react";
import ReactQuill from "react-quill";

import { CategoryFormWrapper } from "../Categories.styles";

import "react-quill/dist/quill.snow.css";
import { ImageUpload, Input, SelectInput } from "../../../components/commons";
import { Category } from "../Categories";
import { getDataFromCache } from "../../../utils/cache";
import axios from "axios";
import { PUBLIC_API_ENDPOINT } from "../../../utils/globalTypes";
import { FormattedMessage, useIntl } from "react-intl";
import {
  CategoryDataType,
  useCategoriesCreateOne,
} from "../../../api/categories";

const modules = {
  toolbar: [
    [{ header: "1" }, { header: "2" }, { font: [] }],
    [{ size: [] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
    ],
    ["link", "image"],
  ],
  clipboard: {
    // toggle to add extra line breaks when pasting HTML:
    matchVisual: false,
  },
};
/*
 * Quill editor formats
 * See https://quilljs.com/docs/formats/
 */

type CreateCategoryProps = {
  parent?: CategoryDataType | 0;
  onCategoryCreated: (action: string, selectedCat?: string | 0) => void;
};

const CreateCategory = (props: CreateCategoryProps) => {
  const { parent, onCategoryCreated } = props;

  const [files, setFiles] = useState<
    {
      progress: boolean;
      id: string;
      src: File | string | undefined;
    }[]
  >([]);
  const { mutate, data, isLoading, isSuccess, isError, error } =
    useCategoriesCreateOne();

  const handleFile = async (file: File) => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("upload_preset", "ml_default");
    formData.append("public_id", `categories/${file.name}`);
    setFiles((prevFiles) => [
      ...prevFiles,
      {
        src: file,
        id: file.name,
        progress: true,
        name: file.name,
        type: file.type,
      },
    ]);
    const data = await (
      await fetch("https://api.cloudinary.com/v1_1/dgpjskmv1/upload", {
        method: "POST",
        body: formData,
      })
    ).json();

    setFiles((prevFiles) => [
      ...prevFiles.filter(({ id }) => id !== file.name),
      {
        src: data.secure_url,
        id: data.asset_id,
        progress: false,
      },
    ]);
  };

  const handleDelete = (id: string) => {
    // console.log(
    //   id,
    //   files.filter(({ id: imageId }) => id !== imageId)
    // );
    setFiles((prevFiles) =>
      prevFiles.filter(({ id: imageId }) => id !== imageId)
    );
  };

  const [tabIndex, setTabIndex] = useState(0);

  const intl = useIntl();
  const toast = useToast();
  useEffect(() => {
    if (isSuccess) {
      toast({
        title: "Category created with succcess",
        status: "success",
        position: `bottom-right`,

        isClosable: true,
      });

      onCategoryCreated("");
      setFiles((prevFiles) =>
        prevFiles.filter(({ id: imageId }) => files?.[0]?.id !== imageId)
      );
    CategoryFormik.setFieldValue("name","");
    CategoryFormik.setFieldValue("description","");
    }
  }, [isSuccess]);
  const CategoryFormik = useFormik({
    validateOnMount: false,
    validateOnBlur: false,
    validateOnChange: false,
    initialValues: {
      name: "",
      description: "",
    },
    validate: (values) => {
      const errors: FormikErrors<typeof values> = {};
      if (!values.name) {
        errors.name = "Category name is required!";
      }
     
      return errors;
    },
    onSubmit: (values, { setSubmitting }) => {
      if (files?.[0]?.src) {
        mutate({
          image: files?.[0]?.src as string,

          name: values.name,
          description: values.description,

          parent: parent != 0 && parent?._id ? parent?._id : "",
        });
      } else {
        mutate({
          name: values.name,
          description: values.description,

          parent: parent != 0 && parent?._id ? parent?._id : "",
        });
      }
    },
  });

  return (
    <CategoryFormWrapper>
      <Stack
        className="category_form_header"
        direction={{ base: "column", md: "row" }}
        spacing="18px"
      >
        {parent !== 0 ? (
          // <Skeleton isLoaded={!categoryNameLoading}>
          <Heading size="md">
             <FormattedMessage id={"category.add.Sub.title"} />"{parent?.name}"
            {/* {categoryNameData?.GetCategoryById?.name}" */}
          </Heading>
        ) : (
          // </Skeleton>
          <Heading size="md">
            {intl.formatMessage({
              id: "category.add.title",
            })}
          </Heading>
        )}
        <Button
          colorScheme="main"
          size="sm"
          type="submit"
          isLoading={isLoading}
          form="create-category-form"
          disabled={
            CategoryFormik.initialValues === CategoryFormik.values || isLoading
          }
        >
          {intl.formatMessage({
            id: "category.Add.btn",
          })}
        </Button>
      </Stack>
      <Tabs index={tabIndex} colorScheme="blue" isLazy>
        <TabList overflowX="auto" overflowY="hidden">
          <TabList overflowX="auto" overflowY="hidden"></TabList>
          <Tab>
            <FormattedMessage id={"product.nav.general"} />
          </Tab>

          <Popover colorScheme="yellow">
            <PopoverTrigger>
              <Tab>
                <FormattedMessage id={"product.nav.seo"} />
              </Tab>
            </PopoverTrigger>

            <PopoverContent>
              <PopoverArrow />

              <PopoverCloseButton />
              <PopoverHeader>
                {" "}
                <FormattedMessage id={"holdOn"} />
              </PopoverHeader>
              <PopoverBody>
                <FormattedMessage id={"categogy.seo"} />
              </PopoverBody>
            </PopoverContent>
          </Popover>
        </TabList>

        <TabPanels>
          <TabPanel p="12px 0px">
            <form
              onSubmit={CategoryFormik.handleSubmit}
              id="create-category-form"
            >
              <Stack spacing="14px">
                <ImageUpload
                  height="230px"
                  width="470px"
                  images={files}
                  handleUpload={handleFile}
                  handleDelete={handleDelete}
                  label="image"
                />
                <Stack direction="row">
                  <Input
                    label="name"
                    inputProps={{
                      placeholder: "Nom",
                      name: "name",
                      onChange: CategoryFormik.handleChange,
                      value: CategoryFormik.values.name,
                    }}
                    errorMessage={CategoryFormik.errors.name}
                    isError={CategoryFormik.errors.name !== undefined}
                  />
                  <Spacer />
                </Stack>
                <Box>
                  <FormLabel marginBottom="1">
                    {intl.formatMessage({
                      id: "category.attribut.description",
                    })}
                  </FormLabel>
                  <ReactQuill
                    value={CategoryFormik.values.description}
                    onChange={(content) =>
                      CategoryFormik.setFieldValue("description", content)
                    }
                    modules={modules}
                    placeholder="Déscription"
                  />
                </Box>
              </Stack>
            </form>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </CategoryFormWrapper>
  );
};

export default CreateCategory;
