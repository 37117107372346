import { Box } from "@chakra-ui/react";
import styled from "@emotion/styled";

export const PageWrapper = styled(Box)`
  .page_header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 12px;
    
    .page_header_title {
      .page_header_backlink {
      }
      .page_header_title_content {
        display: flex;
        align-items: center;
        gap: 6px;
      }
    }
  }
  .css-hcu59u {
    border-radius: 12px !important;
  }
  .css-1ehz216 {
    border-radius: 12px !important;
  }
  .css-p5769z > *:not(style) ~ *:not(style) {
    justify-content: center;
  }
  .page_actions {
    display: flex;
    gap: 6px;
    height: 40px;
    margin-bottom: 12px;
  }
  .empty_table_container {
    /* width: 100%; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 48px 0px;
    opacity: 0.6;
  }
.css-1kc1nok .ql-container:focus-visible{
	border-color: #0093FF;
    box-shadow: 0 0 0 1px #0093ff;
	}
  .typeSelect {
    width: 100%;
    min-width: 0px;
    outline: 2px solid transparent;
    outline-offset: 2px;
    position: relative;
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    appearance: none;
    transition-property: var(--chakra-transition-property-common);
    transition-duration: var(--chakra-transition-duration-normal);
    font-size: var(--chakra-fontSizes-md);
    -webkit-padding-start: var(--chakra-space-4);
    padding-inline-start: var(--chakra-space-4);
    -webkit-padding-end: var(--chakra-space-10);
    padding-inline-end: var(--chakra-space-10);
    height: var(--chakra-sizes-10);
    border-radius: var(--chakra-radii-md);
    border: 1px solid;
    border-color: inherit;
    background: inherit;
    outline-color: var(--chakra-colors-transparent);
  }
`;
