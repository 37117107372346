import { objToParam } from "./categories";

export type paginationBlogs = {
  skip?: number;
  limit?: number;
  search?: string;
  author?: string;
  createdAt?: 1 | -1;
  updatedAt?: 1 | -1;
};
export const BLOGS_FIND_ALL = (obj: paginationBlogs = {}) =>
  `blogs/findAll${objToParam(obj)}`;
export const BLOG_CREATE_ONE = "blogs/createOne";
export const BLOG_DELETE_ONE = "blogs/deleteOne";
export const BLOG_UPDATE_ONE = (obj: string) => `blogs/patchOne/${obj}`;
