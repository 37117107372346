import { useToast } from "@chakra-ui/react";
import { useMutation } from "@tanstack/react-query";
import { ClientsDataType } from "../Clients";
import {
  COUPONS_CREATE_ONE,
  COUPONS_DELETE_ONE,
  COUPONS_FIND_ALL,
  COUPONS_FIND_ONE,
  COUPONS_PATCH_ONE,
  paginationCoupons,
} from "../endpoints/coupons";
import {
  protectedDelete,
  protectedGet,
  protectedPatch,
  protectedPost,
} from "../http-methods";
import { productDataType } from "../products";

export type CouponsDataType = {
  validUntil: Date | null;
  _id: string;
  code: string;
  type: string;
  effectOn: number;
  quantity: number;
  products: productDataType[];
  used:number;
  createdAt: string;
  updatedAt: string;
  __v: number;
};
export type UpdateCouponsDataType = {
  validUntil?: Date | null;
  code?: string;
  type?: string;
  quantity?: number;
  effectOn?: number;
  products?: string[];
 
};
export type CreateCouponsDataType = {
  validUntil?: string;
  code?: string;
  quantity?: number;
  type?: string;
  effectOn?: number;
  products?: string[];

};

export const useFindAllCoupons = () => {
  return useMutation({
    mutationFn: (obj: paginationCoupons) => {
      return protectedGet(COUPONS_FIND_ALL(obj));
    },
  });
};

export const useCouponsCreateOne = () => {
  const toast = useToast();
  return useMutation({
    mutationFn: (catObj: CreateCouponsDataType) => {
      return protectedPost(COUPONS_CREATE_ONE, catObj);
    },
    onError: (data: any) => {
      console.log("dataError", data.response.data.errors);
      const errors: any = data.response.data.errors;
      if (errors[0]) {
        toast({
          title: errors[0].msg,
          position: `bottom-right`,
          status: "error",
          isClosable: true,
        });
      } else if (errors.validUntil) {
        toast({
          title: "validUntil",
          description: errors.validUntil.msg,
          position: `bottom-right`,
          status: "error",
          isClosable: true,
        });
      } else if (errors.code) {
        toast({
          title: "code",
          description: errors.code.msg,
          position: `bottom-right`,
          status: "error",
          isClosable: true,
        });
      } else if (errors.quantity) {
        toast({
          title: "quantity",
          description: errors.quantity.msg,
          position: `bottom-right`,
          status: "error",
          isClosable: true,
        });
      } else if (errors.effectOn) {
        toast({
          title: "Valeur",
          description: errors.effectOn.msg,
          position: `bottom-right`,
          status: "error",
          isClosable: true,
        });
      } else if (errors.products) {
        toast({
          title: "products",
          description: errors.products.msg,
          position: `bottom-right`,
          status: "error",
          isClosable: true,
        });
      } else if (errors.assignedTo) {
        toast({
          title: "assignedTo",
          description: errors.assignedTo.msg,
          position: `bottom-right`,
          status: "error",
          isClosable: true,
        });
      }
    },
  });
};

export const useCouponsDeleteOne = () => {
  return useMutation({
    mutationFn: (catObj: string) => {
      return protectedDelete(COUPONS_DELETE_ONE, catObj);
    },
  });
};
export const usePatchOneCoupons = () => {
  const toast = useToast();
  return useMutation({
    mutationFn: ({
      id,
      catObj,
    }: {
      id: string;
      catObj: CreateCouponsDataType;
    }) => {
      return protectedPatch(COUPONS_PATCH_ONE(id), catObj);
    },
    onError: (data: any) => {
      console.log("dataError", data.response.data.errors);
      const errors: any = data.response.data.errors;
      if (errors[0]) {
        toast({
          title: errors[0].msg,
          position: `bottom-right`,
          status: "error",
          isClosable: true,
        });
      } else if (errors.validUntil) {
        toast({
          title: "validUntil",
          description: errors.validUntil.msg,
          position: `bottom-right`,
          status: "error",
          isClosable: true,
        });
      } else if (errors.code) {
        toast({
          title: "code",
          description: errors.code.msg,
          position: `bottom-right`,
          status: "error",
          isClosable: true,
        });
      } else if (errors.quantity) {
        toast({
          title: "quantity",
          description: errors.quantity.msg,
          position: `bottom-right`,
          status: "error",
          isClosable: true,
        });
      } else if (errors.effectOn) {
        toast({
          title: "Valeur",
          description: errors.effectOn.msg,
          position: `bottom-right`,
          status: "error",
          isClosable: true,
        });
      } else if (errors.products) {
        toast({
          title: "products",
          description: errors.products.msg,
          position: `bottom-right`,
          status: "error",
          isClosable: true,
        });
      } else if (errors.assignedTo) {
        toast({
          title: "assignedTo",
          description: errors.assignedTo.msg,
          position: `bottom-right`,
          status: "error",
          isClosable: true,
        });
      }
    },
  });
};
export const useFindOneCoupon = () => {
  return useMutation({
    mutationFn: (catObj?: string) => {
      return protectedGet(COUPONS_FIND_ONE(catObj));
    },
  });
};
