import { FC } from "react";
import { logoImage, logoImageMobile, logoImageDARKUI } from "../../../assets";
import { Box, useMediaQuery, useColorMode } from "@chakra-ui/react";

const Logo: FC = () => {
  const [isMdUp] = useMediaQuery("(min-width: 62em)");
  const { colorMode } = useColorMode();

  return (
    <Box marginX="auto" w={{ base: "40px", lg: "90%" }}>
      {isMdUp ? (
        <img src={logoImage} alt="logo" />
      ) : colorMode === "dark" ? (
        <img src={logoImageDARKUI} alt="logo" />
      ) : (
        <img src={logoImageMobile} alt="logo" />
      )}
    </Box>
  );
};

export default Logo;
