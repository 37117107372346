import {
  Box,
  Button,
  color,
  Divider,
  FormLabel,
  Heading,
  Radio,
  RadioGroup,
  Select,
  Skeleton,
  Stack,
  Switch,
  Tag,
  Text,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
import { FormikErrors, useFormik } from "formik";
import { ReactNode, useEffect, useState } from "react";
import "react-quill/dist/quill.snow.css";
import ReactQuill from "react-quill";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import {
  ColorPicker,
  ImageUpload,
  Input,
  NumberInput,
  SelectInput,
} from "../../../components/commons";
import CategorySelectInput from "../../../components/modules/CategorySelectInput/CategorySelectInput";
import TagsSelectInput from "../../../components/modules/TagsSelectInput/TagsSelectInput";
import { getDataFromCache } from "../../../utils/cache";
import axios from "axios";
import { PUBLIC_API_ENDPOINT } from "../../../utils/globalTypes";
import { ProductType } from "../AddProduct/AddProduct";

import { FormattedMessage, useIntl } from "react-intl";
import {
  productDataType,
  usePatchOneProducts,
  useProductsCreateOne,
} from "../../../api/products";
import { access } from "fs";
import { updateLocale } from "moment";

type ProductGeneralFormProps = {
  loading?: boolean;
  accessType?: string;
  product?: productDataType;
  actionButtons: (node: ReactNode) => void;
};

const ProductGeneralForm = (props: ProductGeneralFormProps) => {
  const { accessType, product, loading } = props;
  const generalProductInitialValues = {
    name: "",

    description: "",
    images: [],
    sticker: {
      color: "",
      lable: "",
    },
    category: { id: "", label: "" },
    isAvilable: true,
    price: {
      chargeAtTax: false,
      compareAtPrice: "0.000",
      costPerItem: "0.000",
      price: "0.000",
      _id: "",
    },
  };
  useEffect(() => {
    if (product) {
      // console.log("productData", product);
      UpdateProductFormik.setFieldValue("name", product?.name);
      UpdateProductFormik.setFieldValue("slug", product?.slug);
      UpdateProductFormik.setFieldValue("description", product?.description);
      UpdateProductFormik.setFieldValue("images", product?.images);
      UpdateProductFormik.setFieldValue("isAvilable", product?.isAvilable);

      UpdateProductFormik.setFieldValue(
        "price.price",
        product?.price?.price? product?.price?.price.toString():""
      );
      UpdateProductFormik.setFieldValue(
        "price.compareAtPrice",
        product?.price?.compareAtPrice? product?.price?.compareAtPrice.toString():""
      );
      UpdateProductFormik.setFieldValue(
        "price.costPerItem",
        product?.price?.costPerItem? product?.price?.costPerItem.toString():""
      );
      UpdateProductFormik.setFieldValue(
        "price.chargeAtTax",
        product?.price?.chargeAtTax
      );
      UpdateProductFormik.setFieldValue("sticker", product?.sticker);
      // console.log("price",UpdateProductFormik.values.price);
      UpdateProductFormik.setFieldValue("category", {
        id: product?.category?._id,
        label: product?.category?.name,
      });
      setFiles(
        product.images &&
          product.images.map((e) => ({
            progress: false,
            id: e,
            src: e,
          }))
      );
    }
  }, [product]);

  const toast = useToast();
  const {
    mutate: createOneProductMutate,
    data: createOneProductData,
    isLoading: createOneProductIsLoading,
    isSuccess: createOneProductIsSuccess,
    isError: createOneProductIsError,
    error: createOneProductError,
  } = useProductsCreateOne();
  const {
    mutate: productsUpdateMutate,
    data: productsUpdateData,
    isLoading: productsUpdateIsLoading,
    isSuccess: productsUpdateIsSuccess,
    isError: productsUpdateIsError,
    error: productsUpdateError,
  } = usePatchOneProducts();

  const UpdateProductFormik = useFormik({
    validateOnMount: false,
    validateOnBlur: false,
    validateOnChange: false,
    initialValues: generalProductInitialValues,
    validate: (values) => {
      const errors: FormikErrors<typeof values> = {};
      if (!values.name) {
        errors.name = "Product name is required!";
      }

      return errors;
    },
    onSubmit: (values, { setSubmitting }) => {
      if (accessType != "UPDATE") {
        if (values.category.id === "") {
          toast({
            title: "Category is required!",
            position: `bottom-right`,
            status: "error",
            isClosable: true,
          });
        } else
          createOneProductMutate({
            name: values.name,
            description: values.description,
            images:
              files.map((files) => (files.src ? (files.src as string) : "")) ||
              undefined,
            price: {
              chargeAtTax: values.price.chargeAtTax,
              compareAtPrice: values.price.compareAtPrice,
              costPerItem: values.price.costPerItem,
              price: values.price.price,
            },
            category: values.category?.id,
          });
      } else {
        productsUpdateMutate({
          catObj: {
            name: values.name,
            description: values.description,
            images:
              files.map((files) => (files.src ? (files.src as string) : "")) ||
              undefined,
            price: {
              chargeAtTax: values.price.chargeAtTax,
              compareAtPrice: values.price.compareAtPrice,
              costPerItem: values.price.costPerItem,
              price: values.price.price,
            },
            category: values.category?.id,
            sticker: values.sticker,
          },
          id: product?._id ? product?._id : "",
        });
      }
    },
  });
  useEffect(() => {
    if (createOneProductIsSuccess == true) {
      toast({
        title: "Product created with succcess",
        status: "success",
        position: `bottom-right`,

        isClosable: true,
      });
      navigate(`/products`, {
        state: {
          pageName: "Products",
        },
      });
    }
  }, [createOneProductIsSuccess]);
  const modules = {
    toolbar: [
      [{ header: "1" }, { header: "2" }, { font: [] }],
      [{ size: [] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link"],
    ],
    clipboard: {
      matchVisual: false,
    },
  };
  const handleFile = async (file: File) => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("upload_preset", "ml_default");
    formData.append("public_id", `categories/${file.name}`);
    setFiles((prevFiles) => [
      ...prevFiles,
      {
        src: file,
        id: file.name,
        progress: true,
        name: file.name,
        type: file.type,
      },
    ]);
    const data = await (
      await fetch("https://api.cloudinary.com/v1_1/dgpjskmv1/upload", {
        method: "POST",
        body: formData,
      })
    ).json();

    setFiles((prevFiles) => [
      ...prevFiles.filter(({ id }) => id !== file.name),
      {
        src: data.secure_url,
        id: data.asset_id,
        progress: false,
      },
    ]);
  };

  const handleDelete = (id: string) => {
    // console.log(
    //   id,
    //   files.filter(({ id: imageId }) => id !== imageId)
    // );
    setFiles((prevFiles) =>
      prevFiles.filter(({ id: imageId }) => id !== imageId)
    );
  };
  const location = useLocation();
  const navigate = useNavigate();
  const [productTypes, setproductTypes] = useState<
    { label: string; value: string }[]
  >([]);

  useEffect(() => {
    props.actionButtons(
      <>
        <Button
          variant="outline"
          onClick={() =>
            navigate(`/products`, {
              state: {
                pageName: "Products",
              },
            })
          }
        >
          <FormattedMessage id={"discard"} />
        </Button>
        <Button
          colorScheme="main"
          type="submit"
          form="create-product-form"
          isLoading={createOneProductIsLoading}
        >
          <FormattedMessage id={"save"} />
        </Button>
      </>
    );
  }, [UpdateProductFormik.isSubmitting]);
  useEffect(() => {
    if (productsUpdateIsSuccess) {
      toast({
        title: "Product updated with succcess",
        status: "success",
        position: `bottom-right`,

        isClosable: true,
      });
    }
  }, [productsUpdateIsSuccess]);
  const [files, setFiles] = useState<
    {
      progress: boolean;
      id: string;
      src: File | string | undefined;
    }[]
  >([]);

  const [value, setValue] = useState<{ label: string; value: string }[]>([]);

  const intl = useIntl();
  const borderColor = useColorModeValue("gray.100", "gray.700");
  const { from } = (location?.state as { from?: string }) || "";

  return (
    <form id="create-product-form" onSubmit={UpdateProductFormik.handleSubmit}>
      <Stack
        direction="row"
        spacing="14px"
        //border="1px solid red"
        alignItems="flex-start"
      >
        <Stack
          direction={{ base: "column", xl: "row" }}
          flex={1}
          spacing="12px"
        >
          <Stack flex={1} spacing="18px" p={{ base: "auto", xl: "8px" }}>
            {/* <Skeleton
              isLoaded={!generalProductLoading}
              opacity={generalProductLoading ? 0.4 : 1}
            > */}

            <Stack
              padding="18px 18px"
              border="2px"
              borderColor={borderColor}
              rounded="base"
              spacing="18px"
              border-radius={"12px !important"}
            >
              <Heading size="md" fontWeight="semibold">
                <FormattedMessage id={"generalInforation"} />
              </Heading>
              <Stack direction={{ base: "column", xl: "row" }} spacing="25px">
                <Input
                  label="product.name"
                  inputProps={{
                    placeholder: intl.formatMessage({
                      id: "product.name",
                    }),
                    name: "name",
                    onChange: UpdateProductFormik.handleChange,
                    value: UpdateProductFormik.values.name,
                  }}
                  errorMessage={UpdateProductFormik.errors.name}
                  isError={UpdateProductFormik.errors.name !== undefined}
                />
              {product?._id!=="63c40e7c0513c8014979ac77"?( <CategorySelectInput
                  selected={UpdateProductFormik.values.category}
                  handleSelectCategory={(id, label) => {
                    UpdateProductFormik.setFieldValue(
                      "category",

                      { id, label }
                    );
                  }}
                />):(<></>)}
               
              </Stack>

              <Box>
                <FormLabel marginBottom="1">
                  {" "}
                  <FormattedMessage id={"product.description"} />
                </FormLabel>
                <ReactQuill
                  value={UpdateProductFormik.values.description || ""}
                  onChange={(content) =>
                    UpdateProductFormik.setFieldValue("description", content)
                  }
                  modules={modules}
                  placeholder="Describe this category"
                />
              </Box>
              <Stack direction={{ base: "column", xl: "row" }} spacing="25px">
                <Input
                  label="sticker.label"
                  inputProps={{
                    placeholder: intl.formatMessage({
                      id: "sticker.label",
                    }),
                    name: "sticker.lable",
                    onChange: UpdateProductFormik.handleChange,
                    value: UpdateProductFormik.values.sticker.lable,
                  }}
                  errorMessage={UpdateProductFormik.errors.sticker?.lable}
                  isError={
                    UpdateProductFormik.errors.sticker?.lable !== undefined
                  }
                />
                <Box w="80">
                  <ColorPicker
                    label={intl.formatMessage({
                      id: "sticker.color",
                    })}
                    color={
                      UpdateProductFormik.values.sticker.color || "#ffffff"
                    }
                    setColor={(color) => {
                      UpdateProductFormik.setFieldValue("sticker.color", color);
                      // console.log("color picked", "#ffffff");
                    }}
                  />
                </Box>
              </Stack>
            </Stack>

            {/* </Skeleton> */}
            {/* <Skeleton
              isLoaded={!generalProductLoading}
              opacity={generalProductLoading ? 0.4 : 1}
            > */}
            <Box
              padding="18px 18px"
              border="2px"
              width="100%"
              borderColor={borderColor}
              flexDirection={{ base: "column", sm: "row" }}
              rounded="base"
              border-radius={"12px !important"}
            >
              <ImageUpload
                height="180px"
                width={files.length === 0 ? "100%" : "180px"}
                images={files}
                handleUpload={handleFile}
                handleDelete={handleDelete}
                label={intl.formatMessage({
                  id: "images",
                })}
                multiple
                gridColCount={2}
              />
            </Box>
            <Stack
              padding="18px 18px"
              border="2px"
              borderColor={borderColor}
              rounded="base"
              spacing="18px"
              border-radius={"12px !important"}
            >
              <Heading size="md" fontWeight="semibold">
                <FormattedMessage id="product.pricing" />
              </Heading>
              <Stack direction={{ base: "column", xl: "row" }} spacing="25px">
                <Input
                  label="product.price"
                  inputProps={{
                    placeholder: "prix",

                    name: "price.price",
                    onChange: UpdateProductFormik.handleChange,
                    width: "100%",
                    value: UpdateProductFormik.values.price.price,
                  }}
                  errorMessage={UpdateProductFormik.errors.price?.price}
                  isError={UpdateProductFormik.errors.price !== undefined}
                  inputRightAddon={{ children: "TND" }}
                />
                <Input
                  label="compareAtPrice"
                  inputProps={{
                    placeholder: "compareAtPrice",

                    name: "price.compareAtPrice",
                    onChange: UpdateProductFormik.handleChange,
                    width: "100%",
                    value: UpdateProductFormik.values.price.compareAtPrice,
                  }}
                  errorMessage={
                    UpdateProductFormik.errors.price?.compareAtPrice
                  }
                  isError={
                    UpdateProductFormik.errors.price?.compareAtPrice !==
                    undefined
                  }
                  inputRightAddon={{ children: "TND" }}
                />
              </Stack>
              <Stack direction={{ base: "column", sm: "row" }} spacing="18px">
                <Input
                  label="costPerItem"
                  inputProps={{
                    placeholder: "costPerItem",

                    name: "price.costPerItem",
                    onChange: UpdateProductFormik.handleChange,
                    width: "100%",
                    value: UpdateProductFormik.values.price.costPerItem,
                  }}
                  errorMessage={UpdateProductFormik.errors.price?.costPerItem}
                  isError={
                    UpdateProductFormik.errors.price?.costPerItem !== undefined
                  }
                  inputRightAddon={{ children: "TND" }}
                />
                <Stack direction="column" spacing="5px">
                  <FormLabel marginBottom="1">Avilable</FormLabel>
                  <Stack direction="row" alignItems="center">
                    <Switch
                      size="md"
                      colorScheme="green"
                      name="isAvilable"
                      onChange={UpdateProductFormik.handleChange}
                      isChecked={UpdateProductFormik.values.isAvilable}
                    />
                    <Text
                      color={
                        UpdateProductFormik.values.isAvilable
                          ? "green.400"
                          : undefined
                      }
                    >
                      {UpdateProductFormik.values.isAvilable
                        ? "Available"
                        : "Not available"}
                    </Text>
                  </Stack>
                </Stack>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </form>
  );
};

export default ProductGeneralForm;
