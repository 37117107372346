import {
  TableContainer,
  Th,
  Thead,
  Tr,
  Td,
  Table as ChakraTable,
  Tbody,
  useColorModeValue,
  Skeleton,
  Box,
  Text,
  Select,
  IconButton,
} from "@chakra-ui/react";
import { useMemo } from "react";
import { BiChevronLeft, BiChevronRight } from "react-icons/bi";
import { FormattedMessage } from "react-intl";
import { PaginationWrapper, TableWrapper } from "./Table.styles";
import { TablePropsType } from "./Table.types";

const loadingList = [
  <Skeleton height="32px" opacity="0.4" />,
  <Skeleton height="32px" opacity="0.4" />,
  <Skeleton height="32px" opacity="0.4" />,
  <Skeleton height="32px" opacity="0.4" />,
  <Skeleton height="32px" opacity="0.4" />,
  <Skeleton height="32px" opacity="0.4" />,
  <Skeleton height="32px" opacity="0.4" />,
  <Skeleton height="32px" opacity="0.4" />,
  <Skeleton height="32px" opacity="0.4" />,
  <Skeleton height="32px" opacity="0.4" />,
];

function Table<RowData extends object>(props: TablePropsType<RowData>) {
  const {
    head,
    data,
    size = "md",
    onCellClick,
    isLoading,
    emptyState,
    pagination,
  } = props;

  const borderColor = useColorModeValue("gray.100", "gray.700");

  const cellHoverColor = useColorModeValue("gray.100", "gray.700");

  const rows = useMemo(
    () => data.map((item, index) => ({ render: item, key: index })),
    [data]
  );
  const getRowID = (index: number) => {
    const { id } = rows[index]?.render as { id?: string };

    return id || "";
  };

  return (
    <>
      <TableWrapper border="1px" borderColor={borderColor} rounded="md">
        <TableContainer>
          <ChakraTable colorScheme={"gray"} size={size}>
            <Thead>
              <Tr>
                {head.map((column, index) => (
                  <Th key={index} {...column.headCellProps}>
                    {column.label}
                  </Th>
                ))}
              </Tr>
            </Thead>
            <Tbody>
              {isLoading
                ? loadingList.map((skeleton, rowindex) => (
                    <Tr key={rowindex}>
                      {head.map((column, index) => (
                        <Td key={index}>{skeleton}</Td>
                      ))}
                    </Tr>
                  ))
                : rows.length > 0 && (
                    <>
                      {rows.map((row, index) => (
                        <Tr
                          key={row.key}
                          _hover={
                            onCellClick && {
                              background: cellHoverColor,
                              cursor: "pointer",
                            }
                          }
                          onClick={() => onCellClick?.(getRowID(index))}
                        >
                          {head.map((column, index) => (
                            <Td
                              key={index}
                              {...column.headCellProps}
                              wordBreak="normal"
                              whiteSpace="normal"
                            >
                              {row.render[column.accessor]as any}
                            </Td>
                          ))}
                        </Tr>
                      ))}
                    </>
                  )}
            </Tbody>
          </ChakraTable>
          {rows.length === 0 && !isLoading && emptyState}
        </TableContainer>
      </TableWrapper>
      {pagination && rows.length !== 0 && (
        <PaginationWrapper flexDirection={{ base: "column", sm: "row" }}>
          <Box className="rows_perpage">
            <Text><FormattedMessage id="pagination" /></Text>
            <Select
              size="sm"
              onChange={(e) =>
                pagination.onChangeLimit(parseInt(e.target.value))
              }
              value={pagination.limit}
            >
              <option value={5}>5</option>
              <option value={10}>10</option>
              <option value={25}>25</option>
              <option value={50}>50</option>
              <option value={100}>100</option>
            </Select>
          </Box>
          <Box
            marginTop={{ base: "10px", sm: "0px" }}
            className="pagination_action"
          >
            <Text>{`${pagination.skip + 1} - ${
              pagination.skip + pagination.limit >= pagination.count
                ? pagination.count
                : pagination.skip + pagination.limit
            } of ${pagination.count}`}</Text>
            <IconButton
              onClick={() => pagination.onPageChange("prev")}
              disabled={pagination.skip < 1}
              aria-label="previous_button"
              size="sm"
              variant="ghost"
            >
              <BiChevronLeft size={24} />
            </IconButton>
            <IconButton
              onClick={() => pagination.onPageChange("next")}
              aria-label="next_button"
              disabled={pagination.count <= pagination.skip + pagination.limit}
              size="sm"
              variant="ghost"
            >
              <BiChevronRight size={24} />
            </IconButton>
          </Box>
        </PaginationWrapper>
      )}
    </>
  );
}

export default Table;
