import {
  Box,
  Heading,
  Stack,
  useColorModeValue,
  Button,
  Skeleton,
  useToast,
  FormLabel,
} from "@chakra-ui/react";
import { useFormik } from "formik";
import {
  SittingsUpdateDataType,
  useFindAllSettings,
  usePatchOneSettings,
} from "../../api/settings";
import { useEffect } from "react";
import SettingsWrapper from "../../components/modules/SettingsWrapper/SettingsWrapper";
import ReactQuill from "react-quill";
import { FormattedMessage, useIntl } from "react-intl";

const modules = {
  toolbar: [
    [{ header: "1" }, { header: "2" }, { font: [] }],
    [{ size: [] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
    ],
    ["link"],
  ],
  clipboard: {
    // toggle to add extra line breaks when pasting HTML:
    matchVisual: false,
  },
};

const PoliciesSettings = () => {
  const sectionBgColor = useColorModeValue("white", "gray.900");

  const toast = useToast();
  const { data, isLoading, isSuccess, isError } = useFindAllSettings();
  const {
    mutate: settingsUpdate,
    isLoading: isSettingsUpdateLoading,
    isSuccess: isSettingsUpdateSuccess,
    error,
  } = usePatchOneSettings();
  const PoliciesSettingsFormInitialValues: {
    refundPolicy: string;
    contactInfromation: string;
    shippingPolicy: string;
    termsAndConditions: string;
    privacyAndPolicy: string;
  } = {
    refundPolicy: "",
    contactInfromation: "",
    shippingPolicy: "",
    termsAndConditions: "",
    privacyAndPolicy: "",
  };
  const intl = useIntl();
  useEffect(() => {
    if (isSettingsUpdateSuccess) {
      {
        toast({
          title: intl.formatMessage({
            id: "polices.update",
          }),
          status: "success",

          position: "bottom-right",
          duration: 5000,
          isClosable: true,
        });
      }
    }
  }, [isSettingsUpdateSuccess]);

  const PoliciesSettingsFormik = useFormik({
    validateOnMount: false,
    validateOnBlur: false,
    validateOnChange: false,
    initialValues: PoliciesSettingsFormInitialValues,

    onSubmit: (values, { setSubmitting }) => {
      const updateData: SittingsUpdateDataType = {
        moreInfo: {
          refundPolicy: values.refundPolicy,
          contactInfromation: values.contactInfromation,
          shippingPolicy: values.shippingPolicy,
          termsAndConditions: values.termsAndConditions,
          privacyAndPolicy: values.privacyAndPolicy,
        },
      };
      settingsUpdate({
        obj: updateData,
        id: data && data.data.settings[0]._id,
      });
      // UpdateSettingsByIdMutation({
      //   variables: {
      // 	refundPolicy: values.refundPolicy,
      // 	contactInfromation: values.contactInfromation,
      // 	shippingPolicy: values.shippingPolicy,
      // 	termsAndConditions: values.termsAndConditions,
      // 	privacyAndPolicy: values.privacyAndPolicy,
      //   },

      //   onCompleted: (data) => {
      // 	if (data.UpdateSettingsById.code) {
      // 	  setSubmitting(false);
      // 	  toast({
      // 		title: "Your policies has been updated.",
      // 		status: "success",

      // 		position: "bottom-right",
      // 		duration: 5000,
      // 		isClosable: true,
      // 	  });
      // 	  apolloClient.refetchQueries({
      // 		include: "active",
      // 	  });
      // 	}
      //   },
      //   onError: (error) => {
      // 	setSubmitting(false);
      // 	if (error.networkError) {
      // 	  toast({
      // 		title: "Network error.",
      // 		description:
      // 		  "Please check your internet connection, or refresh the page",
      // 		status: "error",

      // 		position: "bottom-right",
      // 		duration: 5000,
      // 		isClosable: true,
      // 	  });
      // 	} else {
      // 	  toast({
      // 		title: "An error occured while updating your policies.",
      // 		description: error.message,
      // 		status: "error",

      // 		position: "bottom-right",
      // 		duration: 5000,
      // 		isClosable: true,
      // 	  });
      // 	}
      //   },
      // });
    },
  });
  useEffect(() => {
    if (isSuccess) {
      PoliciesSettingsFormik.setValues({
        refundPolicy: data?.data?.settings[0]?.moreInfo?.refundPolicy || "",
        privacyAndPolicy:
          data?.data?.settings[0]?.moreInfo?.privacyAndPolicy || "",
        termsAndConditions:
          data?.data?.settings[0]?.moreInfo?.termsAndConditions || "",
        shippingPolicy: data?.data?.settings[0]?.moreInfo?.shippingPolicy || "",
        contactInfromation:
          data?.data?.settings[0]?.moreInfo?.contactInfromation || "",
      });
    }
  }, [isSuccess]);

  const defaultcontent = (variables: string) => {
    switch (variables) {
      case "refundPolicy":
        {
          PoliciesSettingsFormik.setFieldValue(
            "refundPolicy",
            data?.data?.settings[0]?.moreInfo?.refundPolicy
          );
        }
        break;

      case "privacyAndPolicy":
        {
          PoliciesSettingsFormik.setFieldValue(
            "privacyAndPolicy",
            data?.data?.settings[0]?.moreInfo?.privacyAndPolicy
          );
        }
        break;

      case "termsAndConditions":
        {
          PoliciesSettingsFormik.setFieldValue(
            "termsAndConditions",
            data?.data?.settings[0]?.moreInfo?.termsAndConditions
          );
        }
        break;
      case "contactInfromation":
        {
          PoliciesSettingsFormik.setFieldValue(
            "contactInfromation",
            data?.data?.settings[0]?.moreInfo?.contactInfromation
          );
        }
        break;
      case "shippingPolicy":
        {
          PoliciesSettingsFormik.setFieldValue(
            "shippingPolicy",
            data?.data?.settings[0]?.moreInfo?.shippingPolicy
          );
        }
        break;
    }
  };

  return (
    <form onSubmit={PoliciesSettingsFormik.handleSubmit}>
      <Box display="flex" justifyContent="space-between">
        <Heading size="lg" fontSize="2xl" fontWeight="medium">
        <FormattedMessage id="marketPolicies" />
        </Heading>
        <Button
          colorScheme="main"
          type="submit"
          size="sm"
          isLoading={isLoading}
        >
         <FormattedMessage id="save" /> 
        </Button>
      </Box>

      <Stack marginTop="24px" spacing="20px">
        <Box
          p="16px"
          bgColor={sectionBgColor}
          width="100%"
          shadow="md"
          rounded="base"
        >
          <Skeleton isLoaded={!isLoading}>
            <Box display="flex" justifyContent="space-between">
              <FormLabel
                marginBottom="5"
                size="md"
                fontSize="lg"
                fontWeight="medium"
              >
               <FormattedMessage id="refundPolicy" /> 
              </FormLabel>
              <Button
                variant="outline"
                size="sm"
                onClick={() => defaultcontent("refundPolicy")}
              >
               <FormattedMessage id="createFromTemplate" /> 
              </Button>
            </Box>
            <ReactQuill
              value={PoliciesSettingsFormik.values.refundPolicy}
              onChange={(content) =>
                PoliciesSettingsFormik.setFieldValue("refundPolicy", content)
              }
              modules={modules}
              // placeholder="text"
            />
          </Skeleton>
        </Box>
      </Stack>
      <Stack marginTop="24px" spacing="20px">
        <Box
          p="16px"
          bgColor={sectionBgColor}
          width="100%"
          shadow="md"
          rounded="base"
        >
          <Skeleton isLoaded={!isLoading}>
            <Box display="flex" justifyContent="space-between">
              <FormLabel
                marginBottom="5"
                size="md"
                fontSize="lg"
                fontWeight="medium"
              >
               <FormattedMessage id="privacyAndPolicy" />
              </FormLabel>
              <Button
                size="sm"
                variant="outline"
                onClick={() => defaultcontent("privacyAndPolicy")}
              >
               <FormattedMessage id="createFromTemplate" /> 
              </Button>
            </Box>
            <ReactQuill
              value={PoliciesSettingsFormik.values.privacyAndPolicy}
              onChange={(content) =>
                PoliciesSettingsFormik.setFieldValue(
                  "privacyAndPolicy",
                  content
                )
              }
              modules={modules}
              // placeholder="text"
            />
          </Skeleton>
        </Box>
      </Stack>
      <Stack marginTop="24px" spacing="20px">
        <Box
          p="16px"
          bgColor={sectionBgColor}
          width="100%"
          shadow="md"
          rounded="base"
        >
          <Skeleton isLoaded={!isLoading}>
            <Box display="flex" justifyContent="space-between">
              <FormLabel
                marginBottom="5"
                size="md"
                fontSize="lg"
                fontWeight="medium"
              >
              <FormattedMessage id="termsAndConditions"/> 
              </FormLabel>
              <Button
                variant="outline"
                size="sm"
                onClick={() => defaultcontent("termsAndConditions")}
              >
               <FormattedMessage id="createFromTemplate" /> 
              </Button>
            </Box>
            <ReactQuill
              value={PoliciesSettingsFormik.values.termsAndConditions}
              onChange={(content) =>
                PoliciesSettingsFormik.setFieldValue(
                  "termsAndConditions",
                  content
                )
              }
              modules={modules}
              // placeholder="text"
            />
          </Skeleton>
        </Box>
      </Stack>
      <Stack marginTop="24px" spacing="20px">
        <Box
          p="16px"
          bgColor={sectionBgColor}
          width="100%"
          shadow="md"
          rounded="base"
        >
          <Skeleton isLoaded={!isLoading}>
            <Box display="flex" justifyContent="space-between">
              <FormLabel
                marginBottom="5"
                size="md"
                fontSize="lg"
                fontWeight="medium"
              >
                <FormattedMessage id="contactInfromation" />
              </FormLabel>
              <Button
                variant="outline"
                size="sm"
                onClick={() => defaultcontent("shippingPolicy")}
              >
               <FormattedMessage id="createFromTemplate" /> 
              </Button>
            </Box>
            <ReactQuill
              value={PoliciesSettingsFormik.values.shippingPolicy}
              onChange={(content) =>
                PoliciesSettingsFormik.setFieldValue("shippingPolicy", content)
              }
              modules={modules}
              // placeholder="text"
            />
          </Skeleton>
        </Box>
      </Stack>
      <Stack marginTop="24px" spacing="20px">
        <Box
          p="16px"
          bgColor={sectionBgColor}
          width="100%"
          shadow="md"
          rounded="base"
        >
          <Skeleton isLoaded={!isLoading}>
            <Box display="flex" justifyContent="space-between">
              <FormLabel
                marginBottom="5"
                size="md"
                fontSize="lg"
                fontWeight="medium"
              >
               <FormattedMessage id="shippingPolicy" /> 
              </FormLabel>
              <Button
                variant="outline"
                size="sm"
                onClick={() => defaultcontent("contactInfromation")}
              >
               <FormattedMessage id="createFromTemplate" /> 
              </Button>
            </Box>
            <ReactQuill
              value={PoliciesSettingsFormik.values.contactInfromation}
              onChange={(content) =>
                PoliciesSettingsFormik.setFieldValue(
                  "contactInfromation",
                  content
                )
              }
              modules={modules}
              // placeholder="text"
            />
          </Skeleton>
        </Box>
      </Stack>
    </form>
  );
};
export default PoliciesSettings;
