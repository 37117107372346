import {
  Box,
  Button,
  ButtonGroup,
  Card,
  CardBody,
  CardFooter,
  Divider,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  IconButton,
  Image,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Skeleton,
  Stack,
  Text,
  Textarea,
  Tooltip,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { useFormik } from "formik";
import { useEffect, useMemo, useState } from "react";
import {
  BiArrowBack,
  BiChevronLeft,
  BiChevronRight,
  BiEdit,
  BiFilterAlt,
  BiSearch,
  BiTrash,
} from "react-icons/bi";

import { FormattedMessage, useIntl } from "react-intl";
import ReactQuill from "react-quill";
import { useNavigate, Link as RouterLink, useLocation } from "react-router-dom";
import {
  BlogsDataType,
  useBlogsCreateOne,
  useBlogsFindAll,
  useBlogsDeleteOne,
  useBlogsUpdateOne,
} from "../../api/blogs";
import { ImageUpload, Input } from "../../components/commons";
import ConfirmDeleteDialog from "../../components/modules/ConfirmDeleteDialog/ConfirmDeleteDialog";
import { PaginationWrapper } from "../../components/modules/Table/Table.styles";
import { PageWrapper } from "../../utils/globalStyle";

type BlogProps = {
  blog: BlogsDataType;
  key: number | undefined;
  loading: boolean;
};
type CreateBlogModalProps = {
  onClose: () => void;

  onSubmit: () => void;
  // selectedVariant?: VariantType;
};
type UpdateBlogModalProps = {
  onClose: () => void;

  onSubmit: () => void;
  selectedBlog?: BlogsDataType;
};
const Blogs = () => {
  const [selectedBlog, setSelectedBlog] = useState<BlogsDataType>();
  const BlogItem: React.FC<BlogProps> = ({ blog, key, loading }) => {
    return key === undefined ? (
      <Skeleton isLoaded={!loading} mt={4} opacity={loading ? 0.4 : 1}>
        <Card
          direction={{ base: "column", sm: "row" }}
          overflow="hidden"
          variant="outline"
        >
          <Image
            objectFit="cover"
            maxW={{ base: "100%", sm: "200px" }}
            src={blog.image}
            alt="Caffe Latte"
          />

          <Stack>
            <CardBody>
              <Heading size="md">{blog.title}</Heading>
              <Text
                dangerouslySetInnerHTML={{
                  __html: blog.content,
                }}
              />

              <Text color="blue.600" fontSize="2xl">
                {blog.author ? blog.author.fullName : ""}
              </Text>
            </CardBody>

            <CardFooter>
              <ButtonGroup spacing="2">
                <ConfirmDeleteDialog
                  item="Blogs"
                  dialogButtonProps={{
                    type: "IconButton",

                    children: (
                      <Tooltip label="Delete blogs" aria-label="A tooltip">
                        <IconButton
                          aria-label="decline_button"
                          size="sm"
                          variant="outline"
                          colorScheme="red"
                        >
                          <BiTrash size={18} />
                        </IconButton>
                      </Tooltip>
                    ),
                    props: {
                      variant: "ghost",
                      size: "sm",
                    },
                  }}
                  onDelete={() => {
                    BlogsDeleteOneMutate(blog._id);
                  }}
                />
                <Tooltip label="Edit blog" aria-label="A tooltip">
                  <IconButton
                    aria-label="Edit blog"
                    size="sm"
                    variant="outline"
                    colorScheme="whatsapp"
                    onClick={() => {
                      setSelectedBlog(blog);
                      onOpenBlogModal();
                    }}
                  >
                    <BiEdit size={16} />
                  </IconButton>
                </Tooltip>
              </ButtonGroup>
            </CardFooter>
          </Stack>
        </Card>
      </Skeleton>
    ) : (
      <Box></Box>
    );
  };
  const location = useLocation();
  const intl = useIntl();
  const navigate = useNavigate();
  const [blogs, setBlogs] = useState<BlogsDataType[]>([]);
  const {
    mutate: GetAllBlogsMutate,
    data: GetAllBlogsData,
    isLoading: GetAllBlogsIsLoading,
    isSuccess: GetAllBlogsIsSuccess,
    isError: GetAllBlogsIsError,
    error: GetAllBlogsError,
  } = useBlogsFindAll();
  const {
    mutate: BlogsDeleteOneMutate,
    isSuccess: BlogsDeleteOneIsSuccess,
    isError: BlogsDeleteOneIsError,
    error: BlogsDeleteOneError,
  } = useBlogsDeleteOne();
  useEffect(() => {
    if (BlogsDeleteOneIsSuccess) {
      toast({
        title: "blog deleted with succcess",
        status: "success",
        position: `bottom-right`,

        isClosable: true,
      });
      GetAllBlogsMutate({
        skip: pagination.skip,
        limit: pagination.limit,
        search: search,
        // author:author,
        createdAt: sortByCreated,
        updatedAt: sortByUpdated,
      });
    }
  }, [BlogsDeleteOneIsSuccess]);
  const [search, setSearch] = useState("");
  const [author, setAuthor] = useState("");
  const [sortByCreated, setSortByCreated] = useState<1 | -1>(-1);
  const [sortByUpdated, setSortByUpdated] = useState<1 | -1>(-1);
  const [pagination, setPagination] = useState({
    count: 0,
    limit: 5,
    skip: 0,
    page: 1,
  });
  const {
    isOpen: openFilter,
    onOpen: onOpenFilter,
    onClose: onCloseFilter,
  } = useDisclosure();
  useEffect(() => {
    GetAllBlogsMutate({
      skip: pagination.skip,
      limit: pagination.limit,
      search: search,
      // author:author,
      createdAt: sortByCreated,
      updatedAt: sortByUpdated,
    });
  }, [search,pagination.limit,pagination.skip]);
  useEffect(() => {
    if (GetAllBlogsIsSuccess) {
      // console.log("blogs", GetAllBlogsData.data.blogs);
      setBlogs(GetAllBlogsData.data.blogs);
      setPagination((prevState) => {
        let temp = { ...prevState };
        temp.count = GetAllBlogsData?.data.blogsCount;
        return temp;
      });
    }
  }, [GetAllBlogsIsSuccess]);
  const handleLimitChange = (value: number) => {
    setPagination((prevState) => {
      let temp = { ...prevState };
      temp.limit = value;
      return temp;
    });
  };
  const handlePageChange = (dir: "next" | "prev") => {
    if (dir === "next") {
      setPagination((prevState) => {
        let temp = { ...prevState };
        temp.skip = temp.skip + temp.limit;
        return temp;
      });
    } else {
      setPagination((prevState) => {
        let temp = { ...prevState };
        temp.skip = temp.skip - temp.limit;
        return temp;
      });
    }
  };
  const rows = useMemo(
    () => blogs.map((item, index) => ({ render: item, key: index })),
    [blogs]
  );
  const { from } = (location?.state as { from?: string }) || "";
  const handleSearchSubmit = (event: any) => {
    if (event.code === "Enter") {
      // console.log(event.target.value);
      setSearch(event.target.value);
      GetAllBlogsMutate({
        skip: pagination.skip,
        limit: pagination.limit,
        search: search,
        // category:category,
        // isAvliable:isAvliable,

        // status: status,
        createdAt: sortByCreated,
        updatedAt: sortByUpdated,
      });
    }
  };
  const {
    isOpen: isOpenOptionModal,
    onOpen: onOpenOptionModal,
    onClose: onCloseOptionModal,
  } = useDisclosure();
  const {
    isOpen: isOpenBlogModal,
    onOpen: onOpenBlogModal,
    onClose: onCloseBlogModal,
  } = useDisclosure();
  const toast = useToast();
  return (
    <PageWrapper>
      {" "}
      <Stack direction={{ base: "column", md: "row" }} spacing="18px"className="page_header">
        <Box className="page_header_title">
         
          <Box className="page_header_title_content">
            <Heading fontSize="2xl">
              <FormattedMessage id={"blogs.list"} />
            </Heading>
          </Box>
        </Box>

        <Stack direction="row">
          {/* <Button variant='outline'>Export</Button> */}
          <Button colorScheme="main" onClick={() => onOpenOptionModal()}>
            <FormattedMessage id={"blogs.create"} />
          </Button>
        </Stack>
      </Stack>
      <Box className="page_actions">
        <Input
          inputProps={{
            placeholder: intl.formatMessage({
              id: "blogSearch",
            }),
            onKeyUp: handleSearchSubmit,
          }}
          inputLeftElementProps={{ children: <BiSearch /> }}
        />
        {/* <Divider orientation="vertical" />
        <Button
          variant="outline"
          leftIcon={<BiFilterAlt />}
          onClick={onOpenFilter}
        >
          <FormattedMessage id={"filtre"} />
        </Button> */}
      </Box>
      {blogs &&
        blogs.map((blog, index) => (
          <BlogItem blog={blog} key={index} loading={false} />
        ))}
      {blogs && (
        <PaginationWrapper flexDirection={{ base: "column", sm: "row" }}>
          <Box className="rows_perpage">
            <Text><FormattedMessage id={"pagination"} /></Text>
            <Select
              size="sm"
              onChange={(e) => handleLimitChange(parseInt(e.target.value))}
              value={pagination.limit}
            >
              <option value={5}>5</option>
              <option value={10}>10</option>
              <option value={25}>25</option>
              <option value={50}>50</option>
              <option value={100}>100</option>
            </Select>
          </Box>
          <Box className="pagination_action">
              <Text>{`${pagination.skip + 1} - ${
                pagination.skip +GetAllBlogsData?.data.blogsCount >
                (GetAllBlogsData?.data.queryCount || 0)
                  ? GetAllBlogsData?.data.queryCount || 0
                  : pagination.skip +GetAllBlogsData?.data.blogsCount
              } of ${GetAllBlogsData?.data.queryCount || 0}`}</Text>
              <IconButton
                onClick={() => handlePageChange("prev")}
                disabled={pagination.skip < 1}
                aria-label="previous_button"
                size="sm"
                variant="ghost"
              >
                <BiChevronLeft size={24} />
              </IconButton>
              <IconButton
                onClick={() => handlePageChange("next")}
                aria-label="next_button"
                disabled={
                  (GetAllBlogsData?.data.queryCount || 0) <
                  pagination.skip + pagination.limit
                }
                size="sm"
                variant="ghost"
              >
                <BiChevronRight size={24} />
              </IconButton>
            </Box>
        </PaginationWrapper>
      )}
      <Modal
        isOpen={isOpenOptionModal}
        onClose={onCloseOptionModal}
        isCentered
        size="xl"
        scrollBehavior="inside"
      >
        <ModalOverlay />
        <CreateBlogModal
          onClose={() => {
            onCloseOptionModal();
          }}
          onSubmit={() => {
            toast({
              title: "blog created with succcess",
              status: "success",
              position: `bottom-right`,

              isClosable: true,
            });
            onCloseOptionModal();
            GetAllBlogsMutate({
              skip: pagination.skip,
              limit: pagination.limit,
              search: search,
              // author:author,
              createdAt: sortByCreated,
              updatedAt: sortByUpdated,
            });
          }}

          // selectedVariant={selectedVariant}
        />
      </Modal>
      <Modal
        isOpen={isOpenBlogModal}
        onClose={onCloseBlogModal}
        isCentered
        size="xl"
        scrollBehavior="inside"
      >
        <ModalOverlay />
        <UpdateBlogModal
          onClose={() => {
            onCloseBlogModal();
          }}
          onSubmit={() => {
            toast({
              title: "blog updated with succcess",
              status: "success",
              position: `bottom-right`,

              isClosable: true,
            });
            onCloseBlogModal();
            GetAllBlogsMutate({
              skip: pagination.skip,
              limit: pagination.limit,
              search: search,
              // author:author,
              createdAt: sortByCreated,
              updatedAt: sortByUpdated,
            });
          }}
          selectedBlog={selectedBlog}
        />
      </Modal>
    </PageWrapper>
  );
};
export default Blogs;
const CreateBlogModal = (props: CreateBlogModalProps) => {
  const [files, setFiles] = useState<
    {
      progress: boolean;
      id: string;
      src: File | string | undefined;
    }[]
  >([]);
  const {
    mutate: CreateOneBlogsMutate,
    data: CreateOneBlogsData,
    isLoading: CreateOneBlogsIsLoading,
    isSuccess: CreateOneBlogsIsSuccess,
    isError: CreateOneBlogsIsError,
    error: CreateOneBlogsError,
  } = useBlogsCreateOne();
  useEffect(() => {
    if (CreateOneBlogsIsSuccess) {
      props.onSubmit();
      props.onClose();
    }
  }, [CreateOneBlogsIsSuccess]);
  const handleCloseModal = () => {
    setFiles([]);

    props.onClose();
  };
  const modules = {
    toolbar: [
      [{ header: "1" }, { header: "2" }, { font: [] }],
      [{ size: [] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link"],
    ],
    clipboard: {
      // toggle to add extra line breaks when pasting HTML:
      matchVisual: false,
    },
  };

  const toast = useToast();
  const BlogFormik = useFormik({
    validateOnMount: false,
    validateOnBlur: false,
    validateOnChange: false,
    initialValues: { image: "", title: "", description: "", content: "" },

    onSubmit: (values, { setSubmitting }) => {
      if (files?.[0]?.src) {
        const saveData = {
          image: files?.[0]?.src as string,
          title: values.title,
          description: values.description,
          content: values.content,
        };
        CreateOneBlogsMutate(saveData);
      } else {
        const saveData = {
          title: values.title,
          description: values.description,
          content: values.content,
        };
        CreateOneBlogsMutate(saveData);
      }
    },
  });

  const handleFile = async (file: File) => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("upload_preset", "ml_default");
    formData.append("public_id", `categories/${file.name}`);
    setFiles((prevFiles) => [
      ...prevFiles,
      {
        src: file,
        id: file.name,
        progress: true,
        name: file.name,
        type: file.type,
      },
    ]);
    const data = await (
      await fetch("https://api.cloudinary.com/v1_1/dgpjskmv1/upload", {
        method: "POST",
        body: formData,
      })
    ).json();

    setFiles((prevFiles) => [
      ...prevFiles.filter(({ id }) => id !== file.name),
      {
        src: data.secure_url,
        id: data.asset_id,
        progress: false,
      },
    ]);
  };

  const handleDelete = (id: string) => {
    // console.log(
    //   id,
    //   files.filter(({ id: imageId }) => id !== imageId)
    // );
    setFiles((prevFiles) =>
      prevFiles.filter(({ id: imageId }) => id !== imageId)
    );
  };

  const intl = useIntl();

  return (
    <ModalContent>
      <ModalHeader borderBottomWidth="1px">
        <FormattedMessage id={"blogs.create"} />
      </ModalHeader>
      <ModalCloseButton />
      <ModalBody>
        <form id="create-update-variant" onSubmit={BlogFormik.handleSubmit}>
          <Stack spacing="12px">
            <ImageUpload
              height="120px"
              width={"100%"}
              images={files}
              handleUpload={handleFile}
              handleDelete={handleDelete}
              label={intl.formatMessage({
                id: "image",
              })}
              gridColCount={3}
            />
            <Input
              label={intl.formatMessage({
                id: "title",
              })}
              inputProps={{
                placeholder: intl.formatMessage({
                  id: "title",
                }),
                name: "title",
                onChange: BlogFormik.handleChange,
                value: BlogFormik.values.title,
              }}
              errorMessage={BlogFormik.errors.title}
              isError={BlogFormik.errors.title !== undefined}
            />
            <Box>
              <FormLabel marginBottom="1">
                <FormattedMessage id={"content"} />
              </FormLabel>
              <ReactQuill
                value={BlogFormik.values.content}
                onChange={(content) =>
                  BlogFormik.setFieldValue("content", content)
                }
                modules={modules}
              />
            </Box>
            <FormControl>
              <FormLabel marginBottom="1">
                <FormattedMessage id={"category.description"} />
              </FormLabel>
              <Textarea
                placeholder="Description"
                name="description"
                onChange={BlogFormik.handleChange}
                value={BlogFormik.values.description}
              />
              {BlogFormik.errors.description && (
                <FormErrorMessage>
                  {BlogFormik.errors.description}
                </FormErrorMessage>
              )}
            </FormControl>
          </Stack>
        </form>
      </ModalBody>

      <ModalFooter borderTopWidth="1px">
        <Button variant="ghost" mr={3} onClick={handleCloseModal}>
          <FormattedMessage id={"discard"} />
        </Button>
        <Button
          type="submit"
          form="create-update-variant"
          colorScheme="main"
          isLoading={CreateOneBlogsIsLoading}
        >
          <FormattedMessage id={"blog.create"} />
        </Button>
      </ModalFooter>
    </ModalContent>
  );
};
const UpdateBlogModal = (props: UpdateBlogModalProps) => {
  const [files, setFiles] = useState<
    {
      progress: boolean;
      id: string;
      src: File | string | undefined;
    }[]
  >([]);

  useEffect(() => {
    BlogFormik.setFieldValue("image", props.selectedBlog?.image);
    BlogFormik.setFieldValue("title", props.selectedBlog?.title);
    BlogFormik.setFieldValue("description", props.selectedBlog?.description);
    BlogFormik.setFieldValue("content", props.selectedBlog?.content);
    setFiles((prevFiles) => [
      ...prevFiles.filter(({ id }) => id !== props.selectedBlog?._id),
      {
        src: props.selectedBlog ? props.selectedBlog.image : "",
        id: props.selectedBlog ? props.selectedBlog._id : "",
        progress: false,
      },
    ]);
  }, [props.selectedBlog]);
  const {
    mutate: UpdateOneBlogsMutate,
    data: UpdateOneBlogsData,
    isLoading: UpdateOneBlogsIsLoading,
    isSuccess: UpdateOneBlogsIsSuccess,
    isError: UpdateOneBlogsIsError,
    error: UpdateOneBlogsError,
  } = useBlogsUpdateOne();
  const handleCloseModal = () => {
    setFiles([]);

    props.onClose();
  };
  useEffect(() => {
    if (UpdateOneBlogsIsSuccess) {
      props.onSubmit();
      props.onClose();
    }
  }, [UpdateOneBlogsIsSuccess]);
  const modules = {
    toolbar: [
      [{ header: "1" }, { header: "2" }, { font: [] }],
      [{ size: [] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link"],
    ],
    clipboard: {
      // toggle to add extra line breaks when pasting HTML:
      matchVisual: false,
    },
  };
  const toast = useToast();
  const BlogFormik = useFormik({
    validateOnMount: false,
    validateOnBlur: false,
    validateOnChange: false,
    initialValues: { image: "", title: "", description: "", content: "" },

    onSubmit: (values, { setSubmitting }) => {
      if (files?.[0]?.src) {
        const saveData = {
          image: files?.[0]?.src as string,
          title: values.title,
          description: values.description,
          content: values.content,
        };
        UpdateOneBlogsMutate({
          catObj: saveData,
          id: props.selectedBlog ? props.selectedBlog._id : "",
        });
      } else {
        const saveData = {
          title: values.title,
          description: values.description,
          content: values.content,
        };
        UpdateOneBlogsMutate({
          catObj: saveData,
          id: props.selectedBlog ? props.selectedBlog._id : "",
        });
      }
    },
  });

  const handleFile = async (file: File) => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("upload_preset", "ml_default");
    formData.append("public_id", `categories/${file.name}`);
    setFiles((prevFiles) => [
      ...prevFiles,
      {
        src: file,
        id: file.name,
        progress: true,
        name: file.name,
        type: file.type,
      },
    ]);
    const data = await (
      await fetch("https://api.cloudinary.com/v1_1/dgpjskmv1/upload", {
        method: "POST",
        body: formData,
      })
    ).json();

    setFiles((prevFiles) => [
      ...prevFiles.filter(({ id }) => id !== file.name),
      {
        src: data.secure_url,
        id: data.asset_id,
        progress: false,
      },
    ]);
  };

  const handleDelete = (id: string) => {
    // console.log(
    //   id,
    //   files.filter(({ id: imageId }) => id !== imageId)
    // );
    setFiles((prevFiles) =>
      prevFiles.filter(({ id: imageId }) => id !== imageId)
    );
  };

  const intl = useIntl();

  return (
    <ModalContent>
      <ModalHeader borderBottomWidth="1px">
        <FormattedMessage id={"role.update.btn.update"} /> "
        {props.selectedBlog?.title}"
      </ModalHeader>
      <ModalCloseButton />
      <ModalBody>
        <form id="create-update-variant" onSubmit={BlogFormik.handleSubmit}>
          <Stack spacing="12px">
            <ImageUpload
              height="120px"
              width={"100%"}
              images={files}
              handleUpload={handleFile}
              handleDelete={handleDelete}
              label={intl.formatMessage({
                id: "image", })}
              gridColCount={3}
            />
            <Input
              label={intl.formatMessage({
                id: "title",
              })}
              inputProps={{
                placeholder: intl.formatMessage({
                  id: "title",
                }),
                name: "title",
                onChange: BlogFormik.handleChange,
                value: BlogFormik.values.title,
              }}
              errorMessage={BlogFormik.errors.title}
              isError={BlogFormik.errors.title !== undefined}
            />
            <Box>
              <FormLabel marginBottom="1">
                <FormattedMessage id={"content"} />
              </FormLabel>
              <ReactQuill
                value={BlogFormik.values.content}
                onChange={(content) =>  BlogFormik.setFieldValue("content", content)
                }
                modules={modules}
              />
            </Box>
            <FormControl>
              <FormLabel marginBottom="1">
                <FormattedMessage id={"category.description"} />
              </FormLabel>
              <Textarea
                placeholder="Description"
                name="description"
                onChange={BlogFormik.handleChange}
                value={BlogFormik.values.description}
              />
              {BlogFormik.errors.description && (
                <FormErrorMessage>
                  {BlogFormik.errors.description}
                </FormErrorMessage>
              )}
            </FormControl>
          </Stack>
        </form>
      </ModalBody>

      <ModalFooter borderTopWidth="1px">
        <Button variant="ghost" mr={3} onClick={handleCloseModal}>
          <FormattedMessage id={"discard"} />
        </Button>
        <Button
          type="submit"
          form="create-update-variant"
          colorScheme="main"
          isLoading={UpdateOneBlogsIsLoading}
        >
          <FormattedMessage id={"category.attribut.update.btn"} />
        </Button>
      </ModalFooter>
    </ModalContent>
  );
};
