import { FC, ReactNode } from "react";
import {
  Box,
  Button,
  Divider,
  FormLabel,
  Heading,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Skeleton,
  Stack,
  Switch,
  Tag,
  Text,
  useColorModeValue,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { Input } from "../../../components/commons";

type formWrapperLayoutProps = {
  children: ReactNode;
  formik: any;
  loading?: boolean;
  buttonAction?: any;
  buttonText?: any;
  header?: string;
  text?: string;
};

const FormWrapperLayout: FC<formWrapperLayoutProps> = ({
  children,
  formik,
  loading,
  buttonAction,
  buttonText,
  header,
  text,
}) => {
  const borderColor = useColorModeValue("gray.100", "gray.700");
  return (
    // <Stack
    //   direction={{ base: "column-reverse", lg: "row" }}
    //   spacing="14px"
    //   alignItems="flex-start"
    // >
      <Stack spacing="12px" flex={0.65} width="100%">
        <Skeleton isLoaded={!loading} opacity={loading ? 0.4 : 1}>
          <Stack
            padding="18px 18px"
            border="1px"
            borderColor={borderColor}
            rounded="base"
          >
            {(header || text || buttonText) && (
              <Stack
                width="full"
                direction={{ base: "column", md: "row" }}
                justifyContent="space-between"
              >
                <Box>
                  <Heading size="md" mb="2">
                    {header}
                  </Heading>
                  <Text opacity={0.6} lineHeight="1.2" fontSize="sm">
                    {text}
                  </Text>
                </Box>
                <Button
                  onClick={buttonAction}
                  colorScheme="main"
                  size="sm"
                  minWidth="200px"
                  //width="-webkit-fill-available"
                >
                  {buttonText}
                </Button>
              </Stack>
            )}
            {children}
          </Stack>
        </Skeleton>
      </Stack>
      
    // </Stack>
  );
};

export default FormWrapperLayout;
