import { useToast } from "@chakra-ui/react";
import { useMutation } from "@tanstack/react-query";
import {
  CREATE_ONE_SOCIAL_LINKS,
  CREATE_ONE_SOCIAL_LINKS_SECTION,
  DELETE_ONE__SOCIAL_LINKS_SECTION,
  GET_SOCIAL_LINKS_SECTION,
  paginationSocialLinks,
  UPDATE_ONE_SOCIAL_LINKS,
  UPDATE_ONE__SOCIAL_LINKS_SECTION,
} from "../endpoints/socialLinks";
import {
  protectedDelete,
  protectedGet,
  protectedPost,
  protectedPut,
} from "../http-methods";

export type SocialLinksSectionsDataType = {
  _id: string;
  section: string;
  socialLinks: {
    name: string;
    url: string;
    isActive: boolean;
    icon: string;
    _id: string;
  }[];
  createdAt: string;
  updatedAt: string;
  __v: number;
};
export type socialLinksSectionsDataType = {
  section: string;
  socialLinks?: string[];
};
export type socialLinksDataType = {
  name: string;
  url: string;
  isActive: boolean;
  icon: string;
};
export type SocialLinksSectionsUpdateDataType = {
  section?: string;
  socialLinks?: string[];
  createdAt?: string;
  updatedAt?: string;
  __v?: number;
};
export type SocialLinksUpdateDataType = {
  name?: string;
  url?: string;
  isActive?: boolean;
  icon?: string;
};
export type socialLinksCreateDataType = {
  section?: string;
  socialLinks?: string[];
};
export const useSocialLinksSectionsFindAll = () => {
  return useMutation({
    mutationFn: (obj: paginationSocialLinks) => {
      return protectedGet(GET_SOCIAL_LINKS_SECTION(obj));
    },
  });
};
export const useSocialLinksSectionsCreateOne = () => {
  const toast = useToast();
  return useMutation({
    mutationFn: (catObj: socialLinksSectionsDataType) => {
      return protectedPost(CREATE_ONE_SOCIAL_LINKS_SECTION, catObj);
    },
    onError: (data: any) => {
      console.log("dataError", data.response.data.errors);
      const errors: any = data.response.data.errors;
      if (errors[0]) {
        toast({
          title: errors[0].msg,
          position: `bottom-right`,
          status: "error",
          isClosable: true,
        });
      } else if (errors.section) {
        toast({
          title: "section",
          description: errors.section.msg,
          position: `bottom-right`,
          status: "error",
          isClosable: true,
        });
      } else if (errors.socialLinks) {
        toast({
          title: "socialLinks",
          description: errors.socialLinks.msg,
          position: `bottom-right`,
          status: "error",
          isClosable: true,
        });
      }
    },
  });
};
export const useUpdateSocialLinksSections = () => {
  const toast = useToast();
  return useMutation({
    mutationFn: ({
      obj,
      id,
    }: {
      obj: SocialLinksSectionsUpdateDataType;
      id: string;
    }) => {
      return protectedPut(UPDATE_ONE__SOCIAL_LINKS_SECTION(id), obj);
    },
    onError: (data: any) => {
      console.log("dataError", data.response.data.errors);
      const errors: any = data.response.data.errors;
      if (errors[0]) {
        toast({
          title: errors[0].msg,
          position: `bottom-right`,
          status: "error",
          isClosable: true,
        });
      } else if (errors.section) {
        toast({
          title: "section",
          description: errors.section.msg,
          position: `bottom-right`,
          status: "error",
          isClosable: true,
        });
      } else if (errors.socialLinks) {
        toast({
          title: "socialLinks",
          description: errors.socialLinks.msg,
          position: `bottom-right`,
          status: "error",
          isClosable: true,
        });
      }
    },
  });
};
export const useDeleteOneSocialLinksSections = () => {
  return useMutation({
    mutationFn: (catObj: string) => {
      return protectedDelete(DELETE_ONE__SOCIAL_LINKS_SECTION, catObj);
    },
  });
};
export const useSocialLinksCreateOne = () => {
  const toast = useToast();
  return useMutation({
    mutationFn: (catObj: socialLinksDataType) => {
      return protectedPost(CREATE_ONE_SOCIAL_LINKS, catObj);
    },
    onError: (data: any) => {
      console.log("dataError", data.response.data.errors);
      const errors: any = data.response.data.errors;
      if (errors[0]) {
        toast({
          title: errors[0].msg,
          position: `bottom-right`,
          status: "error",
          isClosable: true,
        });
      } else if (errors.name) {
        toast({
          title: "name",
          description: errors.name.msg,
          position: `bottom-right`,
          status: "error",
          isClosable: true,
        });
      } else if (errors.url) {
        toast({
          title: "url",
          description: errors.url.msg,
          position: `bottom-right`,
          status: "error",
          isClosable: true,
        });
      } else if (errors.icon) {
        toast({
          title: "icon",
          description: errors.icon.msg,
          position: `bottom-right`,
          status: "error",
          isClosable: true,
        });
      }
    },
  });
};
export const useUpdateSocialLinks = () => {
  const toast = useToast();
  return useMutation({
    mutationFn: ({
      obj,
      id,
    }: {
      obj: SocialLinksUpdateDataType;
      id: string;
    }) => {
      return protectedPut(UPDATE_ONE_SOCIAL_LINKS(id), obj);
    },
    onError: (data: any) => {
      console.log("dataError", data.response.data.errors);
      const errors: any = data.response.data.errors;
      if (errors[0]) {
        toast({
          title: errors[0].msg,
          position: `bottom-right`,
          status: "error",
          isClosable: true,
        });
      } else if (errors.name) {
        toast({
          title: "name",
          description: errors.name.msg,
          position: `bottom-right`,
          status: "error",
          isClosable: true,
        });
      } else if (errors.url) {
        toast({
          title: "url",
          description: errors.url.msg,
          position: `bottom-right`,
          status: "error",
          isClosable: true,
        });
      } else if (errors.icon) {
        toast({
          title: "icon",
          description: errors.icon.msg,
          position: `bottom-right`,
          status: "error",
          isClosable: true,
        });
      }
    },
  });
};
