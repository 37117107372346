import {
  Box,
  Text,
  Heading,
  Stack,
  useColorModeValue,
  useToast,
  Skeleton,
  Divider,
  Button,
  FormLabel,
  Switch,
  FormControl,
  Textarea,
  IconButton,
  InputGroup,
  InputLeftAddon,
  Input as ChInput,
} from "@chakra-ui/react";
import { ReactNode, useEffect, useState } from "react";
import { FormikErrors, useFormik } from "formik";

import { BiPlus, BiTrash } from "react-icons/bi";

import { useNavigate } from "react-router-dom";
import { Input } from "../../../components/commons";
import CategorySelectInput from "../../../components/modules/CategorySelectInput/CategorySelectInput";
import TagsSelectInput from "../../../components/modules/TagsSelectInput/TagsSelectInput";
import FormWrapperLayout from "./FormWrapperLayout";
import { productDataType, usePatchOneProducts } from "../../../api/products";
import { FormattedMessage, useIntl } from "react-intl";

type ProductFilesFormProps = {
  productID?: string;
  actionButtons: (node: ReactNode) => void;
  product?: productDataType;
};

const ProductSEOForm = (props: ProductFilesFormProps) => {
  const { product, actionButtons } = props;
  useEffect(() => {
    seoProductFormik.setFieldValue("slug", product?.slug|| "");
    seoProductFormik.setFieldValue("seotitle", product?.seo?.seotitle || "");
    seoProductFormik.setFieldValue("keywords", product?.seo?.keywords || "");
    setMetaTags(
      product?.seo?.metatags?.map((tag) => ({
        key: tag.key || "",
        value: tag.value || "",
      })) || []
    );
    seoProductFormik.setFieldValue(
      "metadescription",
      product?.seo?.metadescription || ""
    );
  }, [product]);
  const borderColor = useColorModeValue("gray.100", "gray.700");

  const toast = useToast();

  const [metaTags, setMetaTags] = useState<
    { key?: string | null; value?: string | null }[]
  >([]);

  const handleChangeMetaTags = (
    value: string,
    index: number,
    type: "key" | "value"
  ) => {
    setMetaTags((prevState) => {
      let temp = [...prevState];
      if (type === "key") {
        temp[index].key = value;
      } else {
        temp[index].value = value;
      }
      return temp;
    });
  };

  const handleDeleteMetaTag = (index: number) => {
    setMetaTags((prevState) => {
      let temp = [...prevState];
      temp.splice(index, 1);
      return temp;
    });
  };
  const intl = useIntl();
  const handleAddMetatag = () => {
    setMetaTags((prevState) => [...prevState, { key: "", value: "" }]);
  };
  const {
    mutate: productsUpdateMutate,
    data: productsUpdateData,
    isLoading: productsUpdateIsLoading,
    isSuccess: productsUpdateIsSuccess,
    isError: productsUpdateIsError,
    error: productsUpdateError,
  } = usePatchOneProducts();

  const seoProductFormik = useFormik({
    validateOnMount: false,
    validateOnBlur: false,
    validateOnChange: false,
    initialValues: {
      seotitle: "",
      slug:"",
      keywords: "",
      metadescription: "",
    },

    onSubmit: (values, { setSubmitting }) => {
      productsUpdateMutate({
        catObj: {
          slug:values.slug,
          seo: {
            seotitle: values.seotitle,

            keywords: values.keywords,
            metadescription: values.metadescription,
            metatags: metaTags,
          },
        },
        id: product?._id ? product?._id : "",
      });
    },
  });

  const navigate = useNavigate();
  useEffect(() => {
    if (productsUpdateIsSuccess) {
      toast({
        title: "Product updated with succcess",
        status: "success",
        position: `bottom-right`,

        isClosable: true,
      });
    }
  }, [productsUpdateIsSuccess]);
  useEffect(() => {
    actionButtons(
      <>
        <Button
          variant="outline"
          onClick={() =>
            navigate(`/products`, {
              state: {
                pageName: "Products",
              },
            })
          }
        >
          <FormattedMessage id={"discard"} />
        </Button>
        <Button
          colorScheme="main"
          type="submit"
          form="update-product-seo"
          isLoading={productsUpdateIsLoading}
        >
         <FormattedMessage id={"save"} />
        </Button>
      </>
    );
  }, [seoProductFormik.isSubmitting]);

  return (
    <form id="update-product-seo" onSubmit={seoProductFormik.handleSubmit}>
      <FormWrapperLayout
        formik={seoProductFormik}
        //    loading={seoProductLoading}
      >
        <Box>
          <Heading size="md" mb="2">
           <FormattedMessage id={"product.nav.seo"} />
          </Heading>
          <Text opacity={0.6} lineHeight="1.2" fontSize="sm">
          <FormattedMessage id={"product.seo"} />
          </Text>
        </Box>
        <Stack>
          <Input
            label="Page title"
            inputProps={{
              placeholder: "Page title",
              name: "seotitle",
              onChange: seoProductFormik.handleChange,
              value: seoProductFormik.values.seotitle,
            }}
          />
           <Input
            label="seo.slug"
            inputProps={{
              placeholder: "seo.slug",
              name: "slug",
              onChange: seoProductFormik.handleChange,
              value:seoProductFormik.values.slug,
             
            }}
            inputLeftAddon={{
              children: "bitaqa.tn/home/products/",
            }}
          
          />

       
          {/* <Input
            label="Page slug (read only)"
            //width="100%"
            inputProps={{
              placeholder: "Page slug",
              value: seoProductData?.GetProductById?.slug || "",
              isReadOnly: true,
            }}
            inputLeftAddon={{
              children: process.env.REACT_APP_MARKETPLACE_URL + "products/",
            }}
          />*/}
          <FormControl>
            <FormLabel marginBottom="1"><FormattedMessage id={"description"} /></FormLabel>
            <Textarea
              placeholder={intl.formatMessage({
                id: "description",
              })}
              name="metadescription"
              onChange={seoProductFormik.handleChange}
              value={seoProductFormik.values.metadescription}
            />
          </FormControl>
          <Box>
            <FormLabel><FormattedMessage id={"meta.tags"} /></FormLabel>
            {metaTags.map((tag, index) => (
              <Box>
                <Text><FormattedMessage id={"tag"} /> {index + 1}</Text>
                <Stack
                  mb="12px"
                  direction="row"
                  alignItems="flex-end"
                  spacing="14px"
                >
                  <Input
                    label={intl.formatMessage({
                      id: "key",
                    })}
                    inputProps={{
                      placeholder: intl.formatMessage({
                        id: "key",
                      }),
                      onChange: (e) =>
                        handleChangeMetaTags(e.target.value, index, "key"),
                      value: tag.key || "",
                    }}
                  />
                  <Input
                    label={intl.formatMessage({
                      id: "value",
                    })}
                    inputProps={{
                      placeholder: intl.formatMessage({
                        id: "value",
                      }),
                      onChange: (e) =>
                        handleChangeMetaTags(e.target.value, index, "value"),
                      value: tag.value || "",
                    }}
                  />
                  <IconButton
                    variant="ghost"
                    colorScheme="red"
                    aria-label="delete_meta-tags"
                    onClick={() => handleDeleteMetaTag(index)}
                  >
                    <BiTrash size="18" />
                  </IconButton>
                </Stack>
              </Box>
            ))}
            <Button
              onClick={handleAddMetatag}
              variant="outline"
              leftIcon={<BiPlus />}
            >
            <FormattedMessage id={"metaTag.add"} /> 
            </Button>
          </Box> 
        </Stack>
      </FormWrapperLayout>
    </form>
  );
};
export default ProductSEOForm;
