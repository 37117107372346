import { LinkBox } from "@chakra-ui/react";
import styled from "@emotion/styled";

export const NavItemWrapper = styled(LinkBox)`
  .navContent {
  }

  .selectedNavigation {
    height: 100%;
    width: 3px;
    position: absolute;
    right: 0;
    border-radius: 4px 0px 0px 4px;
  }
`;
