import { messages } from "./messages";
import { IntlProvider } from "react-intl";
import { useContext } from "react";
import { AppContext } from "../context/appContext";
type LanguageProviderPropType = {
  children?: any;
};

const LanguageProvider = ({ children }: LanguageProviderPropType) => {
  const { language } = useContext(AppContext);

  return (
    <IntlProvider
      locale={language}
      defaultLocale={language}
      messages={messages[language]}
    >
      {children}
    </IntlProvider>
  );
};
export default LanguageProvider;
