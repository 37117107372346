import { Box, forwardRef } from "@chakra-ui/react";
import moment from "moment";
import { useState } from "react";
import DatePicker, { ReactDatePickerProps } from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import { BiCalendar, BiCalendarAlt } from "react-icons/bi";
import Input from "../Input/Input";

type DateTimeInputProps = {
  label?: string;
  selected: Date | null | undefined;
  onChange: (
    date: Date | null,
    event: React.SyntheticEvent<ReactDatePickerProps, Event> | undefined
  ) => void;
  showTime?: boolean;
  name?: string;
  errorMessage?: string;
  isError?: boolean;
};

const DateTimeInput = (props: DateTimeInputProps) => {
  const { name, label, onChange, selected, showTime, errorMessage, isError } =
    props;

  const ExampleCustomInput = forwardRef(
    ({ value, onClick, onChange }: any, ref) => {
      const [tempValue, setTempValue] = useState(value);
      return (
        <Input
          inputProps={{
            value,
            ref,
            onClick,
            onChange,
            placeholder: label,
          }}
          inputLeftElementProps={{ children: <BiCalendarAlt size={18} /> }}
          label={label}
          errorMessage={errorMessage}
          isError={isError}
        />
      );
    }
  );

  return (
    <DatePicker
      name={name}
      selected={selected}
      onChange={onChange}
      locale={moment.locale()}
      showTimeSelect={showTime}
      timeFormat={showTime ? "p" : undefined}
      timeIntervals={15}
      dateFormat={showTime ? "Pp" : undefined}
      customInput={<ExampleCustomInput />}
      showYearDropdown
      scrollableYearDropdown
      yearDropdownItemNumber={100}
      showMonthDropdown
      scrollableMonthYearDropdown
    />
  );
};

export default DateTimeInput;
