import { useToast } from "@chakra-ui/react";
import { useMutation, useQuery } from "@tanstack/react-query";
import {
  ACTIVATED_INVENTORY_FIND_ALL,
  INVENTORY_CREATE_ONE,
  INVENTORY_FIND_ALL,
  INVENTORY_PATCH_ONE,
} from "../endpoints/inventory";
import { protectedGet, protectedPatch, protectedPost } from "../http-methods";
import { productDataType } from "../products";

export type InventorysDataType = {
  _id: string;
  product: productDataType;
  isActive: boolean;
  isUsed: boolean;
  isPrinted: boolean;
  from: string;
  code: string;
  refId: string;
  url: string;
  createdAt: string;
  updatedAt: string;
  __v: number;
};
export const useFindAllInventorys = () => {
  return useMutation({
    mutationFn: (obj: { product?: string; skip?: number; limit?: number ;search?:string;createdAt?:  1|-1}) => {
      return protectedGet(INVENTORY_FIND_ALL(obj));
    },
  });
};
export const useFindAllActivatedInventorys = () => {
  return useMutation({
    mutationFn: (obj: {linkedTo?: string; skip?: number; limit?: number ;search?:string}) => {
      return protectedGet(ACTIVATED_INVENTORY_FIND_ALL(obj));
    },
  });
};
export const useInventorysCreateOne = () => {
     const toast = useToast();
  return useMutation({
    mutationFn: ({ qt, obj }: { qt: number; obj: { product: string } }) => {
      return protectedPost(INVENTORY_CREATE_ONE(qt), obj);
    },
    onError: (data: any) => {
      console.log("dataError", data.response.data.errors);
      const errors: any = data.response.data.errors;
      if (errors[0]) {
        toast({
          title: errors[0].msg,
          position: `bottom-right`,
          status: "error",
          isClosable: true,
        });
      } else if (errors.qt) {
        toast({
          title: "quantite",
          description: errors.qt.msg,
          position: `bottom-right`,
          status: "error",
          isClosable: true,
        });
      } else if (errors.product) {
        toast({
          title: "product",
          description: errors.product.msg,
          position: `bottom-right`,
          status: "error",
          isClosable: true,
        });
      }
    },
  });
};
export const usePatchOneInventorys = () => {
  return useMutation({
    mutationFn: ({
      id,
      catObj,
    }: {
      id: string;
      catObj: {isPrinted:boolean};
    }) => {
      return protectedPatch(INVENTORY_PATCH_ONE(id), catObj);
    },
  });
};
