import {
  Box,
  Button,
  Heading,
  Stack,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
import { FormikErrors, useFormik } from "formik";
import {
  ClientsDataType,
  usePatchOneClient,
  usePatchOneUserPassword,
} from "../../api/Clients";
import { Input } from "../../components/commons";
import { getDataFromCache } from "../../utils/cache";
import { ReactNode, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
const AccountSecuritySettings = () => {
  const sectionBgColor = useColorModeValue("white", "gray.900");

  // const [securityMutation] = useUpdateSecuritySettingsMutation();
  const [user, setUser] = useState<ClientsDataType>(getDataFromCache("user"));
  const toast = useToast();
  const {
    mutate: userUpdateMutate,
    data: userUpdateData,
    isLoading: userUpdateIsLoading,
    isSuccess: userUpdateIsSuccess,
    isError: userUpdateIsError,
    error: userUpdateError,
  } = usePatchOneUserPassword();
  const securityInitalValues = {
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  };
  useEffect(() => {
    if (userUpdateIsSuccess)
      toast({
        title: "Password updated with sucsess",
        position: `bottom-right`,
        status: "success",
        isClosable: true,
      });
    // console.log("user", userUpdateData?.data.user);
    securityFormik.setFieldValue("oldPassword", "");
    securityFormik.setFieldValue("newPassword", "");
    securityFormik.setFieldValue("confirmPassword", "");
    // setInCache("user", userUpdateData?.data.user);
  }, [userUpdateIsSuccess]);
  const securityFormik = useFormik({
    validateOnMount: false,
    validateOnBlur: false,
    validateOnChange: false,
    initialValues: securityInitalValues,
    validate: (values) => {
      const errors: FormikErrors<typeof values> = {};
      if (!values.oldPassword) {
        errors.oldPassword = intl.formatMessage({
          id: "password.required",
        });
        if (!values.newPassword) {
          errors.newPassword = intl.formatMessage({
            id: "password.required",
          });
        }
        if (!values.confirmPassword) {
          errors.confirmPassword =intl.formatMessage({
            id: "password.required",
          });
        }

        if (values.newPassword.length < 8) {
          errors.newPassword = intl.formatMessage({
            id: "password.min",
          });
        }
        if (values.newPassword.length > 16) {
          errors.newPassword =  intl.formatMessage({
            id: "password.max",
          });
        }

        if (values.oldPassword.length < 8) {
          errors.oldPassword = intl.formatMessage({
            id: "password.min",
          });
        }
        if (values.oldPassword.length > 16) {
          errors.oldPassword =  intl.formatMessage({
            id: "password.max",
          });
        }
      }
      if (values.newPassword !== values.confirmPassword) {
        errors.confirmPassword = intl.formatMessage({
          id: "password.validation",
        });
      }
      return errors;
    },
    onSubmit: (values, { setSubmitting }) => {
      userUpdateMutate({
        catObj: {
          password: values.newPassword,
          oldPassword: values.oldPassword,
        },
      });
    },
  });
  const intl = useIntl();
  return (
    <form onSubmit={securityFormik.handleSubmit}>
      <Heading size="lg" fontSize="2xl" fontWeight="medium">
        <FormattedMessage id={"accountSecurity"} />
      </Heading>
      <Stack marginTop="24px" spacing="20px">
        <Box
          p="16px"
          bgColor={sectionBgColor}
          width="100%"
          shadow="md"
          rounded="base"
        >
          <Heading size="md" fontSize="xl" fontWeight="medium">
            <FormattedMessage id={"password.update"} />
          </Heading>
          <Stack mt="4" width="480px">
            <Input
              label={intl.formatMessage({
                id: "oldPassword",
              })}
              inputProps={{
                type: "password",
                placeholder: intl.formatMessage({
                  id: "oldPassword",
                }),
                name: "oldPassword",
                value: securityFormik.values.oldPassword,
                onChange: securityFormik.handleChange,
              }}
              errorMessage={securityFormik.errors.oldPassword}
              isError={securityFormik.errors.oldPassword !== undefined}
            />
            <Input
              label={intl.formatMessage({
                id: "newPassword",
              })}
              inputProps={{
                type: "password",
                placeholder: intl.formatMessage({
                  id: "newPassword",
                }),
                name: "newPassword",
                value: securityFormik.values.newPassword,
                onChange: securityFormik.handleChange,
              }}
              errorMessage={securityFormik.errors.newPassword}
              isError={securityFormik.errors.newPassword !== undefined}
            />
            <Input
              label={intl.formatMessage({
                id: "newPassword.confirm",
              })}
              inputProps={{
                type: "password",
                placeholder: intl.formatMessage({
                  id: "newPassword.confirm",
                }),
                name: "confirmPassword",
                value: securityFormik.values.confirmPassword,
                onChange: securityFormik.handleChange,
              }}
              errorMessage={securityFormik.errors.confirmPassword}
              isError={securityFormik.errors.confirmPassword !== undefined}
            />
          </Stack>
        </Box>
        <Box textAlign="right">
          <Button
            colorScheme="main"
            type="submit"
            isLoading={userUpdateIsLoading}
          >
            <FormattedMessage id={"category.Update.btn"} />
          </Button>
        </Box>
      </Stack>
    </form>
  );
};
export default AccountSecuritySettings;
