import {
  Box,
  Button,
  IconButton,
  Link,
  Text,
  Toast,
  useToast,
} from "@chakra-ui/react";
import styled from "@emotion/styled";
import { Formik, FormikErrors } from "formik";
import { useContext, useEffect, useState } from "react";
import { BiShow, BiHide } from "react-icons/bi";
import { Link as RouterLink, useLocation, useNavigate } from "react-router-dom";
import { Input } from "../../../../components/commons";
import { useLoginUser } from "../../../../api/auth";
import { setInCache } from "../../../../utils/cache";

import { AppContext } from "../../../../context/appContext";

const AuthFormWrapper = styled("form")`
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 26px;
`;

const LoginForm = (props: {
  handleEmailNotConfirmed: (email: string) => void;
}) => {
  const { handleEmailNotConfirmed } = props;
  const { mutate, data, isLoading, isSuccess, isError, error } = useLoginUser();
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const toast = useToast();
  const { setFullName, setProfilePicture } = useContext(AppContext);
  const navigate = useNavigate();
  useEffect(() => {
    if (isSuccess === true) {
      if (data?.data.user.isAdmin) {
        setFullName(data?.data.user.fullName);
        setProfilePicture(data?.data.user.profilePicture);

        setInCache("accessToken", data?.data.token.accessToken, true);
        setInCache("fullName", data?.data.user.fullName);
        setInCache("profilePicture", data?.data.user.profilePicture);
       
        setInCache("user", data?.data.user);

        window.location.href = "/";
      } else {
        toast({
          title: "you are not admin",
          position: `bottom-right`,
          status: "error",
          isClosable: true,
        });
      }
    }
  }, [isSuccess === true]);
  useEffect(() => {
    if (error && isError === true) {
      console.log("loginData", error);
      // toast({
      //   title: "BAD_CREDENTIALS",
      //   position: `bottom-right`,
      //   status: "error",
      //   isClosable: true,
      // });
    }
  }, [isError === true]);
  return (
    <Formik
      validateOnMount={false}
      validateOnBlur={false}
      validateOnChange={false}
      initialValues={{ email: "", password: "" }}
      validate={(values) => {
        const errors: FormikErrors<typeof values> = {};
        if (!values.email) {
          errors.email = "Email is required";
        } else if (
          !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
        ) {
          errors.email = "Invalid email address";
        }
        if (values.password.length<8) {
          errors.password = "A minimum 8 characters password ";
        }
        if (values.password.length>16) {
          errors.password = "A maximum 16 characters password ";
        }
        if (!values.password) {
          errors.password = "Password is required";
        }
        // console.log(errors);
        return errors;
      }}
      onSubmit={(values, { setSubmitting, setFieldError }) => {
        // console.log(values.password, "login");

        mutate({ email: values.email, password: values.password });

        // console.log("errors", error);
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
      }) => (
        <AuthFormWrapper onSubmit={handleSubmit}>
          <Input
            inputProps={{
              type: "text",
              name: "email",
              onChange: handleChange,
              onBlur: handleBlur,
              value: values.email,
              placeholder: "Email",
            }}
            errorMessage={errors.email}
            isError={errors.email !== undefined}
            label="Email"
          />
          <Input
            inputProps={{
              type: showPassword ? "text" : "password",
              name: "password",
              onChange: handleChange,
              onBlur: handleBlur,
              value: values.password,
              placeholder: "Password",
            }}
            inputRightElementProps={{
              children: (
                <IconButton
                  onClick={() => setShowPassword(!showPassword)}
                  aria-label="Password toggle"
                  variant="ghost"
                >
                  {showPassword ? <BiShow size={18} /> : <BiHide size={18} />}
                </IconButton>
              ),
            }}
            errorMessage={errors.password}
            isError={errors.password !== undefined}
            label="Password"
          />
          <Box textAlign="right">
            <Link
              color="blue.400"
              as={RouterLink}
              to="/authentication/forgot-password"
            >
              Forgot password?
            </Link>
          </Box>
          <Button
            marginTop="4"
            colorScheme="main"
            type="submit"
            isLoading={isLoading}
            size="lg"
          >
            Login
          </Button>
          {/* <Text>
            New to elmall?{" "}
            <Link
              color="blue.400"
              as={RouterLink}
              to="/authentication/register"
            >
              Create an account!
            </Link>
          </Text> */}
        </AuthFormWrapper>
      )}
    </Formik>
  );
};

export default LoginForm;
