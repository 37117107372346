import {
  useColorModeValue,
  Text,
  Box,
  FormLabel,
  Spinner,
  IconButton,
  Stack,
  StackDirection,
  Grid,
  Flex,
  Spacer,
} from "@chakra-ui/react";
import { Fragment, useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { BiImages, BiSelectMultiple, BiTrash, BiUpload } from "react-icons/bi";
import { FormattedMessage, useIntl } from "react-intl";
import { ImageContainer, ImageUploadWrapper } from "./ImageUpload.styles";

type ImageUploadPropsType = {
  height?: string;
  width?: string;
  multiple?: boolean;
  accept?: string | string[];
  maxFiles?: number;
  label?: string;
  images: {
    progress: boolean;
    id: string;
    src: File | string | undefined;
  }[];
  handleUpload: (file: File) => void;
  handleDelete: (id: string) => void;
  gridColCount?: number;
};

const ImageUpload = (props: ImageUploadPropsType) => {
  const {
    height = "90px",
    width = "100%",
    multiple,
    accept,
    maxFiles,
    label,
    images,
    handleDelete,
    handleUpload,
    gridColCount = 1,
  } = props;

  const borderColor = useColorModeValue("gray.200", "gray.700");

  const onDrop = useCallback(
    (acceptedFiles: Array<File>) => {
      acceptedFiles.forEach((file: File) => {
        handleUpload(file);
      });
    },
    [images]
  );

  const { getRootProps, getInputProps, isDragActive, fileRejections } =
    useDropzone({ onDrop, multiple, maxFiles,accept: {
      'image/jpg': ['.jpg', '.png']
    } });
    const intl = useIntl();
  return (
    <Box>

      {label && <FormLabel marginBottom="1"> <FormattedMessage id={label} /></FormLabel>}
      <Stack
        //gridTemplateColumns={`repeat(${gridColCount}, ${width})`}
        //gap='14px'
        direction="row"
        spacing="14px"
        flexWrap="wrap"
      >
        {images.length > 0 &&
          images.map((image, index) => (
            <Fragment key={index}>
              <ImageContainer
                margin="7px !important"
                position="relative"
                h={height}
                w={width}
                border="1px"
                borderColor={borderColor}
                rounded="base"
              >
                <img
                  src={
                    image.src && typeof image.src !== "string"
                      ? URL.createObjectURL(image.src)
                      : image.src
                  }
                  alt="preview"
                  className="image_preview"
                />

                {image.progress && (
                  <Box className="image_loading">
                    <Spinner color="main.400" size="xl" thickness="6px" />
                  </Box>
                )}
                {!image.progress && (
                  <Box className="image_config">
                    <IconButton
                      aria-label="delete_image"
                      onClick={() => handleDelete(image.id)}
                    >
                      <BiTrash size={24} />
                    </IconButton>
                  </Box>
                )}
              </ImageContainer>
            </Fragment>
          ))}
        {(multiple || images.length === 0) && (
          <ImageUploadWrapper
            {...getRootProps()}
            margin="7px !important"
            h={height}
            w={width}
            border="2px dashed"
            borderColor={isDragActive ? "blue.400" : borderColor}
            rounded="base"
          >
            <input {...getInputProps()} type="file" accept="image/*"/>
            {isDragActive ? (
              <BiUpload size={28} />
            ) : (
              <>
                <BiImages size={28} opacity="0.5" />
                <Text align="center" fontSize="sm" lineHeight="1" opacity="0.6">
                {intl.formatMessage({id: "images.msg"})}
                </Text>
              </>
            )}
          </ImageUploadWrapper>
        )}
      </Stack>
    </Box>                                                                                                       
  );
};

export default ImageUpload;
