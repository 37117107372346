import { Box } from "@chakra-ui/react";
import styled from "@emotion/styled";

export const AuthenticationWrapper = styled(Box)`
  height: 100vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
  // padding: 85px 120px;
  .logo_container {
    width: 200px;
    > img {
      width: 100%;
    }
  }
  .imageCoverContainer {
    width: 100%;
    height: 100%;

    > img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
`;
