import {
    Box,
    Button,
    Heading,
    Text,
    Link,
    Stack,
    Tabs,
    TabList,
    Tab,
    Popover,
    PopoverTrigger,
    PopoverContent,
    PopoverArrow,
    PopoverCloseButton,
    PopoverHeader,
    PopoverBody,
    TabPanels,
    TabPanel,
    Flex,
    useColorModeValue,
    useToast,
    Skeleton,
  } from "@chakra-ui/react";
  import {
    Link as RouterLink,
    Navigate,
    useLocation,
    useNavigate,
    useParams,
  } from "react-router-dom";
  import { BiArrowBack } from "react-icons/bi";
import { ReactNode, useEffect, useState } from "react";
import { CouponsDataType, useFindOneCoupon } from "../../api/Coupons";
import { getDataFromCache } from "../../utils/cache";
import { PageWrapper } from "../../utils/globalStyle";
import Coupon from "./Coupon";

  
  const UpdateCoupon = () => {
    const location = useLocation();
  
    const { from } = (location?.state as { from?: string }) || "";
  
    const { couponID } = useParams();
    const [coupon, setCoupon] = useState<CouponsDataType>();
  

  
    const userRole: string = getDataFromCache("user_role", true);
  
    const [tabIndex, setTabIndex] = useState<number>(0);
  
    const [actionButtons, setActionButtons] = useState<ReactNode>();
  
    const getActionButtons = (node?: ReactNode) => {
      setActionButtons(node);
    };
  
    const toast = useToast();
  
    const navigate = useNavigate();
    const { mutate, data, isLoading, isSuccess, isError, error } =
    useFindOneCoupon();
    useEffect(() => {
       
      mutate(couponID);
    }, []);
    useEffect(() => {
      if (isSuccess) {
        setCoupon(data.data.coupon);
      }
    }, [isSuccess]);
  
    const handleChangeIndex = (index: number) => {
      setTabIndex(index);
      setActionButtons(undefined);
    };
  
    return (
      <PageWrapper>
        <Box
          className="page_header"
          height="auto !important"
          alignItems="baseline !important"
          flexDirection={{ base: "column", md: "row" }}
        >
          <Box className="page_header_title">
            <Box className="page_header_backlink">
              {from && from !== "" && (
                <Link
                  as={RouterLink}
                  to={from}
                  display="flex"
                  alignItems="center"
                  color="blue.400"
                >
                  <BiArrowBack size={14} /> <Text fontSize="sm">Back</Text>
                </Link>
              )}
            </Box>
            <Box className="page_header_title_content">
              <Skeleton
              // isLoaded={!CouponNameLoading}
              // opacity={CouponNameLoading ? 0.4 : 1}
              >
                <Heading fontSize="2xl">
                  {/* {CouponNameData?.GetCouponById?.name} */}
                </Heading>
              </Skeleton>
            </Box>
          </Box>
          <Stack marginTop={{ base: "12px", md: "auto" }} direction="row">
            {actionButtons}
          </Stack>
        </Box>
       
       
  
        
              <Coupon
                actionButtons={getActionButtons}
                loading={isLoading}
                accessType="UPDATE"
                coupon={coupon}
              />
        
      </PageWrapper>
    );
  };
  
  export default UpdateCoupon;
  