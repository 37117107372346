import React, { createContext, useState } from "react";
import { getDataFromCache } from "../utils/cache";
export const AppContext = createContext<{
  language: string;

  setLanguage: React.Dispatch<React.SetStateAction<string>>;
  fullName: string;

  setFullName: React.Dispatch<React.SetStateAction<string>>;
  profilePicture: string;

  setProfilePicture: React.Dispatch<React.SetStateAction<string>>;
}>({
  language: "fr",
  setLanguage: () => {},
  fullName: getDataFromCache("fullName"),
  setFullName: () => {},
  profilePicture: getDataFromCache("profilePicture"),

  setProfilePicture: () => {},
});

export const AppProvider = ({ children }: any) => {
  const [language, setLanguage] = useState("fr");
  const [fullName, setFullName] = useState(getDataFromCache("fullName"));
  const [profilePicture, setProfilePicture] = useState(
    getDataFromCache("profilePicture")
  );
  return (
    <AppContext.Provider
      value={{
        language,
        setLanguage,
        fullName,
        setFullName,
        profilePicture,
        setProfilePicture,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
