import {
  Box,
  useColorMode,
  Divider,
  useColorModeValue,
} from "@chakra-ui/react";
import React, { ReactNode } from "react";
import { useIntl } from "react-intl";
import { useLocation } from "react-router-dom";

import { NavItem } from "../../../commons";
import { SettingsNavigationWrapper } from "../Navigation.styles";
import { NavigationPropsType } from "../Navigation.types";

const SettingsNavigation: React.FC<
  NavigationPropsType & {
    width?: string;
    topSection?: ReactNode;
  }
> = (props) => {
  const { items, width, topSection } = props;
  const intl = useIntl();
  const location = useLocation();
  const { colorMode } = useColorMode();

  const bgColor = "#061223";
  const borderColor = useColorModeValue("gray.200", "gray.700");

  return (
    <SettingsNavigationWrapper
      paddingY={{ base: "2", lg: "0" }}
      bg={bgColor}
      borderRadius="base"
      shadow="md"
      w={{ base: "fit-content", lg: "260px" }}
    >
      {topSection && (
        <Box className="top_section_container">
          {topSection}
          {/* <Box>
            <Divider borderColor={borderColor} />
          </Box> */}
        </Box>
      )}
      <Box paddingBottom="8px">
        <Box marginY="2" paddingX="16px">
          <Divider borderColor={borderColor} />
        </Box>
        {items?.map((nav, index) => (
          <React.Fragment key={index}>
            {nav === "SEPERATOR" ? (
              <Box marginY="2" paddingX="16px">
                <Divider borderColor={borderColor} />
              </Box>
            ) : nav === "vide" ? (
              <></>
            ) : (
              <Box paddingTop="8px" key={index}>
                <NavItem
                  label={intl.formatMessage({
                    id: nav.label,
                  })}
                  Icon={nav.Icon}
                  selected={location.pathname.includes(nav.href)}
                  href={nav.href}
                />
              </Box>
            )}
          </React.Fragment>
        ))}
      </Box>
    </SettingsNavigationWrapper>
  );
};

export default SettingsNavigation;
