import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  Checkbox,
  Divider,
  Heading,
  IconButton,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Skeleton,
  Stack,
  Tab,
  TabList,
  Tabs,
  Text,
  Tooltip,
  useDisclosure,
  useToast,
  WrapItem,
} from "@chakra-ui/react";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import {
  BiArrowBack,
  BiBlock,
  BiBriefcase,
  BiFilterAlt,
  BiLinkExternal,
  BiRadioCircleMarked,
  BiSearch,
  BiShowAlt,
  BiChevronDown,
  BiEdit,
  BiTrash,
} from "react-icons/bi";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";

import Input from "../../components/commons/Input/Input";
import { TableHeadType } from "../../components/modules/Table/Table.types";
import Table from "../../components/modules/Table/Table";
import { PageWrapper } from "../../utils/globalStyle";
import {
  fakeData as dumydata,
  PUBLIC_API_ENDPOINT,
} from "../../utils/globalTypes";
import ProductsFilterDrawer from "./Filters/ProductsFilterDrawer";
import { getDataFromCache } from "../../utils/cache";

import Categorie, { Category } from "../Categories/Categories";
import ConfirmDeleteDialog from "../../components/modules/ConfirmDeleteDialog/ConfirmDeleteDialog";
import { FormattedMessage, useIntl } from "react-intl";
import {
  productDataType,
  useFindAllProducts,
  usePatchOneProducts,
  useProductsDeleteOne,
} from "../../api/products";
import { ContactsDataType, useFindAllContacts } from "../../api/contacts";

const Contacts = () => {
  const [syn, setSyn] = useState(1);
  const [pagination, setPagination] = useState({
    count: 0,
    limit: 5,
    skip: 0,
    page: 1,
  });
  const location = useLocation();
  const {
    isOpen: openFilter,
    onOpen: onOpenFilter,
    onClose: onCloseFilter,
  } = useDisclosure();

  const [defaultFilterValues, setDefaultFilterValues] = useState({
    name: "",
    brand: "",
    type: "",
    category: {
      label: "",
      id: "",
    },
  });

  const handleContacts = () => {};
  const [search, setSearch] = useState("");

  const [sortByCreated, setSortByCreated] = useState<1 | -1>(-1);
  const [sortByUpdated, setSortByUpdated] = useState<1 | -1>(-1);
  const { mutate, data, isLoading, isSuccess, isError, error } =
    useFindAllContacts();
  useEffect(() => {
    mutate({
      skip: pagination.skip,
      limit: pagination.limit,
      search: search,

      createdAt: sortByCreated,
      updatedAt: sortByUpdated,
    });
  }, [pagination, search]);
  useEffect(() => {
    if (isSuccess) {
      // console.log("products", data.data.contacts);
      setContacts(data.data.contacts);
    }
  }, [isSuccess]);

  const handlePageChange = (dir: "next" | "prev") => {
    if (dir === "next") {
      setPagination((prevState) => {
        let temp = { ...prevState };
        temp.skip = temp.skip + temp.limit;
        return temp;
      });
    } else {
      setPagination((prevState) => {
        let temp = { ...prevState };
        temp.skip = temp.skip - temp.limit;
        return temp;
      });
    }
  };

  const handleLimitChange = (value: number) => {
    setPagination((prevState) => {
      let temp = { ...prevState };
      temp.limit = value;
      return temp;
    });
  };
  const handleResetFilter = () => {
    setFilters(undefined);
    setDefaultFilterValues({
      brand: "",
      category: {
        label: "",
        id: "",
      },
      name: "",
      type: "",
    });
  };
  const [filters, setFilters] = useState<any>();
  const handleFilter = (values: {
    name: string;
    type: string;
    category: {
      label: string;
      id: string;
    };
    brand: string;
  }) => {
    setDefaultFilterValues(values);
    setFilters({
      brand: values.brand || undefined,
      category: values.category || undefined,
      name: values.name || undefined,
      type: values.type || undefined,
    });
  };
  const navigate = useNavigate();

  const [contacts, setContacts] = useState<ContactsDataType[]>([]);

  const [tabIndex, setTabIndex] = useState<number>(0);

  const { from } = (location?.state as { from?: string }) || "";
  const handleChangeTabIndex = (index: number) => {
    setTabIndex(index);
  };

  const [selectedContacts, setSelectedContacts] = useState<string[]>([]);

  const handleSelectcontact = (id: string) => {
    setSelectedContacts((prevState) => [...prevState, id]);
  };

  const handleRemoveSelectedcontact = (id: string) => {
    setSelectedContacts((prevState) => {
      let temp = [...prevState];
      const index = temp.indexOf(id, 0);
      if (index > -1) {
        temp.splice(index, 1);
      }
      return temp;
    });
  };
  const contactsAdminTableData =
    (contacts &&
      contacts.map((contact, index) => ({
        key: contact._id,
        check: (
          <Checkbox
            value={contact._id}
            onChange={(e) => {
              if (e.target.checked) {
                handleSelectcontact(e.target.value);
              } else {
                handleRemoveSelectedcontact(e.target.value);
              }
            }}
            isChecked={selectedContacts?.includes(contact._id)}
          />
        ),

        name: contact.fullName ? contact.fullName : "--",

        phone: contact.phoneNumber ? contact.phoneNumber: "--",
        email: contact.email,

        creationDate: moment(contact.createdAt).format("lll"),
        object:contact.object,
        message: (
          <Stack
            direction="row"
            display="inline-flex"
            width="fit-content"
            alignItems="center"
          >
            <Popover>
              <PopoverTrigger>
                <Button>
                  <FormattedMessage id={"contactMessage"} />
                </Button>
              </PopoverTrigger>
              <PopoverContent>
                <PopoverHeader fontWeight="semibold">
                  {contact.fullName} <FormattedMessage id={"message"} />
                </PopoverHeader>
                <PopoverArrow />
                <PopoverCloseButton />
                <PopoverBody>
                  <Text
                    dangerouslySetInnerHTML={{
                      __html: contact.message,
                    }}
                  />
                </PopoverBody>
              </PopoverContent>
            </Popover>
          </Stack>
        ),
      }))) ||
    [];
  const intl = useIntl();
  type ContactAdminTableData = typeof contactsAdminTableData[number];
  const [loading, setLoading] = useState(isLoading);
  const contactAdminTableHead: TableHeadType<ContactAdminTableData>[] = [
    {
      accessor: "name",
      label: intl.formatMessage({
        id: "name",
      }),
    },
    {
      accessor: "phone",
      label: intl.formatMessage({
        id: "station.phoneNumber",
      }),
    },
    {
      accessor: "object",
      label: "object",
    },
    {
      accessor: "email",
      label: intl.formatMessage({
        id: "email",
      }),
    },
   

    {
      accessor: "creationDate",
      label: intl.formatMessage({
        id: "createdDate",
      }),
    },

    {
      accessor: "message",
      label: intl.formatMessage({
        id: "message",
      }),
    },
  ];

  const handleSearchSubmit = (event: any) => {
    if (event.code === "Enter") {
      // console.log(event.target.value);
      setSearch(event.target.value);
      mutate({
        skip: pagination.skip,
        limit: pagination.limit,
        search: search,
        // category:category,
        // isAvliable:isAvliable,

        // status: status,
        createdAt: sortByCreated,
        updatedAt: sortByUpdated,
      });
    }
  };

  const {
    isOpen: isOpenExportDialog,
    onOpen: onOpenExportDialog,
    onClose: onCloseExportDialog,
  } = useDisclosure();

  const cancelExportRef = useRef(null);

  return (
    <PageWrapper>
      <Box className="page_header">
        <Box className="page_header_title">
         
          <Box className="page_header_title_content">
            <Heading fontSize="2xl">
              <FormattedMessage id={"contacts.list"} />
            </Heading>
          </Box>
        </Box>
      </Box>

      {/* )} */}
      <Box className="page_actions">
        <Input
          inputProps={{
            placeholder: intl.formatMessage({
              id: "contactSearch",
            }),
            onKeyUp: handleSearchSubmit,
          }}
          inputLeftElementProps={{ children: <BiSearch /> }}
        />
      </Box>

      <Table
        head={contactAdminTableHead}
        data={contactsAdminTableData}
        isLoading={loading}
        emptyState={
          <Box className="empty_table_container">
            <BiBriefcase size={42} />
            <Text fontSize="md" fontWeight="medium"></Text>
          </Box>
        }
        pagination={{
          count: data?.data.queryCount || 0,
          limit:data?.data.contactsCount,
          skip: pagination.skip,
          onPageChange: handlePageChange,
          onChangeLimit: handleLimitChange,
        }}
      />
    </PageWrapper>
  );
};

export default Contacts;
