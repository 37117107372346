import {
  Box,
  Heading,
  Text,
  Stack,
  useColorModeValue,
  Button,
  useToast,
  AlertIcon,
  Alert,
} from "@chakra-ui/react";
import { FormikErrors, useFormik } from "formik";
import { useEffect, useState } from "react";
import {
  BiArrowBack,
  BiArrowFromRight,
  BiArrowToLeft,
  BiKey,
} from "react-icons/bi";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { Input } from "../../components/commons";
// import { useResetPasswordMutation } from "../../api/generated/graphql";
// import { Input } from "../../components";
import axios from "axios";
import { PUBLIC_API_ENDPOINT } from "../../utils/globalTypes";
import { useResetPassword } from "../../api/auth";
const ResetPassword = () => {
  const [params, setSearchParams] = useSearchParams();
  const { code } = useParams();
  const bgColor = useColorModeValue("gray.100", "gray.800");
  const wrapperBgColor = useColorModeValue("white", "gray.700");
  const [verifCode, setverifCode] = useState<boolean>(false);
  const toast = useToast();
  const [resetPasswordSuccess, setResetPasswordSuccess] = useState(false);
  const { mutate, data, isLoading, isSuccess, isError, error } =
  useResetPassword();
 
  const resetPasswordFormik = useFormik({
    validateOnMount: false,
    validateOnBlur: false,
    validateOnChange: false,
    initialValues: {
      password: "",
      confirmPassword: "",
    },
    validate: (values) => {
      const errors: FormikErrors<typeof values> = {};
      if (values.password.length<8) {
        errors.password = "A minimum 8 characters password ";
      }
      if (values.password.length>16) {
        errors.password = "A maximum 16 characters password ";
      }
      if (!values.password) {
        errors.password = "Password is required";
      }
      if (!values.password) {
        errors.password = "Password is required";
      } 
      if (values.password !== values.confirmPassword) {
        errors.confirmPassword = "Password do not match";
      }
      return errors;
    },
    onSubmit: (values, { setSubmitting }) => {
    //  console.log("token",code);
     mutate({
      token:code?code:"",
      password:values.password,
      paylodType:"RESET_TOKEN",
    })
    },
  });
  // useEffect(() => {
  //   if (isError){
  //     toast({
  //       title: "An error occured while updating password!",
  //       status: "error",
  //       position: `bottom-right`,

  //       isClosable: true,
  //     });
  //   }
  //  }, [isError]);
  
  const navigate = useNavigate();
 
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      height="100vh"
      width="100vw"
      bgColor={bgColor}
    >
      <form onSubmit={resetPasswordFormik.handleSubmit}>
        <Stack
          spacing="16px"
          shadow="md"
          rounded={"base"}
          bgColor={wrapperBgColor}
          padding="32px"
          display="flex"
          alignItems="center"
          justifyContent="center"
          w="380px"
        >
          <Text color="main.400" p="6px" bgColor="#f6233315" rounded="full">
            <BiKey size={26} />
          </Text>
          <Box textAlign="center">
            <Heading fontSize="2xl">Set new password</Heading>
            <Text opacity={0.8}>
              Your new password must be different to previously used passwords.
            </Text>
          </Box>
          <Box
            w="100%"
            display="flex"
            flexDir={"column"}
            alignItems="center"
            justifyContent="center"
          >
            {isSuccess ? (
              <>
                <Alert mt="12px" status="success" rounded="base">
                  <AlertIcon />
                  Your password has been updated
                </Alert>
                <Button
                  mt="8px"
                  variant="ghost"
                  leftIcon={<BiArrowBack size={18} />}
                  onClick={() =>
                    navigate("/authentication/login", {
                      replace: true,
                    })
                  }
                >
                  Back to log in
                </Button>
              </>
            ) : (
              <Stack w="100%" spacing="14px">
                <Input
                  label="Password"
                  inputProps={{
                    placeholder: "Enter your new password",
                    name: "password",
                    type: "password",
                    onChange: resetPasswordFormik.handleChange,
                    value: resetPasswordFormik.values.password,
                  }}
                  isError={resetPasswordFormik?.errors?.password !== undefined}
                  errorMessage={resetPasswordFormik?.errors?.password}
                />
                <Input
                  label="Confirm password"
                  inputProps={{
                    placeholder: "Confirm password",
                    name: "confirmPassword",
                    type: "password",
                    onChange: resetPasswordFormik.handleChange,
                    value: resetPasswordFormik.values.confirmPassword,
                  }}
                  isError={
                    resetPasswordFormik?.errors?.confirmPassword !== undefined
                  }
                  errorMessage={resetPasswordFormik?.errors?.confirmPassword}
                />
                <Button
                  mt="12px"
                  isLoading={isLoading}
                  type="submit"
                  colorScheme="main"
                >
                  Reset your password
                </Button>
              </Stack>
            )}
          </Box>
        </Stack>
      </form>
    </Box>
  );
};

export default ResetPassword;
