import { useToast } from "@chakra-ui/react";
import { useMutation } from "@tanstack/react-query";

import {
  paginationProducts,
  PRODUCTS_FIND_ALL,
  PRODUCT_CREATE_ONE,
  PRODUCT_DELETE_ONE,
  PRODUCT_FIND_ONE,
  PRODUCT_PATCH_ONE,
} from "../endpoints/products";
import {
  get,
  protectedDelete,
  protectedGet,
  protectedPatch,
  protectedPost,
} from "../http-methods";

export type productDataType = {
  isArchived: boolean;
  _id: string;
  slug: string;
  name: string;
  description: string;
  images: string[];
  price: {
    price: number;
    compareAtPrice: number;
    costPerItem: number;
    chargeAtTax: boolean;
    _id: string;
  };
  tags: [];
  category?: {
    isArchived?: boolean;
    _id: string;
    name: string;
    description?: string;
    slug?: string;
    level?: number;
    image?: string;
    status?: string;
    hasRoot?: boolean;
    hasNodes?: boolean;
    nodes?: [];
    hasLocalField?: boolean;
    seo?: {
      _id: string;
      seotitle: string;
      metatags: { key: string; value: string }[];
      keywords: string;
      metadescription: string;
    };
    createdAt?: string;
    updatedAt?: string;
    __v?: number;
  };
  status: string;
  isAvilable: boolean;
  seo?: {
    _id: string;
    seotitle: string;
    metatags: { key: string; value: string }[];
    keywords: string;
    metadescription: string;
  };
  customFields?: [];
  createdAt?: string;
  updatedAt?: string;
  sticker: {
    color: string;
    lable: string;
  };
  __v: 0;
  variation: {
    name: string;
    color: string;
    image: string;
    _id: string;
  }[];
};
export type productUpdateDataType = {
  isArchived?: boolean;

  slug?: string;
  name?: string;
  description?: string;
  images?: string[];
  price?: {
    price: string;
    compareAtPrice: string;
    costPerItem: string;
    chargeAtTax: boolean;
  };
  tags?: [];
  category?: string;
  status?: string;
  isAvilable?: boolean;
  seo?: {
    seotitle: string;
    keywords: string;
    metadescription: string;

    metatags: {
      key?: string | null | undefined;
      value?: string | null | undefined;
    }[];
  };
  customFields?: [];
  createdAt?: string;
  updatedAt?: string;
  __v?: 0;
  sticker?: {
    color: string;
    lable: string;
  };
  variation?: {
    name: string;
    color: string;
    image: string;
  }[];
};
export type createProductDataType = {
  name: string;
  description: string;
  images: any[];
  price: {
    price: string;
    compareAtPrice: string;
    costPerItem: string;
    chargeAtTax: boolean;
  };
  category: string;
};
export const useFindAllProducts = () => {
  return useMutation({
    mutationFn: (obj: paginationProducts) => {
      return protectedGet(PRODUCTS_FIND_ALL(obj));
    },
  });
};
export const useProductsCreateOne = () => {
  const toast = useToast();
  return useMutation({
    mutationFn: (catObj: createProductDataType) => {
      return protectedPost(PRODUCT_CREATE_ONE, catObj);
    },
    onError: (data: any) => {
      console.log("dataError", data.response.data.errors);
      const errors: any = data.response.data.errors;
      if (errors[0]) {
        toast({
          title: errors[0].msg,
          position: `bottom-right`,
          status: "error",
          isClosable: true,
        });
      } else if (errors.name) {
        toast({
          title: "name",
          description: errors.name.msg,
          position: `bottom-right`,
          status: "error",
          isClosable: true,
        });
      } else if (errors.description) {
        toast({
          title: "description",
          description: errors.description.msg,
          position: `bottom-right`,
          status: "error",
          isClosable: true,
        });
      } else if (errors.images) {
        toast({
          title: "images",
          description: errors.images.msg,
          position: `bottom-right`,
          status: "error",
          isClosable: true,
        });
      } else if (errors.category) {
        toast({
          title: "category",
          description: errors.category.msg,
          position: `bottom-right`,
          status: "error",
          isClosable: true,
        });
      }
    },
  });
};
export const useFindOneProducts = () => {
  return useMutation({
    mutationFn: (catObj?: string) => {
      return protectedGet(PRODUCT_FIND_ONE(catObj));
    },
  });
};
export const useProductsDeleteOne = () => {
  return useMutation({
    mutationFn: (catObj: string) => {
      return protectedDelete(PRODUCT_DELETE_ONE, catObj);
    },
  });
};
export const usePatchOneProducts = () => {
  const toast = useToast();
  return useMutation({
    mutationFn: ({
      id,
      catObj,
    }: {
      id: string;
      catObj: productUpdateDataType;
    }) => {
      return protectedPatch(PRODUCT_PATCH_ONE(id), catObj);
    },
    onError: (data: any) => {
      console.log("dataError", data.response.data.errors);
      const errors: any = data.response.data.errors;
      if (errors[0]) {
        toast({
          title: errors[0].msg,
          position: `bottom-right`,
          status: "error",
          isClosable: true,
        });
      } else if (errors.name) {
        toast({
          title: "name",
          description: errors.name.msg,
          position: `bottom-right`,
          status: "error",
          isClosable: true,
        });
      } else if (errors.description) {
        toast({
          title: "description",
          description: errors.description.msg,
          position: `bottom-right`,
          status: "error",
          isClosable: true,
        });
      } else if (errors.images) {
        toast({
          title: "images",
          description: errors.images.msg,
          position: `bottom-right`,
          status: "error",
          isClosable: true,
        });
      } else if (errors.category) {
        toast({
          title: "category",
          description: errors.category.msg,
          position: `bottom-right`,
          status: "error",
          isClosable: true,
        });
      }
    },
  });
};
