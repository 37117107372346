// import { StringifyOptions } from "querystring";
export const objToParam = (obj: any) =>
  "?" +
  Object.keys(obj)
    .map((key) => {
      return `${key}=${encodeURIComponent(obj[key])}`;
    })
    .join("&");
export type paginationCategory = {
  skip?: number;
  limit?: number;
  search?: string;
  level?: number;
  status?: string;
  createdAt?: string;
  updatedAt?: string;
};
export const CATEGORIES_FIND_ALL = (obj: paginationCategory = {}) =>
  `categories/findAll${objToParam(obj)}`;
export const CATEGORY_CREATE_ONE = "categories/createOne";
export const CATEGORY_DELETE_ONE = "categories/deleteOne";
export const CATEGORY_UPDATE_ONE = (obj: string) =>
  `categories/patchOne/${obj}`;
