import { FC } from "react";
import {
  logoImage,
  logoImageMobile,
  logoImageDARKUI,
  
} from "../../../assets";
import { Box, useMediaQuery, useColorMode } from "@chakra-ui/react";

const LogoAuth: FC = () => {
  const [isMdUp] = useMediaQuery("(min-width: 62em)");
  const { colorMode } = useColorMode();

  return (
    <Box marginX="auto" w={"100%"} minWidth={"80%"}>
      {colorMode === "dark" ? (
        <img src={logoImage} alt="logo" />
      ) : (
        <img src={logoImageDARKUI} alt="logo" />
      )}
    </Box>
    // <Box marginX="auto" w={{ base: "40px", lg: "90%" }}>
    //   {colorMode === "dark" ? (
    //     <img src={logoLight} alt="logo" />
    //   ) : (
    //     <img src={logoImageDARKUI} alt="logo" />
    //   )}
    // </Box>
  );
};

export default LogoAuth;
