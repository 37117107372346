import {
  Box,
  Link,
  Text,
  Heading,
  Stack,
  Button,
  useColorModeValue,
  FormLabel,
  useToast,
  Skeleton,
  Flex,
  Switch,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
} from "@chakra-ui/react";
import axios from "axios";
import { FormikErrors, useFormik } from "formik";
import moment from "moment";
import { ReactNode, useEffect, useState } from "react";
import { BiArrowBack } from "react-icons/bi";
import { FormattedMessage } from "react-intl";
import ReactQuill from "react-quill";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Link as RouterLink } from "react-router-dom";
import { ClientsDataType, useClientsFindOne } from "../../../api/Clients";
import {
  DateTimeInput,
  DropDownInput,
  ImageUpload,
  Input,
  SelectInput,
} from "../../../components/commons";
import { getDataFromCache } from "../../../utils/cache";

import { PageWrapper } from "../../../utils/globalStyle";
import {
  countries,
  GetAllCountries,
  PUBLIC_API_ENDPOINT,
} from "../../../utils/globalTypes";
import UserGeneralForm from "../Forms/UserGeneralForm";
import UserLogForm from "../Forms/UserLogForm";
import UserRelatedProductsForm from "../Forms/UserRelatedProducts";
import UserRelatedProfilesForm from "../Forms/UserRelatedProfiles";

const modules = {
  toolbar: [
    [{ header: "1" }, { header: "2" }, { font: [] }],
    [{ size: [] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
    ],
    ["link", "image"],
  ],
  clipboard: {
    matchVisual: false,
  },
};

const UpdateClient = () => {
  const location = useLocation();
  const { clientID } = useParams();
  const { from } = (location?.state as { from?: string }) || "";
  const navigate = useNavigate();
  const borderColor = useColorModeValue("gray.100", "gray.700");
  const toast = useToast();
  const [tabIndex, setTabIndex] = useState<number>(0);

  const [client, setclient] = useState<ClientsDataType>();
  const { mutate, data, isLoading, isSuccess, isError, error } =
    useClientsFindOne();
  useEffect(() => {
    mutate(clientID);
  }, []);

  useEffect(() => {
    if (isSuccess) {
      setclient(data.data.user);
    }
  }, [isSuccess]);
  const handleChangeIndex = (index: number) => {
    setTabIndex(index);
  };

  return (
    <PageWrapper>
      <Box
        className="page_header"
        height="auto !important"
        alignItems="baseline !important"
        flexDirection={{ base: "column", md: "row" }}
      >
        <Box className="page_header_title">
         
          <Box className="page_header_title_content">
            <Skeleton isLoaded={!isLoading} opacity={isLoading ? 0.4 : 1}>
              <Heading fontSize="2xl">{client?.fullName}</Heading>
            </Skeleton>
          </Box>
        </Box>
      </Box>
      <Tabs
        index={tabIndex}
        colorScheme="blue"
        onChange={handleChangeIndex}
        isLazy
      >
        <TabList   width="100%" overflowX="scroll" overflowY="clip">
          <Tab><FormattedMessage id={"category.nav.general"} /></Tab>

          <Tab><FormattedMessage id={"logs"} /></Tab>

<Tab>Linked Products</Tab>
{client?.profileSettings.isBusiness? <Tab><FormattedMessage id={"relatedProfiles"} /></Tab>:<></>}
        </TabList>
        <TabPanels>
          <TabPanel p="12px 0px">
            <UserGeneralForm loading={isLoading} user={client} />
          </TabPanel>

          <TabPanel p="12px 0px">
            <UserLogForm userId={clientID} />
          </TabPanel>
          <TabPanel p="12px 0px"> <UserRelatedProductsForm userId={clientID}/></TabPanel>
          <TabPanel p="12px 0px">
            <UserRelatedProfilesForm user={client}/>
          </TabPanel>

          
        </TabPanels>
      </Tabs>
    </PageWrapper>
  );
};

export default UpdateClient;
