import {
  Box,
  Button,
  Divider,
  FormControl,
  FormLabel,
  Heading,
  IconButton,
  Stack,
  Textarea,
  Text,
  useColorModeValue,
  useDisclosure,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Checkbox,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  useToast,
  Skeleton,
  Spinner,
  Flex,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Avatar,
  Tooltip,
} from "@chakra-ui/react";
import { useFormik } from "formik";
import { Fragment, useEffect, useState } from "react";
import {
  BiAddToQueue,
  BiBlock,
  BiBriefcaseAlt,
  BiEdit,
  BiPlus,
  BiTrash,
} from "react-icons/bi";

import moment from "moment";
import {
  ColorPicker,
  DateTimeInput,
  ImageUpload,
  Input,
} from "../../components/commons";
import { TableHeadType } from "../../components/modules/Table/Table.types";
import Table from "../../components/modules/Table/Table";
import { fakeData, HomePage } from "../../utils/globalTypes";
import {
  SettingsDataType,
  SittingsUpdateDataType,
  useFindAllSettings,
  useUpdateOneSettings,
} from "../../api/settings";
import { productDataType, useFindAllProducts } from "../../api/products";
import { FaMinus, FaPlus, FaQq } from "react-icons/fa";
import ReactQuill from "react-quill";
import { FormattedMessage, useIntl } from "react-intl";

const HomePageSettings = () => {
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789_-./;,?";
  function generateString(length: number) {
    let result = " ";
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }

    return result;
  }
  const {
    isOpen: isOpenUserModal,
    onOpen: onOpenUserModal,
    onClose: onCloseUserModal,
  } = useDisclosure();
  const sectionBgColor = useColorModeValue("white", "gray.900");
  const [setting, setSetting] = useState<SettingsDataType>();
  const {
    mutate: settingsUpdate,
    isLoading: isSettingsUpdateLoading,
    isSuccess: isSettingsUpdateSuccess,
    error,
  } = useUpdateOneSettings();
  const toast = useToast();
  const { data, isLoading, isSuccess, isError } = useFindAllSettings();

  const handleImage = async (file: File, index: number, form: string) => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("upload_preset", "ml_default");
    formData.append("public_id", `homepage/${file.name}`);
    // console.log(`${form}[${index}]`, "index");
    homePageFormik.setFieldValue(`${form}[${index}]`, null);
    const data = await (
      await fetch("https://api.cloudinary.com/v1_1/dgpjskmv1/upload", {
        method: "POST",
        body: formData,
      })
    ).json();

    homePageFormik.setFieldValue(`${form}[${index}]`, data.secure_url);
  };
  const intl = useIntl();
  const handlePartnerImage = async (file: File, form: string) => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("upload_preset", "ml_default");
    formData.append("public_id", `homepage/${file.name}`);
    // console.log(`${form}`, "index");
    homePageFormik.setFieldValue(`${form}`, null);
    const data = await (
      await fetch("https://api.cloudinary.com/v1_1/dgpjskmv1/upload", {
        method: "POST",
        body: formData,
      })
    ).json();

    homePageFormik.setFieldValue(`${form}`, data.secure_url);
  };

  const handleDeleteImage = (index: number, form: string) => {
    // console.log(`${form}[${index}]`, "index");
    homePageFormik.setFieldValue(`${form}[${index}]`, null);
  };
  const handleDeletePartnerImage = (form: string) => {
    // console.log(`${form}`, "index");
    homePageFormik.setFieldValue(`${form}`, null);
  };

  const modules = {
    toolbar: [
      [{ header: "1" }, { header: "2" }, { font: [] }],
      [{ size: [] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link"],
    ],
    clipboard: {
      matchVisual: false,
    },
  };
  const homePageInitalValues: SettingsDataType | SittingsUpdateDataType = {
    _id: "",
    seo: {
      _id: "",
      metatags: [],
    },
    shippingFees: "test",
    bestSelling: [],
    ourProducts: [],
    gallery: {
      display: "",
      images: [],
      _id: "",
    },
    reviews: [],
    partenrs: [],
    moreInfo: {
      refundPolicy: "",
      privacyAndPolicy: "",
      termsAndConditions: "",
      shippingPolicy: "",
      contactInfromation: "",
      _id: "",
    },
    faq: [],
    createdAt: "",
    updatedAt: "",
    __v: 0,
  };
  useEffect(() => {
    if (isSuccess) {
      // console.log("settingElement", data.data.settings[0]);
      homePageFormik.setValues(data.data.settings[0]);
    }
  }, [isSuccess]);
  useEffect(() => {
    if (isSettingsUpdateSuccess) {
      {
        toast({
          title: "Settings has been updated with success!",
          status: "success",
          position: `bottom-right`,

          isClosable: true,
        });
      }
    }
  }, [isSettingsUpdateSuccess]);
  // const [updateHomeSettingsMutation] = useUpdateHomeSettingsMutation();

  const homePageFormik = useFormik({
    validateOnMount: false,
    validateOnBlur: false,
    validateOnChange: false,
    initialValues: homePageInitalValues,
    onSubmit: (values, { setSubmitting }) => {
      const updateData: SittingsUpdateDataType = {
        bestSelling: values.bestSelling.map((e) => e._id),
        shippingFees: values.shippingFees,
        ourProducts: values.ourProducts.map((e) => e._id),
        gallery: {
          display: values.gallery.display,
          images: values.gallery.images,
        },
        seo: {
          metatags: values.seo.metatags,
        },
        reviews: values.reviews.map((f) => ({
          review: f.review,
          userImage: f.userImage,
          userName: f.userName,
          company: f.company,
        })),
        partenrs:values.partenrs.map((partenr)=>({partnerName:partenr.partnerName,
          partnerLogo: partenr.partnerLogo})), 
        moreInfo: {
          refundPolicy: values.moreInfo.refundPolicy,
          privacyAndPolicy: values.moreInfo.privacyAndPolicy,
          termsAndConditions: values.moreInfo.termsAndConditions,
          shippingPolicy: values.moreInfo.shippingPolicy,
          contactInfromation: values.moreInfo.contactInfromation,
        },
        faq: values.faq.map((f) => ({
          section: f.section,
          aq: f.aq.map((q) => ({
            question: q.question,
            answer: q.answer,
          })),
          showInHome: f.showInHome,
        })),
      };
      // console.log("updated data", updateData);
      settingsUpdate({
        obj: updateData,
        id: data && data.data.settings[0]._id,
      });
    },
  });

  const {
    isOpen: isOpenProductModal,
    onOpen: onOpenProductModal,
    onClose: onCloseProductModal,
  } = useDisclosure();

  const {
    isOpen: isOpenOurProducts,
    onOpen: onOpenOurProducts,
    onClose: onCloseOurProducts,
  } = useDisclosure();

  const [selectedReview, setSelectedReview] = useState(
    "6388aa73f488ddbe905d7079"
  );

  const [selectedFlashSales, setSelectedFlashSales] = useState<number | null>(
    null
  );

  const handleAssignProducts = (products: productDataType[]) => {
    homePageFormik.setFieldValue(`bestSelling`, products);
  };
  const handleAssignOurProducts = (products: productDataType[]) => {
    homePageFormik.setFieldValue(`ourProducts`, products);
  };
  const handleAssignFlashSales = (
    products: {
      _id: string;
      name: string;
      slug: string;
    }[]
  ) => {
    homePageFormik.setFieldValue(
      `flashSales[${selectedFlashSales}].products`,
      products
    );
  };

  return (
    <form onSubmit={homePageFormik.handleSubmit}>
      <Box display="flex" justifyContent="space-between">
        <Heading size="lg" fontSize="2xl" fontWeight="medium">
          <FormattedMessage id={"homeConfig"} />
        </Heading>
        <Button
          colorScheme="main"
          type="submit"
          size="sm"
          isLoading={isSettingsUpdateLoading}
        >
          <FormattedMessage id={"category.Update.btn"} />
        </Button>
      </Box>
      <Box marginTop="24px">
        <Tabs colorScheme="blue" isLazy>
          <TabList overflowX="auto" overflowY="hidden">
            <Tab>
              {" "}
              <FormattedMessage id={"gallery"} />
            </Tab>
            <Tab>
              {" "}
              <FormattedMessage id={"brand.nav.product"} />
            </Tab>
            <Tab>
              {" "}
              <FormattedMessage id={"FAQ"} />
            </Tab>
            <Tab>
              {" "}
              <FormattedMessage id={"Reviews"} />
            </Tab>
            <Tab>
              {" "}
              <FormattedMessage id={"partenrs"} />
            </Tab>
            <Tab>
              {" "}
              <FormattedMessage id={"Shipping"} />
            </Tab>
          </TabList>
          <TabPanels>
            <TabPanel p="12px 0px">
              <Box
                p="16px"
                bgColor={sectionBgColor}
                width="100%"
                shadow="md"
                rounded="base"
              >
                <Heading size="md" fontSize="xl" fontWeight="medium">
                  <FormattedMessage id={"gallery"} />
                </Heading>
                <Stack mt="4" spacing="18px">
                  {homePageFormik.values.gallery.images.map((image, index) => (
                    <Stack key={index}>
                      <Box
                        mb="2"
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Heading
                          opacity={0.6}
                          size="sm"
                          fontSize="md"
                          fontWeight="medium"
                        >
                          <FormattedMessage id={"image"} /> {index + 1}
                        </Heading>
                        <IconButton
                          aria-label="remove_banner_button"
                          size="sm"
                          onClick={() =>
                            homePageFormik.setFieldValue(
                              "gallery.images",
                              homePageFormik.values.gallery.images.filter(
                                (item, filterIndex) => index !== filterIndex
                              )
                            )
                          }
                        >
                          <BiTrash size={18} />
                        </IconButton>
                      </Box>

                      <ImageUpload
                        height="140px"
                        width="100%"
                        images={
                          image === null
                            ? []
                            : [
                                {
                                  progress: false,
                                  id: image,
                                  src: image,
                                },
                              ]
                        }
                        handleUpload={(file) =>
                          handleImage(file, index, "gallery.images")
                        }
                        handleDelete={() =>
                          handleDeleteImage(index, "gallery.images")
                        }
                      />
                    </Stack>
                  ))}
                  <Button
                    onClick={() =>
                      homePageFormik.setFieldValue(
                        `gallery.images[${homePageFormik.values.gallery.images.length}]`,
                        null
                      )
                    }
                    variant="outline"
                    leftIcon={<BiPlus />}
                  >
                    <FormattedMessage id={"image.add"} />
                  </Button>
                </Stack>
              </Box>
            </TabPanel>
            <TabPanel p="12px 0px">
              <Box
                p="16px"
                bgColor={sectionBgColor}
                width="100%"
                shadow="md"
                rounded="base"
              >
                <Heading size="md" fontSize="xl" fontWeight="medium">
                  <FormattedMessage id={"bestSelling"} />
                </Heading>
                <Stack mt="4" spacing="18px" mb="6">
                  <Box>
                    <Box
                      mb="2"
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <FormLabel marginBottom="1">
                        <FormattedMessage id={"product.liste"} />
                      </FormLabel>
                      <Button
                        leftIcon={<BiAddToQueue size={18} />}
                        size="sm"
                        onClick={() => {
                          onOpenProductModal();
                        }}
                      >
                        <FormattedMessage id={"assignProducts"} />
                      </Button>
                    </Box>
                    <Divider my="2" />
                    {homePageFormik.values.bestSelling &&
                      homePageFormik.values.bestSelling.map(
                        (product, index) => (
                          <Fragment key={product._id}>
                            <Stack
                              direction="row"
                              justifyContent="space-between"
                              alignItems="center"
                            >
                              <Text fontWeight="medium" fontSize="lg">
                                {product.name}
                              </Text>
                              <Button
                                variant="link"
                                colorScheme="red"
                                size="sm"
                                onClick={() =>
                                  homePageFormik.setFieldValue(
                                    `bestSelling`,
                                    homePageFormik.values.bestSelling.filter(
                                      (item, filterIndex) =>
                                        item._id !== product._id
                                    )
                                  )
                                }
                              >
                                <FormattedMessage id={"unassignProduct"} />
                              </Button>
                            </Stack>
                            <Divider mt="2" />
                          </Fragment>
                        )
                      )}
                  </Box>
                </Stack>
                <Heading size="md" fontSize="xl" fontWeight="medium">
                  <FormattedMessage id={"ourProducts"} /> Bitaqa
                </Heading>
                <Stack mt="4" spacing="18px" mb="6">
                  <Box>
                    <Box
                      mb="2"
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <FormLabel marginBottom="1">
                        <FormattedMessage id={"product.liste"} />
                      </FormLabel>
                      <Button
                        leftIcon={<BiAddToQueue size={18} />}
                        size="sm"
                        onClick={() => {
                          onOpenOurProducts();
                        }}
                      >
                        <FormattedMessage id={"assignProducts"} />
                      </Button>
                    </Box>
                    <Divider my="2" />
                    {homePageFormik.values.ourProducts &&
                      homePageFormik.values.ourProducts.map(
                        (product, index) => (
                          <Fragment key={product._id}>
                            <Stack
                              direction="row"
                              justifyContent="space-between"
                              alignItems="center"
                            >
                              <Text fontWeight="medium" fontSize="lg">
                                {product.name}
                              </Text>
                              <Button
                                variant="link"
                                colorScheme="red"
                                size="sm"
                                onClick={() =>
                                  homePageFormik.setFieldValue(
                                    `ourProducts`,
                                    homePageFormik.values.ourProducts.filter(
                                      (item, filterIndex) =>
                                        item._id !== product._id
                                    )
                                  )
                                }
                              >
                                <FormattedMessage id={"unassignProduct"} />
                              </Button>
                            </Stack>
                            <Divider mt="2" />
                          </Fragment>
                        )
                      )}
                  </Box>
                </Stack>
              </Box>
            </TabPanel>
            <TabPanel p="12px 0px">
              <Box
                p="16px"
                bgColor={sectionBgColor}
                width="100%"
                shadow="md"
                rounded="base"
              >
                <Heading size="md" fontSize="xl" fontWeight="medium">
                  <FormattedMessage id={"faq"} />
                </Heading>
                <Stack mt="4" spacing="18px" mb="4">
                  {homePageFormik.values.faq.map((e, i) => (
                    <Flex key={e._id} direction="column">
                      <Box
                        mb="2"
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Heading
                          opacity={0.6}
                          size="sm"
                          fontSize="md"
                          fontWeight="medium"
                        >
                          <FormattedMessage id={"section"} />
                          {i + 1}
                        </Heading>
                        <IconButton
                          aria-label="remove_slides_button"
                          size="sm"
                          onClick={() =>
                            homePageFormik.setFieldValue(
                              "faq",
                              homePageFormik.values.faq.filter(
                                (item, filterIndex) => i !== filterIndex
                              )
                            )
                          }
                        >
                          <BiTrash size={18} />
                        </IconButton>
                      </Box>
                      <Input
                        label="Title"
                        inputProps={{
                          mb: "2",
                          placeholder: "Title",
                          name: `faq[${i}].section`,
                          value: e.section,
                          onChange: homePageFormik.handleChange,
                        }}
                      />
                      <Box
                        mb="2"
                        mt="2"
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        {" "}
                        <FormLabel>
                          <FormattedMessage id={"Quetions"} />{" "}
                        </FormLabel>
                        <Button
                          leftIcon={<BiAddToQueue size={18} />}
                          size="sm"
                          width={"15%"}
                          onClick={() => {
                            homePageFormik.setFieldValue(
                              `faq[${i}].aq[${e.aq.length}]`,
                              {
                                question: "question",
                                answer: "answer",
                                _id: Math.random() * 999,
                              }
                            );
                          }}
                        >
                          <FormattedMessage id={"questions.add"} />
                        </Button>
                      </Box>

                      <Accordion allowMultiple key={e._id} mb="4">
                        {e.aq.map((q, index) => (
                          <AccordionItem
                            key={q._id}
                            border="0px solid red !important"
                          >
                            {({ isExpanded }) => (
                              <>
                                <h2>
                                  <AccordionButton>
                                    <Box flex="1" textAlign="left">
                                      <Input
                                        inputProps={{
                                          mb: "2",
                                          placeholder: "question",
                                          name: `faq[${i}].aq[${index}].question`,
                                          value: q.question,
                                          onChange: homePageFormik.handleChange,
                                        }}
                                      />
                                    </Box>

                                    <AccordionIcon />
                                    <IconButton
                                      aria-label="delete question"
                                      size="sm"
                                      variant="outline"
                                      colorScheme="red"
                                      onClick={() =>
                                        homePageFormik.setFieldValue(
                                          `faq[${i}].aq`,
                                          homePageFormik.values.faq[
                                            i
                                          ].aq.filter(
                                            (item, filterIndex) =>
                                              item._id !== q._id
                                          )
                                        )
                                      }
                                    >
                                      <BiBlock size={14} />
                                    </IconButton>
                                  </AccordionButton>
                                </h2>
                                <AccordionPanel p={5}>
                                  <FormLabel>
                                    {" "}
                                    <FormattedMessage id={"answer"} />
                                  </FormLabel>
                                  <Textarea
                                    value={q.answer || ""}
                                    onChange={(content) =>
                                      homePageFormik.setFieldValue(
                                        `faq[${i}].aq[${index}].answer`,
                                        content.target.value
                                      )
                                    }
                                    placeholder="Answer to this question"
                                    size="sm"
                                  />
                                </AccordionPanel>
                              </>
                            )}
                          </AccordionItem>
                        ))}
                      </Accordion>

                      <Checkbox
                        name={`faq[${i}].showInHome`}
                        onChange={homePageFormik.handleChange}
                        isChecked={homePageFormik.values.faq[i].showInHome}
                      >
                        Show in Home
                      </Checkbox>
                    </Flex>
                  ))}
                  <Button
                    onClick={() =>
                      homePageFormik.setFieldValue(
                        `faq[${homePageFormik.values.faq.length}]`,
                        { section: "", aq: [], _id: "" }
                      )
                    }
                    variant="outline"
                    leftIcon={<BiPlus />}
                    // isFullWidth
                  >
                    Add another section
                  </Button>
                </Stack>
              </Box>
            </TabPanel>
            <TabPanel p="12px 0px">
              <Box
                p="16px"
                bgColor={sectionBgColor}
                width="100%"
                shadow="md"
                rounded="base"
              >
                <Heading size="md" fontSize="xl" fontWeight="medium">
                  Clients Reviews
                </Heading>
                <Stack mt="4" spacing="18px">
                  <Box>
                    <Divider my="2" />
                    {homePageFormik.values.reviews &&
                      homePageFormik.values.reviews.map((review, index) =>
                        review._id != selectedReview ? (
                          <Box
                            borderWidth="1px"
                            borderRadius="lg"
                            key={review._id}
                            m="2"
                          >
                            <Box
                              p="6"
                              mb="2"
                              display="flex"
                              // justifyContent="space-between"
                              alignItems="center"
                            >
                              <Avatar
                                name="Dan Abrahmov"
                                src={review.userImage}
                              />
                              <FormLabel marginBottom="1">
                                {review.userName}
                              </FormLabel>
                              <Box
                                alignItems={"center"}
                                ml={{ base: "0", md: "auto" }}
                                textAlign="right"
                              >
                                <IconButton
                                  mr="11px !important"
                                  aria-label="delete section"
                                  size="sm"
                                  variant="outline"
                                  colorScheme="red"
                                  onClick={() => {
                                    homePageFormik.setFieldValue(
                                      "reviews",
                                      homePageFormik.values.reviews.filter(
                                        (item, filterIndex) =>
                                          index !== filterIndex
                                      )
                                    );
                                  }}
                                >
                                  <BiBlock size={14} />
                                </IconButton>
                                <Tooltip
                                  label="Edit review"
                                  aria-label="A tooltip"
                                >
                                  <IconButton
                                    aria-label="Edit blog"
                                    size="sm"
                                    variant="outline"
                                    colorScheme="whatsapp"
                                    onClick={() => {
                                      setSelectedReview(review._id);
                                    }}
                                  >
                                    <BiEdit size={16} />
                                  </IconButton>
                                </Tooltip>
                              </Box>
                            </Box>

                            <Box
                              p="6"
                              mb="2"
                              display="flex"
                              // justifyContent="space-between"
                              alignItems="center"
                            >
                              <Text
                                dangerouslySetInnerHTML={{
                                  __html: review.review,
                                }}
                              />
                            </Box>
                            <FormLabel p="6">
                              Company : {review.company}
                            </FormLabel>

                            <Divider mt="2" />
                          </Box>
                        ) : (
                          <Box
                            p="6"
                            borderWidth="1px"
                            borderRadius="lg"
                            key={review._id}
                            m="2"
                          >
                            {" "}
                            <Stack
                              direction={{ base: "column", xl: "row" }}
                              spacing="25px"
                            >
                              {/* <Skeleton isLoaded={!userLoading} w="160px"> */}
                              <Stack direction={"column"} spacing="22px">
                                {" "}
                                <ImageUpload
                                  height="140px"
                                  width="140px"
                                  images={
                                    review.userImage === null
                                      ? []
                                      : [
                                          {
                                            progress: false,
                                            id: review._id,
                                            src: review.userImage,
                                          },
                                        ]
                                  }
                                  handleUpload={(file) =>
                                    handlePartnerImage(
                                      file,

                                      `reviews[${index}].userImage`
                                    )
                                  }
                                  handleDelete={() =>
                                    handleDeletePartnerImage(
                                      `reviews[${index}].userImage`
                                    )
                                  }
                                  label="Profile picture"
                                />
                                <Button
                                  // type="submit"
                                  onClick={() => {
                                    setSelectedReview("0");
                                  }}
                                  colorScheme="main"
                                  // isLoading={CreateOneSocialLinksIsLoading}
                                >
                                  Valider
                                </Button>
                              </Stack>

                              {/* </Skeleton> */}
                              <Stack direction={"column"} spacing="22px">
                                {/* <Skeleton isLoaded={!userLoading} w="full"> */}
                                <Input
                                  label="userName"
                                  inputProps={{
                                    width: "100%",
                                    mt: "7px",
                                    placeholder: "userName",
                                    name: `reviews[${index}].userName`,
                                    value: review.userName,
                                    onChange: homePageFormik.handleChange,
                                  }}
                                />

                                <Input
                                  label="company"
                                  inputProps={{
                                    width: "100%",
                                    mt: "7px",
                                    placeholder: "company",
                                    name: `reviews[${index}].company`,
                                    value: review.company,
                                    onChange: homePageFormik.handleChange,
                                  }}
                                />
                              </Stack>
                              <Box>
                                <FormLabel marginBottom="1">
                                  {intl.formatMessage({
                                    id: "review",
                                  })}
                                </FormLabel>
                                <ReactQuill
                                  value={review.review}
                                  onChange={(content) =>
                                    homePageFormik.setFieldValue(
                                      `reviews[${index}].review`,
                                      content
                                    )
                                  }
                                  modules={modules}
                                  placeholder="review"
                                />
                              </Box>
                            </Stack>
                          </Box>
                        )
                      )}
                  </Box>
                  <Button
                    onClick={() => {
                      let id = generateString(Math.random() * 15);
                      homePageFormik.setFieldValue(
                        `reviews[${homePageFormik.values.reviews.length}]`,
                        {
                          userName: "",
                          userImage: null,
                          company: "",
                          review: "",
                          _id: id,
                        }
                      );
                      setSelectedReview(id);
                    }}
                    variant="outline"
                    leftIcon={<BiPlus />}
                    // isFullWidth
                  >
                    Add another Review
                  </Button>
                </Stack>
              </Box>
            </TabPanel>
            <TabPanel p="12px 0px">
              <Box
                p="16px"
                bgColor={sectionBgColor}
                width="100%"
                shadow="md"
                rounded="base"
              >
                <Heading size="md" fontSize="xl" fontWeight="medium">
                  Partenaires
                </Heading>
                <Stack mt="4" spacing="18px" mb="4" width="100%">
                  <Accordion allowMultiple mb="4">
                    {homePageFormik.values.partenrs.map((e, i) => (
                      <AccordionItem
                        key={e._id}
                        border="0px solid red !important"
                        width="100%"
                      >
                        {({ isExpanded }) => (
                          <>
                            <Box
                              mb="2"
                              display="flex"
                              width="100%"
                              alignItems="center"
                            >
                              <AccordionButton>
                                <Box
                                  mb="2"
                                  display="flex"
                                  width="100%"
                                  alignItems="center"
                                >
                                  <Avatar
                                    name="Dan Abrahmov"
                                    src={e.partnerLogo}
                                    mr={5}
                                  />
                                  <FormLabel marginBottom="1">
                                    {e.partnerName}
                                  </FormLabel>
                                </Box>
                                <AccordionIcon />
                                <IconButton
                                  aria-label="delete question"
                                  size="sm"
                                  variant="outline"
                                  colorScheme="red"
                                  onClick={() =>
                                    homePageFormik.setFieldValue(
                                      "partenrs",
                                      homePageFormik.values.partenrs.filter(
                                        (item, filterIndex) => i !== filterIndex
                                      )
                                    )
                                  }
                                >
                                  <BiBlock size={14} />
                                </IconButton>
                              </AccordionButton>
                            </Box>
                            <AccordionPanel p={5}>
                              <Input
                                label="Nom"
                                inputProps={{
                                  mb: "2",
                                  placeholder: "Nom",
                                  name: `partenrs[${i}].partnerName`,
                                  value: e.partnerName,
                                  onChange: homePageFormik.handleChange,
                                }}
                              />
                              <ImageUpload
                                height="200px"
                                width="200px"
                                images={
                                  e.partnerLogo === null
                                    ? []
                                    : [
                                        {
                                          progress: false,
                                          id: e._id,
                                          src: e.partnerLogo,
                                        },
                                      ]
                                }
                                handleUpload={(file) =>
                                  handlePartnerImage(
                                    file,

                                    `partenrs[${i}].partnerLogo`
                                  )
                                }
                                handleDelete={() =>
                                  handleDeletePartnerImage(
                                    `partenrs[${i}].partnerLogo`
                                  )
                                }
                                label="Image"
                              />
                            </AccordionPanel>
                          </>
                        )}
                      </AccordionItem>
                    ))}
                  </Accordion>

                  <Button
                    onClick={() =>
                      homePageFormik.setFieldValue(
                        `partenrs[${homePageFormik.values.partenrs.length}]`,
                        { partnerName: "", partnerLogo: null, _id: "" }
                      )
                    }
                    variant="outline"
                    leftIcon={<BiPlus />}
                    // isFullWidth
                  >
                    Add another partner
                  </Button>
                </Stack>
              </Box>
            </TabPanel>
            <TabPanel p="12px 0px">
              <Box
                p="16px"
                bgColor={sectionBgColor}
                width="100%"
                shadow="md"
                rounded="base"
              >
                <Heading size="md" fontSize="xl" fontWeight="medium">
                  shipping Fees
                </Heading>
                <Stack mt="4" spacing="18px">
                  <Box>
                    <Divider my="2" />
                    <Box>
                      <FormLabel marginBottom="1">
                        {" "}
                        {/* <FormattedMessage id={"product.description"} /> */}
                        Title
                      </FormLabel>
                      <ReactQuill
                        value={homePageFormik.values.shippingFees}
                        onChange={(content) =>
                          homePageFormik.setFieldValue(`shippingFees`, content)
                        }
                        modules={modules}
                        placeholder="Describe this category"
                      />
                    </Box>
                  </Box>
                </Stack>
              </Box>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>
      <ProductAssignModal
        isOpen={isOpenProductModal}
        onClose={onCloseProductModal}
        assignProducts={handleAssignProducts}
        products={homePageFormik.values.bestSelling}
      />
      <OurProductAssignModal
        isOpen={isOpenOurProducts}
        onClose={onCloseOurProducts}
        assignProducts={handleAssignOurProducts}
        products={homePageFormik.values.ourProducts}
      />
    </form>
  );
};
export default HomePageSettings;

type ProductAssignModalProps = {
  isOpen: boolean;
  onClose: () => void;
  assignProducts: (products: productDataType[]) => void;
  products: productDataType[];
};

const ProductAssignModal = (props: ProductAssignModalProps) => {
  const { isOpen, onClose, assignProducts, products } = props;
  const { mutate, data, isLoading, isSuccess, isError, error } =
    useFindAllProducts();

  const handleAssignProduct = () => {
    assignProducts(selectedProducts);
    onClose();
  };
  const [productList, setProductList] = useState<productDataType[]>();
  const [search, setSearch] = useState("");

  const [sortByName, setSortByName] = useState<1 | -1>(1);
  const [sortByPrice, setSortByPrice] = useState<1 | -1>(1);
  const [sortByCreated, setSortByCreated] = useState<1 | -1>(-1);
  const [sortByUpdated, setSortByUpdated] = useState<1 | -1>(-1);
  const [pagination, setPagination] = useState({
    limit: 10,
    skip: 0,
  });
  useEffect(() => {
    mutate({
      skip: pagination.skip,
      limit: pagination.limit,
      search: search,
      // category:category,
      // isAvliable:isAvliable,
      name: sortByName,
      price: sortByPrice,
      // status: status,
      createdAt: sortByCreated,
      updatedAt: sortByUpdated,
    });
  }, [pagination]);
  useEffect(() => {
    if (isSuccess) {
      setProductList(data.data.products);
    }
  }, [isSuccess, pagination]);

  const handlePageChange = (dir: "next" | "prev") => {
    if (dir === "next") {
      setPagination((prevState) => {
        let temp = { ...prevState };
        temp.skip = temp.skip + temp.limit;
        return temp;
      });
    } else {
      setPagination((prevState) => {
        let temp = { ...prevState };
        temp.skip = temp.skip - temp.limit;
        return temp;
      });
    }
  };

  const handleLimitChange = (value: number) => {
    setPagination((prevState) => {
      let temp = { ...prevState };
      temp.limit = +value;
      return temp;
    });
  };

  const [selectedProducts, setSelectedProducts] =
    useState<productDataType[]>(products);

  useEffect(() => {
    setSelectedProducts(products);
  }, [products]);
  console.log("selectedItem", selectedProducts);
  const handleSelectProduct = (prod: productDataType) => {
    setSelectedProducts((prevState) => [...prevState, prod]);
  };

  const handleRemoveSelectedProduct = (id: string) => {
    setSelectedProducts((prevState) => {
      let temp = [...prevState];
      const index = temp.findIndex((object) => {
        return object._id === id;
      });
      if (index > -1) {
        temp.splice(index, 1);
      }
      return temp;
    });
  };

  const productsToAssignTableData =
    (productList &&
      productList?.map((item, index) => ({
        id: item._id,
        check: (
          <Checkbox
            value={item._id}
            isChecked={Boolean(selectedProducts.find((e) => e._id == item._id))}
            onChange={(e) => {
              if (e.target.checked) {
                handleSelectProduct(item);
              } else {
                handleRemoveSelectedProduct(e.target.value);
              }
            }}
          />
        ),
        name: item.name,

        category: item.category && item.category.name,

        basePrice: item.price.price + " TND",
      }))) ||
    [];

  type ProductsToAssignTableType = typeof productsToAssignTableData[number];

  const productsToAssignTableHead: TableHeadType<ProductsToAssignTableType>[] =
    [
      { accessor: "check", label: "" },
      { accessor: "name", label: "Name" },

      { accessor: "category", label: "Category" },
      { accessor: "basePrice", label: "Base Price" },
    ];

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      scrollBehavior="inside"
      size="2xl"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader borderBottomWidth="1px">
        <FormattedMessage id="bestselling.add" /> 
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Stack spacing="14px" py="14px">
            <Heading size="md"><FormattedMessage id= "bestselling.select" /></Heading>
            <Box>
              {selectedProducts.length !== 0 && (
                <Text fontSize="sm" opacity={0.6}>
                  {selectedProducts.length} <FormattedMessage id= "selected" />
                </Text>
              )}
              <Table
                head={productsToAssignTableHead}
                data={productsToAssignTableData}
                // isLoading={productToAssignListLoading}
                emptyState={
                  <Box className="empty_table_container">
                    <BiBriefcaseAlt size={42} />
                    <Text fontSize="md" fontWeight="medium">
                      There is no assigned products!
                    </Text>
                  </Box>
                }
                pagination={{
                  count: data?.data.queryCount,
                  limit: pagination.limit,
                  skip: pagination.skip,
                  onPageChange: handlePageChange,
                  onChangeLimit: handleLimitChange,
                }}
              />
            </Box>
          </Stack>
        </ModalBody>

        <ModalFooter borderTopWidth="1px">
          <Button variant="Outline" onClick={onClose}>
          <FormattedMessage id="role.update.btn.cancel" />
          </Button>
          <Button colorScheme="main" onClick={handleAssignProduct}>
            <FormattedMessage id={"assignProducts"} />
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
const OurProductAssignModal = (props: ProductAssignModalProps) => {
  const { isOpen, onClose, assignProducts, products } = props;
  const { mutate, data, isLoading, isSuccess, isError, error } =
    useFindAllProducts();

  const handleAssignProduct = () => {
    assignProducts(selectedProducts);
    onClose();
  };
  const [productList, setProductList] = useState<productDataType[]>();
  const [search, setSearch] = useState("");

  const [sortByName, setSortByName] = useState<1 | -1>(1);
  const [sortByPrice, setSortByPrice] = useState<1 | -1>(1);
  const [sortByCreated, setSortByCreated] = useState<1 | -1>(-1);
  const [sortByUpdated, setSortByUpdated] = useState<1 | -1>(-1);
  const [pagination, setPagination] = useState({
    limit: 10,
    skip: 0,
  });
  useEffect(() => {
    mutate({
      skip: pagination.skip,
      limit: pagination.limit,
      search: search,
      // category:category,
      // isAvliable:isAvliable,
      name: sortByName,
      price: sortByPrice,
      // status: status,
      createdAt: sortByCreated,
      updatedAt: sortByUpdated,
    });
  }, [pagination]);
  useEffect(() => {
    if (isSuccess) {
      setProductList(data.data.products);
    }
  }, [isSuccess, pagination]);

  const handlePageChange = (dir: "next" | "prev") => {
    if (dir === "next") {
      setPagination((prevState) => {
        let temp = { ...prevState };
        temp.skip = temp.skip + temp.limit;
        return temp;
      });
    } else {
      setPagination((prevState) => {
        let temp = { ...prevState };
        temp.skip = temp.skip - temp.limit;
        return temp;
      });
    }
  };

  const handleLimitChange = (value: number) => {
    setPagination((prevState) => {
      let temp = { ...prevState };
      temp.limit = +value;
      return temp;
    });
  };

  const [selectedProducts, setSelectedProducts] =
    useState<productDataType[]>(products);

  useEffect(() => {
    setSelectedProducts(products);
  }, [products]);
  // console.log("selectedItem", selectedProducts);
  const handleSelectProduct = (prod: productDataType) => {
    setSelectedProducts((prevState) => [...prevState, prod]);
  };

  const handleRemoveSelectedProduct = (id: string) => {
    setSelectedProducts((prevState) => {
      let temp = [...prevState];
      const index = temp.findIndex((object) => {
        return object._id === id;
      });
      if (index > -1) {
        temp.splice(index, 1);
      }
      return temp;
    });
  };

  const productsToAssignTableData =
    (productList &&
      productList?.map((item, index) => ({
        id: item._id,
        check: (
          <Checkbox
            value={item._id}
            isChecked={Boolean(selectedProducts.find((e) => e._id == item._id))}
            onChange={(e) => {
              if (e.target.checked) {
                handleSelectProduct(item);
              } else {
                handleRemoveSelectedProduct(e.target.value);
              }
            }}
          />
        ),
        name: item.name,

        category: item.category && item.category.name,

        basePrice: item.price.price + " TND",
      }))) ||
    [];

  type ProductsToAssignTableType = typeof productsToAssignTableData[number];

  const productsToAssignTableHead: TableHeadType<ProductsToAssignTableType>[] =
    [
      { accessor: "check", label: "" },
      { accessor: "name", label: "Name" },

      { accessor: "category", label: "Category" },
      { accessor: "basePrice", label: "Base Price" },
    ];

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      scrollBehavior="inside"
      size="2xl"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader borderBottomWidth="1px">
          Assign product to our products Bitaqa
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Stack spacing="14px" py="14px">
            <Heading size="md">Select our products Bitaqa</Heading>
            <Box>
              {selectedProducts.length !== 0 && (
                <Text fontSize="sm" opacity={0.6}>
                  {selectedProducts.length} Selected
                </Text>
              )}
              <Table
                head={productsToAssignTableHead}
                data={productsToAssignTableData}
                // isLoading={productToAssignListLoading}
                emptyState={
                  <Box className="empty_table_container">
                    <BiBriefcaseAlt size={42} />
                    <Text fontSize="md" fontWeight="medium">
                      There is no assigned products!
                    </Text>
                  </Box>
                }
                pagination={{
                  count: data?.data.queryCount,
                  limit: pagination.limit,
                  skip: pagination.skip,
                  onPageChange: handlePageChange,
                  onChangeLimit: handleLimitChange,
                }}
              />
            </Box>
          </Stack>
        </ModalBody>

        <ModalFooter borderTopWidth="1px">
          <Button variant="Outline" onClick={onClose}>
            Cancel
          </Button>
          <Button colorScheme="main" onClick={handleAssignProduct}>
            <FormattedMessage id={"assignProducts"} />
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
