import { useToast } from "@chakra-ui/react";
import { useMutation } from "@tanstack/react-query";

import {
  CATEGORIES_FIND_ALL,
  CATEGORY_CREATE_ONE,
  CATEGORY_DELETE_ONE,
  CATEGORY_UPDATE_ONE,
  paginationCategory,
} from "../endpoints/categories";
import {
  post,
  protectedDelete,
  protectedGet,
  protectedPatch,
  protectedPost,
  protectedPut,
} from "../http-methods";

export type CategoryDataType = {
  _id: string;
  name: string;
  description: string;
  slug: string;
  level: number;
  image: string;
  status: string;
  hasRoot: boolean;
  hasNodes: boolean;
  hasLocalField: boolean;
  seo: {
    _id: string;
    seotitle: string;
    metatags: { key: string; value: string }[];
    keywords: string;
    metadescription: string;
  };
  createdAt: string;
  updatedAt: string;
  _v: number;
  nodes: CategoryDataType[];
};
export type CategoryUpdateDataType = {
  name?: string;
  description?: string;
  slug?: string;
  level?: number;
  image?: string;
  status?: string;
  hasRoot?: boolean;
  hasNodes?: boolean;
  hasLocalField?: boolean;
  seo?: {
    seotitle: string;
    keywords: string;
    metadescription: string;

    metatags: {
      key?: string | null | undefined;
      value?: string | null | undefined;
    }[];
  };
  createdAt?: string;
  updatedAt?: string;
  _v?: number;
  nodes?: CategoryDataType[];
};
type createCategoryDataType = {
  name: string;
  description: string;
  image?: string;
  parent: string;
};

export const useCategoriesFindAll = () => {
  return useMutation({
    mutationFn: (obj: paginationCategory) => {
      return protectedGet(CATEGORIES_FIND_ALL(obj));
    },
  });
};
export const useCategoriesCreateOne = () => {
  const toast = useToast();
  return useMutation({
    mutationFn: (catObj: createCategoryDataType) => {
      return protectedPost(CATEGORY_CREATE_ONE, catObj);
    },
    onError: (data: any) => {
      console.log("dataError", data.response.data.errors);
      const errors: any = data.response.data.errors;
      if (errors[0]) {
        toast({
          title: errors[0].msg,
          position: `bottom-right`,
          status: "error",
          isClosable: true,
        });
      } else if (errors.name) {
        toast({
          title: "name",
          description: errors.name.msg,
          position: `bottom-right`,
          status: "error",
          isClosable: true,
        });
      } else if (errors.description) {
        toast({
          title: "description",
          description: errors.description.msg,
          position: `bottom-right`,
          status: "error",
          isClosable: true,
        });
      } else if (errors.image) {
        toast({
          title: "image",
          description: errors.image.msg,
          position: `bottom-right`,
          status: "error",
          isClosable: true,
        });
      } else if (errors.parent) {
        toast({
          title: "parent",
          description: errors.parent.msg,
          position: `bottom-right`,
          status: "error",
          isClosable: true,
        });
      }
    },
  });
};
export const useCategoriesDeleteOne = () => {
  return useMutation({
    mutationFn: (catObj: string) => {
      return protectedDelete(CATEGORY_DELETE_ONE, catObj);
    },
  });
};
export const useCategoriesUpdateOne = () => {
  const toast = useToast();
  return useMutation({
    mutationFn: ({
      catObj,
      id,
    }: {
      catObj: CategoryUpdateDataType;
      id: string;
    }) => {
      return protectedPatch(CATEGORY_UPDATE_ONE(id), catObj);
    },
    onError: (data: any) => {
      console.log("dataError", data.response.data.errors);
      const errors: any = data.response.data.errors;
      if (errors[0]) {
        toast({
          title: errors[0].msg,
          position: `bottom-right`,
          status: "error",
          isClosable: true,
        });
      } else if (errors.name) {
        toast({
          title: "name",
          description: errors.name.msg,
          position: `bottom-right`,
          status: "error",
          isClosable: true,
        });
      } else if (errors.description) {
        toast({
          title: "description",
          description: errors.description.msg,
          position: `bottom-right`,
          status: "error",
          isClosable: true,
        });
      } else if (errors.image) {
        toast({
          title: "image",
          description: errors.image.msg,
          position: `bottom-right`,
          status: "error",
          isClosable: true,
        });
      } else if (errors.parent) {
        toast({
          title: "parent",
          description: errors.parent.msg,
          position: `bottom-right`,
          status: "error",
          isClosable: true,
        });
      }
    },
  });
};
