import { objToParam } from "./categories";

export type paginationClients = {
  skip?: number;
  limit?: number;
  search?: string;
  email?: 1 | -1;
  user?: string;
  firstName?: 1 | -1;
  lastName?: 1 | -1;
  createdAt?: 1 | -1;
  updatedAt?: 1 | -1;
};
export const USERS_FIND_ALL = (obj: paginationClients = {}) =>
  `users/findAll${objToParam(obj)}`;
export const USER_LOG_FIND_ALL = (obj: paginationClients = {}) =>
  `userLogs/findAll${objToParam(obj)}`;
  export const USER_LINK_LOG_FIND_ALL = (obj: {user?:string} = {}) =>
  `userLogs/linkLog${objToParam(obj)}`;
export const USERS_CREATE_ONE = "users/createOne";
export const USERS_DELETE_ONE = "users/deleteOne";
export const USERS_FIND_ONE = (obj?: string) => `users/findOne/${obj}`;
export const USERS_PATCH_ONE = (id: string) => `users/patchOne/${id}`;
export const USERPROFILES_PATCH_ONE = (id: string) =>
  `userProfiles/patchOne/${id}`;
  export const USERPASSWORD_PATCH_ONE = `users/current/changePassword`;