import { objToParam } from "./categories";

export type paginationSocialLinks = {
  skip?: number;
  limit?: number;
  search?: string;
  createdAt?: 1 | -1;
  updatedAt?: 1 | -1;
};

export const GET_SOCIAL_LINKS_SECTION = (obj: paginationSocialLinks = {}) =>
  `socialLinkSection/findAll${objToParam(obj)}`;
export const CREATE_ONE_SOCIAL_LINKS_SECTION = "socialLinkSection/createOne";
export const UPDATE_ONE__SOCIAL_LINKS_SECTION = (id: string) =>
  `socialLinkSection/updateOne/${id}`;
export const DELETE_ONE__SOCIAL_LINKS_SECTION = "socialLinkSection/deleteOne";
export const CREATE_ONE_SOCIAL_LINKS = "socialLink/createOne";

export const UPDATE_ONE_SOCIAL_LINKS = (id: string) =>
  `socialLink/updateOne/${id}`;
