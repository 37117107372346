import React, { Suspense, useState } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import Layout from "./components/modules/Layout/Layout";
import SettingsWrapper from "./components/modules/SettingsWrapper/SettingsWrapper";
import AboutPage from "./pages/about";
import Authentication from "./pages/Authentication/Authentication";
import Categorie from "./pages/Categories/Categories";
import Categories from "./pages/Categories/Categories";
import ConfirmEmail from "./pages/ConfirmEmail/ConfirmEmail";
import Documents from "./pages/users/users";
import ForgetPassword from "./pages/ForgetPassword/ForgetPassword";
import HomePage from "./pages/home";
import OrderDetails from "./pages/Orders/OrderDetails/OrderDetails";
// import OrderDetails from "./pages/Orders/OrderDetails/OrderDetails";
import Orders from "./pages/Orders/Orders";

import Products from "./pages/products/products";
import TestPage from "./pages/products/products";

import ResetPassword from "./pages/ResetPassword/ResetPassword";
import AccountSecuritySettings from "./pages/Settings/AccountSecuritySettings";

import HomePageSettings from "./pages/Settings/HomePageSettings";
import PoliciesSettings from "./pages/Settings/PoliciesSettings";
import ProfileSettings from "./pages/Settings/ProfileSettings";

import AddProduct from "./pages/products/AddProduct/AddProduct";

import Clients from "./pages/Clients/Clients";

import UpdateClient from "./pages/Clients/UpdateClient/UpdateClient";
import UpdateProduct from "./pages/products/UpdateProduct/UpdateProduct";
import Blogs from "./pages/Blogs/blogs";
import SocialLinks from "./pages/SocialLinks/socialLinks";
import Contacts from "./pages/Contacts";
import ProtectedRoute from "./ProtectedRoute";
import Inventorys from "./pages/Iventorys/inventorys";
import Coupons from "./pages/Coupons/coupons";
import AddCoupon from "./pages/Coupons/AddCoupon";
import UpdateCoupon from "./pages/Coupons/UpdateCoupon";
import Dashbord from "./pages/Dashbord";
import NotFound from "./pages/page404/NotFound";

export interface IApplicationProps {}

const Application: React.FunctionComponent<IApplicationProps> = (props) => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Dashbord />} />
          <Route path="inventorys" element={<Inventorys />} />
          <Route path=":number" element={<AboutPage />} />
          <Route path="about">
            <Route index element={<AboutPage />} />
            <Route path=":number" element={<AboutPage />} />
          </Route>
          <Route path="home">
            <Route path="child1" element={<AddProduct />} />
            <Route path="child2" element={<AddProduct />} />
          </Route>

          <Route path="products">
            <Route path="create" element={<AddProduct />} />
            <Route index element={<Products />} />

            <Route path=":productID" element={<UpdateProduct />} />
            {/* <Route path=":productID" element={<UpdateSimpleProduct />} /> */}
          </Route>
          <Route path="coupons">
            <Route path="create" element={<AddCoupon />} />
            <Route index element={<Coupons />} />

            <Route path=":couponID" element={<UpdateCoupon />} />
            {/* <Route path=":productID" element={<UpdateSimpleProduct />} /> */}
          </Route>

          <Route path="orders">
            <Route index element={<Orders />} />

            <Route path=":orderID" element={<OrderDetails />} />
          </Route>
          <Route path="blogs" element={<Blogs />} />
          <Route path="contacts" element={<Contacts />} />
          <Route path="socialLinks" element={<SocialLinks />} />
          <Route path="categories" element={<Categorie />} />

          <Route path="users">
            <Route index element={<Clients />} />
          
            <Route path=":clientID" element={<UpdateClient />} />
          </Route>
          <Route path="*" element={<NotFound/>} />
        </Route>
        <Route path="settings" element={<SettingsWrapper />}>
          <Route index element={<ProfileSettings />} />
          <Route path="profile" element={<ProfileSettings />} />
          <Route path="security" element={<AccountSecuritySettings />} />
          <Route path="home" element={<HomePageSettings />} />

          <Route path="policies" element={<PoliciesSettings />} />
          <Route path="*" element={<NotFound/>} />
        </Route>

        <Route path="authentication/">
          <Route path="login" element={<Authentication get="LOGIN" />} />
          <Route path="register" element={<Authentication get="REGISTER" />} />
          <Route path="confirm-email" element={<ConfirmEmail />} />
          <Route path="forgot-password" element={<ForgetPassword />} />
          <Route path="reset-password">
            <Route path=":code" element={<ResetPassword />} />
          </Route>
         
        </Route>
        <Route path="*" element={<NotFound/>} />
      </Routes>
    </BrowserRouter>
  );
};

export default Application;
