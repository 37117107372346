import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  Checkbox,
  Divider,
  Heading,
  IconButton,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Skeleton,
  Stack,
  Tab,
  TabList,
  Tabs,
  Text,
  Tooltip,
  useDisclosure,
  useToast,
  WrapItem,
} from "@chakra-ui/react";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import {
  BiArrowBack,
  BiBlock,
  BiBriefcase,
  BiFilterAlt,
  BiLinkExternal,
  BiRadioCircleMarked,
  BiSearch,
  BiShowAlt,
  BiChevronDown,
  BiEdit,
  BiTrash,
} from "react-icons/bi";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";

import Input from "../../components/commons/Input/Input";
import { TableHeadType } from "../../components/modules/Table/Table.types";
import Table from "../../components/modules/Table/Table";
import { PageWrapper } from "../../utils/globalStyle";
import {
  fakeData as dumydata,
  PUBLIC_API_ENDPOINT,
} from "../../utils/globalTypes";
import ProductsFilterDrawer from "./Filters/ProductsFilterDrawer";
import { getDataFromCache } from "../../utils/cache";

import Categorie, { Category } from "../Categories/Categories";
import ConfirmDeleteDialog from "../../components/modules/ConfirmDeleteDialog/ConfirmDeleteDialog";
import { FormattedMessage, useIntl } from "react-intl";
import {
  productDataType,
  useFindAllProducts,
  usePatchOneProducts,
  useProductsDeleteOne,
} from "../../api/products";

const Products = () => {
  const [syn, setSyn] = useState(1);
  const [pagination, setPagination] = useState({
    count: 0,
    limit: 5,
    skip: 0,
    page: 1,
  });
  const location = useLocation();
  const {
    isOpen: openFilter,
    onOpen: onOpenFilter,
    onClose: onCloseFilter,
  } = useDisclosure();

  const [defaultFilterValues, setDefaultFilterValues] = useState({
    name: "",
    brand: "",
    type: "",
    category: {
      label: "",
      id: "",
    },
  });

  const handleProducts = () => {};
  const [search, setSearch] = useState("");
  const [status, setStatus] = useState("");
  const [category, setCategory] = useState();
  const [isAvliable, setIsAvliable] = useState();
  const [sortByName, setSortByName] = useState<1 | -1>(1);
  const [sortByPrice, setSortByPrice] = useState<1 | -1>(1);
  const [sortByCreated, setSortByCreated] = useState<1 | -1>(-1);
  const [sortByUpdated, setSortByUpdated] = useState<1 | -1>(-1);
  const { mutate, data, isLoading, isSuccess, isError, error } =
    useFindAllProducts();
  useEffect(() => {
    if(status===""){
    mutate({
      skip: pagination.skip,
      limit: pagination.limit,
      search: search,
     
      // category:category,
      // isAvliable:isAvliable,
      name: sortByName,
      price: sortByPrice,
      // status: status,
      createdAt: sortByCreated,
      updatedAt: sortByUpdated,
    });}else{  mutate({
      skip: pagination.skip,
      limit: pagination.limit,
      search: search,
     
      // category:category,
      // isAvliable:isAvliable,
      name: sortByName,
      price: sortByPrice,
      status: status,
      createdAt: sortByCreated,
      updatedAt: sortByUpdated,
    });}
  }, [pagination,search,status]);
  useEffect(() => {
    if (isSuccess) {
      // console.log("products", data.data.products);
      setProducts(data.data.products);
    }
  }, [isSuccess]);

  const handlePageChange = (dir: "next" | "prev") => {
    if (dir === "next") {
      setPagination((prevState) => {
        let temp = { ...prevState };
        temp.skip = temp.skip + temp.limit;
        return temp;
      });
    } else {
      setPagination((prevState) => {
        let temp = { ...prevState };
        temp.skip = temp.skip - temp.limit;
        return temp;
      });
    }
  };

  const handleLimitChange = (value: number) => {
    setPagination((prevState) => {
      let temp = { ...prevState };
      temp.limit = value;
      return temp;
    });
  };
  const handleResetFilter = () => {
    setFilters(undefined);
    setDefaultFilterValues({
      brand: "",
      category: {
        label: "",
        id: "",
      },
      name: "",
      type: "",
    });
  };
  const [filters, setFilters] = useState<any>();
  const handleFilter = (values: {
    name: string;
    type: string;
    category: {
      label: string;
      id: string;
    };
    brand: string;
  }) => {
    setDefaultFilterValues(values);
    setFilters({
      brand: values.brand || undefined,
      category: values.category || undefined,
      name: values.name || undefined,
      type: values.type || undefined,
    });
  };
  const navigate = useNavigate();

  const [products, setProducts] = useState<productDataType[]>([]);

  const [tabIndex, setTabIndex] = useState<number>(0);

  const { from } = (location?.state as { from?: string }) || "";
  const handleChangeTabIndex = (index: number) => {
    switch (index) {
      case 1: {
        setStatus("PENDING");
        break;
      }
      case 2: {
        setStatus("PUBLISHED");
        break;
      }
      case 3: {
        setStatus( "ARCHIVED");
        break;
      }
      default:
        setStatus("");
    }


    setTabIndex(index);
   
  };
  const toast = useToast();

  const renderProductStatus = (status: string | null | undefined) => {
    switch (status) {
      case "ARCHIVED":
        return <Text opacity={0.6}><FormattedMessage id={"archived"} />
        </Text>;
      case "DECLINED":
        return <Text color="red.300"><FormattedMessage id={"declined"} /></Text>;
      case "DRAFT":
        return <Text><FormattedMessage id={"draft"} /></Text>;
      case "PENDING":
        return <Text color="yellow.400"><FormattedMessage id={"requested"} /></Text>;
      case "PUBLISHED":
        return (
          <Stack
            direction="row"
            alignItems="center"
            spacing="2px"
            color="green.400"
          >
            <BiRadioCircleMarked />
            <Text><FormattedMessage id={"online"} /></Text>
          </Stack>
        );
    }
  };
  const {
    mutate: productsUpdateMutate,
    data: productsUpdateData,
    isLoading: productsUpdateIsLoading,
    isSuccess: productsUpdateIsSuccess,
    isError: productsUpdateIsError,
    error: productsUpdateError,
  } = usePatchOneProducts();
  useEffect(() => {
    if (productsUpdateIsSuccess)
      toast({
        title: "Product status has bean updates with sucsess",
        position: `bottom-right`,
        status: "success",
        isClosable: true,
      });
    handleProducts();
  }, [productsUpdateIsSuccess]);
  const handleChangeOrderStatus = (
    status: string,
    id: string,
    index: number
  ) => {
    productsUpdateMutate({
      id: id,
      catObj: { status: status },
    });

    setProducts((prevState) => {
      let temp = [...prevState];
      temp[index].status = status;

      return temp;
    });
  };
 

  const {
    mutate: deleteProductMutate,
    isSuccess: isSuccessdeleteProductMutate,
  } = useProductsDeleteOne();
  const handleDeleteProduct = (id: string) => {
    deleteProductMutate(id);

    if (isSuccess) {
      toast({
        title: "Product has been deleted with success.",
        status: "success",

        position: "bottom-right",
        duration: 5000,
        isClosable: true,
      });
      mutate({
        skip: pagination.skip,
        limit: pagination.limit,
        search: search,
        // category:category,
        // isAvliable:isAvliable,
        name: sortByName,
        price: sortByPrice,
        // status: status,
        createdAt: sortByCreated,
        updatedAt: sortByUpdated,
      });
    } else
      toast({
        title: "An error occurred while deleting product.",

        status: "error",

        position: "bottom-right",
        duration: 5000,
        isClosable: true,
      });
  };

  const [selectedProducts, setSelectedProducts] = useState<string[]>([]);

  const handleSelectProduct = (id: string) => {
    setSelectedProducts((prevState) => [...prevState, id]);
  };

  const handleRemoveSelectedProduct = (id: string) => {
    setSelectedProducts((prevState) => {
      let temp = [...prevState];
      const index = temp.indexOf(id, 0);
      if (index > -1) {
        temp.splice(index, 1);
      }
      return temp;
    });
  };
  const productsAdminTableData =
    (products &&
      products.map((product, index) => ({
        key: product._id,
        check: (
          <Checkbox
            value={product._id}
            onChange={(e) => {
              if (e.target.checked) {
                handleSelectProduct(e.target.value);
              } else {
                handleRemoveSelectedProduct(e.target.value);
              }
            }}
            isChecked={selectedProducts?.includes(product._id)}
          />
        ),
        image: (
          <Box className="table_image">
            <img
              src={
                product.images?.[0] ||
                "https://www.freeiconspng.com/thumbs/no-image-icon/no-image-icon-4.png"
              }
              alt="product_profile_picture"
            />
          </Box>
        ),
        name: product.name,

        category: product.category && product.category.name,

        basePrice: product.price.price + " TND",

        status: (
          product._id!=="63c40e7c0513c8014979ac77"?( <WrapItem>
            <Menu>
              <MenuButton
                as={Button}
                variant="link"
                rightIcon={<BiChevronDown size={18} />}
                height="fit-content"
              >
                <Heading size="md" fontWeight="semibold" fontSize="lg">
                  {renderProductStatus(products[index].status)}
                </Heading>
              </MenuButton>
              <MenuList>
                {["ARCHIVED", "DRAFT", "PENDING", "PUBLISHED"]
                  .filter((e) => e != product.status)
                  .map((status: string, i: number) => (
                    <MenuItem
                    key={i}
                      onClick={() =>
                        handleChangeOrderStatus(status, product._id, index)
                      }
                    >
                      {renderProductStatus(status)}
                    </MenuItem>
                  ))}
              </MenuList>
            </Menu>
          </WrapItem>):( <Text> {renderProductStatus(product.status)}</Text>)
         
        ),
        creationDate: moment(product.createdAt).format("lll"),

        action: (
          <Stack
            direction="row"
            display="inline-flex"
            width="fit-content"
            alignItems="center"
            justifyContent="flex-end"
          >
            {(product.status === "PUBLISHED" || product.status === "DRAFT") && (
              <>
                {product.status === "PUBLISHED" ? (
                  <Link
                    color="blue.300"
                    target="_blank"
                    href={`https://bitaqa.tn/home/products/${product.slug}`}
                  >
                    <Stack direction="row" alignItems="center" spacing="4px">
                      <Text whiteSpace="nowrap">See in store</Text>{" "}
                      <BiLinkExternal size={16} />
                    </Stack>
                  </Link>
                ) : (
                  <></>
                )}
              </>
            )}
            {product._id!=="63c40e7c0513c8014979ac77"?( <ConfirmDeleteDialog
              item="Produit"
              dialogButtonProps={{
                type: "IconButton",

                children: "Delete product",
                props: {
                  icon: <BiTrash size={18} />,
                  color: "red",
                  rounded: "base",
                },
              }}
              onDelete={() => handleDeleteProduct(product?._id)}
            />):(<></>)}
           
            <IconButton
              onClick={() => {
                navigate(`/products/${product._id}`);
              }}
              aria-label="editer le product"
              size="md"
              variant="ghost"
              color="green.400"
            >
              <BiEdit size={18} />
            </IconButton>
          </Stack>
        ),
      }))) ||
    [];
  const intl = useIntl();
  type ProductsAdminTableData = typeof productsAdminTableData[number];
  const [loading, setLoading] = useState(isLoading);
  const productsAdminTableHead: TableHeadType<ProductsAdminTableData>[] = [
    //{ accessor: "check", label: "" },
    { accessor: "image", label: "" },
    {
      accessor: "name",
      label: intl.formatMessage({
        id: "name",
      }),
    },

    {
      accessor: "category",
      label: intl.formatMessage({
        id: "nav.categories",
      }),
    },

    {
      accessor: "status",
      label: intl.formatMessage({
        id: "status",
      }),
    },
    {
      accessor: "creationDate",
      label: intl.formatMessage({
        id: "createdDate",
      }),
    },
    {
      accessor: "basePrice",
      label: intl.formatMessage({
        id: "basedPrice",
      }),
      headCellProps: { isNumeric: true },
    },
    { accessor: "action", label: "", headCellProps: { isNumeric: true } },
  ];

  const handleSearchSubmit = (event: any) => {
    if (event.code === "Enter") {
      // console.log(event.target.value);
      setSearch(event.target.value);
      mutate({
        skip: pagination.skip,
        limit: pagination.limit,
        search: search,
        // category:category,
        // isAvliable:isAvliable,
        name: sortByName,
        price: sortByPrice,
        // status: status,
        createdAt: sortByCreated,
        updatedAt: sortByUpdated,
      });
    }
  };

  const {
    isOpen: isOpenExportDialog,
    onOpen: onOpenExportDialog,
    onClose: onCloseExportDialog,
  } = useDisclosure();

  const cancelExportRef = useRef(null);

  return (
    <PageWrapper>
       <Stack direction={{ base: "column", md: "row" }} spacing="18px"className="page_header">
        <Box className="page_header_title">
         
          <Box className="page_header_title_content">
            <Heading fontSize="2xl">
              <FormattedMessage id={"product.liste"} />
            </Heading>
          </Box>
        </Box>

        {/* <Stack direction="row">
          <Button variant="outline" onClick={onOpenExportDialog}>
            {selectedProducts.length > 0 ? `Export selected` : "Export"}
          </Button>
        </Stack> */}
        <Stack direction="row">
          {/* <Button variant='outline'>Export</Button> */}
          <Button
            colorScheme="main"
            onClick={() =>
              navigate(`/products/create`, {
                state: {
                  from: location.pathname,
                  pageName: "nav.product",
                },
              })
            }
          >
            <FormattedMessage id={"product.create"} />
          </Button>
        </Stack>
      </Stack>
      {selectedProducts.length > 0 && (
        <Text fontSize="sm" opacity={0.6}>
          {selectedProducts.length} produits sélectionnés
        </Text>
      )}
      <Tabs
        colorScheme="blue"
        mb="14px"
        index={tabIndex}
        onChange={handleChangeTabIndex}
       
      >
        <TabList  overflowX="auto"
        overflowY= "hidden" 
       
        >
          <Tab>
            {intl.formatMessage({
              id: "all",
            })}
          </Tab>
          <Tab>
            {intl.formatMessage({
              id: "requested",
            })}
          </Tab>
          <Tab>
            {intl.formatMessage({
              id: "published",
            })}
          </Tab>
          <Tab>
            {intl.formatMessage({
              id: "declined",
            })}
          </Tab>
        </TabList>
      </Tabs>
      {/* )} */}
      <Box className="page_actions">
        <Input
          inputProps={{
            placeholder: intl.formatMessage({
              id: "productSearch",
            }),
            onKeyUp: handleSearchSubmit,
          }}
          inputLeftElementProps={{ children: <BiSearch /> }}
        />
        {/* <Divider orientation="vertical" /> */}
        {/* <Button
          variant="outline"
          leftIcon={<BiFilterAlt />}
          onClick={onOpenFilter}
        >
          <FormattedMessage id={"filtre"} />
        </Button> */}
      </Box>

      <Table
        head={productsAdminTableHead}
        data={productsAdminTableData}
        isLoading={loading}
        emptyState={
          <Box className="empty_table_container">
            <BiBriefcase size={42} />
            <Text fontSize="md" fontWeight="medium"></Text>
          </Box>
        }
        pagination={{
          count: data?.data.queryCount || 0,
          limit: data?.data.productsCount,
          skip: pagination.skip,
          onPageChange: handlePageChange,
          onChangeLimit: handleLimitChange,
        }}
      />
      {/* </Skeleton> */}
      <ProductsFilterDrawer
        isOpen={openFilter}
        onClose={onCloseFilter}
        handleSubmitFilter={handleFilter}
        defaultValues={defaultFilterValues}
        handleResetFilter={handleResetFilter}
      />
      <AlertDialog
        isOpen={isOpenExportDialog}
        leastDestructiveRef={cancelExportRef}
        onClose={onCloseExportDialog}
        isCentered
        size="lg"
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogCloseButton />
            <AlertDialogHeader
              borderBottomWidth="1px"
              fontSize="lg"
              fontWeight="bold"
            >
              Export products ?
            </AlertDialogHeader>

            <AlertDialogBody my="14px">
              Are you sure you want to export{" "}
              {selectedProducts.length > 0
                ? `${selectedProducts.length} ${
                    selectedProducts.length > 1 ? "Products" : "Product"
                  }`
                : "All products"}
            </AlertDialogBody>

            <AlertDialogFooter borderTopWidth="1px">
              <Button onClick={onCloseExportDialog}>Cancel</Button>
              <Button colorScheme="main" ml={3}>
                Export
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </PageWrapper>
  );
};

export default Products;
