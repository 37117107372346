export const setInCache = (key: string, data: any, crypt?: boolean) => {
  var CryptoJS = require("crypto-js");
  var AES = require("crypto-js-aes");
  if (crypt) {
    let cypherkey = CryptoJS.enc.Utf8.parse("b75524255a7f54d2726a951bb39204df");
    let iv = CryptoJS.enc.Utf8.parse("1583288699248111");

    let cry = CryptoJS.AES.encrypt(JSON.stringify(data), cypherkey, { iv: iv });
    localStorage?.setItem(key, cry.toString());
  } else {
    localStorage?.setItem(key, JSON.stringify(data));
  }
};

export const removeFromCache = (key: string) => localStorage?.removeItem(key);

export const getDataFromCache = (key: string, decrypt?: boolean) => {
  var CryptoJS = require("crypto-js");
  const data = localStorage?.getItem(key);
  if (data) {
    if (decrypt) {
      let cipher = CryptoJS.lib.CipherParams.create({
        ciphertext: CryptoJS.enc.Base64.parse(data),
      });

      let cypherkey = CryptoJS.enc.Utf8.parse(
        "b75524255a7f54d2726a951bb39204df"
      );
      let iv = CryptoJS.enc.Utf8.parse("1583288699248111");

      let dcry = CryptoJS.AES.decrypt(cipher, cypherkey, { iv: iv });
      return JSON.parse(dcry.toString(CryptoJS.enc.Utf8));
    }
    return JSON.parse(data);
  }

  return undefined;
};
