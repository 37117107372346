import { useToast } from "@chakra-ui/react";
import { useMutation, useQuery } from "@tanstack/react-query";
import {
  SETTINGS_FINF_ALL,
  SETTINGS_PATCH_ONE,
  SETTINGS_UPDATE_ONE,
} from "../endpoints/settings";
import { protectedGet, protectedPatch, protectedPut } from "../http-methods";
import { productDataType } from "../products";

export type SettingsDataType = {
  _id: string;
  shippingFees: string;
  seo: {
    _id: string;
    metatags: [];
  };
  bestSelling: productDataType[];
  ourProducts: productDataType[];
  gallery: {
    display: string;
    images: string[];
    _id: string;
  };
  reviews: {
    userName: string;
    userImage: string;
    company: string;
    review: string;
    _id: string;
  }[];
  partenrs: {
    partnerName: string;
    partnerLogo: string;
    _id: string;
  }[];
  moreInfo: {
    refundPolicy: string;
    privacyAndPolicy: string;
    termsAndConditions: string;
    shippingPolicy: string;
    contactInfromation: string;
    _id: string;
  };
  faq: {
    section: string;
    aq: {
      question: string;
      answer: string;
      _id: string;
    }[];
    showInHome: boolean;
    _id: string;
  }[];

  createdAt: string;
  updatedAt: string;
  __v: 0;
};
export type SittingsUpdateDataType = {
  shippingFees?: string;
  bestSelling?: string[];
  ourProducts?: string[];
  seo?: {
    metatags: [];
  };
  gallery?: {
    display: string;
    images: string[];
  };
  reviews?: {
    userName: string;
    userImage: string;
    company: string;
    review: string;
  }[];
  partenrs?: {
    partnerName: string;
    partnerLogo: string;
  }[];
  moreInfo?: {
    refundPolicy: string;
    privacyAndPolicy: string;
    termsAndConditions: string;
    shippingPolicy: string;
    contactInfromation: string;
  };
  faq?: {
    section: string;
    aq: {
      question: string;
      answer: string;
    }[];
    showInHome: boolean;
  }[];
};

export const useFindAllSettings = () => {
  return useQuery({
    queryKey: ["FindAllSettings"],
    queryFn: () => protectedGet(SETTINGS_FINF_ALL),
  });
};

export const useUpdateOneSettings = () => {
  return useMutation({
    mutationFn: ({ obj, id }: { obj: SittingsUpdateDataType; id: string }) => {
      return protectedPut(SETTINGS_UPDATE_ONE(id), obj);
    },
  });
};
export const usePatchOneSettings = () => {
  const toast = useToast();
  return useMutation({
    mutationFn: ({ obj, id }: { obj: SittingsUpdateDataType; id: string }) => {
      return protectedPatch(SETTINGS_PATCH_ONE(id), obj);
    },
    onError: (data: any) => {
      console.log("dataError", data.response.data.errors);
      const errors: any = data.response.data.errors;
      if (errors[0]) {
        toast({
          title: errors[0].msg,
          position: `bottom-right`,
          status: "error",
          isClosable: true,
        });
      } else if (errors.shippingFees) {
        toast({
          title: "shippingFees",
          description: errors.shippingFees.msg,
          position: `bottom-right`,
          status: "error",
          isClosable: true,
        });
      } else if (errors.bestSelling) {
        toast({
          title: "bestSelling",
          description: errors.bestSelling.msg,
          position: `bottom-right`,
          status: "error",
          isClosable: true,
        });
      } else if (errors.ourProducts) {
        toast({
          title: "ourProducts",
          description: errors.ourProducts.msg,
          position: `bottom-right`,
          status: "error",
          isClosable: true,
        });
      } else if (errors.seo) {
        toast({
          title: "seo",
          description: errors.seo.msg,
          position: `bottom-right`,
          status: "error",
          isClosable: true,
        });
      } else if (errors.gallery) {
        toast({
          title: "gallery",
          description: errors.gallery.msg,
          position: `bottom-right`,
          status: "error",
          isClosable: true,
        });
      } else if (errors.reviews) {
        toast({
          title: "reviews",
          description: errors.reviews.msg,
          position: `bottom-right`,
          status: "error",
          isClosable: true,
        });
      } else if (errors.partenrs) {
        toast({
          title: "partenrs",
          description: errors.partenrs.msg,
          position: `bottom-right`,
          status: "error",
          isClosable: true,
        });
      } else if (errors.moreInfo) {
        toast({
          title: "moreInfo",
          description: errors.moreInfo.msg,
          position: `bottom-right`,
          status: "error",
          isClosable: true,
        });
      } else if (errors.faq) {
        toast({
          title: "faq",
          description: errors.faq.msg,
          position: `bottom-right`,
          status: "error",
          isClosable: true,
        });
      }
    },
  });
};
