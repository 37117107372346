import {
	Box,
	FormLabel,
	Popover,
	PopoverContent,
	PopoverTrigger,
	Stack,
	useDisclosure,
} from "@chakra-ui/react";
import {  HexColorPicker }from  "react-colorful";
import Input from "../Input/Input";

type ColorPickerProps = {
	label?: string;
	color: string;
	setColor?: (newColor: string) => void;
};

const ColorPicker = (props: ColorPickerProps) => {
	const { label, color, setColor } = props;

	const { isOpen, onToggle, onClose } = useDisclosure();

	return (
		<Box w='full'>
			{label && <FormLabel marginBottom='1'>{label}</FormLabel>}
			<Stack direction='row' onClick={onToggle}>
				<Box w='fit-content'>
					<Popover isOpen={isOpen} onClose={onClose} placement='top'>
						<PopoverTrigger>
							<Box
								h='40px'
								w='40px'
								bgColor={color}
								border='1px'
								borderColor='gray.300'
								rounded={"md"}
							/>
						</PopoverTrigger>
						<PopoverContent w='fit-content'>
							<HexColorPicker color={color} onChange={setColor} />
						</PopoverContent>
					</Popover>
				</Box>
				<Input
					inputProps={{
						onClick: onToggle,
						ml: "1",
						value: color,
					}}
				/>
			</Stack>
		</Box>

	);
};

export default ColorPicker;
