import {
  Box,
  Heading,
  Text,
  Stack,
  useColorModeValue,
  Button,
  useToast,
  AlertIcon,
  Alert,
} from "@chakra-ui/react";
import { FormikErrors, useFormik } from "formik";
import { useEffect, useState } from "react";
import axios from "axios";
import {
  BiArrowBack,
  BiArrowFromRight,
  BiArrowToLeft,
  BiKey,
} from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import { Input } from "../../components/commons";
import { PUBLIC_API_ENDPOINT } from "../../utils/globalTypes";
import { useForgetPassword } from "../../api/auth";
// import { useForgetPasswordMutation } from "../../api/generated/graphql";
// import { Input } from "../../components";

const ForgetPassword = () => {
  const bgColor = useColorModeValue("gray.100", "gray.800");
  const wrapperBgColor = useColorModeValue("white", "gray.700");
  const { mutate, data, isLoading, isSuccess, isError, error } =
    useForgetPassword();
  const toast = useToast();
  const [forgetPasswordSuccess, setForgetPasswordSuccess] = useState(false);
  useEffect(() => {
    if (isSuccess === true) {
      window.location.href = "/authentication/login";
    }
  }, [isSuccess === true]);
  // const [forgetPasswordMutation] = useForgetPasswordMutation();

  const forgetPasswordFormik = useFormik({
    validateOnMount: false,
    validateOnBlur: false,
    validateOnChange: false,
    initialValues: {
      email: "",
    },
    validate: (values) => {
      const errors: FormikErrors<typeof values> = {};
      if (!values.email) {
        errors.email = "Email is required";
      } else if (
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
      ) {
        errors.email = "Invalid email address";
      }

      // console.log(errors);
      return errors;
    },
    onSubmit: (values, { setSubmitting }) => {
      // console.log("isError", isError);
      mutate({ email: values.email });
    },
  });

  const navigate = useNavigate();

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      height="100vh"
      width="100vw"
      bgColor={bgColor}
    >
      <form onSubmit={forgetPasswordFormik.handleSubmit}>
        <Stack
          spacing="16px"
          shadow="md"
          rounded={"base"}
          bgColor={wrapperBgColor}
          padding="32px"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Text color="main.400" p="6px" bgColor="#f6233315" rounded="full">
            <BiKey size={26} />
          </Text>
          <Box textAlign="center">
            <Heading fontSize="2xl">Forgot password?</Heading>
            <Text opacity={0.8}>
              No worries. we'll send you reset instructions.
            </Text>
          </Box>
          <Box
            w="100%"
            display="flex"
            flexDir={"column"}
            alignItems="center"
            justifyContent="center"
          >
            {forgetPasswordSuccess ? (
              <Alert mt="12px" status="success" rounded="base">
                <AlertIcon />
                An email has been sent to your inbox.
              </Alert>
            ) : (
              <>
                <Input
                  label="Email"
                  inputProps={{
                    placeholder: "Enter your email",
                    name: "email",
                    onChange: forgetPasswordFormik.handleChange,
                    value: forgetPasswordFormik.values.email,
                  }}
                  isError={forgetPasswordFormik?.errors?.email !== undefined}
                  errorMessage={forgetPasswordFormik?.errors?.email}
                />

                <Button
                  mt="12px"
                  isLoading={isLoading}
                  type="submit"
                  colorScheme="main"
                >
                  Reset password
                </Button>
              </>
            )}
            <Button
              mt="8px"
              variant="ghost"
              leftIcon={<BiArrowBack size={18} />}
              onClick={() =>
                navigate("/authentication/login", {
                  replace: true,
                })
              }
            >
              Back to log in
            </Button>
          </Box>
        </Stack>
      </form>
    </Box>
  );
};

export default ForgetPassword;
