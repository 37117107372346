import {
  Box,
  Button,
  color,
  Divider,
  FormLabel,
  Heading,
  Link,
  Radio,
  RadioGroup,
  Select,
  SimpleGrid,
  Skeleton,
  Stack,
  Stat,
  StatArrow,
  StatHelpText,
  StatLabel,
  StatNumber,
  Switch,
  Tag,
  Text,
  Textarea,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";
import { FormikErrors, useFormik } from "formik";
import { ReactNode, useEffect, useState } from "react";
import "react-quill/dist/quill.snow.css";
import ReactQuill from "react-quill";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import {
  ColorPicker,
  ImageUpload,
  Input,
  NumberInput,
  SelectInput,
} from "../../../components/commons";
import CategorySelectInput from "../../../components/modules/CategorySelectInput/CategorySelectInput";

import { FormattedMessage, useIntl } from "react-intl";
import {
  productDataType,
  usePatchOneProducts,
  useProductsCreateOne,
} from "../../../api/products";
import {
  ClientsDataType,
  usePatchOneClient,
  userLogDataType,
  useUserLinkLogFindAll,
  useUserLogFindAll,
} from "../../../api/Clients";
import { ImageContainer } from "../../../components/commons/ImageUpload/ImageUpload.styles";
import { AiOutlineEye } from "react-icons/ai";
import moment from "moment";
import { TableHeadType } from "../../../components/modules/Table/Table.types";
import Table from "../../../components/modules/Table/Table";
import { BiStore } from "react-icons/bi";
import { LinearScale } from "chart.js";
import {
  Chart as ChartJS,
  BarElement,
  CategoryScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { ChartData } from "chart.js/dist/types/index";
import { Line } from "react-chartjs-2";
import { FcMoneyTransfer } from "react-icons/fc";
import { TbClick } from "react-icons/tb";
import { GrFormView } from "react-icons/gr";

type UserLogFormProps = {
  loading?: boolean;

  userId?: string;
};
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const UserLogForm = (props: UserLogFormProps) => {
  const { userId, loading } = props;
  const past7Days = [6, 5, 4, 3, 2, 1, 0].map((index) => {
    const date = new Date();
    date.setDate(date.getDate() - index);

    return date.toDateString();
  });
  const [clickNumber, setClickNumber] = useState(0);
  const [ViewsNumber, setViewsNumber] = useState(0);
  const [clickList, setClickList] = useState<number[]>([]);
  const [ViewsList, setViewsList] = useState<number[]>([]);
  const {
    mutate: userLinkLogMutate,
    data: userLinkLogData,
    isLoading: userLinkLogIsLoading,
    isSuccess: userLinkLogIsSuccess,
    isError: userLinkLogIsError,
    error: userLinkLogError,
  } = useUserLinkLogFindAll();
  const { mutate, data, isLoading, isSuccess, isError, error } =
    useUserLogFindAll();
  useEffect(() => {
    userLinkLogMutate({
      user: userId,
    });
  }, [userId]);
  useEffect(() => {
    if (userLinkLogIsSuccess) {
      setClickList(userLinkLogData.data.data.clicks);
      setViewsList(userLinkLogData.data.data.views);
      setClickNumber(userLinkLogData.data.data.totalClicks);
      setViewsNumber(userLinkLogData.data.data.totalViews);
    }
  }, [userLinkLogIsSuccess]);
  const labels = past7Days;
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top" as const,
      },
      title: {
        display: true,
        text: "Statistiques",
      },
    },
    scales: {
      y: {
        min: 0,
      },
    },
  };
  const Chartdata = {
    labels,
    datasets: [
      {
        label: "Nombre de cliques",
        data: clickList,
        borderColor: "rgb(255, 99, 132)",
        backgroundColor: "rgba(255, 99, 132, 0.5)",
      },
      {
        label: "Nombre de vues",
        data: ViewsList,
        borderColor: "rgb(53, 162, 235)",
        backgroundColor: "rgba(53, 162, 235, 0.5)",
      },
    ],
  };
  //   const [Chartdata, setData] = useState<ChartData>({
  //   labels: labels,
  //   datasets: [{
  //     label: 'Expenses by Month',
  //     data: [65, 59, 80, 81, 56, 55, 40],
  //     backgroundColor: [
  //       'rgb(153, 102, 255)'
  //     ],
  //     borderColor: [
  //       'rgb(153, 102, 255)'
  //     ],
  //     borderWidth: 1
  //   }]
  // });

  const toast = useToast();

  const [logList, setLogList] = useState<userLogDataType[]>([]);
  const [pagination, setPagination] = useState({
    count: 0,
    limit: 5,
    skip: 0,
    page: 1,
  });
  const handlePageChange = (dir: "next" | "prev") => {
    if (dir === "next") {
      setPagination((prevState) => {
        let temp = { ...prevState };
        temp.skip = temp.skip + temp.limit;
        temp.page = temp.page + 1;
        return temp;
      });
    } else {
      setPagination((prevState) => {
        let temp = { ...prevState };
        temp.skip = temp.skip - temp.limit;
        temp.page = temp.page - 1;
        return temp;
      });
    }
  };
  const handleLimitChange = (value: number) => {
    setPagination((prevState) => {
      let temp = { ...prevState };
      temp.limit = +value;
      return temp;
    });
  };

  useEffect(() => {
    mutate({
      skip: pagination.skip,
      limit: pagination.limit,
      user: userId,
      
    });
  }, [pagination.limit, pagination.skip, userId]);
  useEffect(() => {
    if (isSuccess) {
      setLogList(data.data.logs);
    }
  }, [isSuccess]);

  const modules = {
    toolbar: [
      [{ header: "1" }, { header: "2" }, { font: [] }],
      [{ size: [] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link"],
    ],
    clipboard: {
      matchVisual: false,
    },
  };

  const location = useLocation();
  const navigate = useNavigate();

  const intl = useIntl();
  const borderColor = useColorModeValue("gray.100", "gray.700");
  const { from } = (location?.state as { from?: string }) || "";
  const LogsTableData =
    (logList &&
      logList.map((log, index) => ({
        key: log._id,

        ip: log.ip,
        deviceType: log.deviceType,
        os: log.os,
        browserType: log.browserType,
        browser: log.browser,
        region: log.region,
        date: moment(log.createdAt).format("lll"),
      }))) ||
    [];
  type LogsTableData = typeof LogsTableData[number];
  const LogsTableHead: TableHeadType<LogsTableData>[] = [
    {
      accessor: "ip",
      label: intl.formatMessage({
        id: "adresseIP",
      }),
    },

    {
      accessor: "deviceType",
      label: intl.formatMessage({
        id: "deviceType",
      }),
    },

    {
      accessor: "os",
      label: intl.formatMessage({
        id: "os",
      }),
    },
    {
      accessor: "browserType",
      label: intl.formatMessage({
        id: "browserType",
      }),
    },
    {
      accessor: "browser",
      label: intl.formatMessage({
        id: "browser",
      }),
    },
    {
      accessor: "region",
      label: intl.formatMessage({
        id: "region",
      }),
    },
    {
      accessor: "date",
      label: intl.formatMessage({
        id: "createdDate",
      }),
    },
  ];
  return (
    <Stack>
      <Stack
        direction={"column"}
        spacing="14px"
        //border="1px solid red"
        alignItems="flex-start"
        mb={"30px"}
      >
        {" "}
        <Stack
          direction={{ base: "column", md: "row" }}
          spacing="14px"
          //border="1px solid red"
          alignItems="flex-start"
          placeSelf={"center"}
          placeItems={"center"}
        >
           <Box
          height={"167px"}
          mt="3"
          minW="200px"
          mb="3"
          display="flex"
          alignContent={"center"}
          alignItems={"center"}
          justifyContent="space-between"
          p="14px 18px"
          border="1px"
          borderColor="white"
          rounded="md"
          boxShadow="md"
          transition="0.1s ease-in all"
          _hover={{
            
            cursor: "pointer",
            boxShadow: "0 0 0 1px #0b5eda",
            borderColor: "#0b5eda",
          }}
        >  <Stat>
        <StatLabel>Cliques</StatLabel>
        <StatNumber>{clickNumber}</StatNumber>
       
      </Stat>{" "}
      <TbClick size={50} />
      </Box>
         <Box
          height={"167px"}
          mt="3"
          mb="3"
          minW="200px"
          display="flex"
          alignContent={"center"}
          alignItems={"center"}
          justifyContent="space-between"
          p="14px 18px"
          border="1px"
          borderColor="white"
          rounded="md"
          boxShadow="md"
          transition="0.1s ease-in all"
          _hover={{
           
            cursor: "pointer",
            boxShadow: "0 0 0 1px #0b5eda",
            borderColor: "#0b5eda",
          }}
        >  <Stat>
        <StatLabel>Vues</StatLabel>
        <StatNumber>{ViewsNumber}</StatNumber>
        
      </Stat>{" "}
      <AiOutlineEye size={50} />
      </Box>
         
        
        </Stack>
        <Line options={options} data={Chartdata} />
       
      </Stack>

      <Table
        head={LogsTableHead}
        data={LogsTableData}
        isLoading={isLoading}
        emptyState={
          <Box className="empty_table_container">
            <BiStore size={42} />
            <Text fontSize="md" fontWeight="medium">
              {data?.data.queryCount
                ? "There is no Logs with these filters"
                : "There is no stores."}
            </Text>
          </Box>
        }
        pagination={{
          count: data?.data.queryCount,
          limit: pagination.limit,
          skip: pagination.skip,
          onPageChange: handlePageChange,
          onChangeLimit: handleLimitChange,
        }}
      />
    </Stack>
  );
};

export default UserLogForm;
