import {
  background,
  Badge,
  Box,
  Button,
  Checkbox,
  color,
  Divider,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  IconButton,
  Link,
  Menu,
  MenuButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Popover,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  Skeleton,
  Stack,
  Switch,
  Text,
  useColorModeValue,
  useDisclosure,
  useToast,
  WrapItem,
} from "@chakra-ui/react";

import { FormikErrors, useFormik } from "formik";
import moment from "moment";
import { ReactNode, useEffect, useState } from "react";
import {
  BiAddToQueue,
  BiArrowBack,
  BiBriefcase,
  BiBriefcaseAlt,
  BiChevronDown,
  BiFilterAlt,
  BiLinkExternal,
  BiSearch,
  BiSlider,
} from "react-icons/bi";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { AiOutlineEye } from "react-icons/ai";
import { FormattedMessage, useIntl } from "react-intl";
import { useLocation } from "react-router-dom";
import {
  InventorysDataType,
  useFindAllInventorys,
  useInventorysCreateOne,
  usePatchOneInventorys,
} from "../../api/inventory";
import { productDataType, useFindAllProducts } from "../../api/products";
import { Input } from "../../components/commons";
import Table from "../../components/modules/Table/Table";
import { TableHeadType } from "../../components/modules/Table/Table.types";
import { PageWrapper } from "../../utils/globalStyle";

const Inventorys = () => {
  const {
    mutate: generateInventorysMutate,
    data: generateInventorysData,
    isLoading: generateInventorysIsLoading,
    isSuccess: generateInventorysIsSuccess,
    isError: generateInventorysIsError,
    error: generateInventorysError,
  } = useInventorysCreateOne();
  const {
    mutate: findAllInventorysMutate,
    data: findAllInventorysData,
    isLoading: findAllInventorysIsLoading,
    isSuccess: findAllInventorysIsSuccess,
    isError: findAllInventorysIsError,
    error: findAllInventorysError,
  } = useFindAllInventorys();
  const {
    mutate: inventorysUpdateMutate,
    data: inventorysUpdateData,
    isLoading: inventorysUpdateIsLoading,
    isSuccess: inventorysUpdateIsSuccess,
    isError: inventorysUpdateIsError,
    error: inventorysUpdateError,
  } = usePatchOneInventorys();
  const [inventorys, setInventorys] = useState<InventorysDataType[]>([]);
  const location = useLocation();
  const [selectedInventorys, setSelectedInventorys] = useState<string[]>([]);
  const [selectedproduct, setSelectedproduct] =
    useState<productDataType | null>(null);
  const handleSelectIventory = (id: string) => {
    setSelectedInventorys((prevState) => [...prevState, id]);
  };

  const handleRemoveSelectedIventory = (id: string) => {
    setSelectedInventorys((prevState) => {
      let temp = [...prevState];
      const index = temp.indexOf(id, 0);
      if (index > -1) {
        temp.splice(index, 1);
      }
      return temp;
    });
  };
  const handleSelectProduct = (product: productDataType | null) => {
    setSelectedproduct(product);
  };
  const [productID, setProductID] = useState("0");
  const [pagination, setPagination] = useState({
    count: 0,
    limit: 5,
    skip: 0,
    page: 1,
  });
  const handlePageChange = (dir: "next" | "prev") => {
    if (dir === "next") {
      setPagination((prevState) => {
        let temp = { ...prevState };
        temp.skip = temp.skip + temp.limit;
        return temp;
      });
    } else {
      setPagination((prevState) => {
        let temp = { ...prevState };
        temp.skip = temp.skip - temp.limit;
        return temp;
      });
    }
  };

  const handleLimitChange = (value: number) => {
    setPagination((prevState) => {
      let temp = { ...prevState };
      temp.limit = value;
      return temp;
    });
  };
  useEffect(() => {
    if (findAllInventorysIsSuccess) {
      setInventorys(findAllInventorysData.data.inventorys);
    }
  }, [findAllInventorysIsSuccess]);
  useEffect(() => {
    if (generateInventorysIsSuccess) {
      toast({
        title: "Inventaires crées avec succés",
        position: `bottom-right`,
        status: "success",
        isClosable: true,
      });
      findAllInventorysMutate({
        // product: productID,
        // createdAt: sortByCreated,
        skip: pagination.skip,
        limit: pagination.limit,
      });
      inventoryProductFormik.setFieldValue("quantite", 0);
      setSelectedproduct(null);
    }
  }, [generateInventorysIsSuccess]);
  const inventorysAdminTableData =
    (inventorys &&
      inventorys.map((inventory, index) => ({
        key: inventory._id,
        check: (
          <Checkbox
            value={inventory._id}
            onChange={(e) => {
              if (e.target.checked) {
                handleSelectIventory(e.target.value);
              } else {
                handleRemoveSelectedIventory(e.target.value);
              }
            }}
            isChecked={selectedInventorys?.includes(inventory._id)}
          />
        ),
        refId: inventory.refId,
        code: inventory.code,

        url: (
          <Link href={inventory.url} isExternal>
            <BiLinkExternal size={14} />
          </Link>
        ),
        from: inventory.from,

        creationDate: moment(inventory.createdAt).format("lll"),
        active: (
          <Stack direction="row" alignItems="center">
            {inventory.isActive ? (
              <Badge variant="subtle" colorScheme="green">
                <FormattedMessage id={"active"} />
              </Badge>
            ) : (
              <Badge variant="subtle" colorScheme="red">
                <FormattedMessage id={"notActive"} />
              </Badge>
            )}
          </Stack>
        ),
        used: (
          <Stack direction="row" alignItems="center">
            {inventory.isUsed ? (
              <Badge variant="subtle" colorScheme="green">
                <FormattedMessage id={"used"} />
              </Badge>
            ) : (
              <Badge variant="subtle" colorScheme="red">
                <FormattedMessage id={"notUsed"} />
              </Badge>
            )}
          </Stack>
        ),
        printed: (
          <Stack direction="row" alignItems="center">
            <Switch
              size="md"
              colorScheme="green"
              name="isPrinted"
              onChange={(v) => {
                // console.log("chaked", inventory._id," name: ",inventory.code, " result", v.target.checked  );
                inventorysUpdateMutate({
                  id: inventory._id,
                  catObj: { isPrinted: v.target.checked },
                });
                if (inventorysUpdateIsSuccess) {
                  setInventorys((prevState) => {
                    let temp = [...prevState];
                    temp[index].isPrinted = v.target.checked;

                    return temp;
                  });
                  toast({
                    title:
                      "status d'impresssion de " +
                      inventory.code +
                      " changé avec succés",
                    position: `bottom-right`,
                    status: "success",
                    isClosable: true,
                  });
                }
              }}
              isChecked={inventory.isPrinted}
            />
          </Stack>
        ),
        product: inventory.product ? (
          <Link
            color="blue.300"
            target="_blank"
            href={`https://bitaqa.tn/home/products/${inventory.product.slug}`}
          >
            <Stack direction="row" alignItems="center" spacing="4px">
              <Text whiteSpace="nowrap">
                <FormattedMessage id={"consult"} />
              </Text>{" "}
              <AiOutlineEye size={16} />
            </Stack>
          </Link>
        ) : (
          <></>
        ),
      }))) ||
    [];
  function isNumeric(value: string) {
    return /^-?\d+$/.test(value);
  }
  const [search, setSearch] = useState("");
  const intl = useIntl();

  type ProductsInventorysTableData = typeof inventorysAdminTableData[number];
  const productsInventorysTableHead: TableHeadType<ProductsInventorysTableData>[] =
    [
      //{ accessor: "check", label: "" },

      {
        accessor: "refId",
        label: intl.formatMessage({
          id: "refId",
        }),
      },
      {
        accessor: "code",
        label: intl.formatMessage({
          id: "code",
        }),
      },
      {
        accessor: "url",
        label: intl.formatMessage({
          id: "url",
        }),
      },
      {
        accessor: "from",
        label: intl.formatMessage({
          id: "from",
        }),
      },
      {
        accessor: "product",
        label: intl.formatMessage({
          id: "product",
        }),
      },
      {
        accessor: "creationDate",
        label: intl.formatMessage({
          id: "createdDate",
        }),
      },
      {
        accessor: "active",
        label: intl.formatMessage({
          id: "activity",
        }),
      },

      {
        accessor: "printed",
        label: intl.formatMessage({
          id: "toPrint",
        }),
      },
    ];
  const {
    isOpen: isSelectProduct,
    onOpen: onSelectProduct,
    onClose: onCloseSelectProdct,
  } = useDisclosure();
  const borderColor = useColorModeValue("gray.100", "gray.700");

  const toast = useToast();

  useEffect(() => {
    findAllInventorysMutate({
      // createdAt: sortByCreated,
      search: search,
      skip: pagination.skip,
      limit: pagination.limit,
    });
  }, [productID, pagination, search]);

  // const [updateInventoryMutation] = useUpdateProductsInventoryByIdMutation();

  const inventoryProductFormik = useFormik({
    validateOnMount: false,
    validateOnBlur: false,
    validateOnChange: false,
    initialValues: { quantite: "0" },
    validate: (values) => {
      const errors: FormikErrors<typeof values> = {};
      if (!values.quantite || parseInt(values.quantite) < 1) {
        errors.quantite = intl.formatMessage({
          id: "inventory.error.quantity",
        });
      }
      if (isNumeric(values.quantite) === false) {
        errors.quantite = intl.formatMessage({
          id: "inventory.error.quantity.type",
        });
      }
      return errors;
    },
    onSubmit: (values, { setSubmitting }) => {
      if (selectedproduct !== null) {
        generateInventorysMutate({
          qt: parseInt(values.quantite),
          obj: { product: selectedproduct?._id ? selectedproduct?._id : "" },
        });
      } else {
        toast({
          title: intl.formatMessage({
            id: "inventory.selectedProduct",
          }),
          status: "warning",
          position: `bottom-right`,

          isClosable: true,
        });
      }
    },
  });
  const [sortByCreated, setSortByCreated] = useState<1 | -1>(-1);
  const handleSearchSubmit = (event: any) => {
    if (event.code === "Enter") {
      // console.log(event.target.value);
      setSearch(event.target.value);
      findAllInventorysMutate({
        // product: productID,
        // createdAt: sortByCreated,
        skip: pagination.skip,
        limit: pagination.limit,
        search: search,
      });
    }
  };
  const navigate = useNavigate();
  const { from } = (location?.state as { from?: string }) || "";
  return (
    <PageWrapper>
      <Box className="page_header">
        <Box className="page_header_title"></Box>

        {/* <Stack direction="row">
          <Button variant="outline" onClick={onOpenExportDialog}>
            {selectedProducts.length > 0 ? `Export selected` : "Export"}
          </Button>
        </Stack> */}
      </Box>
      {selectedInventorys.length > 0 && (
        <Text fontSize="sm" opacity={0.6}>
          {selectedInventorys.length}
          <FormattedMessage id={"products.select"} />
        </Text>
      )}

      <Grid
        mt="3"
        mb="3"
        display="flex"
        alignContent={"center"}
        alignItems={"center"}
        justifyContent="space-between"
        p="14px 18px"
        border="1px"
        borderColor="white"
        rounded="md"
        boxShadow="md"
        transition="0.1s ease-in all"
        _hover={{
          // backgroundColor: "gray.50",
          cursor: "pointer",
          boxShadow: "0 0 0 1px #0b5eda",
          borderColor: "#0b5eda",
        }}
      >
        <Stack direction={"column"} spacing="25px" width={"100%"}>
          <Stack direction={"row"} spacing="25px" width={"100%"}>
            <Heading fontSize="xl">
              <FormattedMessage id={"inventory.generate"} />
            </Heading>
          </Stack>
          <Stack
            direction={{ base: "column", md: "row" }}
            spacing="25px"
            width={"100%"}
            align="center"
          >
            {" "}
            <Input
              inputProps={{
                name: intl.formatMessage({
                  id: "quantite",
                }),
                maxW: "300px",
                placeholder: "Nombre d'inventaires à génerer",
                value: inventoryProductFormik.values.quantite,
                onChange: inventoryProductFormik.handleChange,
              }}
              label={intl.formatMessage({
                id: "quantite",
              })}
              errorMessage={inventoryProductFormik.errors.quantite}
              isError={inventoryProductFormik.errors.quantite !== undefined}
            />
            <Button
              // colorScheme="blue"

              ml={0}
              variant="outline"
              onClick={() => {
                onSelectProduct();
              }}
              w="-webkit-fill-available"
            >
              {" "}
              <Text fontSize="lg" color="main">
                {" "}
                {selectedproduct == null ? (
                  <FormattedMessage id={"products.notSelect"} />
                ) : (
                  selectedproduct.name
                )}
              </Text>
            </Button>
            {/*            
              <Button
              colorScheme="blue"
            //   mt={{ base: 2, xl: "auto !important" }}
              minW={"200px"}
              variant="outline"
              leftIcon={<BiAddToQueue size={18} />}
              // _hover={{
              //   backgroundColor: "gray.100",
              // }}
              _focus={{
                backgroundColor: "gray.100",
              }}
              onClick={() => {
                onSelectProduct();
              }}
            >
              Select product
            </Button> */}
            <Button
              //   mt={{ base: 2, xl: "auto !important" }}
              // colorScheme="main"
              textColor="main"
              variant="outline"
              // _hover={{
              //   backgroundColor: "gray.100",
              // }}

              isLoading={generateInventorysIsLoading}
              onClick={() => inventoryProductFormik.handleSubmit()}
              w="-webkit-fill-available"
            >
              <FormattedMessage id={"inventory.generate"} />
            </Button>{" "}
          </Stack>
        </Stack>
      </Grid>

      <Box className="page_header_title_content" mb={5}>
        <Heading fontSize="2xl">Iventory list</Heading>
      </Box>
      <Table
        head={productsInventorysTableHead}
        data={inventorysAdminTableData}
        isLoading={findAllInventorysIsLoading}
        emptyState={
          <Box className="empty_table_container">
            <BiBriefcase size={42} />
            <Text fontSize="md" fontWeight="medium"></Text>
          </Box>
        }
        pagination={{
          count: findAllInventorysData?.data.queryCount || 0,
          limit: pagination.limit,
          skip: pagination.skip,
          onPageChange: handlePageChange,
          onChangeLimit: handleLimitChange,
        }}
      />
      <SelectProductModal
        isOpen={isSelectProduct}
        onClose={onCloseSelectProdct}
        selectProduct={handleSelectProduct}
        product={selectedproduct}
      />
    </PageWrapper>
  );
};

export default Inventorys;
type ProductSelectModalProps = {
  isOpen: boolean;
  onClose: () => void;
  selectProduct: (products: productDataType | null) => void;
  product: productDataType | null;
};
const SelectProductModal = (props: ProductSelectModalProps) => {
  const { isOpen, onClose, selectProduct, product } = props;
  const { mutate, data, isLoading, isSuccess, isError, error } =
    useFindAllProducts();

  const handleSelectProduct = () => {
    selectProduct(selectedProduct);
    onClose();
  };
  const [productList, setProductList] = useState<productDataType[]>();
  const [search, setSearch] = useState("");

  const [sortByName, setSortByName] = useState<1 | -1>(1);
  const [sortByPrice, setSortByPrice] = useState<1 | -1>(1);
  const [sortByCreated, setSortByCreated] = useState<1 | -1>(-1);
  const [sortByUpdated, setSortByUpdated] = useState<1 | -1>(-1);
  const [pagination, setPagination] = useState({
    limit: 10,
    skip: 0,
  });
  useEffect(() => {
    mutate({
      skip: pagination.skip,
      limit: pagination.limit,
      search: search,
      // category:category,
      // isAvliable:isAvliable,
      name: sortByName,
      price: sortByPrice,
      // status: status,
      // createdAt: sortByCreated,
      updatedAt: sortByUpdated,
    });
  }, [pagination]);
  useEffect(() => {
    if (isSuccess) {
      setProductList(
        data.data.products.filter(
          (i: any) => i._id != "63c40e7c0513c8014979ac77"
        )
      );
    }
  }, [isSuccess, pagination]);

  const handlePageChange = (dir: "next" | "prev") => {
    if (dir === "next") {
      setPagination((prevState) => {
        let temp = { ...prevState };
        temp.skip = temp.skip + temp.limit;
        return temp;
      });
    } else {
      setPagination((prevState) => {
        let temp = { ...prevState };
        temp.skip = temp.skip - temp.limit;
        return temp;
      });
    }
  };

  const handleLimitChange = (value: number) => {
    setPagination((prevState) => {
      let temp = { ...prevState };
      temp.limit = +value;
      return temp;
    });
  };

  const [selectedProduct, setSelectedProduct] =
    useState<productDataType | null>(product);

  useEffect(() => {
    setSelectedProduct(product);
  }, [product]);
  // console.log("selectedItem", selectedProduct);

  const handleRemoveSelectedProduct = (id: string) => {
    setSelectedProduct(null);
  };

  const productsToSelectTableData =
    (productList &&
      productList?.map((item, index) => ({
        id: item._id,
        check: (
          <Checkbox
            value={item._id}
            isChecked={Boolean(selectedProduct === item)}
            onChange={(e) => {
              if (e.target.checked) {
                setSelectedProduct(item);
              } else {
                handleRemoveSelectedProduct(e.target.value);
              }
            }}
          />
        ),
        name: item.name,

        category: item.category && item.category.name,

        basePrice: item.price.price + " TND",
      }))) ||
    [];

  type ProductsToSelectTableType = typeof productsToSelectTableData[number];

  const productsToSelectTableHead: TableHeadType<ProductsToSelectTableType>[] =
    [
      { accessor: "check", label: "" },
      { accessor: "name", label: "Name" },

      { accessor: "category", label: "Category" },
      { accessor: "basePrice", label: "Base Price" },
    ];

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      scrollBehavior="inside"
      size="2xl"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader borderBottomWidth="1px">
          <FormattedMessage id={"product.select"} />
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Stack spacing="14px" py="14px">
            <Heading size="md">
              <FormattedMessage id={"product.select"} />
            </Heading>
            <Box>
              {selectedProduct && (
                <Text fontSize="sm" opacity={0.6}>
                  {selectedProduct.name} <FormattedMessage id={"selected"} />
                </Text>
              )}
              <Table
                head={productsToSelectTableHead}
                data={productsToSelectTableData}
                // isLoading={productToSelectListLoading}
                emptyState={
                  <Box className="empty_table_container">
                    <BiBriefcaseAlt size={42} />
                    <Text fontSize="md" fontWeight="medium">
                      <FormattedMessage id={"products.notSelect"} />
                    </Text>
                  </Box>
                }
                pagination={{
                  count: data?.data.queryCount - 1,
                  limit: data?.data.productsCount - 1,
                  skip: pagination.skip,
                  onPageChange: handlePageChange,
                  onChangeLimit: handleLimitChange,
                }}
              />
            </Box>
          </Stack>
        </ModalBody>

        <ModalFooter borderTopWidth="1px">
          <Button variant="Outline" onClick={onClose}>
            <FormattedMessage id={"role.update.btn.cancel"} />
          </Button>
          <Button colorScheme="main" onClick={() => handleSelectProduct()}>
            <FormattedMessage id={"validate"} />
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
