import { Box, useMediaQuery } from "@chakra-ui/react";
import { FC, useEffect, useState } from "react";
import {
  BiArchiveIn,
  BiBook,
  BiBriefcaseAlt,
  BiCog,
  BiCube,
  BiFace,
  BiGift,
  BiGridAlt,
  BiHome,
  BiLayer,
  BiLink,
  BiPhotoAlbum,
  BiShieldQuarter,
  BiStation,
  BiStore,
  BiTransferAlt,
  BiUser,
} from "react-icons/bi";
import { GrUserManager } from "react-icons/gr";
import { Navigate, useLocation } from "react-router-dom";
import AppBar from "../AppBar/AppBar";
import { Outlet } from "react-router-dom";
import Navigation from "../../modules/Navigation/Navigation";
import { NavigationPropsType } from "../Navigation/Navigation.types";
import { LayoutWrapper } from "./Layout.styles";
import { IApplicationProps } from "../../../App";
import { getDataFromCache } from "../../../utils/cache"
import {MdInventory, MdOutlineContacts } from "react-icons/md";
import { AppContext } from "../../../context/appContext";
import { useContext } from "react";
const getNavigation = (isMdUp: boolean): NavigationPropsType["items"] => {
  // console.log("for some wired reason", permission);
  return [
    {
      Icon: <BiHome size={isMdUp ? "18" : "22"} />,
      href: "/",
      label: "nav.dashbord",
    },
    {
      Icon: <BiUser size={isMdUp ? "18" : "22"} />,
      href: "/users",
      label: "nav.users",
    },
    {
      Icon: <BiLink size={isMdUp ? "18" : "22"} />,
      href: "/socialLinks",
      label: "nav.socialLinks",
    },
    {
      Icon: <BiLayer size={isMdUp ? "18" : "22"} />,
      href: "/categories",
      label: "nav.categories",
    },
    {
      Icon: <BiBriefcaseAlt size={isMdUp ? "18" : "22"} />,
      href: "/products",
      label: "nav.product",
    },
    {
      Icon: <MdInventory size={isMdUp ? "18" : "22"} />,
      href: "/inventorys",
      label: "nav.inventory",
    },
    {
      Icon: <BiGift size={isMdUp ? "18" : "22"} />,
      href: "/coupons",
      label: "nav.coupons",
    },
    {
      Icon: <BiArchiveIn size={isMdUp ? "18" : "22"} />,
      href: "/orders",
      label: "nav.orders",
    },
    {
      Icon: <MdOutlineContacts size={isMdUp ? "18" : "22"} />,
      href: "/contacts",
      label: "nav.contacts",
    },
    "SEPERATOR",
    {
      Icon: <BiCog size={isMdUp ? "18" : "22"} />,
      href: "/settings",
      label: "nav.settings",
    },
    {
      Icon: <BiBook size={isMdUp ? "18" : "22"} />,
      href: "/blogs",
      label: "nav.blogs",
    },
  ];
};

const Layout: FC<IApplicationProps> = (props) => {
  const [isAuth, setIsAuth] = useState(getDataFromCache("accessToken", true));
  const [isMdUp] = useMediaQuery("(min-width: 62em)");
  // const location = useLocation();
  const { language } = useContext(AppContext);
  const navList = getNavigation(isMdUp);
  // useEffect(() => {
  //   ChekToken();
  // }, []);

  // if (location.pathname.includes("settings")) return <Box>children</Box>;
  if(isAuth){
  return (
    <LayoutWrapper dir={language === "ar" ? "rtl" : undefined}>
      <Navigation items={navList} />
      <Box className="app_container">
        <AppBar />
        <Box className="app_content">
          <Outlet />
        </Box>
      </Box>
    </LayoutWrapper>
  );}else
  return <Navigate to="authentication/login"/>;
};

export default Layout;
