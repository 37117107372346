import {
  Box,
  Link,
  Text,
  Heading,
  useToast,
  Stack,
  Button,
  Tabs,
  TabList,
  Tab,
  Divider,
  useDisclosure,
  Tooltip,
  IconButton,
  Menu,
  MenuItem,
  MenuButton,
  MenuList,
  MenuDivider,
  Spinner,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  FormLabel,
  ModalFooter,
  Image,
  FormControl,
  Textarea,
  FormErrorMessage,
} from "@chakra-ui/react";
import axios from "axios";
import { FormikErrors, useFormik } from "formik";
import moment from "moment";
import { useEffect, useState } from "react";
import { AiOutlineEnter, AiOutlineEye } from "react-icons/ai";
import {
  BiArrowBack,
  BiBlock,
  BiCheckCircle,
  BiChevronDown,
  BiDotsHorizontalRounded,
  BiEdit,
  BiFilterAlt,
  BiHide,
  BiLinkExternal,
  BiRadioCircleMarked,
  BiSearch,
  BiShow,
  BiShowAlt,
  BiStore,
  BiTrash,
} from "react-icons/bi";
import { FcVip } from "react-icons/fc";
import { RiVipCrownLine, RiVipDiamondLine, RiVipLine } from "react-icons/ri";
import { FormattedMessage, useIntl } from "react-intl";
import ReactQuill from "react-quill";
import { Link as RouterLink, useLocation, useNavigate } from "react-router-dom";
import {
  ClientsDataType,
  useClientsCreateOne,
  useClientsFindAll,
  useUsersDeleteOne,
} from "../../api/Clients";

import { picturePlaceholder } from "../../assets";
import { ImageUpload, Input } from "../../components/commons";
import ConfirmDeleteDialog from "../../components/modules/ConfirmDeleteDialog/ConfirmDeleteDialog";
import Table from "../../components/modules/Table/Table";
import { TableHeadType } from "../../components/modules/Table/Table.types";
import { getDataFromCache } from "../../utils/cache";

import { PageWrapper } from "../../utils/globalStyle";
import { PUBLIC_API_ENDPOINT } from "../../utils/globalTypes";

const Clients = () => {
  const {
    isOpen: isOpenUserModal,
    onOpen: onOpenUserModal,
    onClose: onCloseUserModal,
  } = useDisclosure();
  const {
    mutate: DeleteOneUserMutate,
    data: DeleteOneUserData,
    isLoading: DeleteOneUserIsLoading,
    isSuccess: DeleteOneUserIsSuccess,
    isError: DeleteOneUserIsError,
    error: DeleteOneUserError,
  } = useUsersDeleteOne();
  const location = useLocation();

  const toast = useToast();

  const navigate = useNavigate();
  const [clients, setClients] = useState<ClientsDataType[]>([]);

  const handleClients = () => {};
  const { from } = (location?.state as { from?: string }) || "";

  const [tabIndex, setTabIndex] = useState<number>(0);

  const [status, setStatus] = useState<string>();

  const handleChangeTabIndex = (index: number) => {
    switch (index) {
      case 1: {
        setStatus("DRAFT");
        break;
      }
      case 2: {
        setStatus("PUBLISHED");
        break;
      }
      case 3: {
        setStatus("DECLINED");
        break;
      }
      default:
        setStatus(undefined);
    }
    setTabIndex(index);
  };

  const [search, setSearch] = useState("");

  const [email, setEmail] = useState();

  const [firstName, setFirstName] = useState<1 | -1>(1);
  const [lastName, setLastName] = useState<1 | -1>(1);
  const [sortByCreated, setSortByCreated] = useState<1 | -1>(-1);
  const [sortByUpdated, setSortByUpdated] = useState<1 | -1>(-1);

  const { mutate, data, isLoading, isSuccess, isError, error } =
    useClientsFindAll();
  const [filters, setFilters] = useState();

  const [pagination, setPagination] = useState({
    count: 0,
    limit: 5,
    skip: 0,
    page: 1,
  });
  const [defaultFilterValues, setDefaultFilterValues] = useState({
    name: "",
    vendor: "",
  });
  useEffect(() => {
    if (DeleteOneUserIsSuccess) {
      toast({
        title: "Utilisateur supprimé avec succès",
        status: "success",
        position: `bottom-right`,

        isClosable: true,
      });
      mutate({
        skip: pagination.skip,
        limit: pagination.limit,
        search: search,
        // email: email,
        // firstName: firstName,
        // lastName: lastName,
        createdAt: sortByCreated,
        updatedAt: sortByUpdated,
      });
    }
  }, [DeleteOneUserIsSuccess]);
  useEffect(() => {
    mutate({
      skip: pagination.skip,
      limit: pagination.limit,
      search: search,
      // email: email,
      // firstName: firstName,
      // lastName: lastName,
      createdAt: sortByCreated,
      updatedAt: sortByUpdated,
    });
  }, [pagination.limit, pagination.skip, search]);
  useEffect(() => {
    if (isSuccess) {
      setClients(data.data.users);
    }
  }, [isSuccess]);

  const getFiltersCount = () => {
    let count = 0;
    if (filters) {
      Object.values(filters).forEach((value, index) => {
        if (value) {
          count = count + 1;
        }
      });
    }
    return count;
  };

  const intl = useIntl();
  const {
    isOpen: openFilter,
    onOpen: onOpenFilter,
    onClose: onCloseFilter,
  } = useDisclosure();

  const handlePageChange = (dir: "next" | "prev") => {
    if (dir === "next") {
      setPagination((prevState) => {
        let temp = { ...prevState };
        temp.skip = temp.skip + temp.limit;
        temp.page = temp.page + 1;
        return temp;
      });
    } else {
      setPagination((prevState) => {
        let temp = { ...prevState };
        temp.skip = temp.skip - temp.limit;
        temp.page = temp.page - 1;
        return temp;
      });
    }
    handleClients();
  };
  const handleLimitChange = (value: number) => {
    setPagination((prevState) => {
      let temp = { ...prevState };
      temp.limit = +value;
      return temp;
    });
    handleClients();
  };
  const [loading, setLoading] = useState(true);
  const handleDeleteStore = (id: string) => {};

  const storesTableData =
    (clients &&
      clients.map((client, index) => ({
        key: index,
        image: (
          <Box className="table_image">
            <img
              src={
                client.profilePicture ||
                "https://www.freeiconspng.com/thumbs/no-image-icon/no-image-icon-4.png"
              }
              alt="client_profile_picture"
            />
          </Box>
        ),

        fullName: client.fullName,

        email: client.email,
        links:
          client.links && client.links.displayLinks.length > 1 ? (
            <Menu>
              <MenuButton
                transition="all 0.2s"
                borderRadius="md"
                // bg={"white"}
                borderWidth="1px"
                // _hover={{ bg: "gray.400" }}
                _expanded={{ bg: "blue.400" }}
                as={Button}
                rightIcon={<BiChevronDown />}
              >
                Liste
              </MenuButton>

              {client.links.displayLinks.length <= 1 ? (
                <MenuList>
                  <MenuItem minH="48px"> "Pas de liens"</MenuItem>
                </MenuList>
              ) : (
                <MenuList maxH={150} overflowY="scroll">
                  {client.links.displayLinks.map((link, index) =>
                    link.isCustom === true ? (
                      <MenuItem minH="48px" key={index}>
                        <Image
                          boxSize="2rem"
                          borderRadius="full"
                          src={
                            link.customIcon
                              ? link.customIcon
                              : "https://www.freeiconspng.com/thumbs/no-image-icon/no-image-icon-4.png"
                          }
                          alt="Fluffybuns the destroyer"
                          mr="12px"
                        />
                        <Link
                          color="blue.300"
                          target="_blank"
                          href={link.suffix}
                          isExternal
                        >
                          <Stack
                            direction="row"
                            alignItems="center"
                            spacing="4px"
                          >
                            <Text whiteSpace="nowrap">custom Link</Text>{" "}
                            {/* <AiOutlineEye size={16} /> */}
                          </Stack>
                        </Link>
                      </MenuItem>
                    ) : link.socialLink ? (
                      <MenuItem minH="48px" key={index}>
                        <Image
                          boxSize="2rem"
                          borderRadius="full"
                          src={
                            link.socialLink.icon
                              ? link.socialLink.icon
                              : "https://www.freeiconspng.com/thumbs/no-image-icon/no-image-icon-4.png"
                          }
                          alt="Fluffybuns the destroyer"
                          mr="12px"
                        />
                        <Link
                          color="blue.300"
                          target="_blank"
                          isExternal
                          href={link.socialLink.url + link.suffix}
                        >
                          <Stack
                            direction="row"
                            alignItems="center"
                            spacing="4px"
                          >
                            <Text whiteSpace="nowrap">
                              {link.socialLink.name}
                            </Text>{" "}
                            {/* <AiOutlineEye size={16} /> */}
                          </Stack>
                        </Link>
                      </MenuItem>
                    ) : (
                      <></>
                    )
                  )}
                </MenuList>
              )}
            </Menu>
          ) : (
            "--"
          ),
        profile: (
          <Link
            color="blue.300"
            target="_blank"
            href={`https://bitaqa.tn/${client?.profileSettings?.username}`}
          >
            <Stack direction="row" alignItems="center" spacing="4px">
              <Text whiteSpace="nowrap">
                <FormattedMessage id={"consult"} />
              </Text>{" "}
              <AiOutlineEye size={16} />
            </Stack>
          </Link>
        ),
        action: (
          <Stack
            direction="row"
            display="inline-flex"
            width="fit-content"
            alignItems="center"
            justifyContent="flex-end"
          >
            <></>
            {client?.profileSettings &&
            client?.profileSettings.isBusiness === true ? (
              <RiVipLine size={30} />
            ) : (
              <></>
            )}
            <ConfirmDeleteDialog
              item="user"
              dialogButtonProps={{
                type: "IconButton",

                children: (
                  <Tooltip label={intl.formatMessage({
                    id: "user.delete",
                  })} aria-label="A tooltip">
                    <IconButton
                      aria-label="decline_button"
                      size="sm"
                      variant="outline"
                      colorScheme="red"
                    >
                      <BiTrash size={18} />
                    </IconButton>
                  </Tooltip>
                ),
                props: {
                  variant: "ghost",
                  size: "sm",
                },
              }}
              onDelete={() => {
                DeleteOneUserMutate(client._id);
              }}
            />
            <Tooltip label={intl.formatMessage({
        id: "user.update",
      })} aria-label="A tooltip">
              <IconButton
                aria-label={intl.formatMessage({
                  id: "user.update",
                })}
                size="sm"
                variant="outline"
                colorScheme="whatsapp"
                onClick={() =>
                  navigate(`/users/${client._id}`, {
                    state: {
                      from: `/users`,
                      pageName: "Users",
                    },
                  })
                }
              >
                <BiEdit size={16} />
              </IconButton>
            </Tooltip>
          </Stack>
        ),
      }))) ||
    [];

  type StoresTableData = typeof storesTableData[number];
  const handleSearchSubmit = (event: any) => {
    if (event.code === "Enter") {
      // console.log(event.target.value);
      setSearch(event.target.value);
      mutate({
        skip: pagination.skip,
        limit: pagination.limit,
        search: search,

        createdAt: sortByCreated,
        updatedAt: sortByUpdated,
      });
    }
  };
  const storesTableHead: TableHeadType<StoresTableData>[] = [
    { accessor: "image", label: "" },

    {
      accessor: "fullName",
      label: intl.formatMessage({
        id: "fullName",
      }),
    },

    {
      accessor: "email",
      label: intl.formatMessage({
        id: "client.email",
      }),
    },

    {
      accessor: "links",
      label: intl.formatMessage({
        id: "links",
      }),
    },
    { accessor: "profile", label: intl.formatMessage({
      id: "Profile",
    }) },

    { accessor: "action", label: "", headCellProps: { isNumeric: true } },
  ];

  return (
    <PageWrapper>
      <Stack
        direction={{ base: "column", md: "row" }}
        spacing="18px"
        className="page_header"
      >
        <Box className="page_header_title">
          <Box className="page_header_title_content">
            <Heading fontSize="2xl">
              <FormattedMessage id={"users.liste"} />
            </Heading>
          </Box>
        </Box>
        <Stack>
          {/* <Button variant='outline'>Export</Button> */}
          <Button colorScheme="main" onClick={() => onOpenUserModal()}>
            <FormattedMessage id={"users.create"} />
          </Button>
        </Stack>
      </Stack>

      <Box className="page_actions">
        <Input
          inputProps={{
            placeholder: intl.formatMessage({
              id: "userSearch",
            }),
            onKeyUp: handleSearchSubmit,
          }}
          inputLeftElementProps={{ children: <BiSearch /> }}
          inputRightAddon={{
            children: <AiOutlineEnter onClick={handleSearchSubmit} />,
          }}
        />
      </Box>
      <Table
        head={storesTableHead}
        data={storesTableData}
        isLoading={isLoading}
        emptyState={
          <Box className="empty_table_container">
            <BiStore size={42} />
            <Text fontSize="md" fontWeight="medium">
              {data?.data.queryCount
                ? `${intl.formatMessage({
                  id: "noResult",
                })} "${search}"`
                : getFiltersCount() !== 0 || tabIndex > 0
                ? "There is no stores with these filters"
                : "There is no stores."}
            </Text>
          </Box>
        }
        pagination={{
          count: data?.data.queryCount,
          limit: data?.data.usersCount,
          skip: pagination.skip,
          onPageChange: handlePageChange,
          onChangeLimit: handleLimitChange,
        }}
      />
      {/* <StoreFilterDrawer
        isOpen={openFilter}
        onClose={onCloseFilter}
        handleSubmitFilter={handleFilter}
        defaultValues={defaultFilterValues}
        handleResetFilter={handleResetFilter}
      /> */}
      <Modal
        isOpen={isOpenUserModal}
        onClose={onCloseUserModal}
        isCentered
        size="xl"
        scrollBehavior="inside"
      >
        <ModalOverlay />
        <AddUserModal
          onClose={() => {
            onCloseUserModal();
          }}
          onSubmit={() => {
            toast({
              title: intl.formatMessage({
                id: "users.created.succcess",
              }),
              status: "success",
              position: `bottom-right`,
              isClosable: true,
            });
            onCloseUserModal();
            mutate({
              skip: pagination.skip,
              limit: pagination.limit,
              search: search,
              // author:author,
              createdAt: sortByCreated,
              updatedAt: sortByUpdated,
            });
          }}
        />
      </Modal>
    </PageWrapper>
  );
};

export default Clients;
type CreateUserModalProps = {
  onClose: () => void;

  onSubmit: () => void;
  // selectedVariant?: VariantType;
};
const AddUserModal = (props: CreateUserModalProps) => {
  const [files, setFiles] = useState<
    {
      progress: boolean;
      id: string;
      src: File | string | undefined;
    }[]
  >([]);
  const {
    mutate: CreateOneUsersMutate,
    data: CreateOneUsersData,
    isLoading: CreateOneUsersIsLoading,
    isSuccess: CreateOneUsersIsSuccess,
    isError: CreateOneUsersIsError,
    error: CreateOneUsersError,
  } = useClientsCreateOne();
  const handleCloseModal = () => {
    setFiles([]);

    props.onClose();
  };
  const modules = {
    toolbar: [
      [{ header: "1" }, { header: "2" }, { font: [] }],
      [{ size: [] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link"],
    ],
    clipboard: {
      matchVisual: false,
    },
  };
  useEffect(() => {
    if (CreateOneUsersIsSuccess) {
      props.onSubmit();
    }
  }, [CreateOneUsersIsSuccess]);

  const toast = useToast();
  const AddUserFormik = useFormik({
    validateOnMount: false,
    validateOnBlur: false,
    validateOnChange: false,
    validate: (values) => {
      const errors: FormikErrors<typeof values> = {};
      if (!values.fullName) {
        errors.fullName = "FullName is required";
      }
      if (!values.email) {
        errors.email = "Email is required";
      } else if (
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
      ) {
        errors.email = "Invalid email address";
      }
      if (!values.password) {
        errors.password = "Password is required";
      }
      if (values.password.length < 8) {
        errors.password = "A minimum 8 characters password ";
      }
      if (values.password.length > 16) {
        errors.password = "A maximum 16 characters password ";
      }
      if (!values.password) {
        errors.password = "Password is required";
      }
      return errors;
    },
    initialValues: {
      profilePicture: "",
      userDescription: "",
      email: "",
      fullName: "",
      password: "",
    },

    onSubmit: (values, { setSubmitting }) => {
      // console.log("test", values);
      if (files?.[0]?.src) {
        const saveData = {
          profilePicture: files?.[0]?.src ? (files?.[0]?.src as string) : "",
          userDescription: values.userDescription,
          email: values.email,
          fullName: values.fullName,
          password: values.password,
        };
        CreateOneUsersMutate(saveData);
      } else {
        const saveData = {
          userDescription: values.userDescription,
          email: values.email,
          fullName: values.fullName,
          password: values.password,
        };
        CreateOneUsersMutate(saveData);
      }

      // if (CreateOneUsersIsSuccess) {

      //   setSubmitting(false);
      // } else {
      //   toast({
      //     title: "error",
      //     status: "error",
      //     position: `bottom-right`,

      //     isClosable: true,
      //   });
      // }
    },
  });

  const handleFile = async (file: File) => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("upload_preset", "ml_default");
    formData.append("public_id", `categories/${file.name}`);
    setFiles((prevFiles) => [
      ...prevFiles,
      {
        src: file,
        id: file.name,
        progress: true,
        name: file.name,
        type: file.type,
      },
    ]);
    const data = await (
      await fetch("https://api.cloudinary.com/v1_1/dgpjskmv1/upload", {
        method: "POST",
        body: formData,
      })
    ).json();

    setFiles((prevFiles) => [
      ...prevFiles.filter(({ id }) => id !== file.name),
      {
        src: data.secure_url,
        id: data.asset_id,
        progress: false,
      },
    ]);
  };

  const handleDelete = (id: string) => {
    // console.log(
    //   id,
    //   files.filter(({ id: imageId }) => id !== imageId)
    // );
    setFiles((prevFiles) =>
      prevFiles.filter(({ id: imageId }) => id !== imageId)
    );
  };

  const intl = useIntl();
  const [showPassword, setShowPassword] = useState<boolean>(false);
  return (
    <ModalContent>
      <ModalHeader borderBottomWidth="1px">
        <FormattedMessage id={"users.create"} />
      </ModalHeader>
      <ModalCloseButton />
      <ModalBody>
        <form id="create-user" onSubmit={AddUserFormik.handleSubmit}>
          <Stack spacing="12px">
            <ImageUpload
              height="120px"
              width={"100%"}
              images={files}
              handleUpload={handleFile}
              handleDelete={handleDelete}
              label={intl.formatMessage({
                id: "image",
              })}
              gridColCount={3}
            />
            <Input
              inputProps={{
                type: "text",
                name: "fullName",
                onChange: AddUserFormik.handleChange,
                onBlur: AddUserFormik.handleBlur,
                value: AddUserFormik.values.fullName,
                placeholder: intl.formatMessage({
                  id: "fullName",
                }),
              }}
              errorMessage={AddUserFormik.errors.fullName}
              isError={AddUserFormik.errors.fullName !== undefined}
              label={intl.formatMessage({
                id: "fullName",
              })}
            />

            <FormControl>
              <FormLabel marginBottom="1">
                <FormattedMessage id={"description"} />
              </FormLabel>
              <Textarea
                placeholder={intl.formatMessage({
                  id: "description",
                })}
                name="userDescription"
                onChange={AddUserFormik.handleChange}
                value={AddUserFormik.values.userDescription}
              />
              {AddUserFormik.errors.userDescription && (
                <FormErrorMessage>
                  {AddUserFormik.errors.userDescription}
                </FormErrorMessage>
              )}
            </FormControl>
            <Input
              inputProps={{
                type: "text",
                name: "email",
                onChange: AddUserFormik.handleChange,
                onBlur: AddUserFormik.handleBlur,
                value: AddUserFormik.values.email,
                placeholder: intl.formatMessage({
                  id: "client.email",
                }),
              }}
              errorMessage={AddUserFormik.errors.email}
              isError={AddUserFormik.errors.email !== undefined}
              label={intl.formatMessage({
                id: "client.email",
              })}
            />
            <Input
              inputProps={{
                type: showPassword ? "text" : "password",
                name: "password",
                onChange: AddUserFormik.handleChange,
                onBlur: AddUserFormik.handleBlur,
                value: AddUserFormik.values.password,
                placeholder: intl.formatMessage({
                  id: "password",
                }),
              }}
              inputRightElementProps={{
                children: (
                  <IconButton
                    tabIndex={-1}
                    onClick={() => setShowPassword(!showPassword)}
                    aria-label="Password toggle"
                    variant="ghost"
                  >
                    {showPassword ? <BiShow size={18} /> : <BiHide size={18} />}
                  </IconButton>
                ),
              }}
              errorMessage={AddUserFormik.errors.password}
              isError={AddUserFormik.errors.password !== undefined}
              label={intl.formatMessage({
                id: "password",
              })}
            />
          </Stack>
        </form>
      </ModalBody>

      <ModalFooter borderTopWidth="1px">
        <Button variant="ghost" mr={3} onClick={handleCloseModal}>
          <FormattedMessage id={"discard"} />
        </Button>
        <Button
          type="submit"
          form="create-user"
          colorScheme="main"
          onSubmit={() => AddUserFormik.handleSubmit}
          isLoading={CreateOneUsersIsLoading}
        >
          {"Create user"}
        </Button>
      </ModalFooter>
    </ModalContent>
  );
};
