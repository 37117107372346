import { patch, post } from "../http-methods";
import {
  LOGIN,
  REGISTER,
  FORGET_PASSWORD,
  RESET_USER_PASSWORD,
} from "../endpoints/auth";
import { useMutation } from "@tanstack/react-query";
import { useToast } from "@chakra-ui/react";

/*********  LOGIN USER  *********/
type logInUserDataType = {
  email: string;
  password: string;
};

export const useLoginUser = () => {
  const toast = useToast();
  return useMutation({
    mutationFn: (userData: logInUserDataType) => {
      return login(userData);
    },
    onError: (data: any) => {
      console.log("dataError", data.response.data.errors);
      const errors: any = data.response.data.errors;
      if (errors[0]) {
        toast({
          title: errors[0].msg,
          position: `bottom-right`,
          status: "error",
          isClosable: true,
        });
      } else {
        toast({
          title: errors.password.msg,
          position: `bottom-right`,
          status: "error",
          isClosable: true,
        });
      }
    },
  });
};

export const login = (userData: logInUserDataType) => {
  return post(LOGIN, userData);
};
/*********  REGISTER USER  *********/

type registerUserDataType = {
  email: string;
  firstName: string;
  lastName: string;
  password: string;
};
type resetPasswordDataType = {
  token: string;
  password: string;
  paylodType: string;
};

export const useRegisterUser = () => {
  return useMutation({
    mutationFn: (userData: registerUserDataType) => {
      return register(userData);
    },
  });
};
export const register = (userData: registerUserDataType) => {
  return post(REGISTER, userData);
};

/*********  RESET PASSWORD USER  *********/

type forgetPasswordUserData = {
  email: string;
};

export const useForgetPassword = () => {
  const toast = useToast();
  return useMutation({
    mutationFn: (userData: forgetPasswordUserData) => {
      return patch(FORGET_PASSWORD, userData);
    },
    onError: (data: any) => {
      console.log("dataError", data.response.data.errors);
      const errors: any = data.response.data.errors;
      if (errors[0])
        toast({
          title: errors[0].msg,
          position: `bottom-right`,
          status: "error",
          isClosable: true,
        });
    },
  });
};

export const useResetPassword = () => {
  const toast = useToast();
  return useMutation({
    mutationFn: (userData: resetPasswordDataType) => {
      return patch(RESET_USER_PASSWORD, userData);
    },
    onError: (data: any) => {
      console.log("dataError", data.response.data.errors);
      const errors: any = data.response.data.errors;
      if (errors[0]) {
        toast({
          title: errors[0].msg,
          position: `bottom-right`,
          status: "error",
          isClosable: true,
        });
      } else if (errors.password) {
        toast({
          title: errors.password.msg,
          position: `bottom-right`,
          status: "error",
          isClosable: true,
        });
      } else {
        toast({
          title: errors.token.msg,
          position: `bottom-right`,
          status: "error",
          isClosable: true,
        });
      }
    },
  });
};
