import {
    Badge,
    Box,
    Button,
    color,
    Divider,
    FormLabel,
    Heading,
    Link,
    Radio,
    RadioGroup,
    Select,
    SimpleGrid,
    Skeleton,
    Stack,
    Switch,
    Tag,
    Text,
    Textarea,
    useColorModeValue,
    useToast,
  } from "@chakra-ui/react";
  import { Link as RouterLink } from "react-router-dom";
  import { FormikErrors, useFormik } from "formik";
  import { ReactNode, useEffect, useState } from "react";
  import "react-quill/dist/quill.snow.css";
  import ReactQuill from "react-quill";
  import { useLocation, useNavigate, useParams } from "react-router-dom";
  

  import { FormattedMessage, useIntl } from "react-intl";
  
  import {
    ClientsDataType,
    usePatchOneClient,
    userLogDataType,
    useUserLogFindAll,
  } from "../../../api/Clients";

  import moment from "moment";
  import { TableHeadType } from "../../../components/modules/Table/Table.types";
  import Table from "../../../components/modules/Table/Table";
  import { BiBriefcase, BiLinkExternal, BiStore } from "react-icons/bi";
import { InventorysDataType, useFindAllActivatedInventorys } from "../../../api/inventory";
import { AiOutlineEye } from "react-icons/ai";
  
  
  type UserRelatedProductsFormProps = {
    loading?: boolean;
  
    userId?: string;
  };
 
  
  
  const UserRelatedProductsForm = (props: UserRelatedProductsFormProps) => {
    

    const { userId, loading } = props;
  
    const toast = useToast();
  
    const [inventorys, setInventorys] = useState<InventorysDataType[]>([]);
    const [pagination, setPagination] = useState({
      count: 0,
      limit: 5,
      skip: 0,
      page: 1,
    });
    const handlePageChange = (dir: "next" | "prev") => {
      if (dir === "next") {
        setPagination((prevState) => {
          let temp = { ...prevState };
          temp.skip = temp.skip + temp.limit;
          temp.page = temp.page + 1;
          return temp;
        });
      } else {
        setPagination((prevState) => {
          let temp = { ...prevState };
          temp.skip = temp.skip - temp.limit;
          temp.page = temp.page - 1;
          return temp;
        });
      }
    };
    const handleLimitChange = (value: number) => {
      setPagination((prevState) => {
        let temp = { ...prevState };
        temp.limit = +value;
        return temp;
      });
    };
    const {
        mutate: findAllInventorysMutate,
        data: findAllInventorysData,
        isLoading: findAllInventorysIsLoading,
        isSuccess: findAllInventorysIsSuccess,
        isError: findAllInventorysIsError,
        error: findAllInventorysError,
      } = useFindAllActivatedInventorys();
  
    useEffect(() => {
        findAllInventorysMutate({
            linkedTo: userId,
          skip: pagination.skip,
          limit: pagination.limit,
        });
      }, [userId, pagination]);
      useEffect(() => {
        if (findAllInventorysIsSuccess) {
          setInventorys(findAllInventorysData.data.inventorys);
        }
      }, [findAllInventorysIsSuccess]);
  
    const modules = {
      toolbar: [
        [{ header: "1" }, { header: "2" }, { font: [] }],
        [{ size: [] }],
        ["bold", "italic", "underline", "strike", "blockquote"],
        [
          { list: "ordered" },
          { list: "bullet" },
          { indent: "-1" },
          { indent: "+1" },
        ],
        ["link"],
      ],
      clipboard: {
        matchVisual: false,
      },
    };
  
    const location = useLocation();
    const navigate = useNavigate();
  
    const intl = useIntl();
    const borderColor = useColorModeValue("gray.100", "gray.700");
    const { from } = (location?.state as { from?: string }) || "";
    const inventorysAdminTableData =
    (inventorys &&
      inventorys.map((inventory, index) => ({
        key: inventory._id,
       
        refId: inventory.refId,
        code: inventory.code,

        url: (
          <Link href={inventory.url} isExternal>
            <BiLinkExternal size={14} />
          </Link>
        ),
        from: inventory.from,

        creationDate: moment(inventory.createdAt).format("lll"),
        
     
       
      }))) ||
    [];
    type ProductsInventorysTableData = typeof inventorysAdminTableData[number];
  const productsInventorysTableHead: TableHeadType<ProductsInventorysTableData>[] =
    [
     

      {
        accessor: "refId",
        label: intl.formatMessage({
          id: "refId",
        }),
      },
      {
        accessor: "code",
        label: intl.formatMessage({
          id: "code",
        }),
      },
      {
        accessor: "url",
        label: intl.formatMessage({
          id: "url",
        }),
      },
      {
        accessor: "from",
        label: intl.formatMessage({
          id: "from",
        }),
      },
     
      {
        accessor: "creationDate",
        label: intl.formatMessage({
          id: "createdDate",
        }),
      },
      

     
    ];

    return (
      <Stack > 
      
      <Table
        head={productsInventorysTableHead}
        data={inventorysAdminTableData}
        isLoading={findAllInventorysIsLoading}
        emptyState={
          <Box className="empty_table_container">
            <BiBriefcase size={42} />
            <Text fontSize="md" fontWeight="medium"></Text>
          </Box>
        }
        pagination={{
          count: findAllInventorysData?.data.queryCount || 0,
          limit: pagination.limit,
          skip: pagination.skip,
          onPageChange: handlePageChange,
          onChangeLimit: handleLimitChange,
        }}
      /></Stack>
     
    );
  };
  
  export default UserRelatedProductsForm;
  