import {
  Box,
  Button,
  Divider,
  Heading,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useColorModeValue,
  Text,
  Link,
  LinkBox,
  LinkOverlay,
  MenuDivider,
  useColorMode,
  Stack,
  Toast,
  useToast,
  WrapItem,
} from "@chakra-ui/react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import {
  BiBell,
  BiChevronDown,
  BiHelpCircle,
  BiLayer,
  BiLinkExternal,
  BiLogOut,
  BiMessage,
  BiMoon,
  BiRadioCircleMarked,
  BiRefresh,
  BiSearch,
  BiShield,
  BiShieldAlt,
  BiShieldAlt2,
  BiStore,
  BiSun,
} from "react-icons/bi";
import { GiEarthAfricaEurope } from "react-icons/gi";
import { useLocation } from "react-router-dom";

import { Input } from "../../commons";
import { AppBarWrapper } from "./AppBar.styles";
import {
  CacheUserDataType,
  PUBLIC_API_ENDPOINT,
} from "../../../utils/globalTypes";
import { getDataFromCache, removeFromCache } from "../../../utils/cache";
import axios from "axios";
import { env } from "process";
import { lang } from "moment";
import { AppContext } from "../../../context/appContext";
import { useContext, useEffect } from "react";
import { FormattedMessage } from "react-intl";
const AppBar = () => {
  const { setLanguage, language } = useContext(AppContext);
  const { fullName, setFullName, profilePicture } = useContext(AppContext);
  const locals: { label: string; id: string }[] = [
    { label: "arabic", id: "ar" },
    { id: "fr", label: "french" },
    { id: "en", label: "english" },
  ];
  useEffect(() => {
    // console.log("fullName", fullName);
  }, [fullName]);
  const location = useLocation();
  const toast = useToast();
  const { colorMode, toggleColorMode } = useColorMode();
  const { pageName } = (location?.state as { pageName?: string }) || "";

  const navigate = useNavigate();

  const borderColor = useColorModeValue("gray.200", "gray.700");

  const handleLogout = () => {
    removeFromCache("accessToken");
    removeFromCache("user");
    removeFromCache("refreshToken");
    removeFromCache("fullName");
    removeFromCache("profilePicture");
    window.location.href = "/authentication/login";
    // window.location.reload();
  };


  return (
    <AppBarWrapper>
      <Box className="appbar_content">
        <Heading size="md" fontWeight="semibold">
          {/* <FormattedMessage id={pageName} /> */}
        </Heading>
        <Box className="appbar_elements">
          {/* <Stack direction='row' spacing='24px'>
							<Stack spacing='2px'>
								<Text fontSize='xs' lineHeight='none' opacity={0.6}>
									Current balance
								</Text>
								<Text fontSize='sm' lineHeight='normal' fontWeight='medium'>
									test
									TND
								</Text>
							</Stack>
							<Stack spacing='2px'>
								<Text fontSize='xs' lineHeight='none' opacity={0.6}>
									Online store
								</Text>
								
							</Stack>
						</Stack> */}
          <WrapItem>
            <Menu autoSelect={false}>
              <MenuButton
                as={Button}
                variant="link"
                leftIcon={<GiEarthAfricaEurope size={18} />}
                rightIcon={<BiChevronDown size={18} />}
                height="fit-content"
              >
                <Heading size="md" fontWeight="semibold" fontSize="lg">
                  <FormattedMessage
                    id={
                      language == "en"
                        ? "english"
                        : language == "fr"
                        ? "french"
                        : "arabic"
                    }
                  />
                </Heading>
              </MenuButton>
              <MenuList minW={"9rem"}>
                {locals
                  // .filter((e) => e != product.PRODUCT_STATUS_NAME)
                  .map((lang, index) => (
                    <MenuItem key={index} onClick={() => setLanguage(lang.id)}>
                      <FormattedMessage id={lang.label} />
                    </MenuItem>
                  ))}
              </MenuList>
            </Menu>
          </WrapItem>

          {/* <div>
            <p>Locale switcher:</p>
            <ul>
              {locals.map((language) => {
                return (
                  <li key={language} onClick={() => setLanguage(language)}>
                    {language}
                  </li>
                );
              })}
            </ul>
          </div> */}

          <Menu>
            <MenuButton
              className="profile_button"
              as={Button}
              rightIcon={<BiChevronDown />}
              size="sm"
              variant="ghost"
              px="1"
            >
              <img
                className="profile_picture"
                src={
                  profilePicture
                    ? profilePicture
                    : getDataFromCache("profilePicture")
                }
                alt={"profile_picture"}
              />
            </MenuButton>

            <MenuList px="2" shadow="lg">
              <MenuItem
                as={LinkBox}
                className="menu_profile_item"
                rounded="base"
              >
                <img
                  className="profile_picture_menu"
                  src={
                    profilePicture
                      ? profilePicture
                      : getDataFromCache("profilePicture")
                  }
                  alt={"profile_picture"}
                />
                <Box ml="3">
                  <Text fontWeight="medium">
                    {fullName ? fullName : getDataFromCache("fullName")}
                  </Text>
                  <LinkOverlay
                    as={RouterLink}
                    to="/settings/profile"
                    color="blue.500"
                  >
                    <Text fontSize="sm"><FormattedMessage id={"Profile"} /></Text>
                  </LinkOverlay>
                </Box>
              </MenuItem>
              <MenuDivider />
              <MenuItem
                // /settings/security
                onClick={() => navigate(`/settings/security`)}
                rounded="base"
                icon={<BiShieldAlt2 size={16} />}
              >
               <FormattedMessage id={"accountSecurity"} /> 
              </MenuItem>
              <MenuItem
                // /settings/home
                onClick={() => navigate(`/settings/home`)}
                rounded="base"
                icon={<BiStore size={16} />}
              >
               <FormattedMessage id={"homeConfig"} /> 
              </MenuItem>

              <MenuDivider />
              <MenuItem
                rounded="base"
                onClick={toggleColorMode}
                icon={
                  colorMode === "dark" ? (
                    <BiSun size={18} />
                  ) : (
                    <BiMoon size={18} />
                  )
                }
              >
                {colorMode === "dark" ? "Light mode" : "Dark mode"}
              </MenuItem>
              <MenuDivider />
              <MenuItem
                rounded="base"
                icon={<BiLogOut size={16} />}
                color="status.error"
                onClick={handleLogout}
              >
               <FormattedMessage id={"logOut"} /> 
              </MenuItem>
            </MenuList>
          </Menu>
        </Box>
      </Box>
      <Divider borderColor={borderColor} />
    </AppBarWrapper>
  );
};

export default AppBar;
