import axios from "axios";
import { getDataFromCache } from "../utils/cache";

const URL = process.env.REACT_APP_PUBLIC_API_ENDPOINT;

const timeoutConfig = {
  timeout: 5000,
};

const authorizationConfig = {
  headers: {
    authorization: getDataFromCache("accessToken", true),
  },
 
};

// public routes
export const get = (endpoint: string = "") =>
  axios.get(`${URL}/${endpoint}`, authorizationConfig);

export const post = async (endpoint: string, data: any) =>
  axios.post(
    `${URL}/${endpoint}`,
    { ...data } /*, {
       ...config*/
  );

export const patch = async (endpoint: string, data: any) =>
  axios.patch(
    `${URL}/${endpoint}`,
    { ...data } /*, { 
       ...config*/
  );
export const put = async (endpoint: string, data: any) =>
  axios.put(
    `${URL}/${endpoint}`,
    { ...data } /*, {
       ...config*/
  );

// protected routes
export const protectedGet = (endpoint: string) =>
  axios.get(`${URL}/${endpoint}`, authorizationConfig);

export const protectedPost = async (endpoint: string, data: any) =>
  axios.post(`${URL}/${endpoint}`, { ...data }, { ...authorizationConfig });
export const protectedPatch = async (endpoint: string, data: any) =>
  axios.patch(`${URL}/${endpoint}`, { ...data }, { ...authorizationConfig });
export const protectedPut = async (endpoint: string, data: any) =>
  axios.put(`${URL}/${endpoint}`, { ...data }, { ...authorizationConfig });
export const protectedDelete = async (endpoint: string, data: string) =>
  axios.delete(`${URL}/${endpoint}/${data}`, { ...authorizationConfig });
