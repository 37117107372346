import {
  FormLabel as ChakraFormLabel,
  InputGroup as ChakraInputGroup,
  Input as ChakraInput,
  InputProps,
  InputGroupProps,
  FormLabelProps,
  FormHelperText,
  FormErrorMessage,
  FormControl as ChakraFormControl,
  FormControlProps,
  InputLeftElement,
  InputElementProps,
  InputRightElement,
  InputAddonProps,
  InputLeftAddon,
  InputRightAddon,
} from "@chakra-ui/react";
import { FC } from "react";
import { FormattedMessage } from "react-intl";

export type InputPropsType = {
  inputProps?: Omit<InputProps, "isInvalid"> & {
    ref?: React.LegacyRef<HTMLInputElement> | undefined;
  };
  inputGroupProps?: InputGroupProps;
  formLabelProps?: FormLabelProps;
  formControlProps?: Omit<FormControlProps, "isInvalid">;
  label?: string | null;
  helperText?: string;
  errorMessage?: string;
  isError?: boolean;
  inputLeftElementProps?: InputElementProps;
  inputRightElementProps?: InputElementProps;
  inputRightAddon?: InputAddonProps;
  inputLeftAddon?: InputAddonProps;
};

const Input: FC<InputPropsType> = (props) => {
  const {
    inputProps,
    inputGroupProps,
    formLabelProps,
    formControlProps,
    label,
    helperText,
    errorMessage,
    isError,
    inputLeftElementProps,
    inputRightElementProps,
    inputRightAddon,
    inputLeftAddon,
  } = props;

  return (
    <ChakraFormControl {...formControlProps} isInvalid={isError}>
      {label && (
        <ChakraFormLabel {...formLabelProps} marginBottom="1">
           <FormattedMessage id={label} />
        </ChakraFormLabel>
      )}
      <ChakraInputGroup {...inputGroupProps}>
        {inputLeftElementProps?.children && (
          <InputLeftElement {...inputLeftElementProps} />
        )}
        {inputLeftAddon?.children && <InputLeftAddon {...inputLeftAddon} />}
        <ChakraInput {...inputProps} outlineColor="transparent" />
        {inputRightAddon?.children && <InputRightAddon {...inputRightAddon} />}
        {inputRightElementProps?.children && (
          <InputRightElement {...inputRightElementProps} />
        )}
      </ChakraInputGroup>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
      {errorMessage && <FormErrorMessage>{errorMessage}</FormErrorMessage>}
    </ChakraFormControl>
  );
};

export default Input;
