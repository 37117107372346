import {
  Box,
  Text,
  Heading,
  useColorMode,
  IconButton,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Button,
  useToast,
  CircularProgress,
  Spinner,
} from "@chakra-ui/react";
import { useState } from "react";
import { BiSun, BiMoon } from "react-icons/bi";
import { useLocation } from "react-router-dom";
import { authCoverPicture } from "../../assets";
import LogoAuth from "../../components/commons/Logo/LogoAuth";
// import { useResendVerificationEmailMutation } from "../../api/generated/graphql";

import { AuthenticationWrapper } from "./Authentication.styles";
import { LoginForm, RegisterForm } from "./Forms";

const Authentication: React.FC<{ get: "REGISTER" | "LOGIN" }> = (props) => {
  const location = useLocation();

  const { activity } = (location?.state as { activity?: string }) || "";

  const { colorMode, toggleColorMode } = useColorMode();

  const [emailNotConfirmed, setEmailNotConfirmed] = useState<string>();

  const handleEmailNotConfirmed = (email: string) => {
    setEmailNotConfirmed(email);
  };

  // const [resendVerificationEmailMutation, resendVerificationEmailParam] =
  // 	useResendVerificationEmailMutation();

  const toast = useToast();

  const handleResendVerificationEmail = () => {
    // resendVerificationEmailMutation({
    // 	variables: {
    // 		email: emailNotConfirmed || "",
    // 	},
    // 	onCompleted: (data) => {
    // 		if (data.ResendConfrimEmail?.code) {
    // 			toast({
    // 				title: "Verification email has been send!",
    // 				status: "success",
    // 				position: "bottom-right",
    // 				duration: 5000,
    // 				isClosable: true,
    // 			});
    // 		}
    // 	},
    // 	onError: (error) => {
    // 		toast({
    // 			title: "An error occured while resending verification email.",
    // 			description: error.message,
    // 			status: "error",
    // 			position: "bottom-right",
    // 			duration: 5000,
    // 			isClosable: true,
    // 		});
    // 	},
    // });
  };

  return (
    <AuthenticationWrapper>
     
      <Box
        mx="auto"
        flex={{ base: 0.9, lg: 0.45 }}
        paddingRight={{ base: "auto", lg: "20" }}
      >
        <Box
          mb="75px"
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <Box
            className="logo_container"
            margin={{ base: " 0", lg: "0 0 10px 0" }}
            width={{ base: "40px", lg: "300px" }}
          >
            <LogoAuth />
            {/* <Box marginY="2" paddingX="1px" mb="4">
          <Divider borderColor={borderColor} />
        </Box> */}
          </Box>
          <IconButton aria-label="dark-white-switch" onClick={toggleColorMode}>
            {colorMode === "dark" ? <BiSun /> : <BiMoon />}
          </IconButton>
        </Box>
        {emailNotConfirmed && (
          <Alert
            status="warning"
            variant="subtle"
            rounded="md"
            colorScheme="yellow"
            mb="16px"
          >
            <AlertIcon />
            <Box flex="1">
              <AlertTitle mr={2}>Please verify your email!</AlertTitle>
              <AlertDescription>
                If you haven't received an email yet.{" "}
                <Button
                  variant="link"
                  colorScheme="blue"
                  onClick={handleResendVerificationEmail}
                >
                  Resend email{" "}
                  {/* {resendVerificationEmailParam.loading && (
										<Spinner ml='6px' size='xs' />
									)} */}
                </Button>
              </AlertDescription>
            </Box>
          </Alert>
        )}
        {activity === "REGISTRED" && !emailNotConfirmed && (
          <Alert
            status="info"
            variant="subtle"
            rounded="md"
            colorScheme="gray"
            mb="16px"
          >
            <AlertIcon />
            <Box flex="1">
              <AlertTitle mr={2}>Please verify your email!</AlertTitle>
              <AlertDescription>
                We have sent verification email to your inbox.
              </AlertDescription>
            </Box>
          </Alert>
        )}
        <Box className="form_container">
          <Heading size="lg" fontWeight="extrabold">
            {props.get === "LOGIN" ? "Log in" : "Create your account"}
          </Heading>
          <Text fontSize="md" opacity="0.7">
            {props.get === "LOGIN"
              ? "Pick up where you left off."
              : "Two clicks away from your own marketplace."}
          </Text>
          {props.get === "LOGIN" ? (
            <LoginForm handleEmailNotConfirmed={handleEmailNotConfirmed} />
          ) : (
            <RegisterForm />
          )}
        </Box>
      </Box>
    </AuthenticationWrapper>
  );
};

export default Authentication;
