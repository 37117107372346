import {
  Box,
  Button,
  color,
  Divider,
  FormLabel,
  Heading,
  Link,
  Radio,
  RadioGroup,
  Select,
  SimpleGrid,
  Skeleton,
  Stack,
  Switch,
  Tag,
  Text,
  Textarea,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";
import { FormikErrors, useFormik } from "formik";
import { ReactNode, useEffect, useState } from "react";
import "react-quill/dist/quill.snow.css";
import ReactQuill from "react-quill";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import {
  ColorPicker,
  ImageUpload,
  Input,
  NumberInput,
  SelectInput,
} from "../../../components/commons";
import CategorySelectInput from "../../../components/modules/CategorySelectInput/CategorySelectInput";

import { FormattedMessage, useIntl } from "react-intl";
import {
  productDataType,
  usePatchOneProducts,
  useProductsCreateOne,
} from "../../../api/products";
import {
  ClientsDataType,
  UpdateProfileDataType,
  usePatchOneClient,
  usePatchOneUserProfile,
} from "../../../api/Clients";
import { ImageContainer } from "../../../components/commons/ImageUpload/ImageUpload.styles";

type UserGeneralFormProps = {
  loading?: boolean;

  user?: ClientsDataType;
};

const UserGeneralForm = (props: UserGeneralFormProps) => {
  const { user, loading } = props;

  const toast = useToast();

  const {
    mutate: userUpdateMutate,
    data: userUpdateData,
    isLoading: userUpdateIsLoading,
    isSuccess: userUpdateIsSuccess,
    isError: userUpdateIsError,
    error: userUpdateError,
  } = usePatchOneUserProfile();
  const handleChangeSettings = (test: string, value: boolean) => {
    switch (test) {
      case "isArchived": {
        setIsArchived(value);
        userUpdateMutate({
          catObj: {
            isArchived: value,
          },
          id: user?.profileSettings._id ? user?.profileSettings._id : "",
        });
        break;
      }
      case "isBusiness": {
        setIsBusiness(value);
        userUpdateMutate({
          catObj: {
            isBusiness: value,
          },
          id: user?.profileSettings._id ? user?.profileSettings?._id : "",
        });
        break;
      }
      case "isConfirmed": {
        setIsConfirmed(value);
        userUpdateMutate({
          catObj: {
            isConfirmed: value,
          },
          id: user?.profileSettings._id ? user?.profileSettings._id : "",
        });
        break;
      }

      case "isBlocked": {
        setIsBlocked(value);
        userUpdateMutate({
          catObj: {
            isBlocked: value,
          },
          id: user?.profileSettings._id ? user?.profileSettings._id : "",
        });
        break;
      }
      case "isVerfied": {
        setisVerfied(value);
        userUpdateMutate({
          catObj: {
            isVerfied: value,
          },
          id: user?.profileSettings._id ? user?.profileSettings._id : "",
        });
        break;
      }
    }
  };
  const modules = {
    toolbar: [
      [{ header: "1" }, { header: "2" }, { font: [] }],
      [{ size: [] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link"],
    ],
    clipboard: {
      matchVisual: false,
    },
  };

  const location = useLocation();
  const navigate = useNavigate();
  const [files, setFiles] = useState<
    {
      progress: boolean;
      id: string;
      src: File | string | undefined;
    }[]
  >([
    {
      src: user?.profilePicture ? user?.profilePicture : "",
      id: user?._id ? user?._id : "",
      progress: false,
    },
  ]);
  const [isArchived, setIsArchived] = useState(false);
  const [isBusiness, setIsBusiness] = useState(false);
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [isVerfied, setisVerfied] = useState(false);
  const [isBlocked, setIsBlocked] = useState(false);
  useEffect(() => {
    if (user)
      setFiles([
        {
          src: user?.profilePicture ? user?.profilePicture : "",
          id: user?._id ? user?._id : "",
          progress: false,
        },
      ]);
      if( user?.profileSettings)
   { 
    setIsArchived(
      user?.profileSettings?.isArchived
        ? user?.profileSettings.isArchived
        : false
    );
    setIsBusiness(
      user?.profileSettings.isBusiness
        ? user?.profileSettings.isBusiness
        : false
    );
    setIsConfirmed(
      user?.profileSettings?.isConfirmed
        ? user?.profileSettings?.isConfirmed
        : false
    );
    setisVerfied(
      user?.profileSettings?.isVerfied
        ? user?.profileSettings?.isVerfied
        : false
    );
    setIsBlocked(
      user?.profileSettings?.isBlocked
        ? user?.profileSettings?.isBlocked
        : false
    );}
  }, [user]);

  const intl = useIntl();
  const borderColor = useColorModeValue("gray.100", "gray.700");
  const { from } = (location?.state as { from?: string }) || "";

  return (
    <Stack
      direction="row"
      spacing="14px"
      //border="1px solid red"
      alignItems="flex-start"
    >
      <Stack
        direction={{ base: "column-reverse", xl: "row" }}
        flex={1}
        spacing="12px"
      >
        <Stack flex={1} spacing="18px" p={{ base: "auto", xl: "8px" }}>
          {/* <Skeleton
              isLoaded={!generalProductLoading}
              opacity={generalProductLoading ? 0.4 : 1}
            > */}

          <Stack
            padding="18px 18px"
            border="2px"
            borderColor={borderColor}
            rounded="base"
            spacing="18px"
            border-radius={"12px !important"}
          >
            <Heading size="md" fontWeight="semibold">
              <FormattedMessage id={"generalInforation"} />
            </Heading>
            <Stack direction={{ md: "row", base: "column" }} spacing="20px">
              <Skeleton isLoaded={!loading} w="160px">
                <ImageContainer
                  margin="7px !important"
                  position="relative"
                  height="140px"
                  width="140px"
                  border="1px"
                  borderColor={borderColor}
                  rounded="base"
                >
                  {" "}
                  <img
                    src={
                      files[0].src && typeof files[0].src !== "string"
                        ? URL.createObjectURL(files[0].src)
                        : files[0].src
                    }
                    alt="preview"
                    className="image_preview"
                  />
                </ImageContainer>
              </Skeleton>
              <Box>
                <FormLabel marginBottom="1"><FormattedMessage id={"description"} /></FormLabel>
                {/* <Textarea
               
                  value={user?.userDescription}
                  onChange={() => {}}
                  placeholder={intl.formatMessage({
                    id: "description",
                  })}
                  size="lg"
                  height="120px"
                  isDisabled={true}
                  width={{ lg: "500px", base: "auto" }}
               /> */}
            <Text
            maxW="500px"
          dangerouslySetInnerHTML={{
            __html:user?.userDescription?user?.userDescription:"",
          }}
        />
              
              </Box>
            </Stack>
            <Stack direction={{ sm: "row", base: "column" }} spacing="14px">
              <Skeleton isLoaded={!loading} w="full">
                <Input
                  label={intl.formatMessage({
                    id:"fullName",
                  })}
                  inputProps={{
                    placeholder: intl.formatMessage({
                      id:"fullName",
                    }),
                    name: "fullName",
                    isDisabled: true,
                    value: user?.fullName,
                    onChange: () => {},
                  }}
                />
              </Skeleton>
              <Skeleton isLoaded={!loading} w="full">
                <Input
                  label={intl.formatMessage({
                    id:"email",
                  })}
                  inputProps={{
                    placeholder: intl.formatMessage({
                      id:"email",
                    }),
                    name: "email",
                    isDisabled: true,
                    value: user?.email,
                    onChange: () => {},
                  }}
                />
              </Skeleton>
            </Stack>
          </Stack>

          {/* </Skeleton> */}
          {/* <Skeleton
              isLoaded={!generalProductLoading}
              opacity={generalProductLoading ? 0.4 : 1}
            > */}
          {user?.links ? (
            <Stack
              padding="18px 18px"
              border="2px"
              borderColor={borderColor}
              rounded="base"
              spacing="18px"
              border-radius={"12px !important"}
            >
              <Heading size="md" fontWeight="semibold">
              <FormattedMessage id={"links"} />
              </Heading>
              <SimpleGrid
                mt="3"
                mb="3"
                // display="flex"
                alignContent={"center"}
                alignItems={"center"}
                justifyContent="space-between"
                p="14px 18px"
                // flexDirection={{ base: "column", md: "row" }}
                columns={[1, 2, 2, 4, 6, 7]}
                gap={5}
              >
                {user?.links.displayLinks.length>1  &&
                  user?.links.displayLinks.map((link, index) =>  link.isCustom===true?(<ImageContainer
                    key={link._id}
                    margin="4px !important"
                    position="relative"
                    height="100px"
                    width="100px"
                    border="1px"
                    borderColor={"#ffff"}
                    rounded="base"
                  >
                    {" "}
                    <a href={link.suffix}>
                      {" "}
                      <img
                        src={
                          link.customIcon  &&
                          typeof link.customIcon  !== "string"
                            ? URL.createObjectURL(link.customIcon )
                            : link.customIcon 
                        }
                        alt="preview"
                        className="image_preview"
                      />
                    </a>
                  </ImageContainer>):link.socialLink? (
                   ( <ImageContainer
                      key={link._id}
                      margin="4px !important"
                      position="relative"
                      height="100px"
                      width="100px"
                      border="1px"
                      borderColor={"#ffff"}
                      rounded="base"
                    >
                      {" "}
                      <a href={link.socialLink.url + link.suffix}>
                        {" "}
                        <img
                          src={
                            link.socialLink.icon &&
                            typeof link.socialLink.icon !== "string"
                              ? URL.createObjectURL(link.socialLink.icon)
                              : link.socialLink.icon
                          }
                          alt="preview"
                          className="image_preview"
                        />
                      </a>
                    </ImageContainer> )
                   
                  ):(<></>))}
              </SimpleGrid>
              <Stack direction="row" spacing="18px"></Stack>
            </Stack>
          ) : (
            <></>
          )}
          <Stack
            padding="18px 18px"
            border="2px"
            borderColor={borderColor}
            rounded="base"
            spacing="18px"
            border-radius={"12px !important"}
          >
            <Heading size="md" fontWeight="semibold">
              <FormattedMessage id={"nav.settings"} />
            </Heading>
            <SimpleGrid
              mt="3"
              mb="3"
              // display="flex"
              alignContent={"center"}
              alignItems={"center"}
              justifyContent="space-between"
              p="14px 18px"
              flexDirection={{ base: "column", md: "row" }}
              columns={[1, 2, 2, 5]}
              gap={5}
            >
              <Box>
                <FormLabel marginBottom="1"><FormattedMessage id={"archived"} /></FormLabel>
                <Switch
                  size="lg"
                  colorScheme="green"
                  name="isArchived"
                  onChange={(e) => {
                    handleChangeSettings("isArchived", e.target.checked);
                  }}
                  isChecked={isArchived}
                />
              </Box>
              <Box>
                <FormLabel marginBottom="1"><FormattedMessage id={"businessUser"} /></FormLabel>
                <Switch
                  size="lg"
                  colorScheme="green"
                  name="isBusiness"
                  onChange={(e) => {
                    handleChangeSettings("isBusiness", e.target.checked);
                  }}
                  isChecked={isBusiness}
                />
              </Box>
              <Box>
                <FormLabel marginBottom="1"><FormattedMessage id={"verfiedUser"} /></FormLabel>
                <Switch
                  size="lg"
                  colorScheme="green"
                  name="isVerfied"
                  onChange={(e) => {
                    handleChangeSettings("isVerfied", e.target.checked);
                  }}
                  isChecked={isVerfied}
                />
              </Box>
              <Box>
                <FormLabel marginBottom="1"><FormattedMessage id={"confirmedUser"} /></FormLabel>
                <Switch
                  size="lg"
                  colorScheme="green"
                  name="isConfirmed"
                  onChange={(e) => {
                    handleChangeSettings("isConfirmed", e.target.checked);
                  }}
                  isChecked={isConfirmed}
                />
              </Box>
              <Box>
                <FormLabel marginBottom="1"><FormattedMessage id={"blockedUser"} /></FormLabel>
                <Switch
                  size="lg"
                  colorScheme="green"
                  name="isBlocked"
                  onChange={(e) => {
                    handleChangeSettings("isBlocked", e.target.checked);
                  }}
                  isChecked={isBlocked}
                />
              </Box>
            </SimpleGrid>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default UserGeneralForm;
