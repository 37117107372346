import { extendTheme } from "@chakra-ui/react";
 
export const themeCustomised = extendTheme({
  colors: {
    main: {
      50: "#0093FF",
      100: "#0093FF",
      200: "#0093FF",
      300: "#0093FF",
      400: "#0093FF",
      500: "#0093FF",
      600: "#0093FF",
      700: "#0093FF",
      800: "#0093FF",
      900: "#0093FF",
    },
    blue: {
      50: "#0093FF",
      100: "#0093FF",
      200: "#0093FF",
      300: "#0093FF",
      400: "#0093FF",
      500: "#0093FF",
      600: "#0093FF",
      700: "#0093FF",
      800: "#0093FF",
      900: "#0093FF",
    },
    status: {
      error: "#905AE7",
      success: "#905AE7",
    },
    black: "#191919",
    white: "#ffffff",
    error: {
      50: "#FFE5E5",
      100: "#FFB8B8",
      200: "#FF8A8A",
      300: "#FF5C5C",
      400: "#FF2E2E",
      500: "#FF0000",
      600: "#CC0000",
      700: "#990000",
      800: "#660000",
      900: "#330000",
    },
    gray: {
      // 50: "#F1F2F3",
      // 100: "#D9DBDE",
      // 200: "#C0C4C9",
      // 300: "#A7ADB4",
      // 400: "#8E969E",
      // 500: "#767F89",
      // 600: "#5E666E",
      // 700: "#474D52",
      800: "#061223",
      // 900: "#181A1B",
    },
  },
  fonts: {
    heading: "Outfit, sans-serif",
    body: "Outfit, sans-serif",
  },
  config: {
    initialColorMode: "light",
    useSystemColorMode: false,
  },
});
