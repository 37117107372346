import {
  Box,
  Heading,
  Stack,
  useColorModeValue,
  Text,
  Skeleton,
  useToast,
  Button,
  FormLabel,
  Textarea,
} from "@chakra-ui/react";
import { FormikErrors, useFormik } from "formik";
import moment from "moment";
import {
  ReactNode,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import { BiReset } from "react-icons/bi";
import { FormattedMessage, useIntl } from "react-intl";
import {
  ClientsDataType,
  useClientsFindOne,
  usePatchOneClient,
} from "../../api/Clients";
import {
  DateTimeInput,
  DropDownInput,
  ImageUpload,
  Input,
} from "../../components/commons";
import SettingsWrapper from "../../components/modules/SettingsWrapper/SettingsWrapper";
import { AppContext } from "../../context/appContext";
import { getDataFromCache, setInCache } from "../../utils/cache";

import {
  CacheUserDataType,
  countries,
  GetAllCountries,
  user_data,
} from "../../utils/globalTypes";

const ProfileSettings = () => {
  const {
    mutate: userUpdateMutate,
    data: userUpdateData,
    isLoading: userUpdateIsLoading,
    isSuccess: userUpdateIsSuccess,
    isError: userUpdateIsError,
    error: userUpdateError,
  } = usePatchOneClient();
  const {
    mutate: findUserMutate,
    data: findUserData,
    isLoading: findUserIsLoading,
    isSuccess: findUserIsSuccess,
    isError: findUserIsError,
    error: findUserError,
  } = useClientsFindOne();
  const [user, setUser] = useState<ClientsDataType>(getDataFromCache("user"));
  const sectionBgColor = useColorModeValue("white", "gray.900");

  const toast = useToast();
  const { fullName, setFullName, profilePicture, setProfilePicture } =
    useContext(AppContext);
  const [files, setFiles] = useState<
    {
      progress: boolean;
      id: string;
      src: File | string | undefined;
    }[]
  >([]);

  const handleFile = async (file: File) => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("upload_preset", "ml_default");
    formData.append(
      "public_id",
      user._id ? `${user._id}/${file.name}` : `no_ID/${file.name}`
    );
    setFiles((prevFiles) => [
      ...prevFiles,
      {
        src: file,
        id: file.name,
        progress: true,
        name: file.name,
        type: file.type,
      },
    ]);
    const data = await (
      await fetch("https://api.cloudinary.com/v1_1/dgpjskmv1/upload", {
        method: "POST",
        body: formData,
      })
    ).json();

    setFiles((prevFiles) => [
      ...prevFiles.filter(({ id }) => id !== file.name),
      {
        src: data.secure_url,
        id: data.asset_id,
        progress: false,
      },
    ]);
  };

  const handleDelete = (id: string) => {
    setFiles((prevFiles) =>
      prevFiles.filter(({ id: imageId }) => id !== imageId)
    );
  };
  const intl = useIntl();
  const profileFormInitialValues: {
    email: string;
    fullName: string;
  } = {
    email: "",
    fullName: fullName,
  };
  useEffect(() => {
    if (userUpdateIsSuccess)
      toast({
        title:intl.formatMessage({
          id: "profile.update",
        })
  ,
        position: `bottom-right`,
        status: "success",
        isClosable: true,
      });
    // console.log("user", userUpdateData?.data.user);
    setFullName(profileFormik.values.fullName);
    setProfilePicture(files[0]?.src as SetStateAction<string>);
    setInCache("fullName", profileFormik.values.fullName);
    setInCache(
      "profilePicture",
      files[0] ? files[0]?.src : getDataFromCache("profilePicture")
    );
    // setInCache("user", userUpdateData?.data.user);
  }, [userUpdateIsSuccess]);
  const profileFormik = useFormik({
    validateOnMount: false,
    validateOnBlur: false,
    validateOnChange: false,
    initialValues: profileFormInitialValues,
    validate: (values) => {
      const errors: FormikErrors<typeof values> = {};
      if (!values.email) {
        errors.email = "Email is required";
      } else if (
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
      ) {
        errors.email = "Invalid email address";
      }
      return errors;
    },
    onSubmit: (values, { setSubmitting }) => {
      const newU = {
        email: values.email,
        fullName: values.fullName,
        profilePicture: files[0]
          ? files[0]?.src
          : "https://res.cloudinary.com/dgpjskmv1/image/upload/v1654815498/users/624eea32faa74a79a806f8e4/e2f853f8232acad78bf143c32f2f3a04.webp.webp",
      };

      userUpdateMutate({ catObj: newU, id: user._id });
    },
  });

  useEffect(() => {
    findUserMutate(user._id);
  }, []);

  const handleResetForm = () => {
    profileFormik.setValues({
      email: user?.email,
      fullName: user?.fullName,
    });
    setFiles(
      user?.profilePicture
        ? [
            {
              progress: false,
              id: user?._id,
              src: user?.profilePicture,
            },
          ]
        : []
    );
  };

  useEffect(() => {
    if (findUserIsSuccess) {
      setUser(findUserData.data.user);
      profileFormik.setValues({
        email: findUserData.data.user.email,
        fullName: findUserData.data.user?.fullName,
      });
      setFiles(
        findUserData.data.user.profilePicture
          ? [
              {
                progress: false,
                id: findUserData.data.user._id,
                src: findUserData.data.user.profilePicture,
              },
            ]
          : []
      );
    }
  }, [findUserIsSuccess]);

  return (
    <form onSubmit={profileFormik.handleSubmit}>
      <Heading size="lg" fontSize="2xl" fontWeight="medium">
      <FormattedMessage id="profileSettings" />

      </Heading>
      <Stack marginTop="24px" spacing="20px">
        <Box
          p="16px"
          bgColor={sectionBgColor}
          width="100%"
          shadow="md"
          rounded="base"
        >
          <Heading size="md" fontSize="xl" fontWeight="medium">
          <FormattedMessage id="generalDetails" />

          </Heading>
          <Stack direction={{ base: "column", xl: "row" }} spacing="25px">
            {/* <Skeleton isLoaded={!userLoading} w="160px"> */}
            <ImageUpload
              height="222px"
              width="222px"
              images={files}
              handleUpload={handleFile}
              handleDelete={handleDelete}
              label="profilePicture"
            />
            {/* </Skeleton> */}
            <Stack direction={"column"} spacing="22px">
              {/* <Skeleton isLoaded={!userLoading} w="full"> */}
              <Input
                label="fullName"
                inputProps={{
                  width: "100%",
                  mt: "7px",
                  placeholder: "fullName",
                  name: "fullName",
                  value: profileFormik.values.fullName,
                  onChange: profileFormik.handleChange,
                }}
              />
              {/* </Skeleton> */}
              {/* <Skeleton isLoaded={!userLoading} w="full"> */}
              {/* <Input
                label="Last name"
                inputProps={{
                  placeholder: "Last name",
                  name: "lastName",
                  value: profileFormik.values.lastName,
                  onChange: profileFormik.handleChange,
                }}
              /> */}
              <Input
                label="Email"
                inputProps={{
                  placeholder: "Email",
                  name: "email",
                  value: profileFormik.values.email,
                  onChange: profileFormik.handleChange,
                }}
                errorMessage={profileFormik.errors.email}
                isError={profileFormik.errors.email !== undefined}
              />
              {/* </Skeleton> */}
            </Stack>
            {/* <Stack direction={"column"} spacing="1px">
              <FormLabel>Answer</FormLabel>
              <Textarea
                // value={q.answer || ""}
                // onChange={(content) =>
                //   homePageFormik.setFieldValue(
                //     `faq[${i}].aq[${index}].answer`,
                //     content.target.value
                //   )
                // }
                placeholder="Answer to this question"
                size="sm"
              />
            </Stack> */}
            {/* <Skeleton isLoaded={!userLoading} w="full"> */}

            {/* </Skeleton> */}
          </Stack>
        </Box>

        <Box textAlign="right">
          <Button
            onClick={handleResetForm}
            leftIcon={<BiReset size={18} />}
            mr={4}
          >
           <FormattedMessage id="reset" />

          </Button>
          <Button
            colorScheme="main"
            type="submit"
            isLoading={userUpdateIsLoading}
          >
          <FormattedMessage id= "save"/>
  
          </Button>
        </Box>
      </Stack>
    </form>
    // </SettingsWrapper>
  );
};

export default ProfileSettings;
