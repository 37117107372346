import { useToast } from "@chakra-ui/react";
import { useMutation } from "@tanstack/react-query";
import { ClientsDataType } from "../Clients";
import {
  BLOGS_FIND_ALL,
  BLOG_CREATE_ONE,
  BLOG_DELETE_ONE,
  BLOG_UPDATE_ONE,
  paginationBlogs,
} from "../endpoints/blogs";
import {
  protectedDelete,
  protectedGet,
  protectedPatch,
  protectedPost,
} from "../http-methods";

export type BlogsDataType = {
  _id: string;
  author: ClientsDataType;
  slug: string;
  image: string;
  title: string;
  description: string;
  content: string;
  createdAt: string;
  updatedAt: string;
  __v: number;
};
export type AddBlogDataType = {
  image?: string;
  title: string;
  description: string;
  content: string;
};
export const useBlogsFindAll = () => {
  return useMutation({
    mutationFn: (obj: paginationBlogs) => {
      return protectedGet(BLOGS_FIND_ALL(obj));
    },
  });
};
export const useBlogsCreateOne = () => {
  const toast = useToast();
  return useMutation({
    mutationFn: (catObj: AddBlogDataType) => {
      return protectedPost(BLOG_CREATE_ONE, catObj);
    },
    onError: (data: any) => {
      console.log("dataError", data.response.data.errors);
      const errors: any = data.response.data.errors;
      if (errors[0]) {
        toast({
          title: errors[0].msg,
          position: `bottom-right`,
          status: "error",
          isClosable: true,
        });
      } else if (errors.title) {
        toast({
          title: "title",
          description: errors.title.msg,
          position: `bottom-right`,
          status: "error",
          isClosable: true,
        });
      } else if (errors.description) {
        toast({
          title: "description",
          description: errors.description.msg,
          position: `bottom-right`,
          status: "error",
          isClosable: true,
        });
      } else if (errors.image) {
        toast({
          title: "image",
          description: errors.image.msg,
          position: `bottom-right`,
          status: "error",
          isClosable: true,
        });
      } else if (errors.content) {
        toast({
          title: "content",
          description: errors.content.msg,
          position: `bottom-right`,
          status: "error",
          isClosable: true,
        });
      }
    },
  });
};
export const useBlogsDeleteOne = () => {
  return useMutation({
    mutationFn: (catObj: string) => {
      return protectedDelete(BLOG_DELETE_ONE, catObj);
    },
  });
};
export const useBlogsUpdateOne = () => {
  const toast = useToast();
  return useMutation({
    mutationFn: ({ catObj, id }: { catObj: AddBlogDataType; id: string }) => {
      return protectedPatch(BLOG_UPDATE_ONE(id), catObj);
    },
    onError: (data: any) => {
      console.log("dataError", data.response.data.errors);
      const errors: any = data.response.data.errors;
      if (errors[0]) {
        toast({
          title: errors[0].msg,
          position: `bottom-right`,
          status: "error",
          isClosable: true,
        });
      } else if (errors.title) {
        toast({
          title: "title",
          description: errors.title.msg,
          position: `bottom-right`,
          status: "error",
          isClosable: true,
        });
      } else if (errors.description) {
        toast({
          title: "description",
          description: errors.description.msg,
          position: `bottom-right`,
          status: "error",
          isClosable: true,
        });
      } else if (errors.image) {
        toast({
          title: "images",
          description: errors.images.msg,
          position: `bottom-right`,
          status: "error",
          isClosable: true,
        });
      } else if (errors.content) {
        toast({
          title: "content",
          description: errors.content.msg,
          position: `bottom-right`,
          status: "error",
          isClosable: true,
        });
      }
    },
  });
};
