import { useQuery } from "@tanstack/react-query";
import { METRICS_FIND_ALL } from "../endpoints/dashbord";
import { protectedGet } from "../http-methods";

export type DashbordDataType = {
    totalbalance: number;
    totalOrders:number;
    successfulTransaction: number;
    failedTransaction: number;
    totalUsers: number;
    totalNewUsers: number;
    verfiedUsers: number;
    businessUsers: number;
  };
  export const useFindAllMetrics = () => {
    return useQuery({
      queryKey: ["FindAllDash"],
      queryFn: () => protectedGet(METRICS_FIND_ALL),
    });
  };