import {
  Box,
  Button,
  Divider,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Skeleton,
  Stack,
  Switch,
  Text,
  Tooltip,
  useColorModeValue,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import axios from "axios";
import { FormikErrors, useFormik } from "formik";
import { ReactNode, useEffect, useState } from "react";
import { BiEdit, BiTrash } from "react-icons/bi";
import { FormattedMessage, useIntl } from "react-intl";
import ReactQuill from "react-quill";
import { useNavigate } from "react-router-dom";
import { productDataType, usePatchOneProducts } from "../../../api/products";
import {
  ColorPicker,
  ImageUpload,
  Input,
  NumberInput,
  SelectInput,
} from "../../../components/commons";
import CategorySelectInput from "../../../components/modules/CategorySelectInput/CategorySelectInput";
import ConfirmDeleteDialog from "../../../components/modules/ConfirmDeleteDialog/ConfirmDeleteDialog";
import Table from "../../../components/modules/Table/Table";
import { TableHeadType } from "../../../components/modules/Table/Table.types";
import TagsSelectInput from "../../../components/modules/TagsSelectInput/TagsSelectInput";
import { getDataFromCache } from "../../../utils/cache";
import { PUBLIC_API_ENDPOINT } from "../../../utils/globalTypes";
import { Attribut, Term } from "../../Categories/Forms/UpdateCategoriesForms";

import FormWrapperLayout from "./FormWrapperLayout";
// import { apolloClient } from "../../..";

type ProductVariationsFormProps = {
  actionButtons: (node: ReactNode) => void;
  productID?: string;
  product?: productDataType;
  validation:()=>void;
};

const ProductVariationsForm = (props: ProductVariationsFormProps) => {
  const { productID, actionButtons, product,validation } = props;

  const variationsFormik = useFormik({
    validateOnMount: false,
    validateOnBlur: false,
    validateOnChange: false,
    initialValues: { variation: product?.variation },
    onSubmit: (values, { setSubmitting }) => {
      productsUpdateMutate({
        catObj: {
          variation:
            values.variation &&
            values.variation.map((variant, index) => ({
              color: variant.color,
              image: variant.image,
              name: variant.name,
            })),
        },
        id: product?._id ? product?._id : "",
      });
     
    },
  });

  const [variations, setvariations] = useState<
    {
      color: string;
      image: string;
      _id: string;
    }[]
  >(product ? product.variation : []);

  const [selectedVariant, setSelectedVariant] = useState<{
    color: string;
    image: string;
    name: string;
    _id: string;
  }>();
  const {
    mutate: productsUpdateMutate,
    data: productsUpdateData,
    isLoading: productsUpdateIsLoading,
    isSuccess: productsUpdateIsSuccess,
    isError: productsUpdateIsError,
    error: productsUpdateError,
  } = usePatchOneProducts();
  const intl = useIntl();
  const navigate = useNavigate();
  useEffect(() => {
    if (productsUpdateIsSuccess) {
      toast({
        title: "Product updated with succcess",
        status: "success",
        position: `bottom-right`,

        isClosable: true,
      });
      validation();
    }
  }, [productsUpdateIsSuccess]);
  const handleDeleteVariation = (id: string) => {};
  useEffect(() => {
    props.actionButtons(
      <>
        <Button
          variant="outline"
          onClick={() =>
            navigate(`/products`, {
              state: {
                pageName: "Products",
              },
            })
          }
        >
          <FormattedMessage id={"discard"} />
        </Button>
        <Button
          colorScheme="main"
          type="submit"
          form="create-product-form"
          isLoading={productsUpdateIsLoading}
          onClick={() => variationsFormik.handleSubmit()}
        >
          <FormattedMessage id={"save"} />
        </Button>
      </>
    );
  }, [variationsFormik.isSubmitting]);

  const optionsTableData =
    (variationsFormik.values.variation &&
      variationsFormik.values.variation.map((variant, index) => ({
        key: variant._id,
        image: (
          <Box className="table_image">
            <img
              src={
                variant.image ||
                "https://www.freeiconspng.com/thumbs/no-image-icon/no-image-icon-4.png"
              }
              alt="product_profile_picture"
            />
          </Box>
        ),
        // selectedOptions: variant.ITEMS.map((e) => <Text>{e.}</Text>),
        name: variant.name ? variant.name : "",
        color: (
          <Box w="80">
            <ColorPicker
              label="Variant color"
              color={variant.color || "#ffffff"}
              setColor={(color) => {
                // console.log("color picked", color);
                variationsFormik.setFieldValue(
                  `variation[${index}].color`,
                  color
                );
              }}
            />
          </Box>
        ),

        action: (
          <Stack
            direction="row"
            display="inline-flex"
            width="fit-content"
            alignItems="center"
            justifyContent="flex-end"
          >
            <Tooltip label="Delete variant" aria-label="A tooltip">
              <IconButton
                aria-label="decline_button"
                size="sm"
                variant="outline"
                colorScheme="red"
                onClick={() => {
                  const newListe = variationsFormik.values?.variation?.filter(
                    (e) => e !== variant
                  );
                  variationsFormik.setFieldValue("variation", newListe);
                  toast({
                    title: "variant has been deleted with succcess",
                    status: "success",
                    position: `bottom-right`,

                    isClosable: true,
                  });
                }}
              >
                <BiTrash size={18} />
              </IconButton>
            </Tooltip>
            <IconButton
              onClick={() => {
                setSelectedVariant(variant);
                onOpenVariationModal();
              }}
              aria-label="edit_button"
              size="sm"
              variant="ghost"
            >
              <BiEdit size={18} />
            </IconButton>
          </Stack>
        ),
      }))) ||
    [];
  type OptionsTableData = typeof optionsTableData[number];
  const toast = useToast();
  const optionsTableHead: TableHeadType<OptionsTableData>[] = [
    { accessor: "image", label: "Image" },
    { accessor: "name", label: "Name" },
    { accessor: "color", label: "Color" },
    { accessor: "action", label: "", headCellProps: { isNumeric: true } },
  ];
  const [loading, setLoading] = useState(false);
  const {
    isOpen: isOpenOptionModal,
    onOpen: onOpenOptionModal,
    onClose: onCloseOptionModal,
  } = useDisclosure();
  const {
    isOpen: isOpenVariationModal,
    onOpen: onOpenVariationModal,
    onClose: onCloseVariationModal,
  } = useDisclosure();
  return (
    <>
      <FormWrapperLayout
        formik={""}
        // loading={variantsProductLoading}
        buttonText={intl.formatMessage({
          id: "variation.add",
        })}
        buttonAction={onOpenOptionModal}
        header={intl.formatMessage({
          id: "product.nav.variations",
        })}
        text={intl.formatMessage({
          id: "variation.des",
        })}
      >
        {/* <Skeleton isLoaded={!loading} opacity={loading ? 0.4 : 1}> */}
        <Table
          head={optionsTableHead}
          data={optionsTableData}
          isLoading={loading}
          emptyState={
            <Box className="empty_table_container">
              <Text fontSize="md" fontWeight="medium">
                {intl.formatMessage({
                  id: "noVariations",
                })}
              </Text>
            </Box>
          }
        />
        {/* </Skeleton> */}
      </FormWrapperLayout>

      <Modal
        isOpen={isOpenOptionModal}
        onClose={onCloseOptionModal}
        isCentered
        size="xl"
        scrollBehavior="inside"
      >
        <ModalOverlay />
        <CreateVariationModal
          onClose={() => {
            setSelectedVariant(undefined);
            onCloseOptionModal();
          }}
          onSubmit={(variation: {
            name: string;
            image: string;
            color: string;
            _id: string;
          }) => {
            const newVariations = variationsFormik.values.variation;
            // console.log("newVariation", newVariations?.concat([variation]));
            variationsFormik.setFieldValue(
              "variation",
              newVariations?.concat([variation])
            );
            toast({
              title: "variant created with succcess",
              status: "success",
              position: `bottom-right`,

              isClosable: true,
            });
            onCloseOptionModal();
          }}
          productID={productID || ""}
          // selectedVariant={selectedVariant}
        />
      </Modal>
      <Modal
        isOpen={isOpenVariationModal}
        onClose={onCloseVariationModal}
        isCentered
        size="xl"
        scrollBehavior="inside"
      >
        <ModalOverlay />
        <UpdateVariationModal
          onClose={() => {
            setSelectedVariant(undefined);
            onOpenVariationModal();
          }}
          onSubmit={(variation: {
            name: string;
            image: string;
            color: string;
            _id: string;
          }) => {
            const newVariations = variationsFormik.values.variation?.filter(
              (e) => e._id !== variation._id
            );
            // console.log("newVariation", newVariations?.concat([variation]));
            variationsFormik.setFieldValue(
              "variation",
              newVariations?.concat([variation])
            );
            toast({
              title: "variant updated with succcess",
              status: "success",
              position: `bottom-right`,

              isClosable: true,
            });
            onCloseVariationModal();
            // variationsFormik.setFieldValue("variation", variationsFormik.values.variation?.push(variation));
          }}
          productID={productID || ""}
          selectedVariant={selectedVariant}
        />
      </Modal>
    </>
  );
};

export default ProductVariationsForm;

type CreateVariationModalProps = {
  onClose: () => void;
  productID: string;
  onSubmit: (variation: {
    image: string;
    color: string;
    _id: string;
    name: string;
  }) => void;
  // selectedVariant?: VariantType;
};
type UpdateVariationModalProps = {
  onClose: () => void;
  productID: string;
  onSubmit: (variation: {
    image: string;
    color: string;
    _id: string;
    name: string;
  }) => void;
  selectedVariant?: {
    name: string;
    color: string;
    image: string;
    _id: string;
  };
};
const CreateVariationModal = (props: CreateVariationModalProps) => {
  const [files, setFiles] = useState<
    {
      progress: boolean;
      id: string;
      src: File | string | undefined;
    }[]
  >([]);

  const handleCloseModal = () => {
    setFiles([]);

    props.onClose();
  };

  const variationsFormik = useFormik({
    validateOnMount: false,
    validateOnBlur: false,
    validateOnChange: false,
    initialValues: { color: "#ffffff", image: "", _id: "", name: "" },

    onSubmit: (values, { setSubmitting }) => {
      values._id = values.image;
      props.onSubmit({
        image: files?.[0]?.src ? (files?.[0]?.src as string) : "",
        color: values.color,
        _id: files?.[0]?.src ? (files?.[0]?.src as string) : "",
        name: values.name,
      });
      setSubmitting(false);
    },
  });

  const handleFile = async (file: File) => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("upload_preset", "ml_default");
    formData.append("public_id", `categories/${file.name}`);
    setFiles((prevFiles) => [
      ...prevFiles,
      {
        src: file,
        id: file.name,
        progress: true,
        name: file.name,
        type: file.type,
      },
    ]);
    const data = await (
      await fetch("https://api.cloudinary.com/v1_1/dgpjskmv1/upload", {
        method: "POST",
        body: formData,
      })
    ).json();

    setFiles((prevFiles) => [
      ...prevFiles.filter(({ id }) => id !== file.name),
      {
        src: data.secure_url,
        id: data.asset_id,
        progress: false,
      },
    ]);
  };

  const handleDelete = (id: string) => {
    // console.log(
    //   id,
    //   files.filter(({ id: imageId }) => id !== imageId)
    // );
    setFiles((prevFiles) =>
      prevFiles.filter(({ id: imageId }) => id !== imageId)
    );
  };

  const intl = useIntl();

  return (
    <ModalContent>
      <ModalHeader borderBottomWidth="1px">
        {intl.formatMessage({
          id: "variation.add",
        })}
      </ModalHeader>
      <ModalCloseButton />
      <ModalBody>
        <form
          id="create-update-variant"
          onSubmit={variationsFormik.handleSubmit}
        >
          <Stack spacing="12px">
            <ImageUpload
              height="120px"
              width={"100%"}
              images={files}
              handleUpload={handleFile}
              handleDelete={handleDelete}
              label={intl.formatMessage({
                id: "image",
              })}
              gridColCount={3}
            />
            <Input
              label={intl.formatMessage({
                id: "name",
              })}
              inputProps={{
                placeholder: intl.formatMessage({
                  id: "name",
                }),
                name: "name",
                value: variationsFormik.values.name,
                onChange: variationsFormik.handleChange,
              }}
            />
            <ColorPicker
              label="Variant color"
              color={variationsFormik.values.color || "#ffffff"}
              setColor={(color) => {
                // console.log("color picked", color);
                variationsFormik.setFieldValue("color", color);
              }}
            />
          </Stack>
        </form>
      </ModalBody>

      <ModalFooter borderTopWidth="1px">
        <Button variant="ghost" mr={3} onClick={handleCloseModal}>
          Discard
        </Button>
        <Button
          type="submit"
          form="create-update-variant"
          colorScheme="main"
          isLoading={variationsFormik.isSubmitting}
        >
          {"Create variation"}
        </Button>
      </ModalFooter>
    </ModalContent>
  );
};
const UpdateVariationModal = (props: UpdateVariationModalProps) => {
  useEffect(() => {
    variationsFormik.setFieldValue("color", props.selectedVariant?.color);
    variationsFormik.setFieldValue("image", props.selectedVariant?.image);
    variationsFormik.setFieldValue("_id", props.selectedVariant?._id);
    setFiles((prevFiles) => [
      ...prevFiles.filter(({ id }) => id !== props.selectedVariant?._id),
      {
        src: props.selectedVariant ? props.selectedVariant.image : "",
        id: props.selectedVariant ? props.selectedVariant._id : "",
        progress: false,
      },
    ]);
  }, [props]);
  const [files, setFiles] = useState<
    {
      progress: boolean;
      id: string;
      src: File | string | undefined;
    }[]
  >([]);

  const handleCloseModal = () => {
    setFiles([]);

    props.onClose();
  };

  const variationsFormik = useFormik({
    initialValues: { color: "#ffffff", image: "", _id: "", name: "" },

    onSubmit: (values, { setSubmitting }) => {
      values._id = values.image;
      props.onSubmit({
        image: files?.[0]?.src
          ? (files?.[0]?.src as string)
          : props.selectedVariant?.image
          ? props.selectedVariant?.image
          : "",
        color: values.color,
        _id: values._id,
        name: values.name,
      });
      setSubmitting(false);
    },
  });

  const handleFile = async (file: File) => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("upload_preset", "ml_default");
    formData.append("public_id", `categories/${file.name}`);
    setFiles((prevFiles) => [
      ...prevFiles,
      {
        src: file,
        id: file.name,
        progress: true,
        name: file.name,
        type: file.type,
      },
    ]);
    const data = await (
      await fetch("https://api.cloudinary.com/v1_1/dgpjskmv1/upload", {
        method: "POST",
        body: formData,
      })
    ).json();

    setFiles((prevFiles) => [
      ...prevFiles.filter(({ id }) => id !== file.name),
      {
        src: data.secure_url,
        id: data.asset_id,
        progress: false,
      },
    ]);
  };

  const handleDelete = (id: string) => {
    // console.log(
    //   id,
    //   files.filter(({ id: imageId }) => id !== imageId)
    // );
    setFiles((prevFiles) =>
      prevFiles.filter(({ id: imageId }) => id !== imageId)
    );
  };
  const intl = useIntl();

  return (
    <ModalContent>
      <ModalHeader borderBottomWidth="1px">
        {intl.formatMessage({
          id: "variation.add",
        })}
      </ModalHeader>
      <ModalCloseButton />
      <ModalBody>
        <form
          id="create-update-variant"
          onSubmit={variationsFormik.handleSubmit}
        >
          <Stack spacing="12px">
            <ImageUpload
              height="120px"
              width={"100%"}
              images={files}
              handleUpload={handleFile}
              handleDelete={handleDelete}
              label={" image"}
              gridColCount={3}
            />
            <Input
              label={intl.formatMessage({
                id: "name",
              })}
              inputProps={{
                placeholder: intl.formatMessage({
                  id: "name",
                }),
                name: "name",
                value: variationsFormik.values.name,
                onChange: variationsFormik.handleChange,
              }}
            />
            <ColorPicker
              label="Variant color"
              color={variationsFormik.values.color || "#ffffff"}
              setColor={(color) => {
                // console.log("color picked", color);
                variationsFormik.setFieldValue("color", color);
              }}
            />
          </Stack>
        </form>
      </ModalBody>

      <ModalFooter borderTopWidth="1px">
        <Button variant="ghost" mr={3} onClick={handleCloseModal}>
          Discard
        </Button>
        <Button
          type="submit"
          form="create-update-variant"
          colorScheme="main"
          isLoading={variationsFormik.isSubmitting}
        >
          {"Create variation"}
        </Button>
      </ModalFooter>
    </ModalContent>
  );
};
