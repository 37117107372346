import { objToParam } from "./categories";

export type paginationContacts = {
    skip?: number;
    limit?: number;
    search?: string;
    createdAt?: 1 | -1;
    updatedAt?: 1 | -1;
  };
  export const CONTACTS_FIND_ALL = (obj: paginationContacts = {}) =>
    `/contacts/findAll${objToParam(obj)}`;