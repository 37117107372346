import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'


import reportWebVitals from "./reportWebVitals";
import {
  ChakraProvider,
  ColorModeScript,
  theme as chakraTheme,
} from "@chakra-ui/react";
import {
  useQuery,
  useQueryClient,
  QueryClient,
  QueryClientProvider,
} from "@tanstack/react-query";
import { Fonts, themeCustomised } from "./global";
import LanguageProvider from "../src/Translation/language.provider";
import { AppProvider } from "./context/appContext";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
const queryClient = new QueryClient();

root.render(
  <React.StrictMode>
    <AppProvider>
    <QueryClientProvider client={queryClient}>
      <LanguageProvider>
      <ChakraProvider theme={themeCustomised}>
          <ColorModeScript
            initialColorMode={themeCustomised.config.initialColorMode}
          />
          <Fonts />
          <App />
        </ChakraProvider>
      </LanguageProvider>
      <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </AppProvider>
  </React.StrictMode>
);

reportWebVitals();
