import {
  Box,
  Button,
  Divider,
  Heading,
  IconButton,
  Link,
  Skeleton,
  Spinner,
  Stack,
  Text,
  useColorModeValue,
  useMediaQuery,
} from "@chakra-ui/react";
import axios from "axios";
import { Fragment, useEffect, useState } from "react";
import {
  BiArrowBack,
  BiChevronDown,
  BiChevronRight,
  BiChevronUp,
  BiPlus,
} from "react-icons/bi";
import { FormattedMessage, useIntl } from "react-intl";
import { useLocation, Link as RouterLink } from "react-router-dom";
import { CategoryDataType, useCategoriesFindAll } from "../../api/categories";
import { getDataFromCache } from "../../utils/cache";

import { PageWrapper } from "../../utils/globalStyle";
import {
  Categories,
  fakeCategories as cat,
  PUBLIC_API_ENDPOINT,
} from "../../utils/globalTypes";
import { CategoriesWrapper } from "./Categories.styles";
import { CreateCategory, UpdateCategory } from "./Forms";

export type Category = {
  CATEGORY_ID: string;
  CATEGORY_NAME: string;
  CATEGORY_ICON: any;
  CATEGORY_IMAGE1: any;
  CATEGORY_IMAGE2: any;
  CATEGORY_COLOR_R: string;
  CATEGORY_COLOR_G: string;
  CATEGORY_COLOR_B: string;
  PARENT_ID: string;
  PARENT_Category: string;
  CATEGORY_DESCRIPTION: string;
  HAS_CHILD: boolean;
};

type CategoriesListDisplayProps = {
  data: CategoryDataType[];
  level?: number;
  fatherId?: string | 0;
  loading?: boolean;
  selected?: CategoryDataType | 0;
  onCategorySelect?: (id: CategoryDataType | 0) => void;
  onCreateCategory?: (id: CategoryDataType | undefined | 0) => void;
  setOpenCatList: any;
};

const CategoriesListDisplay = (props: CategoriesListDisplayProps) => {
  const {
    data,
    onCategorySelect,
    onCreateCategory,
    selected,
    fatherId,
    loading,
    setOpenCatList,
    level,
  } = props;

  const [getNodes, setGetNodes] = useState<CategoryDataType[]>([]);
  const [isMdUp] = useMediaQuery("(min-width: 62em)");
  const borderColor = useColorModeValue("gray.100", "gray.700");

  const [moreCategoryID, setMoreCategoryID] = useState<string>("");

  const handleSelectCategory = (id: CategoryDataType) => {
    onCategorySelect?.(id);
    handleCategoryNodes(id._id);
  };
  const intl = useIntl();
  const handleCategoryNodes = (id: string) => {
    if (id) {
      setMoreCategoryID(id);
    }
    if (moreCategoryID === id) {
      setMoreCategoryID("");
    }
  };
  if (loading)
    return (
      <Stack direction="row" spacing="8px" align="center">
        <Spinner color="blue.400" size="xs" thickness="2px" />{" "}
        <Text color="blue.400" fontWeight="medium" fontSize="sm">
          Loading...
        </Text>
      </Stack>
    );

  return (
    <Stack width="full" minWidth="fit-content" spacing="0">
      {level !== 1 ? (
        <Button
          justifyContent="start"
          size="sm"
          variant="ghost"
          fontSize="14px"
          
          textColor="blue.200"
          fontWeight="normal"
          leftIcon={<BiPlus size={16} />}
          onClick={() => {
            onCreateCategory?.(selected);
            if (!isMdUp) {
              setOpenCatList(false);
            }
          }}
          px={2}
        >
          {intl.formatMessage({
            id: "category.add.sub.msg",
          })}
        </Button>
      ) : (
        ""
      )}
      <Stack spacing="4px" width="full">
        {data?.map((category, index) => (
          <Box key={category?._id}>
            <Stack direction="row" align="center" spacing="0">
              <Button
                justifyContent="start"
                size="sm"
                px={1}
                variant={selected === category ? "solid" : "ghost"}
                fontSize="14px"
                leftIcon={
                  moreCategoryID === category._id ? (
                    <BiChevronUp
                      size={18}
                      opacity={category.hasNodes ? 1 : 0}
                    />
                  ) : (
                    <BiChevronDown
                      size={18}
                      opacity={category.hasNodes ? 1 : 0}
                    />
                  )
                }
                onClick={() => {
                  handleSelectCategory(category || "");
                  if (!category.hasNodes && !isMdUp) {
                    setOpenCatList(false);
                  }
                }}
              >
                {category.name}
              </Button>
            </Stack>
            {moreCategoryID === category._id && (
              <Stack
                direction="row"
                pl={2}
                py={2}
                // spacing={"14px"}
                // w='full'
              >
                <Divider
                  orientation="vertical"
                  color={borderColor}
                  height="inherit"
                />
                <CategoriesListDisplay
                  data={category.nodes}
                  onCategorySelect={onCategorySelect}
                  selected={selected}
                  onCreateCategory={onCreateCategory}
                  fatherId={category._id}
                  setOpenCatList={setOpenCatList}
                />
              </Stack>
            )}
          </Box>
        ))}
      </Stack>
    </Stack>
  );
};

const Categorie = () => {
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const borderColor = useColorModeValue("gray.100", "gray.700");

  const { mutate, data, isLoading, isSuccess, isError, error } =
    useCategoriesFindAll();

  const [categories, setCategories] = useState<CategoryDataType[]>([]);
  const [resetcategories, setResetcategories] = useState(0);
  const [isMdUp] = useMediaQuery("(min-width: 62em)");

  const [openCatList, setOpenCatList] = useState<boolean>(false);

  const ChangeCategories = () => {};
  useEffect(() => {
    if (isSuccess) {
      // console.log("categories", data.data);
      setCategories(data.data.categories);
    }
  }, [isSuccess]);
  useEffect(() => {
    mutate({ level: 1 });
  }, [resetcategories]);
  const [selectedCategory, setSelectedCategory] = useState<
 any
  >(0);

  const [createCategory, setCreateCategory] = useState<boolean>(false);

  const handleCreateCategory = (id?: CategoryDataType | 0) => {
    if (id) {
      setSelectedCategory(id);
    } else {
      setSelectedCategory(0);
    }
    setCreateCategory(true);
  };

  const handleSelectCategory = (id: CategoryDataType | 0) => {
    if (id) {
      setSelectedCategory(id);
    } else {
      setSelectedCategory(0);
      // setCreateCategory(true);
    }
    setCreateCategory(false);
  };
  const handleReset = (action: string,selectedCat?:string|0) => {
    if (action !== "update") {
      setCreateCategory(true);
      setSelectedCategory(0);
      
   
    }
    

    setResetcategories(Math.random() * 2666);
  };

  const { from } = (location?.state as { from?: string }) || "";

  const intl = useIntl();
  return (
    <PageWrapper>
      <Box className="page_header">
        <Box className="page_header_title">
         
          <Box className="page_header_title_content">
            <Heading fontSize="2xl">
              {intl.formatMessage({
                id: "nav.categories",
              })}
            </Heading>
          </Box>
        </Box>
      </Box>
      <CategoriesWrapper
        direction={{ base: "row", lg: "row" }}
        spacing="14px"
        position="relative"
      >
        <Box
          width={{ base: openCatList ? "400px" : "222px", lg: "auto" }}
          //className="categories_list"
          border="1px"
          borderColor={borderColor}
          rounded="base"
          position={!isMdUp ? "absolute" : "relative"}
          top="0"
          left="0"
          zIndex={99}
          // backgroundColor="white"
          padding="16px"
        >
          <Heading
            size="md"
            display="flex"
            justifyContent={openCatList ? "space-between" : "start"}
            alignItems="center"
            fontWeight="medium"
          >
            {intl.formatMessage({
              id: "category.list",
            })}
            {!isMdUp && (
              <IconButton
                marginLeft="20px"
                transform={openCatList ? "rotateZ(234deg)" : "rotateZ(45deg)"}
                onClick={() => {
                  setOpenCatList(!openCatList);
                }}
                borderRadius="50%"
                aria-label="Toggle Categorie List"
              >
                <BiChevronRight size="22" />
              </IconButton>
            )}
          </Heading>
          <Box
            overflow="hidden"
            //border="1px solid red"
            height={!isMdUp ? (openCatList ? "auto" : "0px") : "auto"}
          >
            <Text fontSize="sm" opacity={0.7} mb="12px">
              {intl.formatMessage({
                id: "category.modifyMsg",
              })}
            </Text>

            <Button
              justifyContent="start"
              size="sm"
              variant="ghost"
              fontSize="14px"
            
              textColor="blue.200"
              fontWeight="normal"
              leftIcon={<BiPlus size={16} />}
              onClick={() => {
                setSelectedCategory(0);
                handleSelectCategory(0);
              }}
              px={2}
            >
              {intl.formatMessage({
                id: "category.add.msg",
              })}
            </Button>
            <CategoriesListDisplay
              data={categories}
              setOpenCatList={setOpenCatList}
              loading={isLoading}
              level={1}
              onCategorySelect={handleSelectCategory}
              selected={selectedCategory}
              onCreateCategory={handleCreateCategory}
            />
          </Box>
        </Box>
        <Box
          className="categories_action"
          border="1px"
          borderColor={borderColor}
          rounded="base"
          width="100%"
          margin={isMdUp ? "" : "90px 0 0 0 !important"}
        >
          {selectedCategory && !createCategory ? (
            <UpdateCategory
              isLoading={isLoading}
              selectedCat={selectedCategory}
              onDeleteCategory={handleReset}
            />
          ) : (
            <CreateCategory
              parent={selectedCategory}
              onCategoryCreated={handleReset}
            />
          )}
        </Box>
      </CategoriesWrapper>
    </PageWrapper>
  );
};

export default Categorie;
