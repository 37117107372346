import { objToParam } from "./categories";

export type paginationProducts = {
  skip?: number;
  limit?: number;
  search?: string;
  status?: string;
  category?: string;
  isAvliable?: boolean;
  name?: 1 | -1;
  price?: 1 | -1;
  createdAt?: 1 | -1;
  updatedAt?: 1 | -1;
};
export const PRODUCTS_FIND_ALL = (obj: paginationProducts = {}) =>
  `products/findAll${objToParam(obj)}`;
export const PRODUCT_CREATE_ONE = "products/createOne";
export const PRODUCT_DELETE_ONE = "products/deleteOne";
export const PRODUCT_FIND_ONE = (obj?: string) => `products/findOne/${obj}`;
export const PRODUCT_PATCH_ONE = (id: string) => `products/patchOne/${id}`;
