import {
  Box,
  Button,
  ButtonGroup,
  Card,
  CardBody,
  CardFooter,
  color,
  Divider,
  FormLabel,
  Heading,
  Image,
  Link,
  Radio,
  RadioGroup,
  Select,
  SimpleGrid,
  Skeleton,
  Stack,
  StackDivider,
  Switch,
  Tag,
  Text,
  Textarea,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";
import { FormikErrors, useFormik } from "formik";
import { ReactNode, useEffect, useState } from "react";
import "react-quill/dist/quill.snow.css";
import ReactQuill from "react-quill";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import {
  ColorPicker,
  ImageUpload,
  Input,
  NumberInput,
  SelectInput,
} from "../../../components/commons";
import CategorySelectInput from "../../../components/modules/CategorySelectInput/CategorySelectInput";

import { FormattedMessage, useIntl } from "react-intl";
import {
  productDataType,
  usePatchOneProducts,
  useProductsCreateOne,
} from "../../../api/products";
import {
  ClientsDataType,
  usePatchOneClient,
  userLogDataType,
  useUserLogFindAll,
} from "../../../api/Clients";
import { ImageContainer } from "../../../components/commons/ImageUpload/ImageUpload.styles";
import { AiOutlineEye } from "react-icons/ai";
import moment from "moment";
import { TableHeadType } from "../../../components/modules/Table/Table.types";
import Table from "../../../components/modules/Table/Table";
import { BiStore } from "react-icons/bi";

type UserRelatedProfilesFormProps = {
  loading?: boolean;

  user?: ClientsDataType;
};

const UserRelatedProfilesForm = (props: UserRelatedProfilesFormProps) => {
  const { user, loading } = props;

  const toast = useToast();

  const [profiles, setprofiles] = useState<ClientsDataType[]>();
  useEffect(() => {
    if (user)
      setprofiles(user?.businessAccounts);
      // setprofiles([user,user,user,user]);
  }, [user]);
  const AccountItem = (props: { profile?: ClientsDataType }) => {
    const profile = props;
    return (
      <Card maxW="sm" align="center" height="530px">
        <CardBody>
          <Stack placeItems="center">
            {" "}
            <Image
              src={
                profile?.profile?.profilePicture ||
                "https://www.freeiconspng.com/thumbs/no-image-icon/no-image-icon-4.png"
              }
              alt=""
              borderRadius="full"
              width="200px"
              height="200px"
            />
            <Box>
              <Heading size="md" textTransform="uppercase" mt="2">
                {profile?.profile?.fullName}
              </Heading>
              <Text mt="2" fontSize="sm">
                {profile?.profile?.email}
              </Text>
            </Box>
          </Stack>

          <Stack divider={<StackDivider />} spacing="4">
            <Box>
              <Text>{profile?.profile?.userDescription}</Text>
            </Box>
            {/* <Box>
              {" "}
              {profile?.profile?.links ? (
                <SimpleGrid
                  // mt="3"
                  // mb="3"
                  // display="flex"
                  alignContent={"center"}
                  alignItems={"center"}
                  justifyContent="space-between"
                  p="14px 18px"
                  // flexDirection={{ base: "column", md: "row" }}
                  columns={[1, 2, 2, 4]}
                  gap={5}
                >
                  {profile?.profile?.links.displayLinks.length>1 &&
                    profile?.profile?.links.displayLinks.map((link, index) => link.socialLink?  (
                      <ImageContainer
                        key={link._id}
                        margin="4px !important"
                        position="relative"
                        height="50px"
                        width="50px"
                        border="1px"
                        borderColor={"#ffff"}
                        rounded="base"
                      >
                        {" "}
                        <a href={link.socialLink.url + link.suffix}>
                          {" "}
                          <img
                            src={
                              link.socialLink.icon &&
                              typeof link.socialLink.icon !== "string"
                                ? URL.createObjectURL(link.socialLink.icon)
                                : link.socialLink.icon
                            }
                            alt="preview"
                            className="image_preview"
                          />
                        </a>
                      </ImageContainer>
                    ):(<></>))}
                </SimpleGrid>
              ) : (
                <></>
              )}
            </Box> */}
          </Stack>
        </CardBody>
        <Divider />
        <CardFooter>
          <Link
            color="blue.300"
            target="_blank"
            href={`/users/${profile?.profile?._id}`}
          >
            <Stack direction="row" alignItems="center" spacing="4px">
              <Text whiteSpace="nowrap"><FormattedMessage id={"consultProfile"} /></Text>{" "}
              <AiOutlineEye size={16} />
            </Stack>
          </Link>
        </CardFooter>
      </Card>
    );
  };

  const modules = {
    toolbar: [
      [{ header: "1" }, { header: "2" }, { font: [] }],
      [{ size: [] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link"],
    ],
    clipboard: {
      matchVisual: false,
    },
  };

  const location = useLocation();
  const navigate = useNavigate();

  const intl = useIntl();
  const borderColor = useColorModeValue("gray.100", "gray.700");
  const { from } = (location?.state as { from?: string }) || "";

  return (
    <Box>
      <SimpleGrid
        mt="3"
        mb="3"
        // display="flex"
        alignContent={"center"}
        alignItems={"center"}
        justifyContent="space-between"
        p="14px 18px"
        // flexDirection={{ base: "column", md: "row" }}
        columns={[1, 2,3]}
        gap={5}
      >
        {profiles&&profiles?.map((profile,index)=>(<AccountItem profile={profile} />))}
       
      </SimpleGrid>
    </Box>
  );
};

export default UserRelatedProfilesForm;
