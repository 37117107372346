import {
  Box,
  Button,
  Checkbox,
  Divider,
  Grid,
  Heading,
  IconButton,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  SimpleGrid,
  Skeleton,
  Stack,
  Stat,
  StatHelpText,
  StatLabel,
  StatNumber,
  Tab,
  TabList,
  Tabs,
  Text,
  Tooltip,
  useColorModeValue,
  useDisclosure,
  useToast,
  WrapItem,
} from "@chakra-ui/react";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import {
  BiArrowBack,
  BiBlock,
  BiBriefcase,
  BiFilterAlt,
  BiLinkExternal,
  BiRadioCircleMarked,
  BiSearch,
  BiShowAlt,
  BiChevronDown,
  BiEdit,
  BiTrash,
} from "react-icons/bi";
import { FcApproval, FcCancel, FcMoneyTransfer, FcVip } from "react-icons/fc";
import{FiUserCheck}from "react-icons/fi"
import { Link as RouterLink, useLocation, useNavigate } from "react-router-dom";

import { TableHeadType } from "../../components/modules/Table/Table.types";
import Table from "../../components/modules/Table/Table";
import { PageWrapper } from "../../utils/globalStyle";

import ConfirmDeleteDialog from "../../components/modules/ConfirmDeleteDialog/ConfirmDeleteDialog";
import { FormattedMessage, useIntl } from "react-intl";
import {
  productDataType,
  useFindAllProducts,
  usePatchOneProducts,
  useProductsDeleteOne,
} from "../../api/products";
import { DashbordDataType, useFindAllMetrics } from "../../api/dashbord";
import { GiShoppingCart } from "react-icons/gi";
import { GrUserNew, GrUserExpert } from "react-icons/gr";
import { RiVipCrown2Line } from "react-icons/ri";
import { AiOutlineUserAdd, AiOutlineUsergroupDelete } from "react-icons/ai";

const Dashbord = () => {
  const [status, setStatus] = useState("");
  const bg = useColorModeValue('gray.50', '#182f3e')
  //   const [date, setDate] = useState(moment(Date.now.toString()).format("YYYY/MM/DD"));
  const [date, setDate] = useState(Date.now());
  const [dashbord, setDashbord] = useState<DashbordDataType>();
  const {
    data: dashbordData,
    isLoading: dashbordIsLoading,
    isSuccess: dashbordIsSuccess,
    isError: dashbordIsError,
    error: dashbordError,
  } = useFindAllMetrics();
  const { mutate, data, isLoading, isSuccess, isError, error } =
    useFindAllProducts();
  useEffect(() => {
    mutate({
      skip: 0,
      limit: 5,

      createdAt: -1,
    });
  }, [status]);
  useEffect(() => {
    if (isSuccess) {
      // console.log("products", data.data.products);
      setProducts(data.data.products);
    }
  }, [isSuccess]);
  useEffect(() => {
    if (dashbordIsSuccess) {
      setDashbord(dashbordData.data);
    }
  }, [dashbordIsSuccess]);

  const navigate = useNavigate();

  const [products, setProducts] = useState<productDataType[]>([]);

  const [tabIndex, setTabIndex] = useState<number>(0);
  const location = useLocation();
  const { from } = (location?.state as { from?: string }) || "";
  const handleChangeTabIndex = (index: number) => {
    switch (index) {
      case 1: {
        setStatus("PENDING");
        break;
      }
      case 2: {
        setStatus("PUBLISHED");
        break;
      }
      case 3: {
        setStatus("DECLINED");
        break;
      }
      default:
        setStatus("");
    }

    setTabIndex(index);
  };
  const toast = useToast();

  
  const renderProductStatus = (status: string | null | undefined) => {
    switch (status) {
      case "ARCHIVED":
        return <Text opacity={0.6}><FormattedMessage id={"archived"} />
        </Text>;
      case "DECLINED":
        return <Text color="red.300"><FormattedMessage id={"declined"} /></Text>;
      case "DRAFT":
        return <Text><FormattedMessage id={"draft"} /></Text>;
      case "PENDING":
        return <Text color="yellow.400"><FormattedMessage id={"requested"} /></Text>;
      case "PUBLISHED":
        return (
          <Stack
            direction="row"
            alignItems="center"
            spacing="2px"
            color="green.400"
          >
            <BiRadioCircleMarked />
            <Text><FormattedMessage id={"online"} /></Text>
          </Stack>
        );
    }
  };
  const {
    mutate: productsUpdateMutate,
    data: productsUpdateData,
    isLoading: productsUpdateIsLoading,
    isSuccess: productsUpdateIsSuccess,
    isError: productsUpdateIsError,
    error: productsUpdateError,
  } = usePatchOneProducts();

  useEffect(() => {
    if (productsUpdateIsSuccess)
      toast({
        title: "Product status has bean updates with sucsess",
        position: `bottom-right`,
        status: "success",
        isClosable: true,
      });
  }, [productsUpdateIsSuccess]);
  const handleChangeOrderStatus = (
    status: string,
    id: string,
    index: number
  ) => {
    productsUpdateMutate({
      id: id,
      catObj: { status: status },
    });

    setProducts((prevState) => {
      let temp = [...prevState];
      temp[index].status = status;

      return temp;
    });
  };

  const {
    mutate: deleteProductMutate,
    isSuccess: isSuccessdeleteProductMutate,
  } = useProductsDeleteOne();
  const handleDeleteProduct = (id: string) => {
    deleteProductMutate(id);

    if (isSuccess) {
      toast({
        title: "Product has been deleted with success.",
        status: "success",

        position: "bottom-right",
        duration: 5000,
        isClosable: true,
      });
      mutate({
        skip: 0,
        limit: 5,

        createdAt: 1,
      });
    } else
      toast({
        title: "An error occurred while deleting product.",

        status: "error",

        position: "bottom-right",
        duration: 5000,
        isClosable: true,
      });
  };

  const [selectedProducts, setSelectedProducts] = useState<string[]>([]);

  const handleSelectProduct = (id: string) => {
    setSelectedProducts((prevState) => [...prevState, id]);
  };

  const handleRemoveSelectedProduct = (id: string) => {
    setSelectedProducts((prevState) => {
      let temp = [...prevState];
      const index = temp.indexOf(id, 0);
      if (index > -1) {
        temp.splice(index, 1);
      }
      return temp;
    });
  };
  const productsAdminTableData =
    (products &&
      products.map((product, index) => ({
        key: index,
        image: (
          <Box className="table_image">
            <img
              src={
                product.images?.[0] ||
                "https://www.freeiconspng.com/thumbs/no-image-icon/no-image-icon-4.png"
              }
              alt="product_profile_picture"
            />
          </Box>
        ),
        name: product.name,

        category: product.category && product.category.name,

        basePrice: product.price.price + " TND",

        status: (
          product._id!=="63c40e7c0513c8014979ac77"?( <WrapItem>
            <Menu>
              <MenuButton
                as={Button}
                variant="link"
                rightIcon={<BiChevronDown size={18} />}
                height="fit-content"
              >
                <Heading size="md" fontWeight="semibold" fontSize="lg">
                  {renderProductStatus(products[index].status)}
                </Heading>
              </MenuButton>
              <MenuList>
                {["ARCHIVED", "DRAFT", "PENDING", "PUBLISHED"]
                  .filter((e) => e != product.status)
                  .map((status: string, i: number) => (
                    <MenuItem
                    key={i}
                      onClick={() =>
                        handleChangeOrderStatus(status, product._id, index)
                      }
                    >
                      {renderProductStatus(status)}
                    </MenuItem>
                  ))}
              </MenuList>
            </Menu>
          </WrapItem>):( <Text> {renderProductStatus(product.status)}</Text>)
         
        ),
        creationDate: moment(product.createdAt).format("lll"),

        action: (
          <Stack
            direction="row"
            display="inline-flex"
            width="fit-content"
            alignItems="center"
            justifyContent="flex-end"
          >
            {(product.status === "PUBLISHED" || product.status === "DRAFT") && (
              <>
                {product.status === "PUBLISHED" ? (
                  <Link
                    color="blue.300"
                    target="_blank"
                    href={`https://bitaqa.tn/home/products/${product.slug}`}
                  >
                    <Stack direction="row" alignItems="center" spacing="4px">
                      <Text whiteSpace="nowrap">See in store</Text>{" "}
                      <BiLinkExternal size={16} />
                    </Stack>
                  </Link>
                ) : (
                  <></>
                )}
              </>
            )}
            <ConfirmDeleteDialog
              item="Produit"
              dialogButtonProps={{
                type: "IconButton",

                children: "Delete product",
                props: {
                  icon: <BiTrash size={18} />,
                  color: "red",
                  rounded: "base",
                },
              }}
              onDelete={() => handleDeleteProduct(product?._id)}
            />
            <IconButton
              onClick={() => {
                navigate(`/products/${product._id}`);
              }}
              aria-label="editer le product"
              size="md"
              variant="ghost"
              color="green.400"
            >
              <BiEdit size={18} />
            </IconButton>
          </Stack>
        ),
      }))) ||
    [];
  const intl = useIntl();
  type ProductsAdminTableData = typeof productsAdminTableData[number];
  const [loading, setLoading] = useState(isLoading);
  const productsAdminTableHead: TableHeadType<ProductsAdminTableData>[] = [
    { accessor: "image", label: "" },
    {
      accessor: "name",
      label: intl.formatMessage({
        id: "name",
      }),
    },

    {
      accessor: "category",
      label: intl.formatMessage({
        id: "nav.categories",
      }),
    },

    {
      accessor: "status",
      label: intl.formatMessage({
        id: "status",
      }),
    },
    {
      accessor: "creationDate",
      label: intl.formatMessage({
        id: "createdDate",
      }),
    },
    {
      accessor: "basePrice",
      label: intl.formatMessage({
        id: "basedPrice",
      }),
      headCellProps: { isNumeric: true },
    },
    { accessor: "action", label: "", headCellProps: { isNumeric: true } },
  ];

  return (
    <PageWrapper>
      <Box className="page_header">
        <Box className="page_header_title">
         
          <Box className="page_header_title_content">
            <Heading fontSize="2xl">
              <FormattedMessage id={"statistics"} />
            </Heading>
          </Box>
        </Box>
      </Box>
      <SimpleGrid
        mt="3"
        mb="3"
        // display="flex"
        alignContent={"center"}
        alignItems={"center"}
        justifyContent="space-between"
        p="14px 18px"
        // flexDirection={{ base: "column", md: "row" }}
        columns={[1, 2, 4]}
        gap={5}
      >
        <Box
          height={"167px"}
          mt="3"
          mb="3"
          display="flex"
          alignContent={"center"}
          alignItems={"center"}
          justifyContent="space-between"
          p="14px 18px"
          border="1px"
          borderColor="white"
          rounded="md"
          boxShadow="md"
          transition="0.1s ease-in all"
          _hover={{
            backgroundColor: {bg},
           
            boxShadow: "0 0 0 1px #0b5eda",
            borderColor: "#0b5eda",
          }}
        >
          <Stat>
            <StatLabel>
              <FormattedMessage id={"totalbalance"} />{" "}
            </StatLabel>
            <StatNumber>{dashbord?.totalbalance}</StatNumber>
            <StatHelpText>{moment(date).format("YYYY-MM-DD")}</StatHelpText>
          </Stat>
          <FcMoneyTransfer size={50} />
        </Box>
        <Box
          height={"167px"}
          mt="3"
          mb="3"
          display="flex"
          alignContent={"center"}
          alignItems={"center"}
          justifyContent="space-between"
          p="14px 18px"
          border="1px"
          borderColor="white"
          rounded="md"
          boxShadow="md"
          transition="0.1s ease-in all"
          _hover={{
            backgroundColor: {bg},
           
            boxShadow: "0 0 0 1px #0b5eda",
            borderColor: "#0b5eda",
          }}
        >
          <Stat>
            <FormattedMessage id={"totalOrders"} />{" "}
            <StatNumber>{dashbord?.totalOrders}</StatNumber>
            <StatHelpText>{moment(date).format("YYYY-MM-DD")}</StatHelpText>
          </Stat>
          <GiShoppingCart size={50} />
        </Box>

        <Box
          height={"167px"}
          mt="3"
          mb="3"
          display="flex"
          alignContent={"center"}
          alignItems={"center"}
          justifyContent="space-between"
          p="14px 18px"
          border="1px"
          borderColor="white"
          rounded="md"
          boxShadow="md"
          transition="0.1s ease-in all"
          _hover={{
            backgroundColor: {bg},
           
            boxShadow: "0 0 0 1px #0b5eda",
            borderColor: "#0b5eda",
          }}
        >
          <Stat>
            <FormattedMessage id={"successfulTransaction"} />{" "}
            <StatNumber>{dashbord?.successfulTransaction}</StatNumber>
            <StatHelpText>{moment(date).format("YYYY-MM-DD")}</StatHelpText>
          </Stat>
          <FcApproval size={50} />
        </Box>
        <Box
          height={"167px"}
          mt="3"
          mb="3"
          display="flex"
          alignContent={"center"}
          alignItems={"center"}
          justifyContent="space-between"
          p="14px 18px"
          border="1px"
          borderColor="white"
          rounded="md"
          boxShadow="md"
          transition="0.1s ease-in all"
          _hover={{
            backgroundColor: {bg},
           
            boxShadow: "0 0 0 1px #0b5eda",
            borderColor: "#0b5eda",
          }}
        >
         
          <Stat>
            <FormattedMessage id={"failedTransaction"} />{" "}
            <StatNumber>{dashbord?.failedTransaction}</StatNumber>
            <StatHelpText>{moment(date).format("YYYY-MM-DD")}</StatHelpText>
          </Stat>
          <FcCancel size={50} />
        </Box>
        <Box
          height={"167px"}
          mt="3"
          mb="3"
          display="flex"
          alignContent={"center"}
          alignItems={"center"}
          justifyContent="space-between"
          p="14px 18px"
          border="1px"
          borderColor="white"
          rounded="md"
          boxShadow="md"
          transition="0.1s ease-in all"
          _hover={{
            backgroundColor: {bg},
           
            boxShadow: "0 0 0 1px #0b5eda",
            borderColor: "#0b5eda",
          }}
        >
          <Stat>
            <FormattedMessage id={"totalUsers"} />{" "}
            <StatNumber>{dashbord?.totalUsers}</StatNumber>
            <StatHelpText>{moment(date).format("YYYY-MM-DD")}</StatHelpText>
          </Stat>
          <AiOutlineUsergroupDelete size={50} />
        </Box>
        <Box
          height={"167px"}
          mt="3"
          mb="3"
          display="flex"
          alignContent={"center"}
          alignItems={"center"}
          justifyContent="space-between"
          p="14px 18px"
          border="1px"
          borderColor="white"
          rounded="md"
          boxShadow="md"
          transition="0.1s ease-in all"
          _hover={{
            backgroundColor: {bg},
           
            boxShadow: "0 0 0 1px #0b5eda",
            borderColor: "#0b5eda",
          }}
        >
          <Stat>
            <FormattedMessage id={"totalNewUsers"} />{" "}
            <StatNumber>{dashbord?.totalNewUsers}</StatNumber>
            <StatHelpText>{moment(date).format("YYYY-MM-DD")}</StatHelpText>
          </Stat>
          <AiOutlineUserAdd size={50} />
        </Box>
        <Box
          height={"167px"}
          mt="3"
          mb="3"
          display="flex"
          alignContent={"center"}
          alignItems={"center"}
          justifyContent="space-between"
          p="14px 18px"
          border="1px"
          borderColor="white"
          rounded="md"
          boxShadow="md"
          transition="0.1s ease-in all"
          _hover={{
            backgroundColor: {bg},
           
            boxShadow: "0 0 0 1px #0b5eda",
            borderColor: "#0b5eda",
          }}
        >
          <Stat>
            <FormattedMessage id={"verfiedUsers"} />{" "}
            <StatNumber>{dashbord?.verfiedUsers}</StatNumber>
            <StatHelpText>{moment(date).format("YYYY-MM-DD")}</StatHelpText>
          </Stat>
          <FiUserCheck size={50} />
        </Box>
        <Box
          height={"167px"}
          mt="3"
          mb="3"
          display="flex"
          alignContent={"center"}
          alignItems={"center"}
          justifyContent="space-between"
          p="14px 18px"
          border="1px"
          borderColor="white"
          rounded="md"
          boxShadow="md"
          transition="0.1s ease-in all"
          _hover={{
            backgroundColor: {bg},
           
            boxShadow: "0 0 0 1px #0b5eda",
            borderColor: "#0b5eda",
          }}
        >
          <Stat>
            <FormattedMessage id={"businessUsers"} />{" "}
            <StatNumber>{dashbord?.businessUsers}</StatNumber>
            <StatHelpText>{moment(date).format("YYYY-MM-DD")}</StatHelpText>
          </Stat>
          <RiVipCrown2Line size={50} />
        </Box>
      </SimpleGrid>
      <Box className="page_header_title_content">
        <Heading fontSize="2xl">
          <FormattedMessage id={"newProducts"} />
        </Heading>
      </Box>
      <Table
        head={productsAdminTableHead}
        data={productsAdminTableData}
        isLoading={loading}
        emptyState={
          <Box className="empty_table_container">
            <BiBriefcase size={42} />
            <Text fontSize="md" fontWeight="medium"></Text>
          </Box>
        }
      />
    </PageWrapper>
  );
};

export default Dashbord;
