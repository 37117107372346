import { useToast } from "@chakra-ui/react";
import { useMutation } from "@tanstack/react-query";
import {
  paginationClients,
  USERPASSWORD_PATCH_ONE,
  USERPROFILES_PATCH_ONE,
  USERS_CREATE_ONE,
  USERS_DELETE_ONE,
  USERS_FIND_ALL,
  USERS_FIND_ONE,
  USERS_PATCH_ONE,
  USER_LINK_LOG_FIND_ALL,
  USER_LOG_FIND_ALL,
} from "../endpoints/clients";
import {
  protectedDelete,
  protectedGet,
  protectedPatch,
  protectedPost,
} from "../http-methods";
export type userLogDataType = {
  _id: string;
  user: string;
  ip: string;
  deviceType: string;
  os: string;
  browserType: string;
  browser: string;
  region: string;
  createdAt: string;
  updatedAt: string;
  __v: number;
};
export type ProfileDataType = {
  isPublic: boolean;
  isActive: boolean;
  isArchived: boolean;
  isVerfied: boolean;
  isBusiness: boolean;
  isConfirmed: boolean;
  isBlocked: boolean;
  _id: string;
  linkedTo: string;
  username: string;
  createdAt: string;
  updatedAt: string;
  __v: number;
};
export type UpdateProfileDataType = {
  isPublic?: boolean;
  isActive?: boolean;
  isArchived?: boolean;
  isVerfied?: boolean;
  isBusiness?: boolean;
  isConfirmed?: boolean;
  isBlocked?: boolean;
  _id?: string;
  linkedTo?: string;
  username?: string;
  createdAt?: string;
  updatedAt?: string;
  __v?: number;
};

export type ClientsDataType = {
  businessAccounts: ClientsDataType[];
  fullName: string;
  userDescription: string;
  profilePicture: string;
  links: {
    displayType: string;
    _id: string;
    displayLinks:
      | {
          _id: string;
          socialLink: {
            name: string;
            url: string;
            isActive: boolean;
            icon: string;
            _id: string;
          };
          suffix: string;
          customIcon: string;
          isCustom: boolean;
          indexInList: number;
          createdAt: string;
          updatedAt: string;
          __v: number;
        }[]
      | [];
    createdAt: string;
    updatedAt: string;
    __v: number;
  };
  _id: string;
  email: string;
  firstName: string;
  lastName: string;
  createdAt: string;
  updatedAt: string;
  __v: number;
  profileSettings: ProfileDataType;
};
export type UpdateClientsDataType = {
  userDescription?: string;
  fullName?: string;
  profilePicture?: File | string;
  links?: string;
  _id?: string;
  email?: string;
  firstName?: string;
  lastName?: string;
  createdAt?: string;
  updatedAt?: string;
  password?: string;
  __v?: number;
  profileSettings?: {
    isPublic?: boolean;
    isActive?: boolean;
    isArchived?: boolean;
    isConfirmed?: boolean;
    isBusiness?: boolean;
    isBlocked?: boolean;
    isVerfied?: boolean;
    _id?: string;
    linkedTo?: string;
    username?: string;
    createdAt?: string;
    updatedAt?: string;
    __v?: number;
  };
};
export const useUsersDeleteOne = () => {
  return useMutation({
    mutationFn: (catObj: string) => {
      return protectedDelete(USERS_DELETE_ONE, catObj);
    },
  });
};
export type createClientDataType = {
  email: string;
  password: string;
  fullName: string;
  userDescription: string;
  profilePicture?: string;
};

export const useClientsFindAll = () => {
  return useMutation({
    mutationFn: (obj: paginationClients) => {
      return protectedGet(USERS_FIND_ALL(obj));
    },
  });
};
export const useUserLogFindAll = () => {
  return useMutation({
    mutationFn: (obj: paginationClients) => {
      return protectedGet(USER_LOG_FIND_ALL(obj));
    },
  });
};
export const useUserLinkLogFindAll = () => {
  return useMutation({
    mutationFn: (obj: {user?:string}) => {
      return protectedGet(USER_LINK_LOG_FIND_ALL(obj));
    },
  });
};
export const useClientsCreateOne = () => {
  const toast = useToast();
  return useMutation({
    mutationFn: (catObj: createClientDataType) => {
      return protectedPost(USERS_CREATE_ONE, catObj);
    },
    onError: (data: any) => {
      console.log("dataError", data.response.data.errors);
      const errors: any = data.response.data.errors;
      if (errors[0]) {
        toast({
          title: errors[0].msg,
          position: `bottom-right`,
          status: "error",
          isClosable: true,
        });
      }
      // else {
      //   for (let i = 0; i < params.length; i++) {
      //     if (`errors?.${params[i]}`) {
      //       toast({
      //         title: `errors?.${params[i]}`,
      //         position: `bottom-right`,
      //         status: "error",
      //         isClosable: true,
      //       });
      //     }
      //   }
      // }
      else if (errors.email) {
        toast({
          title: "email",
          description: errors.email.msg,
          position: `bottom-right`,
          status: "error",
          isClosable: true,
        });
      } else if (errors.password) {
        toast({
          title: "password",
          description: errors.password.msg,
          position: `bottom-right`,
          status: "error",
          isClosable: true,
        });
      } else if (errors.fullName) {
        toast({
          title: "fullName",
          description: errors.fullName.msg,
          position: `bottom-right`,
          status: "error",
          isClosable: true,
        });
      } else if (errors.profilePicture) {
        toast({
          title: "profilePicture",
          description: errors.profilePicture.msg,
          position: `bottom-right`,
          status: "error",
          isClosable: true,
        });
      } else if (errors.userDescription) {
        toast({
          title: "userDescription",
          description: errors.userDescription.msg,
          position: `bottom-right`,
          status: "error",
          isClosable: true,
        });
      }
    },
  });
};
export const useClientsFindOne = () => {
  return useMutation({
    mutationFn: (catObj?: string) => {
      return protectedGet(USERS_FIND_ONE(catObj));
    },
  });
};
export const usePatchOneClient = () => {
  const toast = useToast();
  return useMutation({
    mutationFn: ({
      id,
      catObj,
    }: {
      id: string;
      catObj: UpdateClientsDataType;
    }) => {
      return protectedPatch(USERS_PATCH_ONE(id), catObj);
    },
    onError: (data: any) => {
      console.log("dataError", data.response.data.errors);
      const errors: any = data.response.data.errors;
      if (errors[0]) {
        toast({
          title: errors[0].msg,
          position: `bottom-right`,
          status: "error",
          isClosable: true,
        });
      }
      // else {
      //   for (let i = 0; i < params.length; i++) {
      //     if (`errors?.${params[i]}`) {
      //       toast({
      //         title: `errors?.${params[i]}`,
      //         position: `bottom-right`,
      //         status: "error",
      //         isClosable: true,
      //       });
      //     }
      //   }
      // }
      else if (errors.email) {
        toast({
          title: "email",
          description: errors.email.msg,
          position: `bottom-right`,
          status: "error",
          isClosable: true,
        });
      } else if (errors.password) {
        toast({
          title: "password",
          description: errors.password.msg,
          position: `bottom-right`,
          status: "error",
          isClosable: true,
        });
      } else if (errors.fullName) {
        toast({
          title: "fullName",
          description: errors.fullName.msg,
          position: `bottom-right`,
          status: "error",
          isClosable: true,
        });
      } else if (errors.profilePicture) {
        toast({
          title: "profilePicture",
          description: errors.profilePicture.msg,
          position: `bottom-right`,
          status: "error",
          isClosable: true,
        });
      } else if (errors.userDescription) {
        toast({
          title: "userDescription",
          description: errors.userDescription.msg,
          position: `bottom-right`,
          status: "error",
          isClosable: true,
        });
      }
    },
  });
};
export const usePatchOneUserProfile = () => {
  return useMutation({
    mutationFn: ({
      id,
      catObj,
    }: {
      id: string;
      catObj: UpdateProfileDataType;
    }) => {
      return protectedPatch(USERPROFILES_PATCH_ONE(id), catObj);
    },
  });
};
export const usePatchOneUserPassword = () => {
  const toast = useToast();
  return useMutation({
    mutationFn: ({
      
      catObj
    }: {
      
      catObj: {oldPassword:string,password:string,};
    }) => {
      return protectedPatch(USERPASSWORD_PATCH_ONE, catObj); 
    },   onError: (data: any) => {
      console.log("dataError", data.response.data.errors);
      const errors: any = data.response.data.errors;
      if (errors[0]) {
        toast({
          title: errors[0].msg,
          position: `bottom-right`,
          status: "error",
          isClosable: true,
        });
      }
      
      else if (errors.oldPassword) {
        toast({
          title: "oldPassword",
          description: errors.oldPassword.msg,
          position: `bottom-right`,
          status: "error",
          isClosable: true,
        });
      }  else if (errors.password) {
        toast({
          title: "password",
          description: errors.password.msg,
          position: `bottom-right`,
          status: "error",
          isClosable: true,
        });
      } 
    },
  });
};